import { LoaderService } from 'src/app/_services/loader.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BaseRequestService } from '../../_services/base.service';
import { IconService } from '../../_services/icon.service';
import { debounceTime, filter, map } from 'rxjs/operators';
import { NotificationService } from '../../_services/notification.service';
import { MyToastrService } from '../../_services/toastr.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { ModalService } from '../../_services/modal.service';
import { trackByKey, trackById } from '../../../@vex/utils/track-by';
import tinykeys from 'tinykeys';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'vex-return-order',
  templateUrl: './return-order.component.html',
  styleUrls: ['./return-order.component.scss']
})
export class ReturnOrderComponent implements OnInit {
  objectKeys = Object.keys;
  reasonHash: any = [];
  constructor(
    public ic: IconService,
    public baseService: BaseRequestService,
    private loaderService: LoaderService,
    private notifyService: NotificationService,
    private toast: MyToastrService,
    public authService: AuthenticationService,
    public confirmDialog: ConfirmDialogService,
    public modalService: ModalService,
    private httpClient: HttpClient
  ) {
    tinykeys(window, {
      '$mod+KeyB': (event) => {
        event.preventDefault();
        this.toggleSearch();
      },
    });
    this.invoiceNumberInput.pipe(debounceTime(1200)).subscribe({
      next: (res) => {
        if (!this.isSKOrder) {
          this.getSavedDraft();
        }
      },
    });
    this.barcodeSearchInput.pipe(debounceTime(1200)).subscribe({
      next: (res) => {
        this.fromBarcodeSearch = false;
        if ( res.length > 2) {
          this.loaderService.display(true, 'Searching Products...');
          if (res.slice(0, 3).includes('890')) {
            this.searchGRNProducts(res);
          } else {
            this.searchGRNProducts(res);
          }
        }
      },
    });
  }

  @Output() bview = new EventEmitter<string>();
  @Input() orders;
  @ViewChild('searchText', { static: true }) searchTextCur: ElementRef;
  masterInvProds: any = {};
  viewcurrOrder: any;
  currentReturnProducts: any = [];
  reasons: any = {
    Damage: '1',
    Shortage: '2',
    'MRP Difference': '3',
    'Product Mismatch/Wrong Product': '4',
    'Free product Not received': '5',
    'Wrong Order Received': '6',
    DOA: '7',
    'Accessories Issue': '8',
    'Device Not working': '9',
    'Expired Products': '10',
    'Box Condition Good - Product Damaged': '1',
    'Box Damaged - Product Damaged': '2',
    'Box Condition Good - Product Shortage': '1',
    'Box Damaged - Product Shortage': '2'
  };
  recieved: any = [];
  isLoading = false;
  isSubmitted = false;
  isInvoiceDateReadOnly = false;
  grnBy = '';
  returnBy = '';
  search = '';
  invoiceDate = '';
  invoiceNumber = '';
  zeromrp = false;
  invQty = false;
  orderInvMisMatch = false;
  taxNotExists = false;
  freeQtyList: any = [];
  zeromrpList: any = [];
  invQtyList: any = [];
  orderInvMisMatchList: any = [];
  taxNotExistsList: any = [];
  currentDocument: any = [];
  invoiceDocLink: any = [];
  isSKOrder = false;
  barCodeScan: Subject<any> = new Subject();
  barcodeSearchInput: Subject<string> = new Subject();
  invoiceNumberInput: Subject<string> = new Subject();
  searchProducts: any = [];
  fromBarcodeSearch = false;
  newTaxTypeSelected = false;
  curSelecedTaxType = '';
  filteredProducts: any = [];
  trackById = trackById;
  trackByKey = trackByKey;
  isSearch = false;
  invoiceProdHash: any = {};
  selectedReason = '';
  subReasonList: any = [];
  selectedSubReason = '';
  freeProdMismatch = false;
  orderIDMismatch = false;
  MRPSelected = false;

  dealUploadNotAvailable = false;
  podImgNotAvailable = false;
  videoNotAvailable = false;
  dealUploadNAList: any = [];
  podImgNAList: any = [];
  videoNAList: any = [];
  skOrderId = '';
  currentInvoice: any;
  returnGotClicked = false;
  idGenerator = this.createObjectIdGenerator();


  searchGRNProducts(search): void {
    const searchP = this.masterInvProds[this.invoiceNumber].filter((x: any) => (x.productName.toLowerCase().indexOf(search.toLowerCase()) > -1));
    searchP.forEach((obj: any) => {
      const prod = this.orders.orderedProducts.filter((p) => {
        if (p.productId === obj.productId) {
          return true;
        }
      });
      delete prod[0].inventoryid;
      obj =  Object.assign(obj, prod[0]);
    });

    if (searchP && searchP.length) {
      searchP.forEach((prod: any) => {
        let invProd: any;
        invProd = Object.assign({}, prod  );
        invProd.actualMRP = 0;
        invProd.freeProdName = '';
        invProd.freeProdQty = 0;
        invProd.wrongOrderID = '';
        invProd.selectedSubReasonText = '';
        invProd.isSubReasonSelected = false;
        invProd.subReasonList = [];
        this.addToReturn(invProd);
      });
    } else {
      this.notifyService.notification('Product not available with search text!', false, 'error');
    }
    this.loaderService.display(false);
  }

  updateSubReason($event: any, i: number): void {
    this.currentReturnProducts.subreason = (this.reasonHash[$event] && this.reasonHash[$event].length) ? this.reasonHash[$event][0] : '';
  }

  handleBarcodeSearch(res) {
    this.loaderService.display(true, 'Fetching Product');
    const params = {
      name: res,
      category: '',
      inventoryData: false,
      subcategory: '',
      assignedProducts: true,
      skip: 0,
      limit: 1,
    };
    this.baseService
      .doRequest('/api/productmaster/fetchProducts', 'post', params)
      .subscribe((resp) => {
        if (resp && resp.data && resp.data.length) {
          resp.data.forEach((val) => {
            const arr = this.orders.orderedProducts
              .filter((item) => {
                if (item.id === val._id) {
                  return true;
                }
              });
            if (arr && arr.length) {
              this.searchProducts = arr;
              this.fromBarcodeSearch = true;
              return;
            }
          });
          this.loaderService.display(false);
          this.processBarC(res);
        } else {
          this.searchProducts = [];
          this.processBarC(res);
        }
      });
  }

  handleStringSearch(res) {
    this.searchProducts = this.orders.orderedProducts.filter(
      (item: any) =>
        (item.barCodes &&
          item.barCodes
            .toString()
            .toLowerCase()
            .includes(res.toLowerCase())) ||
        item.productName.toLowerCase().includes(res.toLowerCase()));
    this.fromBarcodeSearch = false;
    this.processBarC(res);
  }

  processBarC(res) {
    if (
      this.orders.invoices &&
      this.orders.invoices.length &&
      this.invoiceNumber
    ) {
      let invProds = [];
      const prodIds = this.invoiceProdHash[this.invoiceNumber]
        ? this.invoiceProdHash[this.invoiceNumber].map((a) => a.productId)
        : [];
      const skProducts = this.invoiceProdHash[this.invoiceNumber]
        ? this.invoiceProdHash[this.invoiceNumber].filter(
          (item: any) =>
            item.barCodes &&
            item.barCodes
              .toString()
              .toLowerCase()
              .includes(res.toLowerCase())
        )
        : [];
      if (skProducts.length && skProducts.length === 1) {
        invProds = this.orders.orderedProducts.filter(
          (item: any) => item.productId === skProducts[0].productId
        );
        invProds[0].barCodes = this.invoiceProdHash[
          this.invoiceNumber
        ].filter(
          (a) => a.productId === invProds[0].productId
        )[0].barCodes;
        invProds[0].mrp = this.invoiceProdHash[this.invoiceNumber].filter(
          (a) => a.productId === invProds[0].productId
        )[0].mrp;
        invProds[0].recievedQty = this.invoiceProdHash[
          this.invoiceNumber
        ].filter(
          (a) => a.productId === invProds[0].productId
        )[0].invoicequantity;
        invProds[0].hsn = invProds[0].hsn
          ? invProds[0].hsn
          : this.invoiceProdHash[this.invoiceNumber].filter(
            (a) => a.productId === invProds[0].productId
          )[0].hsn;
      } else if (skProducts.length && skProducts.length > 1) {
        this.orders.orderedProducts.forEach((item: any) => {
          if (prodIds.includes(item.productId)) {
            item.barCodes = this.invoiceProdHash[
              this.invoiceNumber
            ].filter((a) => a.productId === item.productId)[0].barCodes;
            item.mrp = this.invoiceProdHash[this.invoiceNumber].filter(
              (a) => a.productId === item.productId
            )[0].mrp;
            item.recievedQty = this.invoiceProdHash[
              this.invoiceNumber
            ].filter(
              (a) => a.productId === item.productId
            )[0].invoicequantity;
            item.hsn = item.hsn
              ? item.hsn
              : this.invoiceProdHash[this.invoiceNumber].filter(
                (a) => a.productId === item.productId
              )[0].hsn;
            invProds.push(item);
          }
        });
      } else {
        this.searchProducts.forEach((item: any) => {
          if (prodIds.includes(item.productId)) {
            item.barCodes = this.invoiceProdHash[
              this.invoiceNumber
            ].filter((a) => a.productId === item.productId)[0].barCodes;
            item.mrp = this.invoiceProdHash[this.invoiceNumber].filter(
              (a) => a.productId === item.productId
            )[0].mrp;
            item.recievedQty = this.invoiceProdHash[
              this.invoiceNumber
            ].filter(
              (a) => a.productId === item.productId
            )[0].invoicequantity;
            item.hsn = item.hsn
              ? item.hsn
              : this.invoiceProdHash[this.invoiceNumber].filter(
                (a) => a.productId === item.productId
              )[0].hsn;
            invProds.push(item);
          }
        });
      }
      this.searchProducts = invProds;
    }
    try {
      if (this.searchProducts.length === 1) {
        const ids = this.currentReturnProducts.map((item: any) => item.id);
        if (ids.indexOf(this.searchProducts[0].id) > -1) {
          this.notifyService.notification(
            'Product already added',
            false,
            'info'
          );
        } else {
          this.searchProducts[0].recievedQty =
            this.searchProducts[0].receivedQuantity;
          this.addToReturn(this.searchProducts[0]);
          this.search = '';
          this.clearSearch();
        }
      } else if (this.searchProducts.length > 1 && res !== '') {
        this.searchProducts.forEach((obj: any) => {
          obj.recievedQty = (obj.recievedQty) ? obj.recievedQty : obj.receivedQuantity;
        });
        this.fromBarcodeSearch = false;
        this.modalService.open('filteredItems');
        this.filteredProducts = this.searchProducts;
      } else {
        this.notifyService.notification(
          'No products matching in ordered/invoice list',
          false,
          'info'
        );
      }
      this.loaderService.display(false);
    } catch (e) {
      console.log('error', e);
      this.loaderService.display(false);
    }
    this.loaderService.display(false);
    return;
  }

  toggleSearch(force?: boolean) {
    this.search = '';
    this.isSearch = force ? false : !this.isSearch;
  }

  clearSearch(): void {
    this.search = '';
    this.filteredProducts = [];
    setTimeout(() => {
      document.getElementById('search').focus();
      this.searchTextCur.nativeElement.focus();
    }, 1500);
  }

  onChange(val): void {
    this.barcodeSearchInput.next(val);
    document.getElementById('search').focus();
    this.searchTextCur.nativeElement.focus();
  }

  onUpdate(val): void {
    this.invoiceNumberInput.next(val);
    if (this.orders.invoices && this.orders.invoices.length) {
      this.populateInvoiceDoclinks(this.invoiceNumber);
      this.populateInvoiceDate(this.invoiceNumber);
      this.isSKOrder = true;
      const invoice = this.orders.invoices.filter((obj) => {
        if (obj.invoiceNo === this.invoiceNumber) {
          return true;
        }
      });
      if (invoice && invoice.length === 1) {
        this.currentInvoice = invoice[0]; const opHash = {};
        this.currentInvoice.invoiceproducts.forEach((op: any) => {
          if (!opHash[op.inventoryid]) {
            opHash[op.inventoryid] = op;
          } else {
            opHash[op.inventoryid].recievedQty += op.recievedQty;
          }
        });
        this.currentReturnProducts = [];
        this.skOrderId = invoice[0].orderId;
        this.objectKeys(opHash).forEach((obj: any) => {
          const item = opHash[obj];
          let invProd: any = {};
          const prod = this.orders.orderedProducts.filter((p) => {
            if (p.id === item.productId) {
              return true;
            }
          });
          if (prod && prod.length) {
            invProd = Object.assign(prod[0], item);
            invProd.reason = invProd.reason;
            invProd.subreason = invProd.subreason;
            invProd.actualMRP = 0;
            invProd.freeProdName = '';
            invProd.freeProdQty = 0;
            invProd.wrongOrderID = '';
            invProd.selectedSubReasonText = '';
            invProd.isSubReasonSelected = false;
            invProd.subReasonList = [];
            this.addToReturn(invProd);
          }
        });
        const data = {
          query: {
            bool: {
              must: [{match: {'_type_.keyword': 'returnorder'}}, {match: {'invoiceid.keyword': this.currentInvoice.invoiceNo}},
                {match: {'grnNo.keyword': this.currentInvoice.grnNo}}, {match: {'returngrnstatus.keyword': 'Draft'}}]
            }
          }
        };
        this.baseService.doRequest('/api/returnorder', 'get', null, {q: data, skip: 0, limit: 100}).subscribe((res: any) => {
          if (res && res.data) {
            const draftProducts = [];
            res.data[0].draftproducts.forEach((obj: any) => {
              this.currentReturnProducts.forEach((a: any, i: number) => {
                if (obj.inventoryid === a.inventoryid) {
                  obj.claimQty = obj.claimQuantity;
                  obj.reason = obj.reason;
                  obj.subreason = obj.subreason;
                  obj.remark = obj.remarks;
                  this.currentReturnProducts[i].dealupload = (obj.deal_upload);
                  this.currentReturnProducts[i].podImage = (obj.pod_image);
                  this.currentReturnProducts[i].video = (obj.video);
                  this.currentReturnProducts[i].assetAvailable = (obj.deal_upload || obj.pod_image || obj.video) ? true : false;
                  this.currentReturnProducts[i] = Object.assign(a, obj);
                  draftProducts.push(this.currentReturnProducts[i]);
                }
              });
            });
            if (draftProducts.length) {
              this.currentReturnProducts = draftProducts;
            }
          }
        });
      } else {
        if (this.invoiceNumber && this.invoiceNumber !== '') {
          this.toast.sToast('error', 'Error occurred. Found more than one invoices with same invoice number.');
        }
      }
    }
  }

  populateInvoiceDoclinks(invNumber) {
    if (invNumber) {
      const invoiceDetails = this.orders.invoices.filter((val) => {
        if (val.invoiceNo === invNumber) {
          return true;
        }
      });
      if (invoiceDetails && invoiceDetails.length === 1) {
        this.invoiceDocLink = invoiceDetails;
      }
    }
  }

  populateInvoiceDate(invNumber) {
    if (invNumber) {
      const invoiceDetails = this.orders.invoices.filter((val) => {
        if (val.invoiceNo === invNumber) {
          return true;
        }
      });
      if (invoiceDetails && invoiceDetails.length === 1) {
        if (invoiceDetails[0] && invoiceDetails[0].invoicedate) {
          this.invoiceDate = invoiceDetails[0].invoicedate.split('T')[0];
          this.isInvoiceDateReadOnly = true;
        } else {
          this.toast.sToast('info', 'Did not find invoice date.');
        }
      }
    }
  }

  ngOnInit(): void {
    /*if (this.orders && this.orders.invoices) {
      this.orders.invoices.forEach((a) => {
        this.invoiceProdHash[a.invoiceNo] = a.invoiceproducts;
      });
    }*/
    if (this.authService.currentUser.email) {
      this.returnBy = this.authService.currentUser.email;
    }
    this.getReasonSubReason();
    this.initReturn();
    this.isLoading = false;
    this.getGRNData();
  }

  getReasonSubReason(): void {
    this.loaderService.display(true, 'Loading reason sub-reasons...');
    this.baseService.doRequest('/api/returnreason/getReturnReason', 'post', {}).subscribe((result: any) => {
      this.loaderService.display(false);
      this.reasonHash = {};
      result.data.forEach((obj: any) => {
        this.reasonHash[obj.reason] = obj.subReason;
      });
    });
  }

  addToReturn(_product: any) {
    const product = Object.assign({}, _product);
    const ids = this.currentReturnProducts.map((item: any) => item.inventoryid);
    if (ids.indexOf(product.inventoryid) > -1) {
      this.notifyService.notification('Product already added', false, 'info');
    } else if (product.hsn === null || !product.hsn || product.hsn === '') {
      this.notifyService.notification(
        'HSN not found for this product - ' + product.productName,
        false,
        'error'
      );
    } else if (product.tax === null || !product.tax || product.tax === '') {
      this.notifyService.notification(
        'Tax not found for this product - ' + product.productName,
        false,
        'error'
      );
    } else {
      if (product && product.barCodes && product.barCodes.length === 1) {
        product.barCode = product.barCodes[0];
      }
      if (product && product.barCodes && product.barCodes.length >= 1 && this.isSKOrder) {
        product.barCode = product.barCodes[0];
      }
      if (product.orderQuantity && product.unitsize && product.recievedQty
          && ((product.orderQuantity * product.unitsize) === product.recievedQty)) {
        product.completedStatus = 'completed';
      } else {
        product.completedStatus = 'not_completed';
      }
      if (this.fromBarcodeSearch && !this.isSKOrder) {
        let tmpSearch = '';
        tmpSearch = this.search;
        product.barCode = tmpSearch;
      } else if (!this.fromBarcodeSearch && !this.isSKOrder) {
        product.barCode = '';
        if (product.completedStatus && product.completedStatus === 'not_completed') {
          setTimeout(() => {
            this.toggleSearch(true);
            const el = document.getElementById('barcode0');
            el.focus();
          }, 30);
        }
      }
      if (this.newTaxTypeSelected) {
        if (product.completedStatus && product.completedStatus !== 'completed') {
          product.taxType = this.curSelecedTaxType;
        }
      }
      product.assetAvailable = false;
      product.imgAssets = [];
      product.videoAssets = [];
      this.currentReturnProducts.push(product);
      if (!this.isSKOrder) {
        this.notifyService.notification(
          product.productName + ', added, Scan next',
          false,
          'success'
        );
      }
      this.modalService.close('filteredItems');
      this.clearSearch();
      this.fromBarcodeSearch = false;
      this.currentReturnProducts.sort((a: any, b: any) => {
        const c = a.productName.toLowerCase();
        const d = b.productName.toLowerCase();
        if (c < d) {
          return -1;
        } else if (c > d) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  getSavedDraft() {
    this.loaderService.display(true, 'Checking draft...');
    let params: any = {};
    params = {
      q: {
        query: {
          bool: {
            must: [
              { match: { 'orderId.keyword': `${this.orders._id}` } },
              // { match: { "grnstatus.keyword": "Draft" } },
            ],
          },
        },
      },
      skip: 0,
      limit: 999,
    };
    if (this.invoiceNumber) {
      params.q.query.bool.must.push({
        match: { 'invoiceNumber.keyword': `${this.invoiceNumber}` },
      });
    }
    this.baseService
      .doRequest('/api/goodsreceivednote', 'get', null, params)
      .subscribe((res) => {
        this.loaderService.display(false);
        if (res && res.data && res.data[0]) {
          this.initReturn(res.data[0]);
        }
      });
  }

  getGRNData(): void {
    this.loaderService.display(true, 'Getting GRN data...');
    this.baseService
        .doRequest('/api/goodsreceivednote', 'get', null, {
          q: {
            query: {
              bool: {
                must: [{ match: { 'orderId.keyword': `${this.orders._id}` } }, { match: { 'grnstatus.keyword': 'Saved' } }],
              },
            },
          },
          skip: 0,
          limit: 999,
        })
        .subscribe((res) => {
          this.loaderService.display(false);
          if (res.data && res.data.length > 0) {
            this.orders.invoices = [];
            res.data.forEach((obj: any) => {
              const products: any = [];
              obj.productData.forEach((prod: any) => {
                if (!prod.returnProduct) {
                  prod.productId = prod.productmaster_ref.id;
                  prod.productName = prod.productmaster_ref.name;
                  products.push(prod);
                }
              });
              this.masterInvProds[obj.invoiceNumber] = products;
              this.orders.invoices.push(
                  {
                    invoiceFileLink: (obj.doclinks[0]) ? obj.doclinks[0].doc : '',
                    invoiceFileName: (obj.doclinks[0]) ? obj.doclinks[0].fileName : '',
                    invoiceNo: obj.invoiceNumber,
                    invoiceamount: obj.invoiceAmount,
                    invoicedate: obj.invoiceDate,
                    grnNo: obj.grnNo,
                    invoiceproducts: products,
                    orderId: this.orders.orderID,
                  }
              );
            });
            this.viewcurrOrder = res.data;
          } else {
            this.toast.sToast('error', 'No GRNs Found.');
          }
        });
  }

  initReturn(sourceData?: any): void {
    if (sourceData) {
      this.invoiceDate = sourceData.invoiceDate;
      this.invoiceNumber = sourceData.invoiceNumber;
      this.grnBy = sourceData.grnBy;
      this.returnBy = sourceData.returnBy;
      const arr = sourceData.inputData.map((val) => {
        const x = this.orders.orderedProducts.filter((valx) => {
          if (valx.id === val.productId) {
            return true;
          }
        });
        if (x.length) {
          return Object.assign(x[0], val);
        }
      });
      const cGRN = arr.filter((val) => val !== undefined);
      cGRN.forEach((obj) => {
        obj.expiryDate = (obj.expiryDate && obj.expiryDate !== null) ? obj.expiryDate : '';
        if (!obj.tax || obj.tax === null) {
          this.taxNotExistsList.push(obj.productName);
          this.taxNotExists = true;
        }
      });
      if (this.taxNotExists) {
        this.notifyService.notification('Products with no tax found ' + this.taxNotExistsList.join(','), false, 'error');
        this.taxNotExists = false;
        this.bviewChanged('orders');
        return;
      }
      this.currentReturnProducts = cGRN;
    } else {
      this.orders.orderedProducts.forEach((obj) => {
        obj.productId = '' + obj.id;
        obj.caseQty = 0 + obj.caseSize;
        obj.freeQty = 0;
        obj.discAmount = 0;
        obj.batchNo = '';
        obj.taxType = (this.orders.orderGstType) ? (this.orders.orderGstType.toUpperCase()) : 'SGST';
        obj.recievedQty =
          obj.orderQuantity * obj.unitsize - obj.receivedQuantity;
        obj.mrp = (obj.mrp / obj.unitsize).toFixed(2);
      });
    }
    this.barCodeScan.pipe(debounceTime(200)).subscribe((res: any) => {
      document.getElementById('mrp').focus();
    });
  }

  paste($event, loc) {
    this.barCodeScan.next({ str: $event, location: loc });
  }

  bviewChanged(val: string) {
    this.bview.emit(val);
  }

  onlyUnique(value, index, self): any {
    return self.indexOf(value) === index;
  }

  saveGRN() {
  }

  confirmCloseInvoice() {
    const titleName = 'Confirmation';
    const message =
      'Please make sure you entered all the items in the invoice, and the respective received quantity. Are you sure you want to close this invoice?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.isLoading = true;
        this.loaderService.display(true, 'Closing the invoice...');
        this.baseService
          .doRequest('/api/goodsreceivednote/closeInvoice', 'post', {
            orderId: this.orders._id,
            invoiceNumber: this.invoiceNumber,
          })
          .subscribe(
            (res) => {
              this.loaderService.display(false);
              this.isLoading = false;
              if (res[0] === true) {
                this.notifyService.notification(res[1], false, 'success');
              } else if (res[0] === false) {
                this.notifyService.notification(res[1], false, 'error');
              }
              this.currentReturnProducts = [];
              this.toggleSearch(true);
              this.bviewChanged('orders');
            },
            (error) => {
              this.loaderService.display(false);
              this.isLoading = false;
              this.notifyService.notification(error.message, false, 'error');
            }
          );
      }
    });
  }

  taxTypeChanged(value) {
    this.newTaxTypeSelected = true;
    this.curSelecedTaxType = value;
    this.currentReturnProducts.forEach((val) => {
      if (val.completedStatus !== 'completed') {
        val.taxType = value;
      }
    });
  }

  createGRN() {
    const invoiceCopy = this.currentDocument.filter((val) => {
      if (val.name === 'invoiceCopy') {
        return true;
      }
    });
    /*const invoiceDocLinks = this.invoiceDocLink.filter((val) => {
      if (val.invoiceFileName) {
        return true;
      }
    });*/
    if (invoiceCopy.length) {
      this.isLoading = true;
      this.loaderService.display(true, 'Creating GRN');
      const prods: any = [];
      let negativeValues = 0;
      /*const uniqueCheck = this.orders.orderedProducts.map(x => x.mrp + ',' + x.batchNo).filter(this.onlyUnique);
            if (uniqueCheck.length !== this.orders.orderedProducts.length) {
              this.notifyService.notification('Please enter unique Batch Number and MRP', false, 'error');
              this.isLoading = false;
              this.loaderService.display(false);
              return;
            }*/
      this.freeQtyList = [];
      this.zeromrpList = [];
      this.invQtyList = [];
      this.currentReturnProducts.forEach((obj: any) => {
        if (obj.freeQty < 0) {
          this.freeQtyList.push(obj.productName);
          negativeValues -= 1;
        }
        if (obj.mrp <= 0) {
          this.zeromrpList.push(obj.productName);
          this.zeromrp = true;
        }
        if ((((obj.orderQuantity * obj.unitsize) - obj.receivedQuantity) < obj.invoicequantity)) {
          this.orderInvMisMatchList.push(obj.productName);
          this.orderInvMisMatch = true;
        }
        if ((obj.recievedQty > obj.invoicequantity)) {
          this.invQtyList.push(obj.productName);
          this.invQty = true;
        }
        prods.push({
          caseQty: obj.caseQty,
          mrp: obj.mrp,
          recievedQty: obj.recievedQty,
          freeQty: obj.freeQty,
          taxType: obj.taxType,
          discAmount: obj.discAmount,
          batchNo: obj.batchNo,
          barCode: obj.barCode,
          productId: obj.productId,
          expiryDate: obj.expiryDate,
          invoicequantity: obj.invoicequantity,
          completeStatus: (obj.completedStatus === 'completed') ? true : false
        });
      });
      const nonCompleteProds = prods.filter((val) => { if (!val.completeStatus) { return true; } });
      const objGRN = {
        orderId: this.orders._id,
        invoiceNumber: this.invoiceNumber,
        invoiceDate: this.invoiceDate,
        grnBy: this.grnBy,
        productsData: nonCompleteProds,
        doclinks: this.currentDocument,
        saveAsDraft: false,
      };

      if (negativeValues < 0) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Negative values in Free Quantity found',
          false,
          'error'
        );
      } else if (this.zeromrp) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Zero MRP found.' + this.zeromrpList.join(', '),
          false,
          'error'
        );
        this.zeromrp = false;
      } else if (this.invQty) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Invoiced Quantity lesser than Recieved Quantity not allowed. ' + this.invQtyList.join(', '),
          false,
          'error'
        );
        this.invQty = false;
      } else if (this.orderInvMisMatch) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Invoice qty entered is more than order qty for ' + this.orderInvMisMatchList.join(', '),
          false,
          'error'
        );
        this.orderInvMisMatch = false;
      } else {

        this.baseService
          .doRequest('/api/goodsreceivednote/createGRN', 'post', objGRN)
          .subscribe(
            (res) => {
              this.loaderService.display(false);
              this.isLoading = false;
              if (res[0] === true) {
                this.notifyService.notification(res[1], false, 'success');
                this.currentReturnProducts = [];
                this.toggleSearch(true);
                this.bviewChanged('orders');
              } else if (res[0] === false) {
                this.notifyService.notification(res[1], false, 'error');
              }
            },
            (error) => {
              this.loaderService.display(false);
              this.isLoading = false;
              this.notifyService.notification(error.message, false, 'error');
            }
          );
      }
    } else {
      this.toast.sToast('error', 'Invoice copy is mandatory. Please upload!');
    }
    this.isLoading = false;
  }

  freeQtyValueChange(val: number) {
    if (val < 0) {
      this.notifyService.notification(
        'Free Quantity can not be negative',
        false,
        'error'
      );
    }
  }

  uploadFile(event: any, key: string, pos: any, posName: any) {
    if (event.target.files.length > 0) {
      if (Math.round((event.target.files[0].size / 1024)) >= 2049 && posName !== 'Unpacking Video') {
        this.toast.sToast('error', 'File too Big, please select a file less than 2mb ');
        return;
      } else if (Math.round((event.target.files[0].size / 1024)) >= 5121 && posName === 'Unpacking Video') {
        this.toast.sToast('error', 'File too Big, please select a file less than 5mb ');
        return;
      }
      this.loaderService.display(true, 'Uploading...');
      if (event.target.files[0]) {
        this.isLoading = true;
        const img = [];
        const formData = new FormData();
        formData.append('uploadfile', event.target.files[0]);
        formData.append('productid', this.currentReturnProducts[pos].productId);
        formData.append('orderid', this.orders._id);
        formData.append('invoiceid', this.invoiceNumber);
        if (this.skOrderId !== '') {
          formData.append('skorderid', this.skOrderId);
        } else {
          formData.append('skorderid', this.orders._id);
        }
        formData.append('filename', key);
        this.httpClient.post<any>('/api/skreturnorderimages/skuploadProductImage', formData)
          .subscribe((res) => {
            if (res && res[0]) {
              this.toast.sToast('success', res[1]);
              this.currentReturnProducts[pos] = Object.assign(this.currentReturnProducts[pos], res[2]);
              this.currentReturnProducts[pos].assetAvailable = true;
              if (key.toLowerCase().includes('deal_upload') || key.toLowerCase().includes('pod_image') || key.toLowerCase().includes('video')) {
                this.currentReturnProducts[pos].assetAvailable = true;
                /*this.objectKeys(res[2]).forEach((obj: any) => {
                  this.currentReturnProducts[pos].imgAssets.push(res[2][obj]);
                });*/
                /*/!*this.getBase64(event.target.files[0]).then(
                  (data) => (
                    img.push(data)
                  )
                ).finally(() => {
                  if (this.currentReturnProducts[pos][`${key}`] && Object.keys(this.currentReturnProducts[pos][`${key}`])) {
                    this.currentReturnProducts[pos][`${key}`] = {};
                    const assetPos = this.currentReturnProducts[pos].imgAssets.findIndex((curVal) => {
                      if (curVal.name === `${key}`) {
                        return true;
                      }
                    });
                    if (assetPos !== -1) {
                      this.currentReturnProducts[pos].imgAssets.splice(assetPos, 1);
                    }
                  }
                  const obj = {
                    name: key,
                    file: img[0],
                    fileName: event.target.files[0].name,
                    posName
                  };*!/
                  this.currentReturnProducts[pos][`${key}`] = obj;
                  this.currentReturnProducts[pos].imgAssets.push(obj);*/
                this.currentReturnProducts[pos].assetAvailable = true;
                // });
                this.isLoading = false;
              } else {
                /*this.objectKeys(res[2]).forEach((obj: any) => {
                  this.currentReturnProducts[pos].videoAssets.push(res[2][obj]);
                });*/
                /*const file = event.target.files[0];
                const blobURL = URL.createObjectURL(file);
                const obj = {
                  name: key,
                  file: blobURL,
                  fileName: event.target.files[0].name,
                  posName
                };
                this.currentReturnProducts[pos][`${key}`] = obj;
                this.currentReturnProducts[pos].videoAssets[0] = obj;
                this.currentReturnProducts[pos].assetAvailable = true;*/
              }
            } else {
              this.toast.sToast('error', res[1]);
            }
            this.isLoading = false;
            this.loaderService.display(false);
          });
      }
    }
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  deleteFile(index) {
    if (this.currentDocument[index].name) {
      // @ts-ignore
      document.getElementById(`${this.currentDocument[index].name}`).value = '';
    }
    this.currentDocument.splice(index, 1);
  }

  saveDraft() {
    this.returnGotClicked = true;
    this.isLoading = true;
    this.loaderService.display(true, 'Creating return request...');
    this.dealUploadNAList = [];
    this.podImgNAList = [];
    this.videoNAList = [];
    const returnProducts = [];
    this.currentReturnProducts.forEach((prod) => {
      const obj = {
        productid: prod.productId,
        dealName: prod.productName,
        orderId: this.skOrderId, // not coming
        price: prod.mrp,
        unitcost: prod.unitcost,
        casesize: prod.caseSize,
        claimQuantity: prod.claimQty,
        inventoryid: (prod.inventoryid) ? prod.inventoryid : '',
        receivedQuantity: (prod.invoicequantity) ? prod.invoicequantity : prod.receivedQuantity, // for non sk how much the user received
        actualMrpReceived: prod.actualMRP,
        freeProductName: prod.freeProdName,
        wrongOrderId: prod.wrongOrderID,
        reason: prod.reason,
        subreason: prod.subreason,
        remarks: prod.remark,
        deal_upload: prod.deal_upload,
        pod_image: prod.pod_image,
        video: prod.video
      };
      returnProducts.push(obj);
    });

    const obj1 = {
      invoiceid: this.invoiceNumber,
      returnby: this.returnBy,
      invoicedate: this.invoiceDate,
      grnNo: this.currentInvoice.grnNo,
      deals: returnProducts,
      orderId: this.orders._id,
      saveAsDraft: true
    };
    this.baseService.doRequest('/api/orders/returnOrder', 'post', obj1).subscribe((res) => {
      if (res && res[0]) {
        this.loaderService.display(false);
        this.bviewChanged('orders');
        this.notifyService.notification('Return saved as draft', false, 'success');
      } else {
        this.loaderService.display(false);
        this.notifyService.notification(res[1], false, 'error');
        this.returnGotClicked = false;
        this.isLoading = false;
      }
    });
    this.returnGotClicked = false;
  }

  deleteOrder(index) {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to Delete ${this.currentReturnProducts[index].productName}?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.currentReturnProducts.splice(index, 1);
        // this.toast.sToast(
        //   "info",
        //   "Press Save Draft to save the current changes."
        // );
      }
    });
  }
  downloadPDF(val) {
    const linkSource = val.doc;
    const downloadLink = document.createElement('a');
    const fileName = `${val.fileName}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    if (val.status) {
      window.open(linkSource, '_blank');
    } else {
      downloadLink.click();
    }
  }
  downloadFile(val) {
    window.open(val, '_blank');
  }
  changeField(value, fieldName, loc) {
    // product.fieldName = $event
    const arr = this.currentReturnProducts[loc];
    arr[fieldName] = value;
    if (fieldName === 'freeQty') {
      this.freeQtyValueChange(value);
    }
  }

  prodTrackByFn(index: number, prod) {
    return prod.id;
  }
  createObjectIdGenerator() {
    const objectIds = new WeakMap<Object, number>();
    let counter = 1;
    return function(item: Object): number {
      let result = objectIds.get(item);
      if (!result) {
        result = counter++;
        objectIds.set(item, result);
      }
      return result;
    };
  }

  /* reasonSelected(value: any, pos) {
    console.log(pos, 'position that came from');
    this.currentReturnProducts[pos].selectedSubReason = '';
    this.currentReturnProducts[pos].orderIDMismatch = false;
    this.currentReturnProducts[pos].freeProdMismatch = false;
    this.currentReturnProducts[pos].MRPSelected = false;
    switch (value) {
      case '1': {
        this.currentReturnProducts[pos].selectedReasonText = 'Damage';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].isSubReasonSelected = true;
        break;
      }
      case '2': {
        this.currentReturnProducts[pos].selectedReasonText = 'Shortage';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].isSubReasonSelected = true;
        break;
      }
      case '3': {
        this.currentReturnProducts[pos].selectedReasonText = 'MRP Difference';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].freeProdMismatch = false;
        this.currentReturnProducts[pos].orderIDMismatch = false;
        this.currentReturnProducts[pos].MRPSelected = true;
        this.currentReturnProducts[pos].isSubReasonSelected = false;
        this.currentReturnProducts[pos].subReasonList = [];
        break;
      }
      case '4': {
        this.currentReturnProducts[pos].selectedReasonText = 'Product Mismatch/Wrong Product';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].isSubReasonSelected = false;
      }
      case '5': {
        this.currentReturnProducts[pos].selectedReasonText = 'Free product Not received';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].freeProdMismatch = true;
        this.currentReturnProducts[pos].orderIDMismatch = false;
        this.currentReturnProducts[pos].MRPSelected = false;
        this.currentReturnProducts[pos].isSubReasonSelected = false;
        this.currentReturnProducts[pos].subReasonList = [];
        break;
      }
      case '6': {
        this.currentReturnProducts[pos].selectedReasonText = 'Wrong Order Received';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].orderIDMismatch = true;
        this.currentReturnProducts[pos].freeProdMismatch = false;
        this.currentReturnProducts[pos].MRPSelected = false;
        this.currentReturnProducts[pos].isSubReasonSelected = false;
        this.currentReturnProducts[pos].subReasonList = [];
        break;
      }
      case '7': {
        this.currentReturnProducts[pos].selectedReasonText = 'DOA';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].isSubReasonSelected = false;
      }
      case '8': {
        this.currentReturnProducts[pos].selectedReasonText = 'Accessories Issue';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].isSubReasonSelected = false;
      }
      case '9': {
        this.currentReturnProducts[pos].selectedReasonText = 'Device Not working';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].isSubReasonSelected = false;
      }
      case '10': {
        this.currentReturnProducts[pos].selectedReasonText = 'Expired Products';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].actualMRP = 0;
        this.currentReturnProducts[pos].freeProdName = '';
        this.currentReturnProducts[pos].freeProdQty = 0;
        this.currentReturnProducts[pos].wrongOrderID = '';
        this.currentReturnProducts[pos].selectedSubReasonText = '';
        this.currentReturnProducts[pos].isSubReasonSelected = false;
      }
    }
  } */

  returnProducts() {
    this.returnGotClicked = true;
    this.isLoading = true;
    this.loaderService.display(true, 'Creating return request...');
    this.dealUploadNAList = [];
    this.podImgNAList = [];
    this.videoNAList = [];
    const returnProducts = [];
    const invoiceQuantityMismatch = [];
    this.currentReturnProducts.forEach((prod) => {
      if (!prod.deal_upload || prod.deal_upload === null || !Object.keys(prod.deal_upload).length) {
        this.dealUploadNotAvailable = true;
        this.dealUploadNAList.push(prod.productName);
      }
      if (!prod.pod_image || prod.pod_image === null || !Object.keys(prod.pod_image).length) {
        this.podImgNotAvailable = true;
        this.podImgNAList.push(prod.productName);
      }
      if (!prod.video || prod.video === null || !Object.keys(prod.video).length) {
        this.videoNotAvailable = true;
        this.videoNAList.push(prod.productName);
      }
      if (prod.claimQty > (prod.receivedQuantity - prod.approvedQuantity)) {
        invoiceQuantityMismatch.push(prod.productName);
      }
      const obj = {
        productid: prod.productId,
        dealName: prod.productName,
        orderId: this.skOrderId, // not coming
        price: prod.mrp,
        unitcost: prod.unitcost,
        casesize: prod.caseSize,
        claimQuantity: prod.claimQty,
        inventoryid: (prod.inventoryid) ? prod.inventoryid : '',
        receivedQuantity: (prod.invoicequantity) ? prod.invoicequantity : prod.receivedQuantity, // for non sk how much the user received
        actualMrpReceived: prod.actualMRP,
        freeProductName: prod.freeProdName,
        wrongOrderId: prod.wrongOrderID,
        reason: prod.reason,
        subreason: prod.subreason,
        remarks: prod.remark,
         deal_upload: prod.deal_upload,
        pod_image: prod.pod_image,
        video: prod.video
      };
      returnProducts.push(obj);
    });
    if (this.dealUploadNotAvailable) {
      this.notifyService.notification(
        `Deal Image not available for ` + this.dealUploadNAList.join(', '), false, 'error'
      );
      this.dealUploadNotAvailable = false;
      this.isLoading = false;
    } else if (this.podImgNotAvailable) {
      this.notifyService.notification(
        `Delivery Image not available for ${this.podImgNAList.join(', ')}`, false, 'error'
      );
      this.isLoading = false;
      this.podImgNotAvailable = false;
    /*} else if (this.videoNotAvailable) {
      this.notifyService.notification(
        `Deal Video not available for ${this.videoNAList.join(', ')}`, false, 'error'
      );
      this.isLoading = false;
      this.videoNotAvailable = false;*/
    } else if (invoiceQuantityMismatch && invoiceQuantityMismatch.length) {
      this.notifyService.notification(
        `Claim quantity shouldn't be greater than what was really received. Please check,
         ${invoiceQuantityMismatch.join(', ')}`, false, 'error'
      );
      this.isLoading = false;
    } else {
      const obj = {
        invoiceid: this.invoiceNumber,
        returnby: this.returnBy,
        invoicedate: this.invoiceDate,
        grnNo: this.currentInvoice.grnNo,
        deals: returnProducts,
        orderId: this.orders._id,
        saveAsDraft: false
      };
      this.baseService.doRequest('/api/orders/returnOrder', 'post', obj).subscribe((res) => {
        if (res && res[0]) {
          this.loaderService.display(false);
          this.bviewChanged('orders');
          this.notifyService.notification('Return request created', false, 'success');
        } else {
          this.loaderService.display(false);
          this.notifyService.notification(res[1], false, 'error');
          this.returnGotClicked = false;
          this.isLoading = false;
        }
      });
    }
    this.returnGotClicked = false;
  }

  searchNow(): void {
    this.searchGRNProducts(this.search);
  }

  removeAsset(key: string, pos) {
    delete this.currentReturnProducts[pos][key];
    /*if (key === 'deal_uploadimages') {
      this.currentReturnProducts[pos].dealupload = '';
      const assetPos = this.currentReturnProducts[pos].imgAssets.findIndex((curVal) => {
        if (curVal.name === 'deal_uploadimages') {
          return true;
        }
      });
      this.currentReturnProducts[pos].imgAssets.splice(assetPos, 1);
      this.currentReturnProducts[pos][`${key}`] = {};
    } else if (key === 'pod_images') {
      this.currentReturnProducts[pos].podImage = '';
      const assetPos = this.currentReturnProducts[pos].imgAssets.findIndex((curVal) => {
        if (curVal.name === 'pod_images') {
          return true;
        }
      });
      this.currentReturnProducts[pos].imgAssets.splice(assetPos, 1);
      this.currentReturnProducts[pos][`${key}`] = {};
    } else {
      this.currentReturnProducts[pos].video = '';
      const assetPos = this.currentReturnProducts[pos].videoAssets.findIndex((curVal) => {
        if (curVal.name === 'pod_images') {
          return true;
        }
      });
      this.currentReturnProducts[pos].videoAssets.splice(assetPos, 1);
    }*/
    // tslint:disable-next-line:max-line-length
    if (!this.currentReturnProducts[pos].video && !this.currentReturnProducts[pos].deal_upload && !this.currentReturnProducts[pos].pod_image) {
      this.currentReturnProducts[pos].assetAvailable = false;
    }
  }
}
