import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  CamelToHumanPipe, DaysHoursSecondsPipe, BytesConvertFilterPipe,
  EpochToDateFilterPipe, StrToMacPipe, SafePipe, ArrayOrderByPipe,
  FormatTrafficUnitsPipe, DateAgoPipe, FormatCellPipe, UniqueFilterPipe, ArrayToStrPipe,
  ObjectToStrPipe, TimeFormat, DateFormat, DateAndTimeFormat,
  CveToDateFilterPipe, MacFilterPipe, AssessmentDateFilterPipe, SplitPipe, CapitalizeFilterPipe,
  FilterPipe, ArrayToStrEllipsisPipe, SearchPipe, UtcToLocale, EllipsisPipe,
  SafeHTMLPipe, TimeAgoPipe, ArraySortPipe, serviceroleMappingPipe, DateTimeConverterFilterPipe,
  formatDateDatePipe, formatOnlyDateDatePipe, DecimalPipe
} from './app.filter.pipe';

@NgModule({
  declarations: [
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe,
    SafePipe, MacFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe, ArrayToStrEllipsisPipe, SearchPipe,
    UtcToLocale, EllipsisPipe, FormatTrafficUnitsPipe, SafeHTMLPipe, TimeAgoPipe, ArraySortPipe, formatDateDatePipe,
    formatOnlyDateDatePipe,
    DateAgoPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    ObjectToStrPipe,
    TimeFormat,
    DateFormat,
    CapitalizeFilterPipe,
    DateAndTimeFormat,
    DecimalPipe,
    CveToDateFilterPipe, FilterPipe, serviceroleMappingPipe, DateTimeConverterFilterPipe
  ],
  imports: [CommonModule],
  exports: [
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe,
    SafePipe, MacFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe, ArrayToStrEllipsisPipe, SearchPipe,
    UtcToLocale, EllipsisPipe,
    FormatTrafficUnitsPipe, SafeHTMLPipe, TimeAgoPipe, ArraySortPipe, formatDateDatePipe,
    formatOnlyDateDatePipe,
    DateAgoPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    ObjectToStrPipe,
    serviceroleMappingPipe,
    TimeFormat,
    DateFormat,
    CapitalizeFilterPipe,
    DateAndTimeFormat,
    CveToDateFilterPipe,
    FilterPipe, DateTimeConverterFilterPipe,
    DecimalPipe
  ],
  providers: [DatePipe, FilterPipe]
})
export class AppFilterPipeModule {
}
