import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { MyToastrService } from "../_services/toastr.service";

@Directive({
  selector: "[zeroMRPCheck]",
})
export class ZeroCheckDirective {

  private run(oldValue) {
    setTimeout(() => {
      let currentValue: string = this.el.nativeElement.value;
      if (currentValue === '0') {
        this.el.nativeElement.value = oldValue;
        console.log(this.el.nativeElement.value)
        this.toast.sToast("error", "Invalid value. Zero is not allowed.");
        this.el.nativeElement.focus();
      }
    });
  }

  constructor(private el: ElementRef, private toast: MyToastrService) { }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener("focusout")
  onFocusOut() {
    this.run(this.el.nativeElement.value);
  }
}
