import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { BaseRequestService } from "../../_services/base.service";
import { CommonService } from "../../_services/common.services";
import { ConfirmDialogService } from "../../_services/confirmdialog.service";
import { IconService } from "../../_services/icon.service";
import { LoaderService } from "../../_services/loader.service";
import { ModalService } from "../../_services/modal.service";
import { MyToastrService } from "../../_services/toastr.service";

@Component({
  selector: "vex-document-proofs",
  templateUrl: "./document-proofs.component.html",
  styleUrls: ["./document-proofs.component.scss"],
})
export class DocumentProofsComponent implements OnInit {
  @Input() currentEntity: any;
  @Input() isCompany: boolean;
  layoutCtrl = new FormControl("boxed");
  docProofsTableOptions: any;
  docProofscurrentPage = 0;
  docProofsfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  Objectkeys = Object.keys;
  isLoading = false;
  toggleImageClass = false;
  showSubDocument = false;
  currentDocument: any = {
    name: "",
    image: "",
    number: "",
    doclinks: [],
    entity_ref: { id: "", name: "" },
  };
  constructor(
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private router: Router,
    public toast: MyToastrService,
    private loaderService: LoaderService,
    public ic: IconService,
    public confirmDialog: ConfirmDialogService,
    public cs: CommonService
  ) {
    this.docProofsTableOptions = {
      columns: [
        {
          header: "",
          columnDef: "image",
          filter: "",
          cell: "(element: any) => `${element.image}`",
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          width: "50px",
          baseImg: true,
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: "Document Name",
          columnDef: "name",
          filter: "",
          cell: "(element: any) => `${element.name}`",
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          isHyperlink: true,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: "Document Number",
          columnDef: "number",
          filter: "",
          cell: "(element: any) => `${element.description}`",
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: "",
          hasMultiData: false,
          class: "",
          color: "",
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: "",
          img: false,
          imgPath: "",
          isSort: false,
          iscolumnSearch: false,
        },
      ],
      sortOptions: { active: "name.keyword", direction: "asc" },
      _pageData: [],
      tableOptions: {
        title: "Document Proof",
        isServerSide: true,
        selectText: "document(s)",
        loading: false,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {
            text: "Delete",
            icon: "delete",
            callback: "deleteFn",
            isGlobal: false,
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: true,
        columnSearch: true,
        compareData: false,
      },
    };
  }

  toggleImg() {
    this.toggleImageClass = !this.toggleImageClass;
  }
  getImg(img) {
    return this.cs.blobURL + img;
  }
  ngOnInit() {
    this.docProofsTableOptions.pageData = [];
    this.getDocument();
  }
  docProofsshowHideLoading(status: any): void {
    const data = Object.assign({}, this.docProofsTableOptions);
    this.docProofsTableOptions = {};
    this.docProofsTableOptions = data;
    this.docProofsTableOptions.tableOptions.loading = status;
  }
  docProofssortCall(idata) {
    this.docProofsTableOptions.sortOptions = idata;
    this.getDocument();
  }

  docProofsdetailFn(idata: any): void {
    idata.doclinks.forEach((element) => {
      element = this.cs.blobURL + element;
    });
    this.currentDocument = idata;
    this.modalService.open("viewDocs");
  }

  docProofshyperlinkCall(idata: any): void {
    if (idata.col === "name") {
      this.docProofsdetailFn(idata.row);
    }
  }

  closeSubDocument(): void {
    this.showSubDocument = false;
    this.currentDocument = {};
  }

  docProofseditFn(idata: any): void {
    this.currentDocument = idata;
    this.modalService.open("addDocument");
  }

  updateDocument(currentDocument: any): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.baseService
      .doRequest(`/api/documentproof/upload_docproof`, "post", {
        item: currentDocument,
      })
      .subscribe((result: any) => {
        this.loaderService.Modeldisplay(false);
        this.toast.sToast("success", "Updated successfully");
        this.isLoading = false;
        setTimeout(() => {
          this.getDocument();
        }, 2000);
        this.modalService.close("addDocument");
      });
  }

  saveDocument(): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.currentDocument.entity_ref = {
      id: this.currentEntity._id,
      name: this.currentEntity.name,
    };
    this.baseService
      .doRequest("/api/documentproof/upload_docproof", "post", {
        item: this.currentDocument,
      })
      .subscribe((result: any) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = true;
        if (result) {
          this.modalService.close("addDocument");
          this.toast.sToast("success", "Added Successfully");
          setTimeout(() => {
            this.getDocument();
          }, 2000);
          this.isLoading = false;
        } else {
          this.toast.sToast("error", JSON.stringify(result));
        }
      });
  }

  docProofsdeleteFn(currentDocument: any): void {
    const titleName = "Confirmation";
    const message =
      "Are you sure you want to delete " + currentDocument.categoryName + " ?";
    const cancelText = "No";
    const acceptText = "Yes";
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.baseService
          .doRequest(`/api/documentproof/${currentDocument._id}`, "delete", {
            id: currentDocument._id,
          })
          .subscribe((result: any) => {
            this.toast.sToast("success", "Removed successfully");
            setTimeout(() => {
              this.getDocument();
            }, 2000);
          });
      }
    });
  }

  docProofsactionCall(idata: any): void {
    if (idata.action.text === "Edit") {
      const dataRow = idata.row;
      dataRow.doclinks.forEach((element) => {
        element = this.cs.blobURL + element;
      });
      this.docProofseditFn(dataRow);
    }
    if (idata.action.text === "Delete") {
      const dataRow = idata.row;
      this.docProofsdeleteFn(dataRow);
    }
  }

  docProofsfilterCall(idata: any): void {
    const fields = [];
    this.docProofsTableOptions.columns.forEach((obj) => {
      fields.push(obj.columnDef);
    });
    this.docProofsfilterQuery =
      idata && idata.length > 0
        ? { multi_match: { query: idata, type: "phrase_prefix", fields } }
        : undefined;
    this.getDocument();
  }

  docProofspageCall(event: any): void {
    this.docProofsTableOptions.tableOptions.pageSize = event.pageSize;
    this.docProofscurrentPage = event.pageIndex;
    this.getDocument();
  }

  docProofsaddTableData(): void {
    this.currentDocument = {
      name: "",
      image: "",
      number: "",
      doclinks: [],
      entity_ref: { id: "", name: "" },
    };
    this.modalService.open("addDocument");
  }

  uploadFile(event: any, key: string) {
    if (event.target.files.length > 0) {
      this.getBase64(event.target.files[0]).then((data) => {
        this.currentDocument.doclinks.push(data);
        this.toast.sToast("info", "Please add next proof");
        const updateDocProf: HTMLInputElement = document.getElementById(
          "updateDocProf"
        ) as HTMLInputElement;
        updateDocProf.value = "";
        const addDocProf: HTMLInputElement = document.getElementById(
          "addDocProf"
        ) as HTMLInputElement;
        addDocProf.value = "";
      });
    }
  }
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  getDocument(): void {
    if (!this.currentEntity) {
      setTimeout(() => {
        this.getDocument();
      }, 1000);
      return;
    }
    this.docProofsshowHideLoading(true);
    let params: any;
    if (this.isCompany) {
      params = {
        query: {
          bool: {
            must: [
              { match: { "company_ref.id.keyword": this.currentEntity._id } },
            ],
          },
        },
      };
      if (this.docProofsfilterQuery && this.docProofsfilterQuery.multi_match) {
        params.query.bool.must.push(this.docProofsfilterQuery);
      }
    } else {
      params = {
        query: {
          bool: {
            must: [
              { match: { "entity_ref.id.keyword": this.currentEntity._id } },
            ],
          },
        },
      };
      if (this.docProofsfilterQuery && this.docProofsfilterQuery.multi_match) {
        params.query.bool.must.push(this.docProofsfilterQuery);
      }

    }
    const sorts: any = [{}];
    if (this.docProofsTableOptions.sortOptions) {
      sorts[0][this.docProofsTableOptions.sortOptions.active] = {
        order: this.docProofsTableOptions.sortOptions.direction,
      };
    }
    const q = JSON.stringify(params);
    const skip = this.docProofscurrentPage;
    const limit = this.docProofsTableOptions.tableOptions.pageSize;
    const sort = JSON.stringify(sorts);
    this.baseService
      .doRequest("/api/documentproof", "get", null, { q, skip, limit, sorts })
      .subscribe((result: any) => {
        this.docProofsTableOptions.pageData = result.data;
        this.docProofsTableOptions.tableOptions.pageTotal = result.total;
        this.docProofsshowHideLoading(false);
      });
  }
}
