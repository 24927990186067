import { Directive, HostListener, ElementRef } from '@angular/core';
import { MyToastrService } from '../_services/toastr.service';

@Directive({
  selector: '[appPanCheck]'
})
export class PanNumberCheckDirective {

  constructor(private el: ElementRef,
    private toast: MyToastrService
  ) {
  }

  // tslint:disable-next-line:max-line-length
  PAN_REGX = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);


  @HostListener('focusout')
  onFocusOut() {
    const current: string = this.el.nativeElement.value;
    if (current !== '') {
      if (current && !String(current).match(this.PAN_REGX)) {
        this.toast.sToast('error', 'Invalid PAN');
        this.el.nativeElement.focus();
        this.el.nativeElement.dirty()
      }
    }
  }
}
