import { Directive, HostListener, ElementRef } from '@angular/core';
import { MyToastrService } from '../_services/toastr.service';

@Directive({
  selector: '[vexNumberCheck]'
})
export class QtyNumberDirective {

  constructor(private el: ElementRef,
    private toast: MyToastrService
  ) {
  }

  // tslint:disable-next-line:max-line-length
  NUM_REGX = new RegExp(/^[1-9]+[0-9]*$/);


  @HostListener('focusout')
  onFocusOut() {
    const current: string = this.el.nativeElement.value;
    if (current !== '') {
      if (current && !String(current).match(this.NUM_REGX)) {
        this.toast.sToast('error', 'Please enter valid number', 'Invalid Number');
        this.el.nativeElement.focus();
        this.el.nativeElement.dirty();
      }
    }
  }
}
