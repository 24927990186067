<s-modal id="failedpayment">
  <form #failedpayment="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0">
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <div class="mat-title">Payment Failed!</div>
        <div class="flex flex-row items-center justify-center text-center">
          <img src="/assets/cross.svg" alt="Error" class="w-16">
        </div>
        <mat-card-content class="">
          <h3 class="py-3"><span class="text-secondary">Transaction ID</span> #{{transactionId}}.</h3>
          <p class=""pt-3>Please do not refresh! Redirecting...</p>
        </mat-card-content>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
