import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BaseRequestService} from '../../_services/base.service';
import {ModalService} from '../../_services/modal.service';
import {Router} from '@angular/router';
import {MyToastrService} from '../../_services/toastr.service';
import {LoaderService} from '../../_services/loader.service';
import {IconService} from '../../_services/icon.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';
import {CommonService} from '../../_services/common.services';

@Component({
  selector: 'vex-store-rate-card',
  templateUrl: './store-rate-card.component.html',
  styleUrls: ['./store-rate-card.component.scss']
})

export class StoreRateCardComponent implements OnInit {
  @Input() currentEntity: any;
  layoutCtrl = new FormControl('boxed');
  storeRatecardsTableOptions: any;
  storeRatecardscurrentPage = 0;
  storeRatecardsfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  Objectkeys = Object.keys;
  isLoading = false;
  showSubStoreProducts = false;
  currentStoreProducts: any = { categoryName: '', image: '', description: '' };
  constructor(
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private router: Router,
    public toast: MyToastrService,
    private loaderService: LoaderService,
    public ic: IconService,
    public confirmDialog: ConfirmDialogService,
    public cs: CommonService
  ) {
    this.storeRatecardsTableOptions = {
      faClass: 'Ratecard',
      customCols: false,
      serverSide: {
        service: '',
        fn: '',
        q: {},
        sort: [{}],
      },
      columns: [
        {
          header: 'State',
          columnDef: 'state',
          filter: '',
          cell: '(element: any) => `${element.state}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Region',
          columnDef: 'region',
          filter: '',
          cell: '(element: any) => `${element.region}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'MRP',
          columnDef: 'mrp',
          filter: '',
          cell: '(element: any) => `${element.mrp}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          isHyperlink: false,
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Selling Price',
          columnDef: 'sprice',
          filter: '',
          cell: '(element: any) => `${element.sprice}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Supplier Price',
          columnDef: 'aprice',
          filter: '',
          cell: '(element: any) => `${element.aprice}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Unit Size',
          columnDef: 'unitsize',
          filter: '',
          cell: '(element: any) => `${element.unitsize}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'UOM',
          columnDef: 'uom',
          filter: '',
          cell: '(element: any) => `${element.uom}`',
          order: 7,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: true,
        },
        {
          header: 'Product Master',
          columnDef: 'productmaster_ref.name',
          filter: '',
          cell: '(element: any) => `${element.productmaster_ref.name}`',
          order: 8,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Supplier',
          columnDef: 'entity_ref.name',
          filter: '',
          cell: '(element: any) => `${element.entity_ref.name}`',
          order: 9,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'ID',
          columnDef: '_id',
          filter: '',
          cell: '(element: any) => `${element._id}`',
          order: 10,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
      ],
      sortOptions: { active: 'mrp', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Store Rate Card',
        isServerSide: true,
        selectText: 'ratecard(s)',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Remove',
            icon: 'delete',
            callback: 'deleteFn',
            isGlobal: false,
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: true,
        compareData: false,
      },
    };
  }
  ngOnInit() {
    this.storeRatecardsTableOptions.pageData = [];
    this.getStoreProducts();
  }
  storeRatecardsshowHideLoading(status: any): void {
    const data = Object.assign({}, this.storeRatecardsTableOptions);
    this.storeRatecardsTableOptions = {};
    this.storeRatecardsTableOptions = data;
    this.storeRatecardsTableOptions.tableOptions.loading = status;
  }
  storeRatecardssortCall(idata) {
    this.storeRatecardsTableOptions.sortOptions = idata;
    this.getStoreProducts();
  }

  storeRatecardsdetailFn(idata: any): void {
    this.currentStoreProducts = idata;
    this.showSubStoreProducts = true;
  }

  storeRatecardshyperlinkCall(idata: any): void {
    if (idata.col === 'storeRatecardsgoryName') {
      this.storeRatecardsdetailFn(idata.row);
    }
  }

  closeSubStoreProducts(): void {
    this.showSubStoreProducts = false;
    this.currentStoreProducts = {};
  }

  storeRatecardsdeleteFn(currentStoreProducts: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to remove ' + currentStoreProducts.categoryName + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.baseService
          .doRequest(`/api/storeproductmaster/${currentStoreProducts._id}`, 'delete', {
            id: currentStoreProducts._id,
          }).subscribe((result: any) => {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getStoreProducts();
            }, 2000);
          });
      }
    });
  }

  storeRatecardsactionCall(idata: any): void {
    if (idata.action.text === 'Remove') {
      const dataRow = idata.row;
      this.storeRatecardsdeleteFn(dataRow);
    }
  }

  storeRatecardsfilterCall(idata: any): void {
    const fields = [];
    this.storeRatecardsTableOptions.columns.forEach((obj) => {
      fields.push(obj.columnDef);
    });
    this.storeRatecardsfilterQuery =
      idata && idata.length > 0
        ? { multi_match: { query: idata, type: 'phrase_prefix', fields } }
        : undefined;
    this.getStoreProducts();
  }

  storeRatecardspageCall(event: any): void {
    this.storeRatecardsTableOptions.tableOptions.pageSize = event.pageSize;
    this.storeRatecardscurrentPage = event.pageIndex;
    this.getStoreProducts();
  }

  storeRatecardsaddTableData(): void {
  }

  storeRatecardstimerCallData(idata: any): void {
    this.getStoreProducts();
  }

  getStoreProducts(): void {
    if (!this.currentEntity) { setTimeout(() => { this.getStoreProducts(); }, 1000); return; } else {
      this.storeRatecardsTableOptions.serverSide.q = {query: {bool: {must: [{match: {'entity_ref.id.keyword': this.currentEntity._id}}]}}};
    }
    this.storeRatecardsshowHideLoading(true);
    let params: any;
    params = {
      query: { bool: { must: [{ match: { 'entity_ref.id.keyword': this.currentEntity._id } }] } },
    };
    if (this.storeRatecardsfilterQuery && this.storeRatecardsfilterQuery.multi_match) {
      params.query.bool.must.push(this.storeRatecardsfilterQuery);
    }
    const sorts: any = [{}];
    if (this.storeRatecardsTableOptions.sortOptions) {
      sorts[0][this.storeRatecardsTableOptions.sortOptions.active] = {
        order: this.storeRatecardsTableOptions.sortOptions.direction,
      };
    }
    const q = JSON.stringify(params);
    const skip = this.storeRatecardscurrentPage;
    const limit = this.storeRatecardsTableOptions.tableOptions.pageSize;
    const sort = JSON.stringify(sorts);
    this.baseService
      .doRequest('/api/ratecard', 'get', null, { q, skip, limit, sorts })
      .subscribe((result: any) => {
        this.storeRatecardsTableOptions.pageData = result.data;
        this.storeRatecardsTableOptions.tableOptions.pageTotal = result.total;
        this.storeRatecardsshowHideLoading(false);
      });
  }

}
