import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../_services/auth.service';
import { ApiService } from '../../_services/api.service';
import { NotificationService } from '../../_services/notification.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { MyToastrService } from '../../_services/toastr.service';
import { LoaderService } from 'src/app/_services/loader.service';
import {AuthenticationService} from "../../_services/authentication.service";

@Component({
  selector: 'vex-error-logs',
  templateUrl: './error-logs.component.html',
  styleUrls: ['./error-logs.component.scss']
})
export class ErrorLogsComponent implements OnInit {
  @Input() tableType: any;
  erroTableOptions: any;
  errocurrentPage = 0;
  errofilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  queryParams = { skip: 0, limit: 30 };
  colfil: any = [];
  perColConditions: any;
  globalConditions: {};
  globalsearch: any;

  constructor(private dialog: MatDialog, public authService: AuthenticationService, private apiService: ApiService,
    private notifyService: NotificationService, private loaderService: LoaderService,
    private confirmDialog: ConfirmDialogService, private toast: MyToastrService) {
    this.erroTableOptions = {
      columns: [],
      sortOptions: { active: 'updated', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        title: 'Error Logs',
        isServerSide: true,
        selectText: 'logs(s)',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [{
          text: 'Details',
          icon: 'info',
          callback: 'detailFn',
          isGlobal: false
        }, { text: 'Edit', icon: 'edit', callback: 'editFn', isGlobal: true }, {
          text: 'Delete',
          icon: 'delete',
          callback: 'deleteFn',
          isGlobal: true
        }],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: true,
        compareData: false
      }
    };
  }

  ngOnInit() {
    this.erroTableOptions.pageData = [];
    if (this.tableType === 'Users') {
      this.erroTableOptions.tableOptions.id="userErrorLogs"
      this.erroTableOptions.columns = [
        {
          'header': 'Created at', 'columnDef': 'created', 'filter': 'epochToDate', 'width': '170px',
          'cell': '(element: any) => `${element.created}`', 'order': 11, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'Description', 'columnDef': 'description', 'filter': '', 'width': '250px',
          'cell': '(element: any) => `${element.description}`', 'order': 1, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'Upload ID', 'columnDef': 'uploadId', 'filter': '',
          'cell': '(element: any) => `${element.uploadId}`', 'order': 2, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'First Name', 'columnDef': 'firstName', 'filter': '', 'width': '100px',
          'cell': '(element: any) => `${element.firstName}`', 'order': 3, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'Last Name', 'columnDef': 'lastName', 'filter': '', 'width': '100px',
          'cell': '(element: any) => `${element.lastName}`', 'order': 3, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'File Name', 'columnDef': 'fileName', 'filter': '', 'width': '150px',
          'cell': '(element: any) => `${element.fileName}`', 'order': 3, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'Input Type', 'columnDef': 'inputMedium', 'filter': '',
          'cell': '(element: any) => `${element.inputMedium}`', 'order': 4, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'Type', 'columnDef': 'type', 'filter': '',
          'cell': '(element: any) => `${element.type}`', 'order': 5, 'visible': false, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'Email Address', 'columnDef': 'email', 'filter': '',
          'cell': '(element: any) => `${element.email}`', 'order': 10, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },

      ];
    } else if (this.tableType === 'Tickets') {
      this.erroTableOptions.tableOptions.id = "tesErrorLogs"
      this.erroTableOptions.columns = [
        {
          header: 'Created at',
          columnDef: 'created',
          filter: 'epochToDate',
          cell: '(element: any) => `${element.created}`',
          order: 16,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true,
          width: '200px'
        },
        {
          header: 'Description',
          columnDef: 'description',
          filter: '',
          cell: '(element: any) => `${element.description}`',
          order: 0,
          width: '300px',
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true
        }, {
          header: 'Upload Id',
          columnDef: 'uploadId',
          filter: '',
          cell: '(element: any) => `${element.uploadId}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true
        }, {
          'header': 'First Name', 'columnDef': 'firstName', 'filter': '', 'width': '100px',
          'cell': '(element: any) => `${element.firstName}`', 'order': 3, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'Last Name', 'columnDef': 'lastName', 'filter': '', 'width': '100px',
          'cell': '(element: any) => `${element.lastName}`', 'order': 3, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
        {
          'header': 'File Name', 'columnDef': 'fileName', 'filter': '', 'width': '150px',
          'cell': '(element: any) => `${element.fileName}`', 'order': 3, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
          'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
          'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
          'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        },
         {
          header: 'Type',
          columnDef: 'type',
          filter: '',
          cell: '(element: any) => `${element.type}`',
          order: 12,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true
        }, 
        // {
        //   'header': 'Tournament', 'columnDef': 'tournament', 'filter': '', 'width': '150px',
        //   'cell': '(element: any) => `${element.tournament}`', 'order': 6, 'visible': true, 'isToolTip': false, 'isToolTipCol': '',
        //   'hasMultiData': false, 'class': '', 'color': '', 'isProgressCntrl': false, 'isColoredCntrl': false,
        //   'colList': [], 'isfaicon': false, 'isAddingText': false, 'addingText': '', 'img': false, 'imgPath': '',
        //   'isSort': true, 'columnSearch': true, 'iscolumnSearch': true, 'cFilter': true, 'showColFilter': true
        // }, {
        //   header: 'Event Name',
        //   columnDef: 'eventName',
        //   filter: '',
        //   cell: '(element: any) => `${element.eventName}`',
        //   order: 8,
        //   visible: true,
        //   isToolTip: false,
        //   isToolTipCol: '',
        //   hasMultiData: false,
        //   class: '',
        //   color: '',
        //   isProgressCntrl: false,
        //   isColoredCntrl: false,
        //   colList: [],
        //   isfaicon: false,
        //   isAddingText: false,
        //   addingText: '',
        //   img: false,
        //   imgPath: '',
        //   isSort: true,
        //   iscolumnSearch: true,
        //   cFilter: true,
        //   showColFilter: true,
        //   width: '150px'
        // }, {
        //   header: 'Venue Detail',
        //   columnDef: 'venueDetail',
        //   filter: '',
        //   cell: '(element: any) => `${element.venueDetail}`',
        //   order: 11,
        //   visible: true,
        //   isToolTip: false,
        //   isToolTipCol: '',
        //   hasMultiData: false,
        //   class: '',
        //   color: '',
        //   isProgressCntrl: false,
        //   isColoredCntrl: false,
        //   colList: [],
        //   isfaicon: false,
        //   isAddingText: false,
        //   addingText: '',
        //   img: false,
        //   imgPath: '',
        //   isSort: true,
        //   iscolumnSearch: true,
        //   cFilter: true,
        //   showColFilter: true,
        //   width: '150px'
        // }, 
        {
          header: 'Input Type',
          columnDef: 'inputMedium',
          filter: '',
          cell: '(element: any) => `${element.inputMedium}`',
          order: 13,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true
        },
        {
          header: 'Source Name',
          columnDef: 'ticketSource',
          filter: '',
          cell: '(element: any) => `${element.inputMedium}`',
          order: 13,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true
        },
        {
          header: 'Source User ID',
          columnDef: 'sourceUserId',
          filter: '',
          cell: '(element: any) => `${element.sourceUserId}`',
          order: 9,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true
        },
        {
          header: 'Ticket Id',
          columnDef: 'ticketId',
          filter: '',
          cell: '(element: any) => `${element.ticketId}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true
        },
        {
          header: 'Email',
          columnDef: 'email',
          filter: '',
          cell: '(element: any) => `${element.email}`',
          order: 7,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          saveData: true,
          iscolumnSearch: true,
          cFilter: true,
          showColFilter: true
        },

      ];
    }
    this.getErrorLogs();
  }

  erroshowHideLoading(status) {
    const data = Object.assign({}, this.erroTableOptions);
    this.erroTableOptions = {};
    this.erroTableOptions = data;
    this.erroTableOptions.tableOptions.loading = status;
  }

  erroglobalActionCall(idata) {
    console.log(idata);
    this.erroshowHideLoading(true);
    setTimeout(() => {
      this.erroshowHideLoading(false);
    }, 1000);
  }

  errosortCall($event) {
    this.erroTableOptions.sortOptions = $event;
    let query: any = {};
    query = this.queryParams;
    if ($event.direction === 'desc') {
      query.orderBy = [{ [$event.active]: -1 }];
      this.getErrorLogs();
    } else {
      query.orderBy = [{ [$event.active]: 1 }];
      this.getErrorLogs();
    }

  }
  colfiltercall(val) {
    this.colfil.push(val);
    console.log(val);
    this.colFilterQuery = {};
    this.queryParams = { skip: 0, limit: 30 };
    this.colfil.forEach(ele => {
      this.colFilterQuery[ele.col] = ele.value;
    });
    if (this.colFilterQuery === undefined) {
    this.queryParams = { skip: 0, limit: 30 };
    }
    this.getErrorLogs();
  }

  errofilterCall(idata) {
    const fields = [];
    this.erroTableOptions.columns.forEach(obj => {
      fields.push(obj.columnDef);
    });
    this.erroTableOptions = (idata && idata.length > 0) ? {
      multi_match: {
        query: idata,
        type: 'phrase_prefix',
        fields
      }
    } : undefined;
    this.getErrorLogs();
  }

  erropageCall(event) {
    this.erroTableOptions.tableOptions.pageSize = event.pageSize;
    this.errocurrentPage = event.pageIndex;
    this.getErrorLogs();
  }

  errotimerCallData(idata) {
    this.getErrorLogs();
  }

  errorlogfilterCall(idata) {
    if (idata) {
      this.globalConditions = {};
      const fields = ['created','description', 'firstName', 'lastName','uploadId', 'fileName', 'recievedStatus','sourceUserId',
        'type', 'email','occuredAt', 'customerName', 'uploadId', 'tournament', 'venueDetails', 'stadkinkId', 'ticketId',];
      this.globalsearch = idata.split(',');
      this.globalsearch.forEach(ele => {
        this.globalConditions[ele] = fields;
      });
    }
    this.getErrorLogs();
  }

  getErrorLogs(type = "", download = false) {
    setTimeout(() => {      
    this.erroshowHideLoading(true);
    let params;
    if (this.colFilterQuery) { this.perColConditions = this.colFilterQuery; }
    params = { skip: this.errocurrentPage,
       limit: this.erroTableOptions.tableOptions.pageSize,
        perColConditions: this.perColConditions,
        globalConditions: this.globalConditions
      };
    if (this.tableType) {
      if (!params.perColConditions) { params.perColConditions = {}; }
      params.perColConditions.type = this.tableType;
    }
    if (this.erroTableOptions.sortOptions) {
      params.orderBy = [{}];
      params.orderBy[0][this.erroTableOptions.sortOptions.active] = (this.erroTableOptions.sortOptions.direction === 'asc') ? 1 : -1;
    }
    if (this.globalConditions) {
      params = {
        globalConditions: this.globalConditions
      };
    }
    if (!download) {
      this.erroTableOptions.pageData = [];
      this.erroTableOptions.tableOptions.pageTotal = 0
    }
    else {
      // params = {};
      params["fields"] = { 'created': 'Created At','description': 'Description', 'uploadId': 'Upload Id', 'firstName': 'First Name', 'lastName':'Last Name ','fileName':' File Name','inputMedium':'Input Type ','type': 'Type', 'mail': 'Email Address' }
    }
    if (window.location.href.indexOf("ticket-enablement") > -1) {
      params["perColConditions"] = { "type": "Tickets" }
    }
    else if (window.location.href.indexOf("user-management") > -1) {
      params["perColConditions"] = { "type": "Users" }
    }
    this.apiService.get('error_logs', undefined,
      { query: params,"download": download }).subscribe(result => {
        if (download == true) {
          this.erroshowHideLoading(false);
          window.open(result, "_blank");
          this.erroshowHideLoading(false);
        } else {
          this.globalConditions = '';
          this.loaderService.display(false);
        this.erroTableOptions.pageData = result.data;
        this.erroTableOptions.tableOptions.pageTotal = result.total;
        this.erroshowHideLoading(false);
        console.log("error-log", result);
        }
      }, error => {
        this.loaderService.display(false);
        this.notifyService.notification(JSON.stringify(error), false, 'error');
      });
    }, 5000);
  }

  saveSettings(idata){}

}
