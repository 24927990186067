<button class="mr-4 mt-4 float-right" matTooltip="Close Distributor View" *ngIf="currentCompany && currentCompany._id"
  mat-icon-button type="button" (click)="triggerClose()">
  <mat-icon [icIcon]="ic.icClose"></mat-icon>
</button>
<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
      class="w-full flex sm:flex-row justify-between">
      <div>
        <h1 class="title mt-0 mb-1">Manage <span
            *ngIf="currentCompany && currentCompany.name">{{currentCompany.name}}</span> Distributors</h1>
        <vex-breadcrumbs [crumbs]="['Administration', 'Manage Distributors']"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
    <div class="card overflow-auto -mt-16" *ngIf="bView === 'supplier'">
      <app-s-table aria-label="Manage Distributors" role="table" [sTableOptions]="supplierTableOptions"
        (hyperlinkCallback)="supplierhyperlinkCall($event)" (sortCallback)="suppliersortCall($event)"
        (colFilterCallback)="colFilterCall($event)" (filterCallback)="supplierfilterCall($event)"
        (actionCallback)="supplieractionCall($event)" (parentCallback)="supplierParentCall($event)"
        (globalActionCallback)="supplierglobalActionCall($event)" (pageCallback)="supplierpageCall($event)"
        (refreshCallback)="getSupplier()" (addCallback)="supplieraddTableData()"
        (timerCallback)="suppliertimerCallData($event)"></app-s-table>
    </div>
    <div class="" *ngIf="bView === 'addSupplier'">
      <mat-card class="-mt-14">
        <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
          <h2 class="subheading-2 m-0" fxFlex="auto">Add/Update Distributor</h2>
          <button class="text-secondary" mat-dialog-close mat-icon-button type="button" (click)="closeSupplier()">
            <mat-icon [icIcon]="ic.icClose"></mat-icon>
          </button>
        </div>
        <mat-divider class="text-border"></mat-divider>
        <form #addSupplierForm="ngForm">
          <mat-card-content class="p-3" *ngIf="currentSupplier">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field *ngIf="!currentCompany || !currentCompany._id" class="flex-auto">
                <mat-label>Company Name</mat-label>
                <mat-select (ngModelChange)="getSelectedCompany($event)" *ngIf="companyList" name="company_ref" required
                  [(ngModel)]="currentSupplier.company_ref.id" placeholder="Choose Company">
                  <mat-option *ngFor="let company of companyList" [value]="company._id">{{ company.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class=" flex-auto">
                <mat-label>Supplier Name</mat-label>
                <input matInput name="name" [(ngModel)]="currentSupplier.name" type="text" [required]="true"
                  pattern="^[a-zA-Z][a-zA-Z0-9-_]{1,30}$" placeholder="Enter Supplier Name" />
                <mat-error>Characters Only Allowed</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>Legal Name</mat-label>
                <input matInput name="name" maxlength="64" pattern="^[a-zA-Z][a-zA-Z0-9-_]{1,30}$"
                  [(ngModel)]="currentSupplier.legalname" type="text" [required]="true"
                  placeholder="Enter Legal Name" />
                <mat-error>Characters Only Allowed</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Distributor GSTIN</mat-label>
                <input matInput name="GSTIN" [(ngModel)]="currentSupplier.gstIn" type="text" [min]="3" [max]="32"
                  [required]="true" pattern="^[a-zA-Z0-9 ]+$[^\s]*" placeholder="Enter GSTIN" appGSTCheck />
                <mat-error>No Spacial Characters are allowed,Length is 3-32</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-select (ngModelChange)="getSelectedStates($event)" *ngIf="regionStates" name="region" multiple
                  required [(ngModel)]="currentSupplier.region" placeholder="Choose Region">
                  <mat-option value="*">All</mat-option>
                  <mat-option *ngFor="let region of Objectkeys(regionStates)" [value]="region">{{ region }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-select name="state" multiple required [disabled]="!selectedStates"
                  [(ngModel)]="currentSupplier.state" placeholder="Choose State">
                  <mat-option value="*">All</mat-option>
                  <mat-option *ngFor="let state of selectedStates" [value]="state">
                    {{ state }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>District</mat-label>
                <input matInput name="district" [(ngModel)]="currentSupplier.district" type="text" [required]="true"
                  placeholder="Enter District" />
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Pincode</mat-label>
                <input matInput name="pincodes" [(ngModel)]="currentSupplier.pinCodes" type="text" [required]="true"
                  placeholder="Enter Pincode" (ngModelChange)="changePinCodeValue($event)"
                  pattern="^[a-zA-Z0-9 ]+$[^\s]*" />
                <mat-hint>Enter comma separated pin codes</mat-hint>
                <mat-error>Enter Correct Pincode</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>SAP Code</mat-label>
                <input matInput name="SAPCode" [required]="true" [(ngModel)]="currentSupplier.SAPCode" type="number"
                  placeholder="Enter SAP Code" />
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>BPCL Invoicing Party</mat-label>
                <input matInput name="BPCLInvoicingParty" [(ngModel)]="currentSupplier.BPCLInvoicingParty" type="text"
                  [required]="true" placeholder="Enter BPCL Invoicing Party" />
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Type</mat-label>
                <mat-select required name="supplierType" (ngModelChange)="updateIntegration($event)"
                  [(ngModel)]="currentSupplier.supplierType" placeholder="Choose Supplier Type">
                  <mat-option [value]="1">API</mat-option>
                  <mat-option [value]="2">Manual</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="flex-auto" *ngIf="currentSupplier.supplierType === 1">
                <mat-label>Choose Integration</mat-label>
                <mat-select required name="integration_ref_id" (ngModelChange)="setIntegration($event)"
                  [(ngModel)]="currentSupplier.integration_ref.id" placeholder="Choose Integration">
                  <mat-option *ngFor="let integ of integrationList;" [value]="integ.id">{{integ.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>Minimum Order Value</mat-label>
                <input matInput name="mov" [(ngModel)]="currentSupplier.minOrderValue" type="number" min="0" numeric
                  placeholder="Enter Minimum Order Value" />
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>GSTTDS</mat-label>
                <mat-select required name="GSTTDS" [(ngModel)]="currentSupplier.GSTTDS" placeholder="Choose GSTTDS">
                  <mat-option [value]="'Yes'">Yes</mat-option>
                  <mat-option [value]="'No'">No</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>DigitalInvoice</mat-label>
                <mat-select required name="DigitalInvoice" [(ngModel)]="currentSupplier.DigitalInvoice"
                  placeholder="Choose DigitalInvoice">
                  <mat-option [value]="'Yes'">Yes</mat-option>
                  <mat-option [value]="'No'">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <p class="text-bold py-2">Address</p>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>Region</mat-label>
                <input matInput name="address_region" [(ngModel)]="currentSupplier.address.region" type="text"
                  [required]="true" placeholder="Enter Region" />
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <input matInput name="address_state" [(ngModel)]="currentSupplier.address.state" type="text"
                  [required]="true" placeholder="Enter State" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>Address Line 1</mat-label>
                <input matInput name="address_1" [(ngModel)]="currentSupplier.address.address[0]" type="text"
                  [required]="true" placeholder="Enter Address Line 1" />
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Address Line 2</mat-label>
                <input matInput name="address_2" [(ngModel)]="currentSupplier.address.address[1]" type="text"
                  placeholder="Enter Address Line 2" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>Landmark</mat-label>
                <input matInput name="landmark" [(ngModel)]="currentSupplier.address.landmark" type="text"
                  [required]="true" placeholder="Enter landmark" />
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Pincode</mat-label>
                <input matInput name="pincode" [(ngModel)]="currentSupplier.address.pincode" type="text"
                  (ngModelChange)="changePinCodeValue($event)" [required]="true" placeholder="Enter pincode"
                  pattern="^[a-zA-Z0-9 ]+$[^\s]*" />
                <mat-error>Enter Correct Pincode</mat-error>
              </mat-form-field>
            </div>
            <p class="text-bold py-2">Primary Contact Details</p>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>Name</mat-label>
                <input matInput name="pname" [(ngModel)]="currentSupplier.primaryContact.name" type="text"
                  [required]="true" placeholder="Enter Primary Contact Name" pattern="^[a-zA-Z][a-zA-Z0-9-_]{1,30}$" />
                <mat-error>Characters Only Allowed</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Mobile</mat-label>
                <input matInput name="pmobile" appMobileCheck [(ngModel)]="currentSupplier.primaryContact.contact"
                  minlength="10" pattern="^((\\+91-?)|0)?[0-9]{10}$$[^\s]*" maxlength="10" type="tel" [required]="true"
                  placeholder="Enter Primary Contact Mobile" />
                <mat-error>Enter Correct Phone Number</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Email</mat-label>
                <input matInput name="pemail" [(ngModel)]="currentSupplier.primaryContact.email" type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [required]="true"
                  placeholder="Enter Primary Contact Email" />
                <mat-error>Enter Correct Email</mat-error>
              </mat-form-field>
            </div>
            <p class="text-bold py-2">Secondary Contact Details</p>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
              <mat-form-field class="flex-auto">
                <mat-label>Name</mat-label>
                <input matInput name="sname" [(ngModel)]="currentSupplier.secondaryContact.name" type="text"
                  pattern="^[a-zA-Z][a-zA-Z0-9-_]{1,30}$" placeholder="Enter Secondary Contact Name" />
                <mat-error>Characters Only Allowed</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Mobile</mat-label>
                <input matInput name="smobile" appMobileCheck [(ngModel)]="currentSupplier.secondaryContact.contact"
                  minlength="10" pattern="^((\\+91-?)|0)?[0-9]{10}$$[^\s]*" maxlength="10" type="tel"
                  placeholder="Enter Secondary Contact Mobile" />
                <mat-error>Enter Correct Phone Number</mat-error>
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Email</mat-label>
                <input matInput name="semail" [(ngModel)]="currentSupplier.secondaryContact.email" type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" placeholder="Enter Secondary Contact Email" />
                <mat-error>Enter Correct Email</mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
          <mat-card-actions align="end" *ngIf="currentSupplier">
            <button (click)="saveSupplier()" *ngIf="!currentSupplier._id"
              [disabled]="!addSupplierForm.form.valid || isLoading" mat-raised-button class="mat-primary" type="submit">
              ADD DISTRIBUTOR
            </button>
            <button *ngIf="currentSupplier._id" (click)="updateSupplier(currentSupplier)"
              [disabled]="!addSupplierForm.form.valid || isLoading" mat-raised-button class="mat-primary" type="submit">
              UPDATE
            </button>
            <button mat-button mat-dialog-close type="button" (click)="closeSupplier()">
              CLOSE
            </button>
          </mat-card-actions>
        </form>
      </mat-card>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
<s-modal id="addSupplier">
  <mat-card>
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">Add Distributors</h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('addSupplier')">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <form #addSupplierForm="ngForm">
      <mat-card-content class="p-3" *ngIf="currentSupplier">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
          <mat-form-field class="resp-input-w">
            <mat-label>Name</mat-label>
            <input matInput name="name" pattern="^[a-zA-Z][a-zA-Z0-9-_]{1,30}$" [(ngModel)]="currentSupplier.name"
              type="text" [required]="true" placeholder="Enter Name" />
            <mat-error>Characters Only Allowed</mat-error>
          </mat-form-field>
          <mat-form-field class="resp-input-w">
            <mat-label>Description</mat-label>
            <input matInput name="description" [(ngModel)]="currentSupplier.description" type="text" [required]="true"
              placeholder="Enter description" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
          <mat-form-field class="resp-input-w">
            <mat-label>Country</mat-label>
            <input matInput name="country" [(ngModel)]="currentSupplier.country" type="text" [required]="true"
              placeholder="Enter country" />
          </mat-form-field>
          <mat-form-field class="resp-input-w" *ngIf="regionStates">
            <mat-label>Region</mat-label>
            <mat-select name="region" (ngModelChange)="getSelectedStates($event)" *ngIf="regionStates" name="region"
              multiple required [(ngModel)]="currentSupplier.address.region" placeholder="Choose Region">
              <mat-option value="*">All</mat-option>
              <mat-option *ngFor="let region of Objectkeys(regionStates)" [value]="region">{{ region }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
          <mat-form-field class="resp-input-w" *ngIf="regionStates">
            <mat-label>State</mat-label>
            <mat-select name="state" multiple required [disabled]="!selectedStates"
              [(ngModel)]="currentSupplier.address.state" placeholder="Choose State">
              <mat-option value="*">All</mat-option>
              <mat-option *ngFor="let state of selectedStates" [value]="state">
                {{ state }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="resp-input-w">
            <mat-label>Pincode</mat-label>
            <input matInput name="pincode" [(ngModel)]="currentSupplier.pincode" type="text" placeholder="Enter pincode"
              (ngModelChange)="changePinCodeValue($event)" [required]="true" pattern="^[a-zA-Z0-9 ]+$[^\s]*" />
            <mat-error>Enter Correct Pincode</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
          <mat-form-field class="resp-input-w">
            <mat-label>Mobile</mat-label>
            <input matInput name="mobile" appMobileCheck [(ngModel)]="currentSupplier.mobile" type="tel" minlength="10"
              pattern="^((\\+91-?)|0)?[0-9]{10}$$[^\s]*" maxlength="10" [required]="true" placeholder="Enter mobile" />
            <mat-error>Enter Correct Phone Number</mat-error>
          </mat-form-field>
          <mat-form-field class="resp-input-w">
            <mat-label>Address</mat-label>
            <input matInput name="address" [(ngModel)]="currentSupplier.address" type="text" [required]="true"
              placeholder="Enter address" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
          <mat-form-field class="resp-input-w">
            <mat-label>GSTIN</mat-label>
            <input matInput name="taxidentifier" [(ngModel)]="currentSupplier.taxidentifier" type="text" [min]="3"
              pattern="^[a-zA-Z0-9 ]+$[^\s]*" [max]="32" [required]="true" placeholder="Enter GST Number" appGSTCheck />
            <mat-error>No Spacial Characters are allowed,Length is 3-32</mat-error>
          </mat-form-field>
          <mat-form-field class=" resp-input-w">
            <mat-label>Type</mat-label>
            <mat-select required name="suppliertype" [(ngModel)]="currentSupplier.suppliertype"
              placeholder="Choose Supplier Type">
              <mat-option [value]="1">API</mat-option>
              <mat-option [value]="2">Manual</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions align="end" *ngIf="currentSupplier">
        <button [disabled]="!addSupplierForm.form.valid || isLoading" *ngIf="!currentSupplier._id"
          (click)="saveSupplier()" mat-raised-button class="mat-primary" type="submit">
          ADD NEW
        </button>
        <button *ngIf="currentSupplier._id" (click)="updateSupplier(currentSupplier)"
          [disabled]="!addSupplierForm.form.valid || isLoading" mat-raised-button class="mat-primary" type="submit">
          UPDATE
        </button>
        <button mat-button mat-dialog-close type="button" (click)="modalService.close('addSupplier')">
          CLOSE
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</s-modal>
<s-modal id="uploadProducts">
  <mat-card>
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">Upload Product Master For
        <span *ngIf="currentSupplier">{{currentSupplier.name}}</span>
      </h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('uploadProducts')">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <form #prodMasterForm="ngForm">
      <mat-card-content class="p-3">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
          <input type="file" accept=".csv,.xlsx,.xls" name="file" required
            (change)="uploadCSVFile($event, 'uploadfile')" />
          <mat-hint _ngcontent-opx-c260="" class="mat-hint text-us pt-2">File Format:
            *.CSV, *.XLSX, *.XLS</mat-hint>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button class="mat-primary" [disabled]="!prodMaster.uploadfile || isLoading" type="button"
          (click)="saveProductMasterFile()">
          SAVE
        </button>
        <button mat-button mat-dialog-close type="button" (click)="modalService.close('uploadProducts')">
          CLOSE
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</s-modal>