// tslint:disable: no-any
// tslint:disable: no-unsafe-any
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url = '';
  serviceApiUrl = '';
  httpOptions: any;
  accessToken: any;
  constructor(public http: HttpClient) {
    this.serviceApiUrl = '';
    this.url = `${this.serviceApiUrl}/api`;
    this.accessToken = sessionStorage.getItem('authToken');
    this.setHeader();
  }

  setHeader() {
    if (this.accessToken === null) {
      this.httpOptions = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('restrictAccess', 'False');
    } else {
      this.httpOptions = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Authorization', this.accessToken)
        .set('restrictAccess', 'False');
    }
  }

  getSnakeCaseName(camelCase: string) {
    return camelCase
      .replace(/([A-Z])/g, '_$1')
      .toLowerCase()
      .replace(/^_(.*)/g, '$1');
  }

  get(endpoint: string, id?: string, getData?: any, reqOpts?: any) {
    // endpoint = this.getSnakeCaseName(endpoint)
    if (id !== undefined && id !== "novenue") {
      endpoint = `${endpoint}/${id}`;
    }
    else if( id === "novenue"){
      endpoint = `${endpoint}`;
    }
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    // Support easy query params for GET requests
    if (getData) {
      reqOpts.params = new HttpParams();
      for (const key in getData) {
        if (getData.hasOwnProperty(key)) {
          reqOpts.params = reqOpts.params.append(key, JSON.stringify(getData[key]));
        }
      }
    }

    reqOpts.headers = this.httpOptions;
// @ts-ignore
    return this.http.get(`${this.url}/${endpoint}`, { headers: reqOpts.headers, params: reqOpts.params }).map(getResp => {
      const getResponse: any = getResp;
      if (getResponse.status !== undefined && getResponse.status === 'error') {
        throw getResponse;
      }
      if (getResponse.status !== undefined && getResponse.status === 'ok') {
        return getResponse.msg;
      } else {
        throw getResponse;
      }
    });
  }

  post(endpoint: string, data: any, reqOpts?: any) {
    // endpoint = this.getSnakeCaseName(endpoint)
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    reqOpts.headers = this.httpOptions; // @ts-ignore
    return this.http.post(`${this.url}/${endpoint}`, data, reqOpts).map(postResp => {
      const postResponse: any = postResp;
      if (postResponse.status !== undefined && postResponse.status === 'error') {
        throw postResponse;
      }
      if (postResponse.status !== undefined && postResponse.status === 'ok') {
        return postResponse.msg;
      } else {
        throw postResponse;
      }
    });
  }

  action(endpoint: string, id: string, action: string, data: any, reqOpts?: any) {
    // endpoint = this.getSnakeCaseName(endpoint)
    console.log(`${this.url}/${endpoint}/${id}/${action}`);
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    reqOpts.headers = this.httpOptions;
    let urli=`${this.url}/${endpoint}/${id}/${action}`
    if (id === "norole"){
      urli=`${this.url}/${endpoint}/${action}`
    }
    return this.http

      .post(urli, data, reqOpts)// @ts-ignore
      .map(postAcionResp => {
        const postAcionResponse: any = postAcionResp;
        if (postAcionResponse.status !== undefined && postAcionResponse.status === 'error') {
          throw postAcionResponse;
        }
        if (postAcionResponse.status !== undefined && postAcionResponse.status === 'ok') {
          return postAcionResponse.msg;
        } else {
          throw postAcionResponse;
        }
      });
  }

  put(endpoint: string, id: string, data: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    reqOpts.headers = this.httpOptions;
    endpoint = `${endpoint}/${id}`; // @ts-ignore
    return this.http.put(`${this.url}/${endpoint}`, data, reqOpts).map(putResp => {
      const putResponse: any = putResp;
      if (putResponse.status !== undefined && putResponse.status === 'error') {
        throw putResponse;
      }
      if (putResponse.status !== undefined && putResponse.status === 'ok') {
        return putResponse.msg;
      } else {
        throw putResponse;
      }
    });
  }

  delete(endpoint: string, id: string, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    reqOpts.headers = this.httpOptions;
    endpoint = `${endpoint}/${id}`; // @ts-ignore
    return this.http.delete(`${this.url}/${endpoint}`, reqOpts).map(deleteResp => {
      const deleteResponse: any = deleteResp;
      if (deleteResponse.status !== undefined && deleteResponse.status === 'error') {
        throw deleteResponse;
      }
      if (deleteResponse.status !== undefined && deleteResponse.status === 'ok') {
        return deleteResponse.msg;
      } else {
        throw deleteResponse;
      }
    });
  }
}
