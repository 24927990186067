import { Injectable, EventEmitter } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  // public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }
  tableProgressOff: Subject<object> = new Subject<object>();
  public status = new EventEmitter();
  public ModelStatus = new EventEmitter();
  public selectedSiteChanged = new EventEmitter();
  public setCompanyTab = new EventEmitter();

  private data: number | undefined;
  private tabNo: number | undefined;

  tableProgress(value: boolean): void {
    this.tableProgressOff.next({val: value});
  }

  display(value: boolean, text?: any) {
    this.status.next({ value, text });
  }

  Modeldisplay(value: boolean, text?: any) {
    this.ModelStatus.next({ value, text });
  }
  selectedSiteChange(value: any) {
    this.selectedSiteChanged.next(value);
  }

  changeCompanyTab(value: any) {
    this.setCompanyTab.next(value);
  }

  setOption(value) {
    this.data = value;
  }

  getOption() {
    return this.data;
  }

  setTabNo(value) {
    this.tabNo = value;
  }

  getTabNo() {
    return this.tabNo;
  }
}
