<mat-form-field class="w-50 position-absolute z-index-10 mt-1 ml-6">
    <mat-label>Dashboard view</mat-label>
    <mat-select [multiple]="false" (ngModelChange)="updateDash($event)" placeholder="Choose dashboard view"
    [(ngModel)]="dashUrl">
      <mat-select-trigger class="flex flex-row align-items-center">
        <span><mat-icon color="primary" [icIcon]="ic.icDashboard"></mat-icon> </span>
        <span class="mt-1 ml-2">{{currentDashboard}}</span>
      </mat-select-trigger>
      <mat-option *ngFor="let dash of dashList"
        [value]="dash.url">
        <span><mat-icon color="primary" [icIcon]="ic.icDashboard"></mat-icon> </span>
        <span class="ml-1">{{dash.name}}</span>
      </mat-option>
    </mat-select>
</mat-form-field>
<ng-container *ngIf="dashUrl">
  <div class="h-100vh -mt-5">
    <div class="iframe-container">
      <iframe [src]="dashUrl | safe" allowfullscreen></iframe>
    </div>
  </div>
</ng-container>
