import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaseRequestService } from './base.service';
import { CommonService } from './common.services';
import { UserIdleService } from 'angular-user-idle';
import { LoaderService } from './loader.service';
import { MyToastrService } from './toastr.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icGroup from '@iconify/icons-ic/group';
import icPerson from '@iconify/icons-ic/person';
import icSettings from '@iconify/icons-ic/twotone-settings';
// import { NavigationService } from "../../@vex/services/navigation.service";
import { TranslocoService } from '@ngneat/transloco';
import { MenuService } from './menu.service';

interface User {
  role: string;
  entityId: any;
  parentEntityId: any;
  contact: any;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  family_name: string;
  given_name: string;
  permissions: any;
  usertype?: any;
  isNewSetup: boolean;
  isWLB: boolean;
  isMSP: boolean;
  state: string;
  region: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  timeoutSec: any;
  targetTimeout: any;
  sessionUpdated: Subject<object> = new Subject<object>();
  sessionLoader = new BehaviorSubject<boolean>(false);
  sessionTimeout$ = this.sessionLoader.asObservable();
  isAuthenticated = true;
  currentUser: User | undefined;
  currentEntity: any = {};
  resultOut: any;
  tmpData: any;
  cspChange: Subject<object> = new Subject<object>();
  resultOutChange: Subject<any> = new Subject<any>();
  tfaOutChange: Subject<any> = new Subject<any>();
  currentUserChange: Subject<object> = new Subject<object>();
  companyHashMap: any = {};
  logoutWindow: any;
  userData: any;
  entity: any;
  translateFileAvailable = false;

  constructor(
    readonly router: Router,
    // private navigationService: NavigationService,
    public baseRequestService: BaseRequestService,
    private commonService: CommonService,
    readonly location: Location,
    private userIdle: UserIdleService,
    private loaderService: LoaderService,
    private toast: MyToastrService,
    public translationService: TranslocoService,
    private menuService: MenuService
  ) {
    this.isAuthenticated = false;
    this.currentUser = undefined;
    this.userData = undefined;
    this.resultOut = undefined;
    this.currentUserChange.next(this.currentUser);
    this.resultOutChange.next(this.resultOut);
  }

  setCSP(): void {
    this.cspChange.next({ csp: true });
  }

  isBase64(str: string): boolean {
    try {
      return atob(btoa(str)) === str;
    } catch (err) {
      return false;
    }
  }

  hasPermission(cname: string, action: string): boolean {
    /* permissions: {me: ["read"], company: ["read", "update"]} */
    return !!(
      this.currentUser &&
      this.currentUser.permissions[cname] &&
      this.currentUser.permissions[cname].includes(action.toLowerCase())
    );
  }

  getCEntity(): void {
    if (!this.currentUser.entityId) {
      return;
    }
    this.loaderService.display(true);
    this.baseRequestService
      .doRequest(`/api/entity/${this.currentUser.entityId}`, 'get')
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.currentEntity = result;
      });
  }

  getSessionSettings(): void {
    console.log('Session settings... ');
    this.loaderService.display(true, 'Getting session data...');
    if (localStorage.getItem('isHidden') === null) {
      localStorage.setItem('isHidden', 'false');
    }
    this.setSession({ idle: 1800, timeout: 1800, ping: 480 });
    /*this.baseRequestService
      .doRequest("/api/cyberutils/imaws/getSessionTimeoutSettings", "post", {})
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res) {
          console.log("Got session data" + JSON.stringify(res));
          this.setSession(res);
        }
      });*/
  }

  setSession(session: any): void {
    console.log('Got session data' + JSON.stringify(session));
    this.userIdle.stopWatching();
    this.userIdle.setConfigValues({
      idle: session.idle,
      timeout: session.timeout,
      ping: session.ping,
    });
    // Start watching for user inactivity.
    this.userIdle.startWatching();
    console.log('Start session data' + JSON.stringify(session));
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      if (!count) {
        return;
      }
      if (!this.timeoutSec) {
        this.targetTimeout =
          Math.floor(new Date().getTime() / 1000.0) + session.timeout;
      }
      const currentTime = Math.floor(new Date().getTime() / 1000.0);
      this.timeoutSec = this.targetTimeout - currentTime;
      if (this.targetTimeout < currentTime) {
        this.timeoutSec = 1;
        this.logout();
      }
      console.log(this.timeoutSec);
      this.sessionLoader.next(true);
    });
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.sessionLoader.next(false);
      this.logout();
    });
    this.userIdle.ping$.subscribe(() => this.keepalive());
  }

  resetSession(): void {
    this.sessionLoader.next(false);
    this.sessionUpdated.next({ value: true });
    this.userIdle.resetTimer();
  }

  login(loginData: any): void {
    this.loaderService.display(true);
    this.baseRequestService
      .doRequest('/usermgmt/api/auth', 'post', loginData)
      .subscribe((result: any) => {
        if (result.status === 'error') {
          this.resultOutChange.next(result);
          this.loaderService.display(false);
        } else {
          this.resultOut = result;
          //  console.log(result);
          localStorage.setItem('uasaeara', btoa(loginData.email));
          if (this.resultOut) {
            // Start watching for user inactivity.
            this.userIdle.startWatching();

            // Start watching when user idle is starting.
            this.userIdle.onTimerStart().subscribe((count) => {
              console.log(count);
              this.sessionLoader.next(true);
            });

            // Start watch when time is up.
            this.userIdle.onTimeout().subscribe(() => this.logout());
            this.userIdle.ping$.subscribe(() => this.keepalive());

            if (this.resultOut.location) {
              window.location = this.resultOut.location;
              this.companyHashCall();
              this.loaderService.display(false);
            } else if (this.resultOut.requiredMFA) {
              this.tfaOutChange.next({ mfa: true });
            } else {
              this.afterLogin(this.resultOut, false);
            }
          } else {
            this.loaderService.display(false);
            this.afterLogout();
          }
        }
      });
  }

  sessionInit(): void {
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      console.log(count);
      if (count) {
        this.sessionLoader.next(true);
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => this.logout());
    this.userIdle.ping$.subscribe(() => this.keepalive());
  }

  logout(): void {
    this.loaderService.display(true, 'Logging out...');
    this.baseRequestService.doRequest('/api/logout', 'get').subscribe(
      (re: any) => {
        window.location.href = `${re.url}`;
      },
      (err: any) => {
        window.location.href = `${err.error.url}`;
      }
    );
  }

  logout_o(): void {
    this.loaderService.display(true, 'Logging out...');
    this.baseRequestService.doRequest('/api/logout', 'get').subscribe(
      (re: any) => {
        this.logoutWindow = window.open(
          re.url,
          'NFR Logout',
          'width=200, height=100'
        );
        setTimeout(() => {
          window.location.href = `/`;
          this.logoutWindow.close();
        }, 5000);
        this.afterLogout();
      },
      (err: any) => {
        this.logoutWindow = window.open(
          err.error.url,
          'NFR Logout',
          'width=200, height=100'
        );
        setTimeout(() => {
          window.location.href = `/`;
          this.logoutWindow.close();
        }, 5000);
        this.afterLogout();
      }
    );
  }

  getEntity(): void {
    if (!this.currentUser.parentEntityId) {
      return;
    }
    this.loaderService.display(true, 'Getting entity...');
    this.baseRequestService
      .doRequest(`/api/entity/${this.currentUser.parentEntityId}`, 'get')
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          this.entity = result;
        } else {
          this.toast.sToast('error', result);
        }
      });
  }

  checkSession(): void {
    this.loaderService.display(true);
    this.baseRequestService.doRequest(`/api/me`, 'get').subscribe(
      (result: any) => {
        this.loaderService.display(false);
        this.tmpData = result;
        if (this.tmpData === 'Already logged in' || result) {
          const shouldCheckPath = !this.tmpData.isNewSetup;
          this.afterLogin(this.tmpData, shouldCheckPath);
        } else {
          this.afterLogout();
        }
      },
      (error: any) => {
        if (error.ok === false) {
          if (error.status === 401) {
            if (!error.error.url) {
              this.toast.sToast(
                'error',
                'Redirect URL not found. Contact Admin.'
              );
            } else {
              const path = this.location.path();
              if (path === '' || path === '/' || path === '/login') {
                window.location = error.error.url;
              }
            }
            this.isAuthenticated = false;
            this.currentUser = undefined;
          } else {
            // this.afterLogout();
            console.log('Check Session error came..');
          }
        } else {
          this.afterLogout();
          console.log('Check Session error came..');
        }
      }
    );
  }

  keepalive(): void { }

  companyHashCall(): void {
    this.companyHashMap = {};
    this.loaderService.display(true);
    this.baseRequestService
      .doRequest(`/api/company/`, 'get', 'null', {
        query: {},
        skip: 0,
        limit: 1000,
      })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          if (result.data.length === 0) {
            this.router
              .navigateByUrl('/companies/onboarding')
              .then(() => console.log('redirected to on-boarding'));
          } else {
            result.data.forEach((obj: { _id: string | number; name: any }) => {
              this.companyHashMap[obj._id] = obj.name;
            });
          }
        }
      });
  }

  private afterLogin(result: User, shouldCheckPath: boolean): void {
    // this.companyHashCall();
    this.isAuthenticated = true;
    // result.usertype = 'CONSUMER';
    this.currentUser = result;
    this.getEntity();
    this.getCEntity();
    this.loaderService.display(true);
    const path: string | null = localStorage.getItem('path');
    const pathLocation = path && true ? path : '';
    this.getSessionSettings();
    this.loaderService.display(false);
    this.processMenu();
    if (
      !shouldCheckPath ||
      this.location.path() === '' ||
      this.location.path() === '/'
    ) {
      this.router
        .navigateByUrl('/')
        .then(() => console.log('redirecting to dashboard'));
    } else if (this.location.path() === '/login' && pathLocation !== '') {
      this.router
        .navigateByUrl(pathLocation)
        .then(() => console.log('redirected to stored path'));
    } else {
      this.router
        .navigateByUrl('/')
        .then(() => console.log('redirecting to dashboard'));
    }
  }

  processMenu(): void {
    // if (this.translateFileAvailable) {
    //   if (this.isAdmin()) {
    //     this.navigationService.items = [
    //       {
    //         type: "link",
    //         label: "Home",
    //         route: "/",
    //         // icon: icLayers,
    //         img: "assets/svg/dashboard.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Manage Company",
    //         route: "/settings/company",
    //         isVisible: this.hasPermission("company", "read"),
    //         img: "assets/svg/administration.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Manage Distributor",
    //         route: "/inventory/suppliers",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("supplier", "read"),
    //         img: "assets/svg/administration.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Distributor Catalogue",
    //         route: "/inventory/supplier-catalog",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("suppliermaster", "read"),
    //         img: "assets/svg/admin-inventory.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Master Catalogue",
    //         route: "/inventory/inventory-master",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("productmaster", "read"),
    //         img: "assets/svg/admin-inventory.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Manage Ratesheet",
    //         route: "/inventory/ratecard",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("ratecard", "read"),
    //         img: "assets/svg/reports.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },

    //       /* {
    //         type: "link",
    //         label: "Catalogue",
    //         route: "/inventory/category",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("category", "read"),
    //         img: "assets/svg/catalogue.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       }, */

    //       {
    //         type: "link",
    //         label: "Manage IO Store",
    //         route: "/settings/io-store",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("entity", "read"),
    //         img: "assets/svg/administration.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Manage UD",
    //         route: "/settings/ud",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("entity", "read"),
    //         img: "assets/svg/administration.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Manage Coupons",
    //         route: "/inventory/manage-coupons",
    //         img: "assets/svg/administration.svg",
    //         isVisible: this.hasPermission("coupons", "read"),
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Manage Users",
    //         route: "/settings/users",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("kusers", "read"),
    //         img: "assets/svg/administration.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Manage Roles",
    //         route: "/settings/roles",
    //         // icon: icLayers,
    //         isVisible: this.hasPermission("kroles", "read"),
    //         img: "assets/svg/administration.svg",
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Manage Shipment",
    //         route: "/orders/cart-orders/1",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: this.hasPermission("orders", "read"),
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Reports",
    //         route: "/reports/",
    //         // icon: icLayers,
    //         img: "assets/svg/reports.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       /*{
    //         type: 'link',
    //         label: 'Manage Carousels',
    //         route: '/settings/manage-carousels',
    //         // icon: icLayers,
    //         img: 'assets/svg/administration.svg',
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },*/
    //       {
    //         type: "link",
    //         label: "Administration",
    //         route: "/settings/administration",
    //         img: "assets/svg/administration.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //     ];
    //   } else if (this.isBUD()) {
    //     this.navigationService.items = [
    //       // {
    //       //     type: 'link',
    //       //     label: 'Dashboard',
    //       //     route: '/',
    //       //     icon: icLayers,
    //       //     isVisible: true,
    //       //     routerLinkActiveOptions: {exact: true}
    //       // },
    //       {
    //         type: "link",
    //         label: this.translationService.translate("menu.pos", {}, this.translationService.getActiveLang()),
    //         route: "/inventory/sell",
    //         // icon: icLayers,
    //         img: "assets/svg/pos.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },

    //       /*{
    //         type: "link",
    //         label: "Request",
    //         route: "/inventory/buy",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },*/
    //       {
    //         type: "link",
    //         label: "My Inventory",
    //         route: "/inventory/inventory",
    //         // icon: icLayers,
    //         img: "assets/svg/inventory.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "My Orders",
    //         route: "/orders/orders-landing",
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         /*routerLinkActiveOptions: { exact: true },*/
    //       },
    //       /* {
    //         type: "link",
    //         label: "Open Orders",
    //         route: "/orders/open-orders",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       }, */
    //       /* {
    //         type: "link",
    //         label: "Online Orders",
    //         route: "/orders/online-orders",
    //         // icon: icLayers,
    //         img: "assets/svg/online-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       }, */
    //       /*{
    //         type: "link",
    //         label: "Stock Transfer Orders",
    //         route: "/orders/stock-transfer/2",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Consumer Orders",
    //         route: "/orders/consumer-orders/3",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },*/
    //       {
    //         type: "link",
    //         label: "Payment Dashboard",
    //         route: "/orders/payment-dashboard",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       /*{
    //         type: "link",
    //         label: "Return/Exchange",
    //         route: "/inventory/returns/exchange",
    //         // icon: icLayers,
    //         img: "assets/svg/return-exchange.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },*/
    //       {
    //         type: "link",
    //         label: "Administration",
    //         route: "/administration/bud-agent",
    //         // icon: icLayers,
    //         img: "assets/svg/authentication.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       /*{
    //         type: "link",
    //         label: "Contact Us",
    //         route: "/contact-us",
    //         // icon: icLayers,
    //         img: "assets/svg/contact-us.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },*/
    //     ];
    //   }
    //   if (this.isBUDC()) {
    //     this.navigationService.items = [
    //       {
    //         type: "link",
    //         label: "Home",
    //         route: "/",
    //         // icon: icLayers,
    //         img: "assets/svg/dashboard.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "POS",
    //         route: "/inventory/sell",
    //         // icon: icLayers,
    //         img: "assets/svg/pos.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "My Inventory",
    //         route: "/inventory/inventory",
    //         // icon: icLayers,
    //         img: "assets/svg/inventory.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "My Orders",
    //         route: "/orders/orders-landing",
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         /*routerLinkActiveOptions: { exact: true },*/
    //       },
    //       /*{
    //         type: "link",
    //         label: "Order Placement",
    //         route: "/inventory/purchase",
    //         // icon: icLayers,
    //         img: "assets/svg/pos.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Order Status",
    //         route: "/orders/cart-orders/1",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Consumer Orders",
    //         route: "/orders/consumer-orders/3",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Stock Transfer Orders",
    //         route: "/orders/stock-transfer/2",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },*/
    //       {
    //         type: "link",
    //         label: "Payment Dashboard",
    //         route: "/orders/payment-dashboard",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Administration",
    //         isVisible: true,
    //         route: "/settings/iostore-admin",
    //         // icon: icLayers,
    //         img: "assets/svg/administration.svg",
    //       },
    //       /*{
    //         type: "link",
    //         label: "Manage UD",
    //         isVisible: true,
    //         route: "/settings/ud",
    //         // icon: icLayers,
    //         img: "assets/svg/open-orders.svg",
    //       },
    //       {
    //         type: "link",
    //         label: "Manage Store",
    //         isVisible: true,
    //         route: "/settings/store-users",
    //         // icon: icLayers,
    //         img: "assets/svg/administration.svg",
    //       },*/
    //     ];
    //   } else if (this.isConsumer()) {
    //     // this is extra -> should be else if
    //     this.navigationService.items = [
    //       {
    //         type: "link",
    //         label: "Home",
    //         route: "/consumer/home",
    //         img: "assets/svg/dashboard.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Buy",
    //         route: "/consumer/buy",
    //         img: "assets/svg/pos.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "My Orders",
    //         route: "/consumer/my-orders",
    //         img: "assets/svg/open-orders.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Contact-Us",
    //         route: "/consumer/contact-us",
    //         img: "assets/svg/contact-us.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //       {
    //         type: "link",
    //         label: "Settings",
    //         route: "/consumer/settings",
    //         img: "assets/svg/administration.svg",
    //         isVisible: true,
    //         routerLinkActiveOptions: { exact: true },
    //       },
    //     ];
    //   }
    // } else {
    //   setTimeout(() => { this.processMenu() }, 200)
    // }
    this.menuService.processMenu(this.currentUser);
  }

  afterLogout(): void {
    this.isAuthenticated = false;
    this.currentUser = undefined;
    localStorage.clear();
    if (this.router.url.indexOf('resetpassword') > -1) {
      console.log('showing reset password. no redirect');
    } else {
      setTimeout(() => {
        this.router
          .navigateByUrl('/login')
          .then(() => console.log('redirected to login'));
      }, 500);
    }
  }

  isConsumer(): boolean {
    return !!(this.currentUser && this.currentUser.usertype === 'CONSUMER');
  }

  isBUDC(): boolean {
    return !!(this.currentUser && (this.currentUser.usertype === 'BUDC' || this.currentUser.usertype === 'StoreManager' || this.currentUser.usertype === 'StoreUser'));
  }

  isBUD(): boolean {
    return !!(this.currentUser && this.currentUser.usertype === 'BUD');
  }

  isAdmin(): boolean {
    return !!(this.currentUser && this.currentUser.usertype === 'ADMIN');
  }
}
