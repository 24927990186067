import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  NavigationItem,
  NavigationLink,
} from '../../interfaces/navigation-item.interface';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { trackByRoute } from '../../utils/track-by';
import { ApiService } from 'src/app/_services/api.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CommonService } from '../../../app/_services/common.services';

@Component({
  selector: 'vex-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent implements OnInit, AfterViewInit {
  @Input() item: NavigationItem;
  @Input('userRoles') userRoles;
  taskUser: any;
  isActive$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => (item: NavigationItem) => this.hasActiveChilds(item))
  );

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;
  trackByRoute = trackByRoute;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private apiService: ApiService,
    public http: HttpClient,
    public authService: AuthenticationService,
    public cS: CommonService
  ) {
    console.log('userRoles', this.userRoles);
    this.cS.newNavigationMenuObservable.subscribe((res) => {
      console.log('new navigation list');
    });
  }

  ngAfterViewInit() {
    this.getUserRoles();
  }

  ngOnInit() { }

  getUserRoles() {
    const roles = [];
    this.apiService.get('users/me').subscribe((obj: any) => {
      if (obj.approvedRoles && obj.approvedRoles.length > 0) {
        obj.approvedRoles.forEach((ele) => {
          if (
            ele.markedFor === undefined ||
            ele.markedFor === '' ||
            ele.markedFor === 'Addition'
          ) {
            roles.push(ele.roleName);
          }
        });
      }
    });
    if (roles && roles.length) {
      this.authService.currentUser.permissions = roles;
    }
  }

  hasActiveChilds(parent: NavigationItem): boolean {
    if (this.isLink(parent)) {
      return this.router.isActive(parent.route as string, true);
    }

    if (this.isDropdown(parent) || this.isSubheading(parent)) {
      return parent.children.some((child) => {
        if (this.isDropdown(child)) {
          return this.hasActiveChilds(child);
        }

        if (this.isLink(child) && !this.isFunction(child.route)) {
          return this.router.isActive(child.route as string, true);
        }

        return false;
      });
    }

    return false;
  }

  isFunction(prop: NavigationLink['route']) {
    return prop instanceof Function;
  }

  isVisible(item: any): boolean {
    return item.isVisible && item.isVisible !== false;
  }
}
