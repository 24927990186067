import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoaderService } from '../../_services/loader.service';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { CommonService } from '../../_services/common.services';
import { IconService } from '../../_services/icon.service';
import { MyToastrService } from '../../_services/toastr.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { BaseRequestService } from '../../_services/base.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'vex-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})

export class TransactionHistoryComponent implements OnInit {
  @Input() currentEntity: any;
  layoutCtrl = new FormControl('boxed');
  transactionHistoryTableOptions: any;
  transactionHistorycurrentPage = 0;
  transactionHistoryfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  Objectkeys = Object.keys;
  isLoading = false;
  showSubTransactionHistory = false;
  otpSent = false;
  isResendOTP = false;
  currentTransactionHistory: any = { amount: 0, entityid: '' };
  constructor(
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private router: Router,
    public toast: MyToastrService,
    private loaderService: LoaderService,
    public ic: IconService,
    public confirmDialog: ConfirmDialogService,
    public cs: CommonService,
    public authService: AuthenticationService,
  ) {
    this.transactionHistoryTableOptions = {
      faClass: 'TransactionHistory',
      customCols: true,
      serverSide: {
        service: '',
        fn: '',
        q: {},
        sort: [{}],
      },
      columns: [
        {
          header: 'UD Name',
          columnDef: 'entity_ref.name',
          filter: '',
          cell: '(element: any) => `${element.entity_ref.name}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
        {
          header: 'Amount Paid',
          columnDef: 'amountpaid',
          filter: 'decimalPipe',
          cell: '(element: any) => `${element.amountpaid}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
        {
          header: 'Credit Used',
          columnDef: 'creditUsed',
          filter: 'decimalPipe',
          cell: '(element: any) => `${element.creditUsed}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
        {
          header: 'Available Credit',
          columnDef: 'availableCredit',
          filter: 'decimalPipe',
          cell: '(element: any) => `${element.availableCredit}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
          {
          header: 'Credit Used',
          columnDef: 'creditused',
          filter: 'decimalPipe',
          cell: '(element: any) => `${element.creditused}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
        {
          header: 'Wallet Amount',
          columnDef: 'walletamount',
          filter: 'decimalPipe',
          cell: '(element: any) => `${element.walletamount}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
        {
          header: 'Transaction Date',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 5,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        }
      ],
      sortOptions: { active: 'c', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        title: '',
        isServerSide: true,
        selectText: 'transaction(s)',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
      }
    };
  }
  ngOnInit() {
    this.transactionHistoryTableOptions.pageData = [];
    if (this.currentEntity && this.currentEntity._id) {
      this.transactionHistoryTableOptions.serverSide.q =
        { query: { bool: { must: [{ match: { 'entity_ref.id.keyword': this.currentEntity._id } }] } } };
    } else {
      this.transactionHistoryTableOptions.serverSide.q =
        { query: { bool: { must: [{ match: { 'parent_ref.id.keyword': this.authService.currentEntity._id } }] } } };
    }
    this.getTransactionHistory();
  }
  transactionHistoryshowHideLoading(status: any): void {
    const data = Object.assign({}, this.transactionHistoryTableOptions);
    this.transactionHistoryTableOptions = {};
    this.transactionHistoryTableOptions = data;
    this.transactionHistoryTableOptions.tableOptions.loading = status;
  }
  transactionHistorysortCall(idata) {
    this.transactionHistoryTableOptions.sortOptions = idata;
    this.getTransactionHistory();
  }

  transactionHistorydetailFn(): void {
    this.currentTransactionHistory = { amount: 0, entityid: this.currentEntity._id };
    this.showSubTransactionHistory = true;
  }

  closeSubTransactionHistory(): void {
    this.showSubTransactionHistory = false;
    this.currentTransactionHistory = {};
  }

  paymentReceived(currentTransactionHistory: any): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.baseService
      .doRequest(`/api/transactionhistory/paymentsReceived`, 'post', currentTransactionHistory)
      .subscribe((result: any) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = false;
        if (result[0]) {
          this.toast.sToast('success', result[1]);
          setTimeout(() => {
            this.getTransactionHistory();
          }, 2000);
          this.modalService.close('receivePayment');
        } else {
          this.toast.sToast('error', result[1]);
        }
      });
  }

  transactionHistoryactionCall(idata: any): void {
    if (idata.action.text === 'Receive Payment') {
      const dataRow = idata.row;
      this.transactionHistorydetailFn();
    }
  }

  transactionHistoryfilterCall(idata: any): void {
    const fields = [];
    this.transactionHistoryTableOptions.columns.forEach((obj) => {
      if (obj.columnDef !== 'c') {
        fields.push(obj.columnDef);
      }
    });
    this.transactionHistoryfilterQuery =
      idata && idata.length > 0
        ? { multi_match: { query: idata, type: 'phrase_prefix', fields } }
        : undefined;
    this.getTransactionHistory();
  }

  transactionHistorypageCall(event: any): void {
    this.transactionHistoryTableOptions.tableOptions.pageSize = event.pageSize;
    this.transactionHistorycurrentPage = event.pageIndex;
    this.getTransactionHistory();
  }

  transactionHistoryaddTableData(): void {
    this.currentTransactionHistory = { amount: 0, entityid: this.currentEntity._id };
    this.modalService.open('receivePayment');
  }

  transactionHistorytimerCallData(idata: any): void {
    this.getTransactionHistory();
  }

  requestOTP(): void {
    this.loaderService.display(true);
    if (this.isResendOTP && this.currentTransactionHistory.otp) {
      delete this.currentTransactionHistory.otp;
    }
    this.baseService
      .doRequest(`/api/transactionhistory/generateOTP`, 'post',
        this.currentTransactionHistory)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result[0]) {
          this.otpSent = true; this.isResendOTP = false;
          setTimeout(() => { this.isResendOTP = true; }, 36000);
          this.toast.sToast('success', `OTP sent to ${this.currentEntity.name}, successfully`);
        } else {
          this.otpSent = true; this.isResendOTP = false;
          this.toast.sToast('error', result[1]);
        }
      });
  }

  getTransactionHistory(): void {
    this.transactionHistoryshowHideLoading(true);
    let params: any;
    params = {
      query: { bool: { must: [{ exists: { field: 'walletamount' } }] } },
    };
    if (this.currentEntity && this.currentEntity._id) {
      params.query.bool.must.push({ match: { 'entity_ref.id.keyword': this.currentEntity._id } });
      this.transactionHistoryTableOptions.columns[0].visible = false;
    } else {
      params.query.bool.must.push({ match: { 'parent_ref.id.keyword': this.authService.currentEntity._id } });
    }
    if (this.transactionHistoryfilterQuery && this.transactionHistoryfilterQuery.multi_match) {
      params.query.bool.must.push(this.transactionHistoryfilterQuery);
    }
    const sorts: any = [{}];
    if (this.transactionHistoryTableOptions.sortOptions) {
      sorts[0][this.transactionHistoryTableOptions.sortOptions.active] = {
        order: this.transactionHistoryTableOptions.sortOptions.direction,
      };
    }
    const q = JSON.stringify(params);
    const skip = this.transactionHistorycurrentPage;
    const limit = this.transactionHistoryTableOptions.tableOptions.pageSize;
    const sort = JSON.stringify(sorts);
    this.baseService
      .doRequest('/api/transactionhistory', 'get', null, { q, skip, limit, sorts })
      .subscribe((result: any) => {
        result.data.forEach((val) => {
          if (!val.creditUsed && val.creditUsed != 0) {
            val.creditUsed = '-';
          }
          if (!val.availableCredit && val.crediavailableCredittUsed != 0) {
            val.availableCredit = '-';
          }
        })
        this.transactionHistoryTableOptions.pageData = result.data;
        this.transactionHistoryTableOptions.tableOptions.pageTotal = result.total;
        this.transactionHistoryshowHideLoading(false);
      });
  }
}
