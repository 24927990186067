import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from './providers';
import {LoaderService} from './loader.service';
import {BaseRequestService} from './base.service';
import {CommonService} from './common.services';
import {Router} from '@angular/router';
import {MyToastrService} from './toastr.service';
import {LoggingService} from './logging.service';

@Injectable()
export class CustomErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector, private auth: AuthenticationService, private ngZone: NgZone,
              private commonService: CommonService, private router: Router,  private toast: MyToastrService,
              private baseService: BaseRequestService, private tostr: MyToastrService,
              private loaderService: LoaderService, private loggingService: LoggingService) {
  }
  handleError(error) {
    // this.loggingService.logException(error);
    if (error instanceof HttpErrorResponse) {
      this.loaderService.display(false);
      // Backend returns unsuccessful response codes such as 404, 500 etc.
      if (error.status === 401) {
        error = null;
        localStorage.removeItem('isLoggedin');
        const path = window.location.pathname;
        this.auth.isAuthenticated = false;
        if (path !== '/login') {
          localStorage.removeItem('isLoggedin');
          this.ngZone.run(x => this.auth.logout());
          this.router.navigateByUrl('/login');
        }
      } else if (error.status === 403) {
        setTimeout(() => {
        this.loaderService.display(false); this.loaderService.Modeldisplay(false); }, 1500);
        try {
          if (error && error.url) {
            const fn = error.url.split('api/')[1].split('/')[0];
            this.toast.sToast('error', fn.toUpperCase() + '- Not authorized to access this data. Please contact administrator.', 'Not Authorized');
          }
        } catch (e) {
           this.toast.sToast('error', error.url, 'Not Authorized');
        }
        this.loaderService.tableProgress(false);
      } else if (error.status === 422) {
        setTimeout(() => {
          this.loaderService.display(false);
          this.loaderService.Modeldisplay(false);
        }, 1500);
        error.error.detail.forEach((obj: any) => {
          this.toast.sToast('error', obj.loc[1] + ' - ' + obj.msg, 'Validation Error');
        });
        this.loaderService.tableProgress(false);
      } else if (error.status === 504) {
        setTimeout(() => {
        this.loaderService.display(false); this.loaderService.Modeldisplay(false); }, 1500);
        error.error.detail.forEach((obj: any) => {
          this.toast.sToast('error', 'Gateway Timeout', 'Timeout Error');
        });
        this.loaderService.tableProgress(false);
      } else if (error.status === 500) {
        setTimeout(() => { this.loaderService.display(false); }, 1000);
        this.toast.sToast('error', error.url + '\r\n' + error.error, '500 Error');
        const path = window.location.pathname;
        this.loaderService.display(false);
        /*this.updateErrLogs(error);*/
        const getCircularReplacer = () => {
          const seen = new WeakSet();
          return (key, value) => {
            if (typeof value === 'object' && value !== null) {
              if (seen.has(value)) {
                return;
              }
              seen.add(value);
            }
            return value;
          };
        };
        let errorLogsData;
        if (localStorage.getItem('errorLogs') && localStorage.getItem('errorLogs') !== null) {
          errorLogsData = JSON.parse(localStorage.getItem('errorLogs'));
        } else {
          errorLogsData = [];
        }
        errorLogsData.push(JSON.stringify(error, getCircularReplacer()));
        localStorage.setItem('errorLogs', JSON.stringify(errorLogsData));
        error = null;
      } else {
        if (error.status > 500) {
          this.toast.sToast('error', error.url + '\r\n' + error.error, error.status + '');
        }
      }
    } else if (error.status === 401) {
        const path = window.location.pathname;
        this.loaderService.display(false);
    } else {
      if (error.status > 500) {
        this.toast.sToast('error', error.url + '\r\n' + error.error, error.status + '');
      }
    }
  }
}
