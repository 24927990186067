import {Component, Input, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseRequestService } from '../../_services/base.service';
import { ModalService } from '../../_services/modal.service';
import { MyToastrService } from '../../_services/toastr.service';
import { LoaderService } from '../../_services/loader.service';
import { IconService } from '../../_services/icon.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { CommonService } from '../../_services/common.services';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'vex-manage-inv',
  templateUrl: './manage-inv.component.html',
  styleUrls: ['./manage-inv.component.scss'],
})
export class ManageInvComponent implements OnInit {
  @Input() currentEntity: any;
  layoutCtrl = new FormControl('boxed');
  addInventoryWidth = 600;
  currentAsset: any;
  budList: any = [];
  currentInventory: any = {
    type: 1,
    email: '',
    brand: '',
    firstName: '',
    lastName: '',
  };
  isLoading = false;
  inventoryTableOptions: any;
  inventorycurrentPage = 0;
  inventoryfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  netmaskList: any = [];
  isEndIp = false;
  isNetMask = true;
  brands: any = [];
  currentProd: any;

  constructor(
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private router: Router,
    public toast: MyToastrService,
    private loaderService: LoaderService,
    public ic: IconService,
    public confirmDialog: ConfirmDialogService,
    public cs: CommonService,
    public authService: AuthenticationService
  ) {
    this.inventoryTableOptions = {
      columns: [
        {
          header: 'Product Name',
          columnDef: 'productmaster_ref.name',
          filter: '',
          cell: '(element: any) => `${productmaster_ref.name}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Available Quantity',
          columnDef: 'availableQuantity',
          filter: '',
          cell: '(element: any) => `${element.availableQuantity}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: true,
        },
          {
          header: 'MRP',
          columnDef: 'rate_card.mrp',
          filter: '',
          cell: '(element: any) => `${element.rate_card.mrp}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          isHyperlink: true,
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: true,
        },
        {
          header: 'Case Size',
          columnDef: 'caseSize',
          filter: '',
          cell: '(element: any) => `${element.caseSize}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          isHyperlink: true,
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: true,
        },
        {
          header: 'Purchase Price',
          columnDef: 'purchasePrice',
          filter: '',
          cell: '(element: any) => `${element.purchasePrice}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          isHyperlink: true,
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: true,
        },
        {
          header: 'Total Quantity',
          columnDef: 'totalQuantity',
          filter: '',
          cell: '(element: any) => `${element.totalQuantity}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
      ],
      sortOptions: { active: 'availableQuantity', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        id: 'inventoryTableOptions',
        title: 'Inventory',
        isServerSide: true,
        selectText: 'inventory(s)',
        loading: false,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: false,
        compareData: false,
      },
    };
  }

  updateInventory(currentInventory: any): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.baseService
      .doRequest(`/api/inventory`, 'post', { body: currentInventory })
      .subscribe((result: any) => {
        this.loaderService.Modeldisplay(false);
        this.toast.sToast('success', 'Updated successfully');
        this.isLoading = false;
        setTimeout(() => {
          this.getInventory();
        }, 2000);
        this.modalService.close('addInventory');
      });
  }

  saveInventory(): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.baseService
      .doRequest('/api/inventory/CreatePartner', 'post', this.currentInventory)
      .subscribe((result: any) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = true;
        if (result[0]) {
          this.modalService.close('addInventory');
          this.toast.sToast('success', result[1]);
          setTimeout(() => {
            this.getInventory();
          }, 2000);
          this.isLoading = false;
        } else {
          this.toast.sToast('error', JSON.stringify(result));
        }
      });
  }

  deleteRange(currentInventory: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to delete this Inventory ' +
      currentInventory.name +
      ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.baseService
          .doRequest(`/api/inventory`, 'delete', { id: currentInventory._id })
          .subscribe((result: any) => {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getInventory();
            }, 2000);
          });
      }
    });
  }

  ngOnInit(): void {
    const cartList = localStorage.getItem('cartList');
    if (cartList && !this.cs.cartList.length) {
      this.cs.cartList = JSON.parse(cartList);
    }
    this.inventoryTableOptions.pageData = [];
    this.getInventory();
  }

  inventoryshowHideLoading(status: any): void {
    const data = Object.assign({}, this.inventoryTableOptions);
    this.inventoryTableOptions = {};
    this.inventoryTableOptions = data;
    this.inventoryTableOptions.tableOptions.loading = status;
  }

  inventoryglobalActionCall(idata: any): void {
    console.log(idata);
    this.inventoryshowHideLoading(true);
    setTimeout(() => {
      this.inventoryshowHideLoading(false);
    }, 1000);
  }

  inventorysortCall(idata: any): void {
    this.inventoryTableOptions.sortOptions = idata;
    this.getInventory();
  }

  inventoryeditFn(idata: any): void {
    this.currentInventory = idata;
    this.loaderService.display(true, 'Getting bud quantity');
    this.baseService
      .doRequest(`/api/inventory/GetBudcQuantity`, 'post', {
        productName: idata.productName,
      })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result[0]) {
          this.budList = result[1];
          this.modalService.open('viewBudOrders');
        } else {
          this.toast.sToast('error', result[1]);
        }
      });
  }

  inventorydeleteFn(idata: any): void {
    this.deleteRange(idata);
  }

  inventoryactionCall(idata: any): void {
    if (idata.action.text === 'View UD Orders') {
      const dataRow = idata.row;
      this.inventoryeditFn(dataRow);
    }
    if (idata.action.text === 'Order More') {
      this.currentProd = idata.row;
      this.modalService.open('orderMore');
      /*const dataRow = idata.row;
            this.inventorydeleteFn(dataRow);*/
      // this.router.navigateByUrl('/inventory/new-order');
    }
    if (idata.action.text === 'Sell Product') {
      const dataRow = idata.row;
      this.currentProd = idata.row;
      this.modalService.open('sellProduct');
    }
  }

  sellProduct(prod: any): void {}

  inventoryfilterCall(idata: any): void {
    const fields: any[] = [];
    this.inventoryTableOptions.columns.forEach((obj: { columnDef: any }) => {
      fields.push(obj.columnDef);
    });
    this.inventoryfilterQuery =
      idata && idata.length > 0
        ? {
            multi_match: {
              query: idata,
              type: 'phrase_prefix',
              fields,
            },
          }
        : undefined;
    this.getInventory();
  }

  inventorypageCall(event: any): void {
    this.inventoryTableOptions.tableOptions.pageSize = event.pageSize;
    this.inventorycurrentPage = event.pageIndex;
    this.getInventory();
  }

  inventoryaddTableData(): void {
    this.currentInventory = {
      type: 1,
      email: '',
      brand: '',
      firstName: '',
      lastName: '',
    };
    this.modalService.open('addInventory');
  }

  inventorytimerCallData(idata: any): void {
    this.getInventory();
  }

  syncInventory() {
    this.loaderService.display(true, 'Sync Inventory');
    this.baseService
      .doRequest(`/api/inventory/SyncInventory`, 'post', {})
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          this.toast.sToast('success', 'Sync Successfully.');
          this.getInventory();
        } else {
          this.toast.sToast('error', result);
        }
      });
  }

  getInventory(): void {
    this.loaderService.display(true, 'Getting Inventory...');
    this.inventoryshowHideLoading(true);
    let params;
    params = {
      query: {
        bool: {
          must: [{ exists: { field: 'availableQuantity' } },
            { match: { 'entity_ref.id.keyword': this.currentEntity._id } },
           { range: { 'rate_card.mrp': { gte: 0 } } }
          ],
        },
      },
    };
    if (this.inventoryfilterQuery && this.inventoryfilterQuery.multi_match) {
      // @ts-ignore
      params.query.bool.must.push(this.inventoryfilterQuery);
    }
    let sorts;
    if (this.inventoryTableOptions.sortOptions) {
      sorts = [{}];
      // @ts-ignore
      sorts[0][this.inventoryTableOptions.sortOptions.active] = {
        order: this.inventoryTableOptions.sortOptions.direction,
      };
    }
    if (this.authService.isBUDC()) {
      // this.inventoryTableOptions.columns[4].header = 'BUD';
      /*{
                    text: 'View UD Orders',
                    icon: 'edit',
                    callback: 'editFn',
                    isGlobal: false
                }, {text: 'Order More', icon: 'delete', callback: 'deleteFn', isGlobal: false}*/
      this.inventoryTableOptions.tableOptions.actionMenuItems = [];
    } else if (this.authService.currentUser.usertype === 'BUD') {
      // this.inventoryTableOptions.columns[3].header = 'BUDC';
      this.inventoryTableOptions.tableOptions.actionMenuItems = [];
    }
    const q = JSON.stringify(params);
    const skip = this.inventorycurrentPage;
    const limit = this.inventoryTableOptions.tableOptions.pageSize;
    const sort = JSON.stringify(sorts);
    this.baseService
      .doRequest(`/api/inventory`, 'get', null, { q, skip, limit, sorts })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result && result.data && result.data.length) {
          result.data.forEach((obj: any) => {
            obj.totalQuantity = obj.totalQuantity ? obj.totalQuantity : 0;
          });
          this.inventoryTableOptions.pageData = result.data;
          this.inventoryTableOptions.tableOptions.pageTotal = result.total;
          this.inventoryshowHideLoading(false);
        } else {
          this.inventoryTableOptions.pageData = [];
          this.inventoryTableOptions.tableOptions.pageTotal = 0;
          this.inventoryshowHideLoading(false);
        }
      });
  }

  addToCart(currentProd: any) {
    this.cs.cartList.push(currentProd);
    this.modalService.close('orderMore');
  }

  placeOrder(): void {
    this.modalService.open('placeOrderBUDC');
  }
  placeOrderNow(): void {
    localStorage.setItem('cartList', JSON.stringify(this.cs.cartList));
    this.router.navigateByUrl('/orders/place-order');
  }

  hyperlinkCall($event: any) {
    if ($event.col === 'bud') {
      const dataRow = $event.row;
      this.inventoryeditFn(dataRow);
    }
  }
}
