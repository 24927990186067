<div class="card overflow-auto">
  <app-s-table aria-label="Document Proof" role="table" [sTableOptions]="docProofsTableOptions"
    (hyperlinkCallback)="docProofshyperlinkCall($event)" (sortCallback)="docProofssortCall($event)"
    (filterCallback)="docProofsfilterCall($event)" (actionCallback)="docProofsactionCall($event)"
    (pageCallback)="docProofspageCall($event)" (refreshCallback)="getDocument()" (addCallback)="docProofsaddTableData()"
    (timerCallback)="getDocument()"></app-s-table>
</div>
<s-modal id="addDocument">
  <mat-card>
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">Add Document Proof</h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('addDocument')">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <form #addDocumentForm="ngForm">
      <mat-card-content class="p-3" *ngIf="currentDocument">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="flex-auto">
            <mat-label>Document Name</mat-label>
            <input matInput name="name" [(ngModel)]="currentDocument.name" type="text" required
              placeholder="Enter Document Name" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="flex-auto">
            <mat-label>Document Number</mat-label>
            <input matInput name="number" [(ngModel)]="currentDocument.number" type="text" required
              placeholder="Enter Document Number" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="mb-2">
          <span>
            <input id="addDocProf" type="file" accept=".png,.jpg,.jpeg" name="docProof"
              (change)="uploadFile($event, 'docImage')" />
            <mat-hint _ngcontent-opx-c260="" class="mat-hint text-us pt-2 ng-tns-c202-344" >File Format:
              *.PNG, *.JPG, *.JPEG</mat-hint>
          </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="py-1"
          *ngFor="let img of currentDocument.doclinks; let i = index;">
          <span>
            <img matTooltip="Click to toggle zoom" *ngIf="img" class="pointer" (click)="toggleImg()"
              [ngClass]="{'h-100': toggleImageClass, 'h-16': !toggleImageClass}" [src]="img | safe" alt="" />
          </span>
          <span class="spacer"></span>
          <span>
            <button mat-icon-button class="text-secondary" (click)="currentDocument.doclinks.splice(i, 1)">
              <mat-icon [icIcon]="ic.icDelete"></mat-icon>
            </button>
          </span>
        </div>
      </mat-card-content>
      <mat-card-actions align="end" *ngIf="currentDocument">
        <button [disabled]="!addDocumentForm.form.valid || isLoading" *ngIf="!currentDocument._id"
          (click)="saveDocument()" mat-raised-button class="mat-primary" type="submit">
          ADD NEW
        </button>
        <button *ngIf="currentDocument._id" (click)="updateDocument(currentDocument)"
          [disabled]="!addDocumentForm.form.valid || isLoading" mat-raised-button class="mat-primary" type="submit">
          UPDATE
        </button>
        <button mat-button mat-dialog-close type="button" (click)="modalService.close('addDocument')">
          CLOSE
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</s-modal>
<s-modal id="viewDocs">
  <mat-card>
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">Document Proof</h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('viewDocs')">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <form #docProofForm="ngForm">
      <mat-card-content class="p-3">
        <div fxLayout="row" fxLayoutAlign="start center" class="py-1"
          *ngFor="let img of currentDocument.doclinks; let i = index;">
          <ng-container *ngIf="img.indexOf('data:image') > -1; then thenTemplate; else elseTemplate"></ng-container>
          <ng-template #thenTemplate>
            <span><img matTooltip="Click to toggle zoom" *ngIf="img" class="pointer" (click)="toggleImg()"
                [ngClass]="{'h-100': toggleImageClass, 'h-16': !toggleImageClass}" [src]="img | safe" alt="" /></span>
          </ng-template>
          <ng-template #elseTemplate>
            <span><img matTooltip="Click to toggle zoom" *ngIf="img" class="pointer" (click)="toggleImg()"
                [ngClass]="{'h-100': toggleImageClass, 'h-16': !toggleImageClass}" [src]="getImg(img) | safe"
                alt="" /></span>
          </ng-template>
          <span class="spacer"></span>
          <span>
            <button mat-icon-button class="text-secondary" (click)="currentDocument.doclinks.splice(i, 1)">
              <mat-icon [icIcon]="ic.icDelete"></mat-icon>
            </button>
          </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="mt-2">
          <span>
            <input id="updateDocProf" type="file" accept=".png,.jpg,.jpeg" name="updateDocProof"
              (change)="uploadFile($event, 'docImage')" />
            <mat-hint _ngcontent-opx-c260="" class="mat-hint text-us pt-2 ng-tns-c202-344" >File Format:
              *.JPG, *.JPEG, *.PNG</mat-hint>
          </span>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button class="mat-primary" type="button" (click)="updateDocument(currentDocument)">
          UPDATE
        </button>
        <button mat-button mat-dialog-close type="button" (click)="modalService.close('viewDocs')">
          CLOSE
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</s-modal>
