import { Component, Input, OnInit } from '@angular/core';
import { IconService } from '../../_services/icon.service';
import { CommonService } from '../../_services/common.services';
import { LoaderService } from 'src/app/_services/loader.service';
import { ApiService } from 'src/app/_services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'vex-s-dash',
  templateUrl: './s-dash.component.html',
  styleUrls: ['./s-dash.component.scss']
})
export class SDashComponent implements OnInit {
  @Input() service: any;

  masterDashList: any = {
  };

  dashList: any = [];
  dashUrl: any;
  currentDashboard: any;
  constructor(public ic: IconService, public cs: CommonService, public loaderService: LoaderService,
              public apiService: ApiService, private http: HttpClient, public notifyService: NotificationService) { }

  ngOnInit(): void {
    this.dashList = [...this.masterDashList.GLOBAL];
    this.dashList = [...this.dashList, ...this.masterDashList[this.service]];
    this.initAuth();
  }

  initAuth(): void {
  }

  updateDash($event: any): void {
    this.dashUrl.forEach((obj: any) => {
      if (obj.url === $event) {
        this.currentDashboard = obj.name;
      }
      this.loaderService.display(false);
    });
  }
}
