import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import icGroup from '@iconify/icons-ic/twotone-group';
import icPageView from '@iconify/icons-ic/twotone-pageview';
import icCloudOff from '@iconify/icons-ic/twotone-cloud-off';
import icTimer from '@iconify/icons-ic/twotone-timer';
import { defaultChartOptions } from '../../../@vex/utils/default-chart-options';
import { Order, tableSalesData } from '../../../static-data/table-sales-data';
import { TableColumn } from '../../../@vex/interfaces/table-column.interface';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { BaseRequestService } from '../../_services/base.service';
import {ModalService} from '../../_services/modal.service';
import {MyToastrService} from '../../_services/toastr.service';
import {LoaderService} from '../../_services/loader.service';
import {IconService} from '../../_services/icon.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';
import {CommonService} from '../../_services/common.services';
import {NotificationService} from '../../_services/notification.service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'vex-bud-dashboard',
  templateUrl: './bud-dashboard.component.html',
  styleUrls: ['./bud-dashboard.component.scss'],
})
export class BudDashboardComponent implements OnInit {
  images = [
  ];
  tableColumns: TableColumn<Order>[] = [
    {
      label: '',
      property: 'status',
      type: 'badge',
    },
    {
      label: 'BUD',
      property: 'name',
      type: 'text',
    },
    {
      label: '₹ SALE AMOUNT',
      property: 'price',
      type: 'text',
      cssClasses: ['font-medium'],
    },
    {
      label: 'DATE',
      property: 'timestamp',
      type: 'text',
      cssClasses: ['text-secondary'],
    },
  ];
  tableData = []; // tableSalesData;

  series: ApexAxisChartSeries = [
    {
      name: 'Subscribers',
      data: [28, 40, 36, 0, 52, 38, 60, 55, 67, 33, 89, 44],
    },
  ];

  userSessionsSeries: ApexAxisChartSeries = [
    {
      name: 'Users',
      data: [10, 50, 26, 50, 38, 60, 50, 25, 61, 80, 40, 60],
    },
    {
      name: 'Sessions',
      data: [5, 21, 42, 70, 41, 20, 35, 50, 10, 15, 30, 50],
    },
  ];

  salesSeries: ApexAxisChartSeries = [
    {
      name: 'Till Date',
      data: [28, 40, 36, 33, 52, 38, 60, 55, 99, 54, 38, 87],
    },
    {
      name: 'This month',
      data: [12, 23, 26, 12, 13, 45, 23, 74, 23, 64, 45, 68],
    },
    {
      name: 'Last Month',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];

  pageViewsSeries: ApexAxisChartSeries = [
    {
      name: 'Page Views',
      data: [405, 800, 200, 600, 105, 788, 600, 204],
    },
  ];

  uniqueUsersSeries: ApexAxisChartSeries = [
    {
      name: 'Unique Users',
      data: [356, 806, 600, 754, 432, 854, 555, 1004],
    },
  ];

  uniqueUsersOptions = defaultChartOptions({
    chart: {
      type: 'area',
      height: 100,
    },
    colors: ['#ff9800'],
  });
  carouselSourceData: any;
  icGroup = icGroup;
  icPageView = icPageView;
  icCloudOff = icCloudOff;
  icTimer = icTimer;
  icMoreVert = icMoreVert;
  username: string;
  location: string;
  isBudc = false;
  region: string;
  userType: string;

  constructor(
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    readonly router: Router,
    private baseService: BaseRequestService,
    public modalService: ModalService,
    public toast: MyToastrService,
    private loaderService: LoaderService,
    public ic: IconService,
    public confirmDialog: ConfirmDialogService,
    public cs: CommonService,
    private notifyService: NotificationService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.tableData = [];
    /* this.baseService
      .doRequest(`/api/partner/getBudlevelCommission`, 'post', {})
      .subscribe((result) => {
        if (result[0]) {
          result[1].forEach((obj: any) => {
            this.tableData.push({
              name: obj.firstName + ' ' + obj.lastName,
              price: '₹' + obj.totalAmount,
              status: 'ready',
              timestamp: DateTime.local().minus({ minutes: 2 }).toRelative(),
            });
          });
        }
      }); */

    setTimeout(() => {
      const temp = [
        {
          name: 'Subscribers',
          data: [55, 213, 55, 0, 213, 55, 33, 55],
        },
        {
          name: '',
        },
      ];
    }, 3000);
    if (this.authService.currentUser.usertype === 'BUD') {
      this.router.navigateByUrl(`/bud-dashboard`);
    } else if (this.authService.currentUser.usertype === 'CONSUMER') {
      this.router.navigateByUrl(`/inventory/purchase`);
    } else if (this.authService.isBUDC()) {
      this.router.navigateByUrl(`/bud-dashboard`);
      this.isBudc = true;
    }
    this.username = this.authService.currentUser.given_name;
    this.location = this.authService.currentUser.state;
    this.region = this.authService.currentUser.region;
    this.userType = this.authService.currentUser.usertype;
    this.getCarousel();
  }

  getCarousel() {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/carouselrules`, 'get', null,
        {q: '{"query": {"bool": {"must": [{"range": {"fromdate": {"lte": "now"}}}, {"range": {"todate": {"gte": "now"}}}]}}}' +
              '\n', skip: 0, limit: 10}).subscribe((result) => {
      this.loaderService.display(false);
      if (result && result.data && result.data.length) {
        this.carouselSourceData = result.data;
        result.data.forEach((obj: any) => {
          obj.carousels_ref.forEach((img: any) => {
            this.getCarouselImage(img.id);
          });
        });
      } else {
        this.images = [
          { path: 'assets/img/scroll_img5.jpg' },
          { path: 'assets/img/scroll_img6.jpg' },
          { path: 'assets/img/scroll_img7.jpg' },
          { path: 'assets/img/scroll_img8.jpg' },
          { path: 'assets/img/scroll_img1.jpg' },
          { path: 'assets/img/scroll_img2.jpg' },
          { path: 'assets/img/scroll_img3.jpg' },
          { path: 'assets/img/scroll_img4.jpg' },
          { path: 'assets/img/scroll_img1.jpg' },
          { path: 'assets/img/scroll_img2.jpg' },
          { path: 'assets/img/scroll_img3.jpg' },
          { path: 'assets/img/scroll_img4.jpg' }
        ];
      }
    });
  }

  getCarouselImage(id: any): any {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/carousels/${id}`, 'get').subscribe((result) => {
      this.loaderService.display(false);
      if (result && result._id) {
        this.images.push({ path: result.image });
      }
    });
  }
}
