<div #originRef (click)="showPopover(originRef)" [class.bg-hover]="dropdownOpen" class="
    flex items-center rounded cursor-pointer relative trans-ease-out
    select-none py-1 pr-1 pl-3 hover:bg-hover" matRipple>
  <div class="flex flex-col justify-center body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 resp-none" fxHide.xs>
    <span *ngIf="authService && authService.currentUser">
      {{ authService.currentUser.given_name | camelToHuman: true }}
      {{ authService.currentUser.family_name | camelToHuman: true }}
    </span>
     <span class="text-secondary text-xs" *ngIf="authService.currentUser.usertype === 'ADMIN'">ADMIN</span>
     <span class="text-secondary text-xs" *ngIf="authService.isBUDC()">In & Out Store</span>
    <span class="text-secondary text-xs" *ngIf="authService.currentUser.usertype === 'BUD'">UD</span>
  </div>
  <div class=" rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">
    <mat-icon [icIcon]="icPerson"></mat-icon>
  </div>
</div>
