import { Directive, HostListener, ElementRef } from '@angular/core';
import { MyToastrService } from '../_services/toastr.service';

@Directive({
  selector: '[appCINCheck]'
})
export class CINCheckDirective {

  constructor(private el: ElementRef,
    private toast: MyToastrService
  ) {
  }

  // tslint:disable-next-line:max-line-length
  CIN_REGX = new RegExp(/^[UL]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/);

  @HostListener('focusout')
  onFocusOut() {
    const current: string = this.el.nativeElement.value.trim().toUpperCase();
    if (current !== '') {
      if (current && !String(current).match(this.CIN_REGX)) {
        this.toast.sToast('error', 'Invalid CIN Number');
        this.el.nativeElement.focus();
      }
    }
  }
}
