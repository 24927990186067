import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NavigationService } from '../../@vex/services/navigation.service';
import { CommonService } from './common.services';
import { CustomTranslateService } from './custom-translate.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  isTranslateFileAvailable = false;
  currentUser: any = {};
  constructor(
    private navigationService: NavigationService,
    private translationService: TranslocoService,
    public commonService: CommonService,
    public customTranslateService: CustomTranslateService
  ) {
    this.customTranslateService.translationLoaded.subscribe((res) => {
      if (this.currentUser) {
        console.log('going to call processmenu from menuservice');
        this.processMenu(this.currentUser);
      }
    });
  }

  processMenu(curUser?: any) {
    this.currentUser = curUser;
    if (this.commonService.isTranslateFileAvailable) {
      this.navigationService.items = [];
      try {
        if (this.currentUser && this.currentUser.usertype === 'ADMIN') {
          this.navigationService.items = [
            {
              type: 'link',
              label: 'Home', tLabel: 'menu.home',
              route: '/',
              // icon: icLayers,
              img: 'assets/svg/dashboard.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage Company', tLabel: 'menu.mgCmp',
              route: '/settings/company',
              isVisible: this.hasPermission('company', 'read'),
              img: 'assets/svg/administration.svg',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage Distributor', tLabel: 'menu.mgDist',
              route: '/inventory/suppliers',
              // icon: icLayers,
              isVisible: this.hasPermission('supplier', 'read'),
              img: 'assets/svg/administration.svg',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Distributor Catalogue', tLabel: 'menu.disCat',
              route: '/inventory/supplier-catalog',
              // icon: icLayers,
              isVisible: this.hasPermission('suppliermaster', 'read'),
              img: 'assets/svg/admin-inventory.svg',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Master Catalogue', tLabel: 'menu.masCat',
              route: '/inventory/inventory-master',
              // icon: icLayers,
              isVisible: this.hasPermission('productmaster', 'read'),
              img: 'assets/svg/admin-inventory.svg',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage Ratesheet', tLabel: 'menu.mgRash',
              route: '/inventory/ratecard',
              // icon: icLayers,
              isVisible: this.hasPermission('ratecard', 'read'),
              img: 'assets/svg/reports.svg',
              routerLinkActiveOptions: { exact: true },
            },

            /* {
              type: "link",
              label: "Catalogue", tLabel: 'menu.catalog',
              route: "/inventory/category",
              // icon: icLayers,
              isVisible: this.hasPermission("category", "read"),
              img: "assets/svg/catalogue.svg",
              routerLinkActiveOptions: { exact: true },
            }, */

            {
              type: 'link',
              label: 'Manage IO Store', tLabel: 'menu.mgIOS',
              route: '/settings/io-store',
              // icon: icLayers,
              isVisible: this.hasPermission('entity', 'read'),
              img: 'assets/svg/administration.svg',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage UD', tLabel: 'menu.mgUD',
              route: '/settings/ud',
              // icon: icLayers,
              isVisible: this.hasPermission('entity', 'read'),
              img: 'assets/svg/administration.svg',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage Coupons', tLabel: 'menu.mgCoup',
              route: '/inventory/manage-coupons',
              img: 'assets/svg/administration.svg',
              isVisible: this.hasPermission('coupons', 'read'),
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage Users', tLabel: 'menu.mgUsers',
              route: '/settings/users',
              // icon: icLayers,
              isVisible: this.hasPermission('kusers', 'read'),
              img: 'assets/svg/administration.svg',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage Roles', tLabel: 'menu.mgRoles',
              route: '/settings/roles',
              // icon: icLayers,
              isVisible: this.hasPermission('kroles', 'read'),
              img: 'assets/svg/administration.svg',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage Returns', tLabel: 'menu.mgRet',
              route: '/orders/returns',
              // icon: icLayers,
              img: 'assets/svg/open-orders.svg',
              isVisible: this.hasPermission('returnorder', 'read'),
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Manage Shipment', tLabel: 'menu.mgShip',
              route: '/orders/cart-orders/1',
              // icon: icLayers,
              img: 'assets/svg/open-orders.svg',
              isVisible: this.hasPermission('orders', 'read'),
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Invoice Processing', tLabel: 'menu.invProc',
              route: '/orders/invoice-processing',
              // icon: icLayers,
              img: 'assets/svg/administration.svg',
              // isVisible: (this.currentUser && this.currentUser.role.toLowerCase() !== "urja-devi-co-ordinator"),
              isVisible: this.hasPermission('invoiceprocessing', 'read'),
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Reports', tLabel: 'menu.reports',
              route: '/reports/',
              // icon: icLayers,
              img: 'assets/svg/reports.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            /*{
              type: 'link',
              label: 'Manage Carousels', tLabel: 'menu.mgCaro',
              route: '/settings/manage-carousels',
              // icon: icLayers,
              img: 'assets/svg/administration.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
            {
              type: 'link',
              label: 'Inventory Ledger', tLabel: 'menu.invLedg',
              route: '/settings/inventory-ledger',
              img: 'assets/svg/inventory.svg',
              // isVisible: (this.currentUser && this.currentUser.role.toLowerCase() !== "urja-devi-co-ordinator"),
              isVisible: this.hasPermission('inventoryledger', 'read'),
              /*routerLinkActiveOptions: { exact: true },*/
            },
            {
              type: 'link',
              label: 'Administration', tLabel: 'menu.admin',
              route: '/settings/administration',
              img: 'assets/svg/administration.svg',
              isVisible: (this.currentUser && this.currentUser.role.toLowerCase() !== 'urja-devi-co-ordinator'),
              routerLinkActiveOptions: { exact: true },
            },
          ];
        } else if (this.currentUser && this.currentUser.usertype === 'BUD') {
          this.navigationService.items = [
            // {
            //     type: 'link',
            //     label: 'Dashboard', tLabel: 'menu.disCat',
            //     route: '/',
            //     icon: icLayers,
            //     isVisible: true,
            //     routerLinkActiveOptions: {exact: true}
            // },
            {
              type: 'link',
              label: 'POS', tLabel: 'menu.pos',
              route: '/inventory/sell',
              // icon: icLayers,
              img: 'assets/svg/pos.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },

            /*{
              type: "link",
              label: "Request", tLabel: 'menu.disCat',
              route: "/inventory/buy",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
            {
              type: 'link',
              label: 'My Inventory', tLabel: 'menu.MInv',
              route: '/inventory/inventory',
              // icon: icLayers,
              img: 'assets/svg/inventory.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'My Orders', tLabel: 'menu.MOrd',
              route: '/orders/orders-landing',
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              /*routerLinkActiveOptions: { exact: true },*/
            },
            /* {
              type: "link",
              label: "Open Orders", tLabel: 'menu.disCat',
              route: "/orders/open-orders",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            }, */
            /* {
              type: "link",
              label: "Online Orders", tLabel: 'menu.disCat',
              route: "/orders/online-orders",
              // icon: icLayers,
              img: "assets/svg/online-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            }, */
            /*{
              type: "link",
              label: "Stock Transfer Orders", tLabel: 'menu.disCat',
              route: "/orders/stock-transfer/2",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Consumer Orders", tLabel: 'menu.disCat',
              route: "/orders/consumer-orders/3",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
            {
              type: 'link',
              label: 'Inventory Ledger', tLabel: 'menu.invLedg',
              route: '/settings/inventory-ledger',
              img: 'assets/svg/inventory.svg',
              isVisible: true,
              /*routerLinkActiveOptions: { exact: true },*/
            },
            {
              type: 'link',
              label: 'Payment Dashboard', tLabel: 'menu.Pdash',
              route: '/orders/payment-dashboard',
              // icon: icLayers,
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Complaints', tLabel: 'menu.complients',
              route: '/settings/complaint',
              // icon: icLayers,
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            /*{
              type: "link",
              label: "Return/Exchange", tLabel: 'menu.disCat',
              route: "/inventory/returns/exchange",
              // icon: icLayers,
              img: "assets/svg/return-exchange.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
            /*{
              type: "link",
              label: "Zipfuel", tLabel: 'menu.disCat',
              route: "/administration/returns/exchange",
              // icon: icLayers,
              img: "assets/svg/return-exchange.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
            {
              type: 'link',
              label: 'Administration', tLabel: 'menu.admin',
              route: '/administration/bud-agent',
              // icon: icLayers,
              img: 'assets/svg/authentication.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            /*{
              type: "link",
              label: "Contact Us", tLabel: 'menu.disCat',
              route: "/contact-us",
              // icon: icLayers,
              img: "assets/svg/contact-us.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
          ];
        }
        if (this.currentUser && (this.currentUser.usertype === 'BUDC')) {
          this.navigationService.items = [
            {
              type: 'link',
              label: 'Home', tLabel: 'menu.home',
              route: '/',
              // icon: icLayers,
              img: 'assets/svg/dashboard.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'POS', tLabel: 'menu.pos',
              route: '/inventory/sell',
              // icon: icLayers,
              img: 'assets/svg/pos.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'My Inventory', tLabel: 'menu.MInv',
              route: '/inventory/inventory',
              // icon: icLayers,
              img: 'assets/svg/inventory.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'My Orders', tLabel: 'menu.MOrd',
              route: '/orders/orders-landing',
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              /*routerLinkActiveOptions: { exact: true },*/
            },
            {
              type: 'link',
              label: 'Return Orders', tLabel: 'menu.retOrd',
              route: '/orders/returns',
              // icon: icLayers,
              img: 'assets/svg/open-orders.svg',
              isVisible: this.hasPermission('returnorder', 'read'),
              routerLinkActiveOptions: { exact: true },
            },
            /*{
              type: "link",
              label: "Order Placement", tLabel: 'menu.MOrd',
              route: "/inventory/purchase",
              // icon: icLayers,
              img: "assets/svg/pos.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Order Status", tLabel: 'menu.MOrd',
              route: "/orders/cart-orders/1",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Consumer Orders", tLabel: 'menu.MOrd',
              route: "/orders/consumer-orders/3",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Stock Transfer Orders", tLabel: 'menu.MOrd',
              route: "/orders/stock-transfer/2",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
            {
              type: 'link',
              label: 'Payment Dashboard', tLabel: 'menu.Pdash',
              route: '/orders/payment-dashboard',
              // icon: icLayers,
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Administration', tLabel: 'menu.admin',
              isVisible: true,
              route: '/settings/iostore-admin',
              // icon: icLayers,
              img: 'assets/svg/administration.svg',
            },
            /*{
              type: "link",
              label: "Manage UD", tLabel: 'menu.MOrd',
              isVisible: true,
              route: "/settings/ud",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
            },
            {
              type: "link",
              label: "Manage Store", tLabel: 'menu.MOrd',
              isVisible: true,
              route: "/settings/store-users",
              // icon: icLayers,
              img: "assets/svg/administration.svg",
            },*/
          ];
        } else if (this.currentUser && (
          this.currentUser.usertype === 'StoreManager' || this.currentUser.usertype === 'StoreUser')) {
          this.navigationService.items = [
            {
              type: 'link',
              label: 'Home', tLabel: 'menu.home',
              route: '/',
              // icon: icLayers,
              img: 'assets/svg/dashboard.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'POS', tLabel: 'menu.pos',
              route: '/inventory/sell',
              // icon: icLayers,
              img: 'assets/svg/pos.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'My Inventory', tLabel: 'menu.MInv',
              route: '/inventory/inventory',
              // icon: icLayers,
              img: 'assets/svg/inventory.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'My Orders', tLabel: 'menu.MOrd',
              route: '/orders/orders-landing',
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              /*routerLinkActiveOptions: { exact: true },*/
            },
            {
              type: 'link',
              label: 'Return Orders', tLabel: 'menu.retOrd',
              route: '/orders/returns',
              // icon: icLayers,
              img: 'assets/svg/open-orders.svg',
              isVisible: this.hasPermission('returnorder', 'read'),
              routerLinkActiveOptions: { exact: true },
            },
            /*{
              type: "link",
              label: "Order Placement", tLabel: 'menu.MOrd',
              route: "/inventory/purchase",
              // icon: icLayers,
              img: "assets/svg/pos.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Order Status", tLabel: 'menu.MOrd',
              route: "/orders/cart-orders/1",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Consumer Orders", tLabel: 'menu.MOrd',
              route: "/orders/consumer-orders/3",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Stock Transfer Orders", tLabel: 'menu.MOrd',
              route: "/orders/stock-transfer/2",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
            // {
            //   type: 'link',
            //   label: 'Payment Dashboard', tLabel: 'menu.Pdash',
            //   route: '/orders/payment-dashboard',
            //   // icon: icLayers,
            //   img: 'assets/svg/open-orders.svg',
            //   isVisible: true,
            //   routerLinkActiveOptions: { exact: true },
            // },
            {
              type: 'link',
              label: 'Administration', tLabel: 'menu.admin',
              isVisible: true,
              route: '/settings/iostore-admin',
              // icon: icLayers,
              img: 'assets/svg/administration.svg',
            },
            /*{
              type: "link",
              label: "Manage UD", tLabel: 'menu.MOrd',
              isVisible: true,
              route: "/settings/ud",
              // icon: icLayers,
              img: "assets/svg/open-orders.svg",
            },
            {
              type: "link",
              label: "Manage Store", tLabel: 'menu.MOrd',
              isVisible: true,
              route: "/settings/store-users",
              // icon: icLayers,
              img: "assets/svg/administration.svg",
            },*/
          ];
        }
        else if (this.currentUser && this.currentUser.usertype === 'CONSUMER') {
          // this is extra -> should be else if
          this.navigationService.items = [
            /*{
              type: "link",
              label: "Home", tLabel: 'menu.MOrd',
              route: "/consumer/home",
              img: "assets/svg/dashboard.svg",
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },*/
            {
              type: 'link',
              label: 'Buy', tLabel: 'menu.buy',
              route: '/consumer/buy',
              img: 'assets/svg/pos.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'My Orders', tLabel: 'menu.MOrd',
              route: '/consumer/my-orders',
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Complaints', tLabel: 'menu.complients',
              route: '/settings/complaint',
              // icon: icLayers,
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Contact-Us', tLabel: 'menu.conta',
              route: '/consumer/contact-us',
              img: 'assets/svg/contact-us.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: 'link',
              label: 'Add KYC', tLabel: 'menu.kyc',
              route: '/consumer/kyc',
              img: 'assets/svg/open-orders.svg',
              isVisible: true,
              routerLinkActiveOptions: { exact: true },
            },
            // {
            //   type: 'link',
            //   label: 'Settings', tLabel: 'menu.settings',
            //   route: '/consumer/settings',
            //   img: 'assets/svg/administration.svg',
            //   isVisible: true,
            //   routerLinkActiveOptions: { exact: true },
            // },
          ];
        }
      } catch (e) {
        console.log(e);
      }
      this.commonService.newNavigationMenu.next(this.navigationService.items);
    } else {
      setTimeout(() => { console.log('Will have to wait'); this.processMenu(); }, 200);
    }
  }

  hasPermission(cname: string, action: string): boolean {
    /* permissions: {me: ["read"], company: ["read", "update"]} */
    return !!(
      this.currentUser &&
      this.currentUser.permissions[cname] &&
      this.currentUser.permissions[cname].includes(action.toLowerCase())
    );
  }
}
