import { LoaderService } from 'src/app/_services/loader.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BaseRequestService } from '../../_services/base.service';
import { IconService } from '../../_services/icon.service';
import { debounceTime, filter, map } from 'rxjs/operators';
import { NotificationService } from '../../_services/notification.service';
import { MyToastrService } from '../../_services/toastr.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { ModalService } from '../../_services/modal.service';
import { trackByKey, trackById } from '../../../@vex/utils/track-by';
import tinykeys from 'tinykeys';

@Component({
  selector: 'vex-grn-order',
  templateUrl: './grn-order.component.html',
  styleUrls: ['./grn-order.component.scss'],
})
export class GrnOrderComponent implements OnInit {
  discAmount: 0;
  public submitted: string = 'No';
  constructor(
    public ic: IconService,
    public _ChangeDetectorRef: ChangeDetectorRef,
    public baseService: BaseRequestService,
    private loaderService: LoaderService,
    private notifyService: NotificationService,
    private toast: MyToastrService,
    public authService: AuthenticationService,
    public confirmDialog: ConfirmDialogService,
    public modalService: ModalService
  ) {
    tinykeys(window, {
      '$mod+KeyB': (event) => {
        event.preventDefault();
        this.toggleSearch();
      },
    });
    this.invoiceNumberInput.pipe(debounceTime(1200)).subscribe({
      next: (res) => {
        const INV_CHK = new RegExp(/^[a-zA-Z0-9/-]{1,16}$/);
        if (res.trim().length !== 0) {
          if (res.match(INV_CHK)) {
            if (!this.isSKOrder) {
              this.getSavedDraft();
              this.invoiceNumberNA = false;
            }
          } else {
            this.invoiceNumberNA = true;
            this.toast.sToast('error', 'Invoice Number not allowed');
          }
        } else {
          this.invoiceNumberNA = true;
          this.toast.sToast('error', 'Invoice Number not allowed');
        }
      },
    });
    this.barcodeSearchInput.pipe(debounceTime(1200)).subscribe({
      next: (res) => {
        this.fromBarcodeSearch = false;
        if (this.orders.orderedProducts.length && res.length > 2) {
          this.loaderService.display(true, 'Searching Products...');
          if (res.slice(0, 3).includes('890')) {
            this.handleBarcodeSearch(res);
          } else {
            this.handleStringSearch(res);
          }
        }
      },
    });
  }
  @Output() bview = new EventEmitter<string>();
  @Input() orders;
  @Input() invoiceNumber = '';
  @ViewChild('searchText', { static: true }) searchTextCur: ElementRef;
  today = new Date().toISOString().slice(0, 10);
  minDay: any;
  currentGRNProducts: any = [];
  recieved: any = [];
  isLoading = false;
  isSubmitted = false;
  isInvoiceDateReadOnly = false;
  grnBy = '';
  search = '';
  imageCategory: any = { type: 'Category' };
  invoiceDate = '';
  invoiceamount = '';
  invoiceAmount = 0;
  invoiceDiscount = 0;
  calGrnAmount = '';
  calculatedGrnAmount = 0;
  discAmt = 0;
  totalsum = 0;
  invoicediscount = 0;
  calcuGrnAmount = '';
  zeromrp = false;
  invQty = false;
  lessmrpprice = false;
  orderInvMisMatch = false;
  taxNotExists = false;
  invalidRecievedQty = false;
  freeQtyList: any = [];
  zeromrpList: any = [];
  invQtyList: any = [];
  lessMrp: any = [];
  orderInvMisMatchList: any = [];
  invalidRecievedQtyList = [];
  taxNotExistsList: any = [];
  currentDocument: any = [];
  invoiceDocLink: any = [];
  recievedQtyExceedingList: any = [];
  recievedQtyExceeding = false;
  isSKOrder = false; isMinPPriceError = false;
  barCodeScan: Subject<any> = new Subject();
  barcodeSearchInput: Subject<string> = new Subject();
  invoiceNumberInput: Subject<string> = new Subject();
  invoiceNumberNA = false;
  searchProducts: any = [];
  fromBarcodeSearch = false;
  recievedQuantity: any;
  newTaxTypeSelected = false;
  curSelecedTaxType = '';
  filteredProducts: any = [];
  trackById = trackById;
  trackByKey = trackByKey;
  isSearch = false;
  invoiceProdHash: any = {};
  isAllComplete = false;
  idGenerator = this.createObjectIdGenerator();

  handleBarcodeSearch(res) {
    this.loaderService.display(true, 'Fetching Product');
    const params = {
      name: res,
      category: '',
      inventoryData: false,
      subcategory: '',
      assignedProducts: true,
      skip: 0,
      limit: 1,
    };
    this.baseService
      .doRequest('/api/productmaster/fetchProducts', 'post', params)
      .subscribe((resp) => {
        if (resp && resp.data && resp.data.length) {
          resp.data.forEach((val) => {
            const arr = this.orders.orderedProducts
              .filter((item) => {
                if (item.id === val._id) {
                  return true;
                }
              });
            if (arr && arr.length) {
              this.searchProducts = arr;
              this.fromBarcodeSearch = true;
              return;
            }
          });
          this.loaderService.display(false);
          this.processBarC(res);
        } else {
          this.searchProducts = [];
          this.processBarC(res);
        }
      });
  }

  handleStringSearch(res) {
    this.searchProducts = this.orders.orderedProducts.filter(
      (item: any) =>
        (item.barCodes &&
          item.barCodes
            .toString()
            .toLowerCase()
            .includes(res.toLowerCase())) ||
        item.productName.toLowerCase().includes(res.toLowerCase()));
    this.fromBarcodeSearch = false;
    this.processBarC(res);
  }

  processBarC(res) {
    if (
      this.orders.invoices &&
      this.orders.invoices.length &&
      this.invoiceNumber
    ) {
      let invProds = [];
      const prodIds = this.invoiceProdHash[this.invoiceNumber]
        ? this.invoiceProdHash[this.invoiceNumber].map((a) => a.productId)
        : [];
      const skProducts = this.invoiceProdHash[this.invoiceNumber]
        ? this.invoiceProdHash[this.invoiceNumber].filter(
          (item: any) =>
            item.barCodes &&
            item.barCodes
              .toString()
              .toLowerCase()
              .includes(res.toLowerCase())
        )
        : [];
      if (skProducts.length && skProducts.length === 1) {
        invProds = this.orders.orderedProducts.filter(
          (item: any) => item.productId === skProducts[0].productId
        );
        invProds[0].barCodes = this.invoiceProdHash[
          this.invoiceNumber
        ].filter(
          (a) => a.productId === invProds[0].productId
        )[0].barCodes;
        invProds[0].mrp = this.invoiceProdHash[this.invoiceNumber].filter(
          (a) => a.productId === invProds[0].productId
        )[0].mrp;
        invProds[0].recievedQty = this.invoiceProdHash[
          this.invoiceNumber
        ].filter(
          (a) => a.productId === invProds[0].productId
        )[0].invoicequantity;
        invProds[0].hsn = invProds[0].hsn
          ? invProds[0].hsn
          : this.invoiceProdHash[this.invoiceNumber].filter(
            (a) => a.productId === invProds[0].productId
          )[0].hsn;
      } else if (skProducts.length && skProducts.length > 1) {
        this.orders.orderedProducts.forEach((item: any) => {
          if (prodIds.includes(item.productId)) {
            item.barCodes = this.invoiceProdHash[
              this.invoiceNumber
            ].filter((a) => a.productId === item.productId)[0].barCodes;
            item.mrp = this.invoiceProdHash[this.invoiceNumber].filter(
              (a) => a.productId === item.productId
            )[0].mrp;
            item.recievedQty = this.invoiceProdHash[
              this.invoiceNumber
            ].filter(
              (a) => a.productId === item.productId
            )[0].invoicequantity;
            item.hsn = item.hsn
              ? item.hsn
              : this.invoiceProdHash[this.invoiceNumber].filter(
                (a) => a.productId === item.productId
              )[0].hsn;
            invProds.push(item);
          }
        });
      } else {
        this.searchProducts.forEach((item: any) => {
          if (prodIds.includes(item.productId)) {
            item.barCodes = this.invoiceProdHash[
              this.invoiceNumber
            ].filter((a) => a.productId === item.productId)[0].barCodes;
            item.mrp = this.invoiceProdHash[this.invoiceNumber].filter(
              (a) => a.productId === item.productId
            )[0].mrp;
            item.recievedQty = this.invoiceProdHash[
              this.invoiceNumber
            ].filter(
              (a) => a.productId === item.productId
            )[0].invoicequantity;
            item.hsn = item.hsn
              ? item.hsn
              : this.invoiceProdHash[this.invoiceNumber].filter(
                (a) => a.productId === item.productId
              )[0].hsn;
            invProds.push(item);
          }
        });
      }
      this.searchProducts = invProds;
    }
    try {
      if (this.searchProducts.length === 1) {
        const ids = this.currentGRNProducts.map((item: any) => item.id);
        if (ids.indexOf(this.searchProducts[0].id) > -1) {
          this.notifyService.notification(
            'Product already added',
            false,
            'info'
          );
        } else {
          this.searchProducts[0].recievedQty = this.searchProducts[0].receivedQuantity;
          this.addToGRN(this.searchProducts[0]);
          this.search = '';
          this.clearSearch();
        }
      } else if (this.searchProducts.length > 1 && res !== '') {
        this.searchProducts.forEach((obj: any) => {
          obj.recievedQty = (obj.recievedQty) ? obj.recievedQty : obj.receivedQuantity;
        });
        this.fromBarcodeSearch = false;
        this.modalService.open('filteredItems');
        this.filteredProducts = this.searchProducts;
      } else {
        this.notifyService.notification(
          'No products matching in ordered/invoice list',
          false,
          'info'
        );
      }
      this.loaderService.display(false);
    } catch (e) {
      console.log('error', e);
      this.loaderService.display(false);
    }
    this.loaderService.display(false);
    return;
  }

  toggleSearch(force?: boolean) {
    this.search = '';
    this.isSearch = force ? false : !this.isSearch;
  }

  clearSearch(): void {
    this.search = '';
    this.filteredProducts = [];
    setTimeout(() => {
      document.getElementById('search').focus();
      this.searchTextCur.nativeElement.focus();
    }, 1500);
  }

  onChange(val): void {
    this.barcodeSearchInput.next(val);
    document.getElementById('search').focus();
    this.searchTextCur.nativeElement.focus();
  }

  onUpdate(val): void {
    this.invoiceNumberInput.next(val);
    if (this.orders.invoices && this.orders.invoices.length) {
      this.populateInvoiceDoclinks(this.invoiceNumber);
      this.populateInvoiceDate(this.invoiceNumber);
      this.isSKOrder = true;
      const invoice = this.orders.invoices.filter((obj) => {
        if (obj.invoiceNo === this.invoiceNumber) {
          return true;
        }
        if (obj.invoiceamount === this.invoiceamount) {
          return true;
        }
      });
      if (invoice && invoice.length === 1) {
        if (invoice.grnLocked) {
          this.toast.sToast('error', 'Docket already created! Please contact administrator.');
          return;
        } else {
          this.currentGRNProducts = [];
          invoice[0].invoiceproducts.forEach((item) => {
            let invProd: any = {};
            const prod = this.orders.orderedProducts.filter((p) => {
              if (p.id === item.productId) {
                return true;
              }
            });
            if (prod && prod.length) {
              invProd = Object.assign(prod[0], item);
              invProd.invoicequantity = invProd.invoicequantity * invProd.unitsize;
              invProd.mrp = (+invProd.mrp / invProd.unitsize).toFixed(2);
              invProd.calGrnAmount = invProd.orderQuantity * invProd.unitCost;

              this.addToGRN(invProd);
            }
          });
        }
      } else {
        this.toast.sToast('error', 'Error occurred. Found more than one invoices with same invoice number.');
      }
    }
  }

  populateInvoiceDoclinks(invNumber) {
    if (invNumber) {
      const invoiceDetails = this.orders.invoices.filter((val) => {
        if (val.invoiceNo === invNumber) {
          return true;
        }
      });
      if (invoiceDetails && invoiceDetails.length === 1) {
        if (invoiceDetails.invoiceFileLink !== '' && invoiceDetails.invoiceFileName !== '') {
          this.invoiceDocLink = [];
          this.invoiceDocLink = invoiceDetails;
          this.invoiceamount = invoiceDetails[0].invoiceamount?.toFixed(2);
        }
      }
    }
  }

  populateInvoiceDate(invNumber) {
    if (invNumber) {
      const invoiceDetails = this.orders.invoices.filter((val) => {
        if (val.invoiceNo === invNumber) {
          return true;
        }
      });
      if (invoiceDetails && invoiceDetails.length === 1) {
        if (invoiceDetails[0] && invoiceDetails[0].invoicedate) {
          this.invoiceDate = invoiceDetails[0].invoicedate.split('T')[0];
          this.isInvoiceDateReadOnly = true;
        } else {
          this.toast.sToast('info', 'Did not find invoice date.');
        }
      }
    }
  }

  ngOnInit(): void {
    this.minDay = this.orders.c.split('T')[0];
    if (this.orders && this.orders.invoices) {
      this.orders.invoices.forEach((a) => {
        this.invoiceProdHash[a.invoiceNo] = a.invoiceproducts;
      });
    }
    if (this.authService.currentUser.email) {
      this.grnBy = this.authService.currentUser.email;
    }
    if (this.invoiceNumber) {
      this.onUpdate(this.invoiceNumber);
    }
    this.initGRN();
    this.calculateTotal();
    this.isLoading = false;
  }

  addToGRN(product: any) {
    try {
      const ids = this.currentGRNProducts.map((item: any) => item.id);
      if (ids.indexOf(product.id) > -1) {
        this.notifyService.notification('Product already added', false, 'info');
      } else if (product.hsn === null || !product.hsn || product.hsn === '') {
        this.notifyService.notification(
          'HSN not found for this product - ' + product.productName,
          false,
          'error'
        );
      } else if (product.tax === null || !product.tax || product.tax === '') {
        this.notifyService.notification(
          'Tax not found for this product - ' + product.productName,
          false,
          'error'
        );
      } else {
        if (product && product.barCodes && product.barCodes.length === 1) {
          product.barCode = product.barCodes[0];
        }
        if (product && product.barCodes && product.barCodes.length >= 1 && this.isSKOrder) {
          product.barCode = product.barCodes[0];
        }
        if (product.orderQuantity && product.unitsize && product.receivedQuantity
          && ((product.orderQuantity * product.unitsize) === product.receivedQuantity)) {
          product.completedStatus = 'completed';
        } else {
          product.completedStatus = 'not_completed';
        }
        if (this.fromBarcodeSearch && !this.isSKOrder) {
          let tmpSearch = '';
          tmpSearch = this.search;
          product.barCode = tmpSearch;
        } else if (!this.fromBarcodeSearch && !this.isSKOrder) {
          product.barCode = '';
          if (product.completedStatus && product.completedStatus === 'not_completed' && this.isSearch) {
            setTimeout(() => {
              this.toggleSearch(true);
              const el = document.getElementById('barcode0');
              el.focus();
            }, 30);
          }
        }
        if (this.newTaxTypeSelected) {
          if (product.completedStatus && product.completedStatus !== 'completed') {
            product.taxType = this.curSelecedTaxType;
          }
        }
        this.currentGRNProducts.push(product);
        if (!this.isSKOrder) {
          this.notifyService.notification(
            product.productName + ', added, Scan next',
            false,
            'success'
          );
        }
        this.modalService.close('filteredItems');
        this.clearSearch();
        this.fromBarcodeSearch = false;
      }
    } catch (e) {
      console.log(e);
    }
    this.getCompletedCount();
  }

  getSavedDraft() {
    this.loaderService.display(true, 'Checking draft...');
    let params: any = {};
    params = {
      q: {
        query: {
          bool: {
            must: [
              { match: { 'orderId.keyword': `${this.orders._id}` } },
              // { match: { "grnstatus.keyword": "Draft" } },
            ],
          },
        },
      },
      skip: 0,
      limit: 10,
    };
    if (this.invoiceNumber) {
      params.q.query.bool.must.push({
        match: { 'invoiceNumber.keyword': `${this.invoiceNumber}` },
      });
    }
    this.baseService
      .doRequest('/api/goodsreceivednote', 'get', null, params)
      .subscribe((res) => {
        this.loaderService.display(false);
        if (res && res.data && res.data[0]) {
          this.initGRN(res.data[0]);
        }
      });
  }

  initGRN(sourceData?: any): void {
    if (sourceData) {
      if (this.isSKOrder) {
        console.log('i am an sk order with source data');
      }
      if (sourceData.doclinks) {
        sourceData.doclinks.forEach((obj) => {
          obj.fileName = obj.fileName;
          obj.status = true;
        });
      }
      this.invoiceDate = sourceData.invoiceDate;
      this.invoiceNumber = sourceData.invoiceNumber;
      this.grnBy = sourceData.grnBy;
      this.invoiceamount = sourceData.invoiceamount,
        this.currentDocument = sourceData.doclinks
          ? sourceData.doclinks
          : this.currentDocument;
      if (sourceData.grnstatus === 'Saved') {
        this.currentGRNProducts = [];
      } else {
        const arr = sourceData.inputData.map((val) => {
          const x = this.orders.orderedProducts.filter((valx) => {
            if (valx.id === val.productId) {
              return true;
            }
          });
          if (x.length) {
            return Object.assign(x[0], val);
          }
        });
        // change here
        arr.forEach((val) => {
          if (val !== undefined) {
            if (val.orderQuantity && val.unitsize && val.receivedQuantity
              && ((val.orderQuantity * val.unitsize) === val.receivedQuantity)) {
              val.completedStatus = 'completed';
            } else {
              val.completedStatus = 'not_completed';
              // push here
            }
          }
        });
        const cGRN = arr.filter((val) => val !== undefined);
        cGRN.forEach((obj) => {
          obj.expiryDate = (obj.expiryDate && obj.expiryDate !== null) ? obj.expiryDate : '';
          if (!obj.tax || obj.tax == null) {
            this.taxNotExistsList.push(obj.productName);
            this.taxNotExists = true;
          }
          if (obj.invoicequantity) {
            obj.orderQuantity = obj.invoicequantity;
          }
        });
        if (this.taxNotExists) {
          this.notifyService.notification('Products with no tax found ' + this.taxNotExistsList.join(','), false, 'error');
          this.taxNotExists = false;
          this.bviewChanged('orders');
          return;
        }

        this.currentGRNProducts = cGRN;
      }

    } else {
      if (this.isSKOrder) {
        console.log('i am an sk order without source data');
      }
      this.orders.orderedProducts.forEach((obj: any, index: any) => {
        obj.productId = '' + obj.id;
        obj.caseQty = 0 + obj.caseSize;
        obj.freeQty = 0;
        obj.discAmount = 0;
        obj.batchNo = '';
        obj.taxType = (this.orders.orderGstType) ? (this.orders.orderGstType.toUpperCase()) : 'SGST';
        obj.invTotal = obj.orderQuantity * obj.unitsize;
        obj.recievedQty = obj.orderQuantity * obj.unitsize - obj.receivedQuantity;
        obj.mrp = (obj.mrp / obj.unitsize).toFixed(2);
        obj.expiryDate = (obj.expiryDate && obj.expiryDate !== null) ? obj.expiryDate : '';
        obj.calGrnAmount = obj.recievedQty * obj.unitCost;
        this.calculatedGrnAmount += obj.calGrnAmount;
      });
      this.orders.calculatedGrnAmount = this.calculatedGrnAmount;
      this.loadAllProducts();
    }
    this.barCodeScan.pipe(debounceTime(200)).subscribe((res: any) => {
      document.getElementById('mrp').focus();
    });
  }
  calculateTotal(rq?: any, index?: any) {
    if (rq) {
      this.currentGRNProducts[index].receivedQty = rq;
    }
    this.totalsum = 0;
    this.calculatedGrnAmount = 0;
    this.discAmount = 0
    this.currentGRNProducts.forEach((obj: any) => {
      obj.receivedQty = (obj.receivedQty) ? obj.receivedQty : 0;
      obj.calGrnnAmount = ((obj.recievedQty * obj.unitCost / obj.caseSize) - obj.discAmount);
      this.calculatedGrnAmount += obj.calGrnnAmount;
    })
    this.calculatedGrnAmount = parseFloat(this.calculatedGrnAmount.toFixed(2));
    console.log(this.calculatedGrnAmount);

  }
  ngOnChanges(): void {
    this.calculateTotal()
  }

  loadAllProducts(): void {
    if (!this.orders.vendorOrderDetails || !this.orders.vendorOrderDetails.length) {
      const prods = this.orders.orderedProducts;
      prods.forEach((obj: any) => {
        let invProd: any = {};
        invProd = Object.assign({}, obj);
        invProd.invoicequantity = invProd.invoicequantity * invProd.unitsize;
        invProd.mrp = (+invProd.mrp / invProd.unitsize).toFixed(2);
        invProd.barCodes = (invProd.barCodes) ? invProd.barCodes : [];
        invProd.recievedQty = 0;
        console.log(this.callAddGRN(invProd));
      });
    }
  }

  callAddGRN(prod: any): boolean {
    this.addToGRN(prod);
    return true;
  }

  paste($event, loc) {
    this.barCodeScan.next({ str: $event, location: loc });
  }

  checkMinPrice($event, prod): void {
    if (this.isSKOrder && $event && $event > 0 && $event < prod.pprice) {
      this.toast.sToast('error', `Minimum price should be ${prod.pprice}`);
      this.isMinPPriceError = true;
    } else {
      this.isMinPPriceError = false;
    }
  }

  bviewChanged(val: string) {
    this.bview.emit(val);
  }

  onlyUnique(value, index, self): any {
    return self.indexOf(value) === index;
  }

  saveGRN() {
  }

  confirmCloseInvoice() {
    const titleName = 'Confirmation';
    const message =
      'Please make sure you entered all the items in the invoice, and the respective received quantity. Are you sure you want to close this invoice?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((resp) => {
      if (resp) {
        this.isLoading = true;
        this.loaderService.display(true, 'Closing the invoice...');
        this.baseService
          .doRequest('/api/goodsreceivednote/closeInvoice', 'post', {
            orderId: this.orders._id,
            invoiceNumber: this.invoiceNumber,
          })
          .subscribe((res: any) => {
            this.loaderService.display(false);
            this.isLoading = false;
            if (res[0] === true) {
              this.notifyService.notification(res[1], false, 'success');
            } else if (res[0] === false) {
              this.notifyService.notification(res[1], false, 'error');
            }
            this.currentGRNProducts = [];
            this.toggleSearch(true);
            this.bviewChanged('orders');
          },
            (error) => {
              this.loaderService.display(false);
              this.isLoading = false;
              this.notifyService.notification(error.message, false, 'error');
            }
          );
      }
    });
  }

  taxTypeChanged(value) {
    this.newTaxTypeSelected = true;
    this.curSelecedTaxType = value;
    this.currentGRNProducts.forEach((val) => {
      if (val.completedStatus !== 'completed') {
        val.taxType = value;
      }
    });
  }
  submitGRN() {
    let invoiceError = false;
    const invoiceCopy = this.currentDocument.filter((val) => {
      if (val.name === 'invoiceCopy') {
        return true;
      }
    });
    const invoiceDocLinks = this.invoiceDocLink.filter((val) => {
      if (val.invoiceFileName) {
        return true;
      }
    });
    if (this.isSKOrder && this.isMinPPriceError) {
      this.toast.sToast('error', 'MRP lesser than actual purchase price.'); return;
    }
    if (!this.isSKOrder && invoiceCopy.length === 0) {
      invoiceError = true;
    }
    if (this.isSKOrder && invoiceDocLinks.length === 0) {
      invoiceError = true;
    }
    if (this.isSKOrder && invoiceDocLinks.length !== 0) {
      invoiceError = false;
    }
    if (this.isSKOrder && invoiceDocLinks.length === 0 && invoiceCopy.length !== 0) {
      invoiceError = false;
    }

    if (!invoiceError) {
      this.isLoading = true;
      this.loaderService.display(true, 'Creating GRN');
      let negativeValues = 0;
      let invoiceNotAvailable = false;
      this.freeQtyList = [];
      this.zeromrpList = [];
      this.invQtyList = [];
      this.lessMrp = [];
      this.recievedQtyExceedingList = [];
      this.orderInvMisMatchList = [];
      if (!this.invoiceNumber || this.invoiceNumber.trim().length === 0) {
        invoiceNotAvailable = true;
      }
      this.currentGRNProducts.forEach((obj: any) => {
        if (obj.freeQty === null) {
          obj.freeQty = 0;
        }
        if (obj.freeQty < 0) {
          this.freeQtyList.push(obj.productName);
          negativeValues -= 1;
        }
        if (obj.mrp <= 0) {
          this.zeromrpList.push(obj.productName);
          this.zeromrp = true;
        }
        /*if (obj.mrp <  (obj.unitCost / obj.unitsize)) {
          this.lessMrp.push(obj.productName);
          this.lessmrpprice = true;
        }*/
        if (((obj.orderQuantity * obj.unitsize) < obj.invoicequantity) && !this.isSKOrder) {
          this.orderInvMisMatchList.push(obj.productName);
          this.orderInvMisMatch = true;
        }
        if ((obj.recievedQty > obj.invoicequantity) && !this.isSKOrder) {
          this.invQtyList.push(obj.productName);
          this.invQty = true;
        }
        if (((obj.orderQuantity * obj.unitsize) - obj.receivedQuantity) < obj.recievedQty) {
          this.recievedQtyExceedingList.push(obj.productName);
          this.recievedQtyExceeding = true;
        }
      });

      if (invoiceNotAvailable) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification('Invoice Number not found', false, 'error');
      } else if (negativeValues < 0) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Negative values in Free Quantity found',
          false,
          'error'
        );
      } else if (this.zeromrp) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Zero MRP found.' + this.zeromrpList.join(', '),
          false,
          'error'
        );
        this.zeromrp = false;
      } else if (this.lessmrpprice) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'The MRP cannot be less than the purchasing price.' + this.zeromrpList.join(', '),
          false,
          'error'
        );
        this.lessmrpprice = false;
      } else if (this.invQty) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Recieved Quantity more than Invoiced Quantity found. Please check for ' + this.invQtyList.join(', '),
          false,
          'error'
        );
        this.invQty = false;
      } else if (this.orderInvMisMatch) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Invoice qty entered is more than order qty for ' + this.orderInvMisMatchList.join(', '),
          false,
          'error'
        );
        this.orderInvMisMatch = false;
      } else if (this.recievedQtyExceeding) {
        this.loaderService.display(false);
        this.isLoading = false;
        this.notifyService.notification(
          'Received Quantity exceeding balanced quantity. Please Check for  ' + this.recievedQtyExceedingList.join(', '),
          false,
          'error'
        );
        this.recievedQtyExceeding = false;
      } else {
        this.isLoading = false;
        this.loaderService.display(false);
        this.modalService.open('congirmGRN');
      }
    } else {
      this.toast.sToast('error', 'Invoice copy is mandatory. Please upload!');
    }
  }

  createGRN() {
    this.submitted = 'No';
    this.isLoading = true;
    this.loaderService.display(true, 'Creating GRN');
    const prods: any = [];
    const free: any = [];
    this.currentGRNProducts.forEach((obj: any) => {
      if (obj.freeQty > obj.recievedQty) {
        free.push(obj.productName);
      }
      prods.push({
        caseQty: obj.caseQty,
        mrp: obj.mrp,
        recievedQty: obj.recievedQty,
        freeQty: obj.freeQty,
        taxType: obj.taxType,
        apiprice: (this.isSKOrder) ? obj.pprice : 0,
        discAmount: obj.discAmount,
        batchNo: obj.batchNo,
        barCode: obj.barCode,
        productId: obj.productId,
        expiryDate: (obj.expiryDate) ? obj.expiryDate : undefined,
        invoicequantity: obj.invoicequantity,
        completeStatus: (obj.completedStatus === 'completed') ? true : false,

      });
    });
    if (free.length > 0) {
      this.loaderService.display(false);
      this.isLoading = false;
      this.notifyService.notification(
        ` ${free.join(', ')} - Free quantity not more than received quantity. `,
        false,
        'error'
      );
      return;
    }
    const nonCompleteProds = prods.filter((val) => { if (!val.completeStatus) { return true; } });
    const objGRN = {
      orderId: this.orders._id,
      invoiceNumber: this.invoiceNumber.trim(),
      invoiceDate: this.invoiceDate,
      grnBy: this.grnBy,
      productsData: nonCompleteProds,
      doclinks: this.currentDocument,
      saveAsDraft: false,
      calculatedGrnAmount: this.calculatedGrnAmount,
      invoiceDiscount: this.invoiceDiscount,
      invoiceAmount: parseFloat(this.invoiceAmount.toFixed(2)),
    };
    this.submitted = 'Yes';
    this.baseService
      .doRequest('/api/goodsreceivednote/createGRN', 'post', objGRN)
      .subscribe(
        (res) => {
          setTimeout(() => {
            this.submitted = "No";
            this.loaderService.display(false);
            this.isLoading = false;
            if (res[0] === true) {
              this.submitted = "No";
              this.notifyService.notification(res[1], false, 'success');
              this.currentGRNProducts = [];
              this.toggleSearch(true);
              this.bviewChanged('orders');
            } else if (res[0] === false) {
              this.submitted = "No";
              this.notifyService.notification(res[1], false, 'error');
            }
          }, 2000);
        },
        (error) => {
          if (!navigator.onLine) {
            this.loaderService.display(false);
            this.isLoading = false;
            this.submitted = 'No';
            this.notifyService.notification('Internet Offline!', false, 'error');
            return;
          }
          this.loaderService.display(false);
          this.isLoading = false;
          this.submitted = 'No';
          this.notifyService.notification(error.message, false, 'error');
        }
      );
    this.isLoading = false;
  }

  freeQtyValueChange(val: number) {
    if (val < 0) {
      this.notifyService.notification(
        'Free Quantity can not be negative',
        false,
        'error'
      );
    }
  }

  uploadFile(event: any, key: string) {
    if (event.target.files.length > 0) {
      this.getBase64(event.target.files[0]).then((data) => {
        const obj = {
          name: key,
          doc: data,
          fileName: event.target.files[0].name,
        };
        this.currentDocument.push(obj);
      });
    }
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  deleteFile(index) {
    if (this.currentDocument[index].name) {
      // @ts-ignore
      document.getElementById(`${this.currentDocument[index].name}`).value = '';
    }
    this.currentDocument.splice(index, 1);
  }

  saveDraft() {
    this.isLoading = true;
    this.loaderService.display(true, 'Saving GRN as Draft');
    const prods: any = [];
    let negativeValues = 0;
    this.currentGRNProducts.forEach((obj: any) => {
      if (obj.freeQty < 0) {
        negativeValues -= 1;
      }
      if (obj.mrp <= 0) {
        this.zeromrp = true;
      }
      prods.push({
        caseQty: obj.caseQty,
        mrp: obj.mrp,
        recievedQty: obj.recievedQty,
        freeQty: obj.freeQty,
        taxType: obj.taxType,
        discAmount: obj.discAmount,
        batchNo: obj.batchNo,
        barCode: obj.barCode,
        productId: obj.productId,
        expiryDate: (obj.expiryDate) ? obj.expiryDate : undefined,
        completeStatus: (obj.completedStatus === 'completed') ? true : false,
      });
    });
    const nonCompleteProds = prods.filter((val) => { if (!val.completeStatus) { return true; } });
    const objGRN = {
      orderId: this.orders._id,
      invoiceNumber: this.invoiceNumber,
      invoiceDate: this.invoiceDate,
      grnBy: this.grnBy,
      productsData: nonCompleteProds,
      doclinks: this.currentDocument,
      saveAsDraft: true,
      calculatedGrnAmount: this.calculatedGrnAmount,
      invoiceDiscount: this.invoiceDiscount,
      invoiceAmount: parseFloat(this.invoiceAmount.toFixed(2)),
    };
    if (negativeValues < 0) {
      this.loaderService.display(false);
      this.isLoading = false;
      this.notifyService.notification(
        'Negative values in Free Quantity found',
        false,
        'error'
      );
    } else if (this.zeromrp) {
      this.loaderService.display(false);
      this.isLoading = false;
      this.notifyService.notification('Zero MRP found.', false, 'error');
    } else {
      this.baseService
        .doRequest('/api/goodsreceivednote/createGRN', 'post', objGRN)
        .subscribe((res) => {
          this.loaderService.display(false);
          this.isLoading = false;
          if (res[0]) {
            this.notifyService.notification(res[1], false, 'success');
          } else if (!res[0]) {
            this.notifyService.notification(res[1], false, 'error');
          }
        });
    }
    this.isLoading = false;
  }

  deleteOrder(index) {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to Delete ${this.currentGRNProducts[index].productName}?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.currentGRNProducts.splice(index, 1);
        this.toast.sToast(
          'info',
          'Press Save Draft to save the current changes.'
        );
        this.getCompletedCount();
        this.calculateTotal();
      }
    });
  }
  downloadPDF(val) {
    const linkSource = val.doc;
    const downloadLink = document.createElement('a');
    const fileName = `${val.fileName}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    if (val.status) {
      window.open(linkSource, '_blank');
    } else {
      downloadLink.click();
    }
  }
  downloadFile(val) {
    window.open(val, '_blank');
  }
  changeField(value, fieldName, loc) {
    // product.fieldName = $event
    const arr = this.currentGRNProducts[loc];
    arr[fieldName] = value;
    if (fieldName === 'freeQty') {
      this.freeQtyValueChange(value);
    }
  }

  prodTrackByFn(index: number, prod) {
    return prod.id;
  }
  createObjectIdGenerator() {
    // tslint:disable-next-line:ban-types
    const objectIds = new WeakMap<Object, number>();
    let counter = 1;
    // tslint:disable-next-line:only-arrow-functions ban-types
    return function (item: Object): number {
      let result = objectIds.get(item);
      if (!result) {
        result = counter++;
        objectIds.set(item, result);
      }
      return result;
    };
  }
  getCompletedCount() {
    if (this.currentGRNProducts && this.currentGRNProducts.length) {
      const nonComplete = this.currentGRNProducts.filter((val) => (val.completedStatus === 'not_completed'));
      if (nonComplete.length) {
        this.isAllComplete = false;
      } else {
        this.isAllComplete = true;
      }
    } else {
      this.isAllComplete = false;
    }
  }
}
