import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from '../../_services/modal.service';
import {LoaderService} from '../../_services/loader.service';

@Component({
  selector: 'vex-spay',
  templateUrl: './spay.component.html',
  styleUrls: ['./spay.component.scss']
})
export class SpayComponent implements OnInit, AfterViewInit {
  transactionId: string;
  constructor(private params: ActivatedRoute, private modalService: ModalService, private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.transactionId = this.params.snapshot.paramMap.get('transactionId');
    localStorage.setItem('transactionId', this.transactionId);
  }

  ngAfterViewInit() {
    this.loaderService.display(true);
    this.modalService.open('successpayment');
    setTimeout(() => { window.close(); }, 2000);
  }
}
