import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { CommonService } from './common.services';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateService {

  private _messages = {};

  public translationLoaded: Observable<boolean>;
  public translationLoadedObserver: Observer<boolean>;
  public langUpdatedEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public translationLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _http: HttpClient, private cs: CommonService) {
    this.loadTranslations();
  }

  loadTranslations(lang?: string) {
    this.translationLoaded = new Observable((observer) => {
      this.translationLoadedObserver = observer;
    });
    const curLang = (lang && lang != undefined) ? lang : 'en';
    var observable = this._http.get(`/assets/i18n/${curLang}.json`).subscribe(res => {
      this._messages = res;
      this.cs.isTranslateFileAvailable = true;
      this.translationLoadedObserver.next(true);
      this.langUpdatedEventEmitter.emit(true);
    });
  }

  getTranslationByKey(key: string, args?: any[]) {
    return this._messages[key];
  }
}
