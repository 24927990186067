import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { orderBy } from 'lodash';
import * as moment from 'moment';


@Pipe({
    name: 'sortBy',
    pure: true
})
/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
export class ArraySortPipe implements PipeTransform {
    transform(value: any[], order = '', column: string = ''): any[] {
        if (!value || order === '' || !order) {
            return value;
        } // no array
        if (value.length <= 1) {
            return value;
        } // array with only one item
        if (!column || column === '') {
            if (order === 'asc') {
                return value.sort();
            } else {
                return value.sort().reverse();
            }
        } // sort 1d array
        return orderBy(value, [column], [order]);
    }
}


@Pipe({
    name: 'filterUnique',
    pure: true
})
export class UniqueFilterPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value !== null) {
            const uniqueArray = value.filter((el, index, array) => {
                return array.indexOf(el) === index;
            });
            return uniqueArray;
        }
    }
}

@Pipe({
    name: 'filter'
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string): any[] {
        if (!items) {
            return [];
        }
        if (!field || !value) {
            return items;
        }

        return items.filter(singleItem =>
            singleItem[field].toLowerCase().includes(value.toLowerCase())
        );
    }
}

@Pipe({ name: 'utcToLocale' })

export class UtcToLocale implements PipeTransform {
    transform(date: string): string {
        if (date === null || !date || date === '' || date === '-') { return '-'; }
        if (date && date.indexOf('Z') === -1) { date += 'Z'; }
        return date ? new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString() : date;
    }
}


@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value || value === '') {
            return '-';
        }
        let content = '';
        const year = Math.floor(value / ((365 * 24) * 3600));
        value = value % ((365 * 24) * 3600);
        if (year > 0) {
            content += year + 'y ';
        }
        const day = Math.floor(value / (24 * 3600));
        value = value % (24 * 3600);
        if (day > 0) {
            content += day + 'd ';
        }
        const hour = Math.floor(value / 3600);
        value %= 3600;
        if (hour > 0) {
            content += hour + 'h ';
        }
        const minutes = Math.floor(value / 60);
        value %= 60;
        if (minutes > 0) {
            content += minutes + 'm ';
        }
        const seconds = Math.floor(value);
        if (seconds > 0) {
            content += seconds + 's ';
        }
        return content;
    }
}


@Pipe({ name: 'formatCell' })
export class FormatCellPipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {
    }

    transform(value: any, format: string): any {
        if (value === undefined) {
            return '';
        }
        if (format === 'date') {
            return this.datePipe.transform(value, 'medium');
        } else if (format === 'cameltohuman') {
            return new CamelToHumanPipe().transform(value, true);
        } else if (format === 'epochToDate') {
            return new EpochToDateFilterPipe().transform(value);
        } else if (format === 'cveToDate') {
            return new CveToDateFilterPipe().transform(value);
        } else if (format === 'dateAgo') {
            return new DateAgoPipe().transform(value);
        } else if (format === 'assessmentDate') {
            return new AssessmentDateFilterPipe().transform(value);
        } else if (format === 'formatTrafficUnits') {
            return new FormatTrafficUnitsPipe().transform(value);
        } else if (format === 'macFilter') {
            return new MacFilterPipe().transform(value);
        } else if (format === 'arrayToStr') {
            return new ArrayToStrPipe().transform(value);
        } else if (format === 'serviceroleMapping') {
            return new serviceroleMappingPipe().transform(value);
        } else if (format === 'objectToStr') {
            return new ObjectToStrPipe().transform(value);
        } else if (format === 'arrayToStrWithEllipsis') {
            return new ArrayToStrEllipsisPipe().transform(value);
        } else if (format === 'daysHoursSeconds') {
            return new DaysHoursSecondsPipe().transform(value);
        } else if (format === 'utcToLocale') {
            return new UtcToLocale().transform(value);
        } else if (format === 'timeAgo') {
            return new TimeAgoPipe().transform(value);
        } else if (format === 'capitalize') {
            return new CapitalizeFilterPipe().transform(value);
        } else if (format === 'dateTimeConverter') {
            return new DateTimeConverterFilterPipe().transform(value);
        } else if (format.indexOf('ellipsis') > -1) {
            const prms = value.split(':');
            return new EllipsisPipe().transform(prms[0], prms[1]);
        } else if (format === 'formatDate') {
            return new formatDateDatePipe().transform(value);
        } else if (format === 'formatOnlyDate') {
            return new formatOnlyDateDatePipe().transform(value);
        } else if (format === 'decimalPipe') {
            return new DecimalPipe().transform(value);
        }
        return value;
    }
}

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform {
    private timer: number;

    transform(value: any) {
        const d = new Date(value * 1000);
        const now = new Date();
        const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
        const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
        const minutes = Math.round(Math.abs(seconds / 60));
        const hours = Math.round(Math.abs(minutes / 60));
        const days = Math.round(Math.abs(hours / 24));
        const months = Math.round(Math.abs(days / 30.416));
        const years = Math.round(Math.abs(days / 365));
        if (Number.isNaN(seconds)) {
            return '';
        } else if (seconds <= 45) {
            return 'A few seconds ago';
        } else if (seconds <= 90) {
            return 'A minute ago';
        } else if (minutes <= 45) {
            return minutes + ' minutes ago';
        } else if (minutes <= 90) {
            return 'An hour ago';
        } else if (hours <= 22) {
            return hours + ' hours ago';
        } else if (hours <= 36) {
            return 'A day ago';
        } else if (days <= 25) {
            return days + ' days ago';
        } else if (days <= 45) {
            return 'A month ago';
        } else if (days <= 345) {
            return months + ' months ago';
        } else if (days <= 545) {
            return 'A year ago';
        } else { // (days > 545)
            return years + ' years ago';
        }
    }


    private getSecondsUntilUpdate(seconds: number) {
        const min = 60;
        const hr = min * 60;
        const day = hr * 24;
        if (seconds < min) { // less than 1 min, update every 2 secs
            return 2;
        } else if (seconds < hr) { // less than an hour, update every 30 secs
            return 30;
        } else if (seconds < day) { // less then a day, update every 5 mins
            return 300;
        } else { // update every hour
            return 3600;
        }
    }
}

@Pipe({
    name: 'macFilter'
})
export class MacFilterPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value || value === '') {
            return '';
        }
        return value.match(/.{1,2}/g).join(':');
    }
}

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
    name: 'orderBy'
})
export class ArrayOrderByPipe {
    private static parseExpression(expression: string): string[] {
        expression = expression.replace(/\[(\w+)\]/g, '.$1');
        expression = expression.replace(/^\./, '');
        return expression.split('.');
    }
    private static getValue(object: any, expression: string[]) {
        for (let i = 0, n = expression.length; i < n; ++i) {
            const k = expression[i];
            if (!(k in object)) {
                return;
            }
            object = object[k];
        }

        return object;
    }

    private static setValue(object: any, value: any, expression: string[]) {
        let i;
        for (i = 0; i < expression.length - 1; i++) {
            object = object[expression[i]];
        }

        object[expression[i]] = value;
    }

    transform(value: any | any[], expression?: any, reverse?: boolean): any {
        if (!value) {
            return value;
        }

        const isArray = value instanceof Array;

        if (isArray) {
            return this.sortArray(value, expression, reverse);
        }

        if (typeof value === 'object') {
            return this.transformObject(value, expression, reverse);
        }

        return value;
    }


    private sortArray(value: any[], expression?: any, reverse?: boolean): any[] {
        const isDeepLink = expression && expression.indexOf('.') !== -1;

        if (isDeepLink) {
            expression = ArrayOrderByPipe.parseExpression(expression);
        }

        const array: any[] = value.sort((a: any, b: any): number => {
            if (!expression) {
                return a > b ? 1 : -1;
            }

            if (!isDeepLink) {
                return a[expression] > b[expression] ? 1 : -1;
            }

            return ArrayOrderByPipe.getValue(a, expression) > ArrayOrderByPipe.getValue(b, expression) ? 1 : -1;
        });

        if (reverse) {
            return array.reverse();
        }

        return array;
    }

    private transformObject(value: any | any[], expression?: any, reverse?: boolean): any {
        const parsedExpression = ArrayOrderByPipe.parseExpression(expression);
        let lastPredicate = parsedExpression.pop();
        let oldValue = ArrayOrderByPipe.getValue(value, parsedExpression);

        if (!(oldValue instanceof Array)) {
            parsedExpression.push(lastPredicate);
            lastPredicate = null;
            oldValue = ArrayOrderByPipe.getValue(value, parsedExpression);
        }

        if (!oldValue) {
            return value;
        }

        const newValue = this.transform(oldValue, lastPredicate, reverse);
        ArrayOrderByPipe.setValue(value, newValue, parsedExpression);
        return value;
    }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Pipe({ name: 'safeHTML' })
export class SafeHTMLPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(url): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(url);
    }
}

@Pipe({
    name: 'camelToHuman'
})
export class CamelToHumanPipe implements PipeTransform {
    transform(input: any, uppercaseFirst: any): any {
        if (typeof input !== 'string') {
            return input;
        }
        let result = input.replace(/([a-z\d])([A-Z])/g, '$1' + (' ' || '_') + '$2');
        if (result.indexOf('_') > -1) {
            result = result.replace(/(?:_| |\b)(\w)/g, (key, p1) => {
                return p1.toUpperCase();
            }).replace(/([a-z])([A-Z])/g, '$1 $2');
        }
        if (uppercaseFirst) {
            result = result.charAt(0).toUpperCase() + result.slice(1);
        }
        return result;
    }
}

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return null;
        }
        if (!args) {
            return value;
        }
        args = args.toLowerCase();
        return value.filter((item) => {
            return JSON.stringify(item).toLowerCase().includes(args);
        });
    }
}


@Pipe({
    name: 'formatTrafficUnits'
})
export class FormatTrafficUnitsPipe implements PipeTransform {
    transform(units: any, decimals?: any, display?: any, base?: any): any {
        if (!units) {
            return '';
        }
        if (display === undefined) {
            display = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
        }
        if (units === 0) {
            return units + display[0];
        }
        base = base || 1000; // or 1024 for binary
        const dm = decimals || 2;
        const i = Math.floor(Math.log(units) / Math.log(base));
        return parseFloat((units / Math.pow(base, i)).toFixed(dm)) + display[i];
    }
}

@Pipe({
    name: 'ellipsis'
})
export class EllipsisPipe {
    transform(val: any, args?: any): any {
        if (!val) { return val; }
        args = (!args) ? 25 : args;
        if (typeof val === 'object' && val.length && val.length !== undefined) {
            val = val.join(', ');
        } else if (typeof val === 'object' && val.length === undefined) {
            val = JSON.stringify(val).replace('{', '')
                .replace('}', '')
                .replace(/\\/g, '');
        } else {
            val = val + '';
        }
        if (val.length > args) {
            return val.substring(0, args) + '...';
        } else {
            return val;
        }
    }
}

@Pipe({
    name: 'arrayToStrWithEllipsis'
})
export class ArrayToStrEllipsisPipe implements PipeTransform {
    transform(input: any, args?: any): any {
        if (!input || input === null || input === '') {
            return '';
        }
        if (input.length < 3) {
            return input.join(', ');
        } else if (input.length > 2) {
            return input[0] + ', ' + input[1] + ', ...';
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'arrayToStr'
})
export class ArrayToStrPipe implements PipeTransform {
    transform(input: any, args?: any): any {
        if (!input || input === null || input === '') {
            return '';
        }
        if (input.length > 0) {
            return input.join(', ');
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'objectToStr'
})
export class ObjectToStrPipe implements PipeTransform {
    transform(input: any, args?: any): any {
        if (!input || input === null || input === '') {
            return '';
        }
        if (input.length > 0) {
            input.forEach(element => {
                const doors = element.doors.join(', ');
                const output = element.zone + ':' + doors;
                return output;
            });
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'strToMac'
})
export class StrToMacPipe implements PipeTransform {
    transform(input: any, uppercase: any): any {
        if (!input || input === null || input === '') {
            return '';
        }
        if (uppercase) {
            input = input.toUpperCase();
        }
        if (input.length >= 3 && input.length <= 16) {
            input = input.replace(/\W/ig, '');
            input = input.replace(/(.{2})/g, '$1:');
            return input;
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'cveToDate'
})
export class CveToDateFilterPipe implements PipeTransform {
    transform(epoch: any): any {
        if (!epoch) {
            return '-';
        }
        epoch = epoch + '';
        const year = epoch.slice(0, 4);
        const month = epoch.slice(4, 6);
        const date = epoch.slice(6, 8);
        return year + '/' + month + '/' + date;
    }
}

@Pipe({
    name: 'assessmentDate'
})
export class AssessmentDateFilterPipe implements PipeTransform {
    transform(epoch: any): any {
        if (!epoch) {
            return '-';
        }
        const ret = [];
        if (epoch.length > 0) {
            epoch.forEach(dt => {
                dt = dt + '';
                dt = dt.split('.')[0];
                const d = (dt.toString().length === 10) ? new Date(dt * 1000) : new Date(dt);
                ret.push(d.toLocaleDateString() + ' ' + d.toLocaleTimeString());
            });
        }
        return ret.join(', ');
    }
}

@Pipe({
    name: 'serviceroleMapping'
})
// tslint:disable-next-line:class-name
export class serviceroleMappingPipe implements PipeTransform {
    transform(rolesData): any {
        let keys = '';
        // tslint:disable-next-line:forin
        for (const key in rolesData) {
            console.log(key);
            keys += rolesData[key].name + ' : ' + rolesData[key].description + '\n';
        }
        return keys;
    }
}

@Pipe({ name: 'split' })
export class SplitPipe implements PipeTransform {
    transform(input: any, separator: string = ' ', limit?: number): any {
        if (typeof input === 'string') {
            return input.split(separator)[limit];
        }
        return input;
    }
}

@Pipe({
    name: 'capitalize'
})
export class CapitalizeFilterPipe implements PipeTransform {
    transform(input: any, args?: any): any {
        if (!input || input === null || input === '') {
            return '';
        }
        if (input.length > 0) {
            return input.charAt(0).toUpperCase() + input.slice(1);
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'dateTimeConverter'
})
export class DateTimeConverterFilterPipe implements PipeTransform {
    transform(input: any, args?: any): any {
        if (!input || input === null || input === '') {
            return '';
        }
        // const dateTime = Math.round((new Date(input).getTime() / 1000)
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        // tslint:disable-next-line:variable-name
        const current_datetime = new Date(input);
        let hours = current_datetime.getHours();
        const minutes = current_datetime.getMinutes();
        const sec = current_datetime.getSeconds();
        let tmpsec = '';
        if (sec < 10) {
            tmpsec = '0' + sec;
        }
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        const tmphours = hours ? '0' + hours : 12; // the hour '0' should be '12'
        const tmpminutes = minutes < 10 ? '0' + minutes : minutes;
        const strTime = tmphours + ':' + tmpminutes + ':' + tmpsec + ' ' + ampm;
        return current_datetime.getDate() + '-' +
            months[current_datetime.getMonth()] + '-' +
            current_datetime.getFullYear() + ' ' + strTime;
    }
}

@Pipe({
    name: 'formatOnlyDate'
})
// tslint:disable-next-line:class-name
export class formatOnlyDateDatePipe implements PipeTransform {
    transform(date: any, args?: any): any {
        if (!date || date === null || date === '-') { return '-'; }
        const d = new Date(date);
        if (!d || !date) {
            return '-';
        }
        let ndt = '';
        if (date) {
            ndt = moment(date).format('DD-MM-YYYY');
            if (ndt.toLocaleLowerCase() === 'invalid date') {
                ndt = moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY');
            }
        } else {
            ndt = '-';
        }
        return ndt;
    }
}

@Pipe({
    name: 'formatDate'
})
// tslint:disable-next-line:class-name
export class formatDateDatePipe implements PipeTransform {
    transform(date: any, args?: any): any {
        const d = new Date(date);
        // let d= date
        // let ndt = moment(date).format('DD-MMM-YYYY hh:mm:ss A');
        let ndt = ''
        if (date) {
            ndt = moment(date).format('DD-MM-YYYY hh:mm:ss A');
            if (ndt.toLocaleLowerCase() === 'invalid date') {
                ndt = moment(date, 'DD/MM/YYYY hh:mm:ss A').format('DD-MM-YYYY hh:mm:ss A');
            }
        }
        else {
            ndt = ''
        }
        return ndt;
        // return this.datepipe.transform(d, 'dd-MMM-yyyy HH:mm:ss a');
    }
}


@Pipe({
    name: 'epochToDate'
})
export class EpochToDateFilterPipe implements PipeTransform {
    transform(epoch: any): any {
        if (!epoch || epoch.toString().length > 13) {
            return '-';
        }
        epoch = (epoch + '').split('.')[0];
        const d = (epoch.toString().length === 10) ? new Date(epoch * 1000) : new Date(epoch);
        const iso = d.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/);
        const val = iso[1].split('-');
        const dt = d.toLocaleDateString('en-GB', { day: '2-digit' }) +
            '-' + (d.toLocaleDateString('en-GB', { month: 'short' })).substring(0, 3) + '-' +
            d.toLocaleDateString('en-GB', { year: 'numeric' });
        return dt + ' ' + d.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    }
}

@Pipe({
    name: 'bytesConvert'
})
export class BytesConvertFilterPipe implements PipeTransform {
    transform(bytes: any, decimals: number = 2): any {
        if (bytes === 0 || !bytes || bytes === '' || bytes === null) {
            return '0';
        } else if (typeof bytes === 'string') {
            bytes = Number(bytes);
        }
        const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
        let unit = 0;
        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }
        return bytes.toFixed(+decimals) + ' ' + units[unit];
    }
}

@Pipe({
    name: 'daysHoursSeconds'
})
export class DaysHoursSecondsPipe implements PipeTransform {
    transform(sec: any, args?: any): any {
        if (!sec) {
            return sec;
        }
        let t: any = Math.floor(sec / 100);
        // tslint:disable-next-line:prefer-const
        let years: any;
        // tslint:disable-next-line:prefer-const
        let months: any;
        let days: any;
        if (t > 86400) {
            days = Math.floor(t / 86400);
            t = t - (days * 86400);
        }
        const hours = Math.floor(t / 3600);
        t = t - (hours * 3600);
        const minutes = Math.floor(t / 60);
        t = t - (minutes * 60);
        let content = '';
        if (years) {
            content += years + ' years';
        }
        if (months) {
            if (content) {
                content += ', ';
            }
            content += months + ' months';
        }
        if (days) {
            if (content) {
                content += ', ';
            }
            content += days + ' days';
        }
        if (hours || days) {
            if (content) {
                content += ', ';
            }
            content += hours + ' hours';
        }
        if (content) {
            content += ', ';
        }
        content += minutes + ' minutes and ' + t + ' seconds.';
        return content;
    }

}

@Pipe({
    name: 'timeFormat'
})
export class TimeFormat implements PipeTransform {
    transform(time: any, args?: any): any {
        let content = '';
        if (time !== '') {
            content = time.Hour + 'h :' + time.Minute + 'm :' + time.Second + 's';
            return content;
        }
        return content;

    }
}

@Pipe({
    name: 'dateFormat'
})
export class DateFormat implements PipeTransform {
    transform(date: any, args?: any): any {
        let content = '';
        if (date !== '') {
            content = date.Year + '-' + date.Month + '-' + date.Day;
            return content;
        }
        return content;

    }
}

@Pipe({
    name: 'dateAndTimeFormat'
})
export class DateAndTimeFormat implements PipeTransform {
    transform(dateAndTime: any, args?: any): any {
        let date = '-';
        let time = '-';
        if (dateAndTime !== '') {
            date = dateAndTime.Date.Year + '-' + dateAndTime.Date.Month + '-' + dateAndTime.Date.Day;
            time = dateAndTime.Time.Hour + 'h :' + dateAndTime.Time.Minute + 'm :' + dateAndTime.Time.Second + 's';
            return date + ' ' + time;
        }
        return date + ' ' + time;

    }
}

@Pipe({
    name: 'decimalPipe'
})
export class DecimalPipe implements PipeTransform {
    transform(decimalPipe: any, args?: any): any {
        if (!decimalPipe) { return decimalPipe; }
        if (typeof decimalPipe === 'number' && decimalPipe.toString().indexOf('.') !== -1) {
            return decimalPipe.toFixed(2);
        }
        return decimalPipe;

    }
}
