import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../app/_services/common.services';
import { CustomTranslateService } from '../../../app/_services/custom-translate.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'vex-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  items = this.navigationService;

  constructor(
    public navigationService: NavigationService,
    private customTranslateService: CustomTranslateService,
    private commonService: CommonService
  ) {

    this.customTranslateService.translationLoadedSubject.subscribe({
      next: (val) => {
        console.log(val, "value receved")
      }
    })
    this.customTranslateService.langUpdatedEventEmitter.subscribe((val) => {
      console.log("got the ding", val)
    })
  }

  ngOnInit() {
    console.log("from navigation", this.navigationService.items)
  }
}
