import { ScrollbarModule } from './../../@vex/components/scrollbar/scrollbar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material.module';
import { NgxPrintModule } from 'ngx-print';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AppFilterPipeModule } from '../_filters/app.filter-pipe.module';
import { TableComponent } from './table/table.component';
import { PageLayoutModule } from '../../@vex/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from '../../@vex/components/breadcrumbs/breadcrumbs.module';
import { IconModule } from '@visurel/iconify-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ErrorLogsComponent } from './error-logs/error-logs.component';
import { ModalComponent } from './modal.component';
import { SidebarModule } from '../../@vex/components/sidebar/sidebar.module';
import { NotifierDangerComponent } from './notifierDanger/notifierDanger.component';
import { NotifierInfoComponent } from './notifierInfo/notifierInfo.component';
import { NotifierSuccessComponent } from './notifierSuccess/notifierSuccess.component';
import { NotifierWarningComponent } from './notifierWarning/notifierWarning.component';
import { SDashComponent } from './s-dash/s-dash.component';
import { LogsJobLogsComponent } from './logs-job-logs/logs-job-logs.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { PaymentComponent } from './payment/payment.component';
import { QuantityToggleComponent } from './quantity-toggle/quantity-toggle.component';
import { DocumentProofsComponent } from './document-proofs/document-proofs.component';
import { PosReceiptComponent } from './pos-receipt/pos-receipt.component';
import { ProdCardComponent } from './prod-card/prod-card.component';
import { StoreProductsComponent } from './store-products/store-products.component';
import { StoreRateCardComponent } from './store-rate-card/store-rate-card.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { DirectivesModule } from '../-directives/-directives.module';
import { InvoicePrintComponent } from './invoice-print/invoice-print.component';
import { SpayComponent } from './spay/spay.component';
import { FpayComponent } from './fpay/fpay.component';
import { GrnOrderComponent } from './grn-order/grn-order.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ReturnOrderComponent } from './return-order/return-order.component';
import { ManageInvComponent } from './manage-inv/manage-inv.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    NgxPrintModule,
    RouterModule,
    AppFilterPipeModule,
    PageLayoutModule,
    BreadcrumbsModule,
    IconModule,
    ImageCropperModule,
    SidebarModule,
    MatSelectFilterModule,
    MatDatepickerModule,
    ScrollbarModule,
    MomentDateModule,
    DirectivesModule,
    TranslocoModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    NgxPrintModule,
    AppFilterPipeModule,
    ConfirmDialogComponent,
    TableComponent,
    ModalComponent,
    ErrorLogsComponent,
    NotifierDangerComponent,
    NotifierInfoComponent,
    NotifierSuccessComponent,
    NotifierWarningComponent,
    SDashComponent,
    LogsJobLogsComponent,
    PaymentComponent,
    QuantityToggleComponent,
    DocumentProofsComponent,
    PosReceiptComponent,
    ProdCardComponent,
    StoreProductsComponent,
    StoreRateCardComponent,
    TransactionHistoryComponent,
    SuppliersComponent,
    SuppliersComponent,
    InvoicePrintComponent,
    UnauthorizedComponent,
    GrnOrderComponent,
    ReturnOrderComponent, ManageInvComponent
  ],
  declarations: [
    ConfirmDialogComponent,
    TableComponent,
    ModalComponent,
    ErrorLogsComponent,
    NotifierDangerComponent,
    NotifierInfoComponent,
    NotifierSuccessComponent,
    NotifierWarningComponent,
    SDashComponent,
    LogsJobLogsComponent,
    UnauthorizedComponent,
    PaymentComponent,
    QuantityToggleComponent,
    DocumentProofsComponent,
    PosReceiptComponent,
    ProdCardComponent,
    StoreProductsComponent,
    StoreRateCardComponent,
    TransactionHistoryComponent,
    SuppliersComponent,
    InvoicePrintComponent,
    SpayComponent,
    FpayComponent,
    GrnOrderComponent,
    ReturnOrderComponent, ManageInvComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    LogsJobLogsComponent,
    TableComponent,
    QuantityToggleComponent,
    NotifierDangerComponent,
    NotifierInfoComponent,
    NotifierSuccessComponent,
    DocumentProofsComponent,
    NotifierWarningComponent,
    PosReceiptComponent,
    ProdCardComponent,
    StoreProductsComponent,
    StoreRateCardComponent,
    TransactionHistoryComponent,
    SuppliersComponent,
    InvoicePrintComponent,
    GrnOrderComponent,
    ReturnOrderComponent, ManageInvComponent
  ],
  providers: [],
})
export class SharedModule {
  constructor() {
  }
}
