import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import icMail from '@iconify/icons-ic/twotone-mail';
import icPhone from '@iconify/icons-ic/twotone-phone';
import { fadeInUp400ms } from '../../../@vex/animations/fade-in-up.animation';
import { BaseRequestService } from '../../_services/base.service';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { MyToastrService } from '../../_services/toastr.service';
import { LoaderService } from '../../_services/loader.service';
import { IconService } from '../../_services/icon.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { CommonService } from '../../_services/common.services';
import { NotificationService } from '../../_services/notification.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'vex-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrls: ['./invoice-print.component.scss'],
  animations: [fadeInUp400ms]
})
export class InvoicePrintComponent implements OnInit, AfterViewInit {
  @Input() order: any = {};
  invoiceDetails: any;
  entity: any;
  itemQty = 0;
  total = 0;
  tDate: any;
  mrpTotal = 0;
  isHindi = false;
  isEnglish = true;
  isDiscountApplied = false;
  isDeliveryChargesApplied = false;
  deliveryCharge = 0;
  storeDiscount = 0;
  couponDiscount = 0;
  globalDiscount = 0;
  invoiceNumber: string;
  gstInclusive: any = {};
  newCartFormat: any = [];
  newCartGroups: any = [];
  startWithZero = true;
  authCurrentEntity: any = {};
  constructor(public baseService: BaseRequestService,
    public modalService: ModalService,
    private router: Router,
    public toast: MyToastrService,
    private loaderService: LoaderService,
    public ic: IconService,
    public confirmDialog: ConfirmDialogService,
    public cs: CommonService,
    private notifyService: NotificationService,
    public commonService: CommonService,
    public authService: AuthenticationService) {
    // console.log(this.order);

    // this.calculateTotal();
    // this.tDate = new Date().toLocaleDateString() + ' ' + this.formatAMPM(new Date());
    this.cs.discountDetailChange.subscribe((res: any) => {
      this.isDiscountApplied = res.discountApplied;
      this.storeDiscount = res.storeDiscount;
      this.couponDiscount = res.couponDiscount;
    });
    this.cs.deliveryChargesChange.subscribe((res) => {
      this.isDeliveryChargesApplied = res.deliveryChargesApplied;
      this.deliveryCharge = res.deliveryCharges;
    });
    if (authService.currentUser.usertype.toLowerCase() === 'budc') {
      this.authCurrentEntity = Object.assign({}, authService.currentEntity);
    } else if (authService.currentUser.usertype.toLowerCase() === 'bud') {
      this.authCurrentEntity = Object.assign({}, authService.entity);
    }

  }

  calculateGST(): void {
    this.newCartFormat = [];
    this.newCartGroups = [];
    this.startWithZero = true;
    const totalSum = this.order.orderedProducts.map((t) => (t.unitCost * t.orderQuantity)).reduce((acc, value) => acc + value, 0);
    this.order.orderedProducts.forEach((prod) => {
      prod.basePrice = this.getBasePrice(prod, totalSum);
      prod.gstPrice = this.getGSTPrice(prod, totalSum);
      prod.cessPrice = this.getCESSPrice(prod, totalSum);
      this.gstInclusive[prod.tax.gst] = true;
    });
    this.newCartGroups = this.order.orderedProducts.map(x => x.tax.gst + ',' + x.tax.cess).filter(this.cs.onlyUnique).sort();
    this.startWithZero = (this.newCartGroups[0].split(',')[0] === '0') ? true : false;
    this.newCartGroups.forEach((gst) => {
      this.newCartFormat.push({
        gst: gst.split(',')[0],
        cess: gst.split(',')[1],
        taxP: (gst.split(',')[0] > 0) ? this.order.orderedProducts.filter(x => x.tax.gst === +gst.split(',')[0] && x.tax.cess === +gst.split(',')[1]).map(x => x.gstPrice).reduce((acc, value) => acc + value, 0) : 0,
        cessP: (gst.split(',')[0] > 0) ? this.order.orderedProducts.filter(x => x.tax.gst === +gst.split(',')[0] && x.tax.cess === +gst.split(',')[1]).map(x => x.cessPrice).reduce((acc, value) => acc + value, 0) : 0,
        baseP: (gst.split(',')[0] > 0) ? this.order.orderedProducts.filter(x => x.tax.gst === +gst.split(',')[0] && x.tax.cess === +gst.split(',')[1]).map(x => x.basePrice).reduce((acc, value) => acc + value, 0) : 0,
        products: this.order.orderedProducts.filter(x => x.tax.gst === +gst.split(',')[0] && x.tax.cess === +gst.split(',')[1])
      });
    });
  }
  sumOf(prop: any): any {
    return this.newCartFormat.map(x => x[prop]).reduce((acc, value) => acc + value, 0);
  }
  getCESSPrice(prod: any, TOTAL: number): number {
    const CESS = (prod.tax && prod.tax.cess) ? prod.tax.cess : 0;
    const BP = this.getBasePrice(prod, TOTAL);
    return BP * (CESS / 100);
  }
  getGSTPrice(prod: any, TOTAL: number): number {
    const GST = (prod.tax && prod.tax.gst) ? prod.tax.gst : 0;
    const BP = this.getBasePrice(prod, TOTAL);
    return BP * (GST / 100);
  }
  getBasePrice(prod: any, TOTAL: any): number {
    const SP = (prod.orderQuantity * prod.unitCost);
    const DIS = (this.globalDiscount)
      ? (this.globalDiscount + this.storeDiscount + this.couponDiscount)
      : (this.couponDiscount + this.storeDiscount);
    const GST = (prod.tax && prod.tax.gst) ? prod.tax.gst + prod.tax.cess : 0;
    const AD = DIS * (SP / TOTAL);
    const DP = SP - AD;
    return (DP / (1 + (GST / 100)));
  }

  returnOrderTime(date: any): any {
    if (date === null || !date || date === '') { return date; }
    if (date && date.indexOf('Z') === -1) { date += 'Z'; }
    return date ? new Date(date).toLocaleDateString() + ' ' + this.formatAMPM(new Date(date)) : date;
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  ngOnInit(): void {
    try {
      const lpg = JSON.parse(localStorage.getItem('lpg'));
      if (lpg.status) {
        this.isHindi = true;
        this.isEnglish = false;
      }
    } catch { }
    /* const sellList = this.order.orderedProducts;
     if (sellList && !sellList.length) {
       this.notifyService.notification('Some Error Occured', false, 'error');
     }*/

  }

  ngAfterViewInit() {
    this.order = [];
    this.globalDiscount = 0;
    this.couponDiscount = 0;
    this.storeDiscount = 0;
    this.cs.currentOrderInvoice.subscribe((invoice) => {
      invoice.ratecard = Object.assign({}, invoice);
      this.order = invoice;
      this.globalDiscount = this.order.globalDiscount.amt;
      this.couponDiscount = this.order.couponDiscount;
      this.storeDiscount = this.order.storeDiscount;
      this.calculateTotal(this.order);
    });
  }

  calculateTotal(invoice?: any) {
    console.log(invoice);
    this.mrpTotal = 0;
    this.total = 0;
    this.itemQty = 0;
    invoice.orderedProducts.forEach((obj: any) => {
      this.total += obj.unitCost * obj.orderQuantity;
      this.mrpTotal += obj.mrp * obj.orderQuantity;
      this.itemQty += obj.orderQuantity;
    });
    // console.log('got called', this.mrpTotal, this.itemQty);
    this.calculateGST();
    this.getEntity(invoice);
  }

  getEntity(data: any) {
    this.loaderService.display(true, 'Getting entity...');
    this.baseService
      .doRequest(`/api/entity/${data.parent_ref.id}`, 'get')
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          this.entity = result;
          this.modalService.open('viewReceipt');
          this.commonService.invoiceOrderChange(this.entity);
          this.invoiceDetails = this.entity;
        } else {
          this.toast.sToast('error', result);
        }
      });
  }

}
