import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseRequestService } from '../../_services/base.service';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { MyToastrService } from '../../_services/toastr.service';
import { LoaderService } from '../../_services/loader.service';
import { IconService } from '../../_services/icon.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { CommonService } from '../../_services/common.services';
import { NotificationService } from '../../_services/notification.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'vex-pos-receipt',
  templateUrl: './pos-receipt.component.html',
  styleUrls: ['./pos-receipt.component.scss'],
})
export class PosReceiptComponent implements OnInit, AfterViewInit, OnDestroy {
  total = 0;
  netAmount = 0;
  tDate: any;
  mrpTotal = 0;
  isHindi = false;
  isEnglish = true;
  isDiscountApplied = false;
  isDeliveryChargesApplied = false;
  isAdvAmount = false;
  deliveryCharge = 0;
  storeDiscount = 0;
  couponDiscount = 0;
  productDiscount = 0;
  globalDiscount = 0;
  invoiceNumber: string;
  gstInclusive: any = {};
  authCurrentEntity: any = {};
  newCartFormat: any = [];
  newCartGroups: any = [];
  startWithZero = true;
  itemQty = 0;
  vDisc = 0;
  finalDiscount: any;
  discounData: any;
  constructor(
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private router: Router,
    public toast: MyToastrService,
    private loaderService: LoaderService,
    public ic: IconService,
    public confirmDialog: ConfirmDialogService,
    public cs: CommonService,
    private notifyService: NotificationService,
    public authService: AuthenticationService
  ) {
    this.tDate = new Date().toLocaleDateString() + ' ' + this.formatAMPM(new Date());
    this.vDisc = (this.cs.voucherDiscount.value) ? this.cs.voucherDiscount.value : 0;
    this.cs.discountDetailChange.subscribe((res: any) => {
      this.isDiscountApplied = res.discountApplied;
      this.storeDiscount = +res.storeDiscount;
      this.couponDiscount = +res.couponDiscount;
    });
    this.cs.deliveryChargesChange.subscribe((res) => {
      this.isDeliveryChargesApplied = res.deliveryChargesApplied;
      this.deliveryCharge = +res.deliveryCharges;
    });
    if (this.cs.advAmount) {
      this.isAdvAmount = true;
    }
    if (authService.currentUser.usertype.toLowerCase() === 'budc') {
      this.authCurrentEntity = Object.assign({}, authService.currentEntity);
    } else if (authService.currentUser.usertype.toLowerCase() === 'bud') {
      this.authCurrentEntity = Object.assign({}, authService.entity);
    } else if (authService.currentUser.usertype === 'StoreManager') {
      this.authCurrentEntity = Object.assign({}, authService.entity);
    } else if (authService.currentUser.usertype === 'StoreUser') {
      this.authCurrentEntity = Object.assign({}, authService.entity);
    }
  }
  ngAfterViewInit(): void {
    if (this.cs.canAutoPrint) {
      document.getElementById('print-btn').click();
      this.cs.canAutoPrint = false;
    }
  }

  // AD = DIS * (SP/TOTAL)
  // DP = SP - AD
  // BP = (DP / (1 + ( GST/100)))
  // GST = BP * (GST/100)

  calculateGST(): void {
    const totalSum = this.cs.pSellList.map((t) => ((t.orderQuantity * (t.ratecard.sprice / t.ratecard.caseSize)) - t._discountValue)).reduce((acc, value) => acc + value, 0);

    this.cs.pSellList.forEach((prod) => {
      prod.basePrice = this.getBasePrice(prod, totalSum);
      prod.gstPrice = this.getGSTPrice(prod, totalSum);
      prod.cessPrice = this.getCESSPrice(prod, totalSum);
      this.gstInclusive[prod.taxinfo.gst] = true;
    });
    this.newCartGroups = this.cs.pSellList.map(x => x.taxinfo.gst + ',' + x.taxinfo.cess).filter(this.onlyUnique).sort();
    console.log("the cart group", this.newCartGroups);
    this.startWithZero = (this.newCartGroups[0].split(',')[0] === '0') ? true : false;
    console.log("the cart zero", this.startWithZero);
    this.newCartGroups.forEach((gst) => {
      this.newCartFormat.push({
        gst: gst.split(',')[0],
        cess: gst.split(',')[1],
        taxP: (gst.split(',')[0] > 0) ? this.cs.pSellList.filter(x => x.taxinfo.gst === +gst.split(',')[0] && x.taxinfo.cess === +gst.split(',')[1]).map(x => x.gstPrice).reduce((acc, value) => acc + value, 0) : 0,
        cessP: (gst.split(',')[0] > 0) ? this.cs.pSellList.filter(x => x.taxinfo.gst === +gst.split(',')[0] && x.taxinfo.cess === +gst.split(',')[1]).map(x => x.cessPrice).reduce((acc, value) => acc + value, 0) : 0,
        baseP: (gst.split(',')[0] > 0) ? this.cs.pSellList.filter(x => x.taxinfo.gst === +gst.split(',')[0] && x.taxinfo.cess === +gst.split(',')[1]).map(x => x.basePrice).reduce((acc, value) => acc + value, 0) : 0,
        products: this.cs.pSellList.filter(x => x.taxinfo.gst === +gst.split(',')[0] && x.taxinfo.cess === +gst.split(',')[1])
      });
    });
    console.log(this.newCartFormat)
  }

  sumOf(prop: any): any {
    return this.newCartFormat.map(x => x[prop]).reduce((acc, value) => acc + value, 0);
  }

  getCESSPrice(prod: any, TOTAL: number): number {
    const CESS = (prod.taxinfo && prod.taxinfo.cess) ? prod.taxinfo.cess : 0;
    const BP = this.getBasePrice(prod, TOTAL);
    return (BP * (CESS / 100)) < 0 ? 0 : (BP * (CESS / 100));
  }
  getGSTPrice(prod: any, TOTAL: number): number {
    const GST = (prod.taxinfo && prod.taxinfo.gst) ? prod.taxinfo.gst : 0;
    const BP = this.getBasePrice(prod, TOTAL);
    return (BP * (GST / 100)) < 0 ? 0 : (BP * (GST / 100));
  }
  getBasePrice(prod: any, TOTAL: any): number {
    const SP = ((prod.orderQuantity * (prod.ratecard.sprice / prod.ratecard.caseSize)) - prod._discountValue);
    const DIS = (this.globalDiscount) ? (this.globalDiscount + this.storeDiscount + this.couponDiscount) : (this.couponDiscount + this.storeDiscount);
    const GST = (prod.taxinfo && prod.taxinfo.gst) ? prod.taxinfo.gst + prod.taxinfo.cess : 0;
    const AD = DIS * (SP / TOTAL);
    const DP = SP - AD;
    return (DP / (1 + (GST / 100))) < 0 ? 0 : (DP / (1 + (GST / 100)));
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  ngOnInit(): void {
    try {
      const lpg = JSON.parse(localStorage.getItem('lpg'));
      if (lpg.status) {
        this.isHindi = true;
        this.isEnglish = false;
      }
      const sellList = localStorage.getItem('sellList');
      if (sellList && !this.cs.pSellList.length) {
        this.cs.pSellList = JSON.parse(sellList);
      }
    } catch { }
    this.calculateTotal();
    localStorage.removeItem('sellList');
  }

  onlyUnique(value, index, self): any {
    return self.indexOf(value) === index;
  }

  calculateTotal() {
    this.mrpTotal = 0;
    this.total = 0;
    this.itemQty = 0;
    this.netAmount = 0;
    this.productDiscount = 0;
    this.cs.pSellList.forEach((obj: any) => {
      this.total += (((obj.ratecard.sprice) / obj.ratecard.caseSize) * obj.orderQuantity);
      this.productDiscount += obj._discountValue * obj.orderQuantity;
      this.netAmount += (((obj.ratecard.sprice) / obj.ratecard.caseSize) * obj.orderQuantity) - obj._discountValue * obj.orderQuantity;
      this.mrpTotal += (obj.ratecard.mrp / obj.ratecard.caseSize) * obj.orderQuantity;
      this.discounData += ((((obj.ratecard.mrp / obj.ratecard.caseSize) * obj.orderQuantity) - (((obj.ratecard.sprice / obj.ratecard.caseSize) * obj.orderQuantity) - obj._discountValue)) * obj.orderQuantity);
      this.finalDiscount += ((obj.ratecard.mrp / obj.ratecard.caseSize) * obj.orderQuantity) - ((((obj.ratecard.mrp / obj.ratecard.caseSize) * obj.orderQuantity) - (((obj.ratecard.sprice / obj.ratecard.caseSize) * obj.orderQuantity) - obj._discountValue)) * obj.orderQuantity);
      this.itemQty += obj.orderQuantity;
    });
    this.globalDiscount = this.cs.globalDiscount.couponDiscount;
    this.calculateGST();
    console.log(11111, this.total);
    console.log(222222, this.itemQty);
    console.log(33333, this.productDiscount);

  }
  ngOnDestroy(): void {
    // this.cs.sellList = []; this.cs.pSellList = []; localStorage.removeItem("sellList");
  }
}
