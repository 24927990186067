<mat-card class="-mt-14" *transloco="let text">
  <form #addGRNForm="ngForm">
    <mat-card-content class="p-3">
      <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title fxLayout.lt-md="column" fxLayoutGap="10px"
        class="mt-2">
        <h2 class="subheadin-2 m-0" fxFlex="auto"> {{text('supOrd.grn')}} </h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button" (click)="bviewChanged('orders')">
          <mat-icon [icIcon]="ic.icClose"></mat-icon>
        </button>
      </div>
      <!-- <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="subheadin-2 m-0" fxFlex="auto">
          GRN
        </h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button" (click)="bviewChanged('orders')">
          <mat-icon [icIcon]="ic.icClose"></mat-icon>
        </button>
      </div> -->
      <mat-divider class="text-border"></mat-divider>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="10px" class="mt-2">
        <mat-form-field class="w-100">
          <mat-label>Invoice Amount </mat-label>
          <input matInput [(ngModel)]="invoiceAmount" matInput name="invoiceAmount" [disabled]="!orders.orderID.length"
            [required]="true" type="number" placeholder="Enter Invoice Amount">
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Invoice Discount </mat-label>
          <input matInput [(ngModel)]="invoiceDiscount" matInput name="invoiceDiscount" [required]="true" type="number"
            placeholder="Enter Invoice Discount">
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Calculated GRN Amount</mat-label>
          <!-- {{(prod.recievedQty * prod.unitCost) |number:'1.2-2' }} -->
          <input matInput [(ngModel)]="calculatedGrnAmount" disabled matInput name="calculatedGrnAmount"
            [required]="true" type="text" placeholder="Enter Calculated GRN Amount">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="10px" class="mt-2">
        <mat-form-field class="w-100">
          <mat-label>{{text('rOrders.ordId')}} </mat-label>
          <input matInput [(ngModel)]="orders.orderID" disabled matInput name="orders" [required]="true" type="text"
            placeholder="Enter Order ID">
        </mat-form-field>
        <mat-form-field class="w-100" *ngIf="!orders.invoices || !orders.invoices.length">
          <mat-label>{{text('supOrd.iNumber')}}</mat-label>
          <input matInput [(ngModel)]="invoiceNumber" appInvoiceNumberCheck (ngModelChange)="onUpdate($event)"
            (click)="toggleSearch(true)" matInput name="invoiceNumber" [required]="true" type="text"
            placeholder="Enter Invoice Number">
        </mat-form-field>
        <mat-form-field class="w-100" *ngIf="orders.invoices && orders.invoices.length">
          <mat-label>{{text('rOrders.choInv')}}</mat-label>
          <mat-select [(ngModel)]="invoiceNumber" name="invoiceNumber" (click)="toggleSearch(true)"
            placeholder="Invoice Number" (ngModelChange)="onUpdate($event)" [required]="true">
            <mat-option *ngFor="let invoice of orders.invoices;" [value]="invoice.invoiceNo">{{invoice.invoiceNo}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>{{text('supOrd.grnBy:')}}</mat-label>
          <input matInput [(ngModel)]="grnBy" matInput name="grnby" type="text" (click)="toggleSearch(true)"
            placeholder="Enter Name">
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>{{text('rOrders.eInvDate')}}</mat-label>
          <input matInput name="invoiceDate" [(ngModel)]="invoiceDate" type="date" (click)="toggleSearch(true)"
            [max]="today" [min]="minDay" [required]="true" />
          <!-- <input matInput [max]="today" [(ngModel)]="invoiceDate" matInput name="invoiceDate" [required]="true"
            type="date" (click)="toggleSearch(true)" placeholder="Invoice Date"> -->
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" class="mb-6" fxLayoutGap="10px">
        <div class="w-100" fxLayout="column">
          <p class="mb-1">{{text('supOrd.iCopy')}} <small>*</small></p>
          <input type="file" accept=".pdf" name="invBill" id="invoiceCopy"
            (change)="uploadFile($event, 'invoiceCopy')" />
          <mat-hint _ngcontent-opx-c260="" class="mat-hint text-us pt-2 ng-tns-c202-344">{{text('rOrders.fFor')}}
            *.PDF</mat-hint>
        </div>
        <div class="w-100" fxLayout="column">
          <p class="mb-1">{{text('supOrd.eWayBill')}} </p>
          <input type="file" accept=".pdf" name="eway" id="ewayBill" (change)="uploadFile($event, 'ewayBill')" />
          <mat-hint _ngcontent-opx-c260="" class="mat-hint text-us pt-2 ng-tns-c202-344">{{text('rOrders.fFor')}}
            *.PDF</mat-hint>
        </div>
        <div class="w-100" fxLayout="column">
          <p class="mb-1">{{text('rOrders.othDoc')}}</p>
          <input type="file" accept=".pdf" name="otherDoc" id="otherDocument"
            (change)="uploadFile($event, 'otherDocument')" />
          <mat-hint _ngcontent-opx-c260="" class="mat-hint text-us pt-2 ng-tns-c202-344">{{text('rOrders.fFor')}}
            *.PDF</mat-hint>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="invoiceDocLink && invoiceDocLink.length"
        fxLayout.lt-md="column" fxLayoutGap="20px" class="mb-2">
        <div class="w-100 flex cstm-box p-1" *ngFor="let doc of invoiceDocLink; let i = index">
          <h4 class="action-link-custom" (click)="downloadFile(doc.invoiceFileLink)">{{doc.invoiceFileName}}</h4>
          <span class="spacer"></span>
          <span>
            <button mat-icon-button class="text-secondary" (click)="deleteFile(i)">
              <mat-icon [icIcon]="ic.icDelete"></mat-icon>
            </button>
          </span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="currentDocument.length" fxLayout.lt-md="column"
        fxLayoutGap="20px" class="mb-2">
        <div class="w-100 flex cstm-box p-1" *ngFor="let doc of currentDocument; let i = index">
          <h4 class=" action-link-custom" (click)="downloadPDF(doc)">{{doc.fileName}}</h4>
          <span class="spacer"></span>
          <span>
            <button mat-icon-button class="text-secondary" (click)="deleteFile(i)">
              <mat-icon [icIcon]="ic.icDelete"></mat-icon>
            </button>
          </span>
        </div>
      </div>
      <!--<div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">

      </div>-->
      <div fxLayout="row" class="border-2 br-1 p-3 border-gray-100">
        <button matSuffix mat-raised-button aria-label="Load All Products" class="mr-3" color="primary"
          *ngIf="!isSKOrder" (click)="loadAllProducts()">
          {{text('rOrders.loadProd')}}
        </button>
        <span *ngIf="!isSearch">
          <input type="hidden">
          <button matSuffix mat-raised-button aria-label="Scan Barcode" color="primary" (click)="toggleSearch()">
            {{text('rOrders.sBarSeaPro')}}
          </button>
        </span>
        <span class="font-size-9 mr-2 w-50" *ngIf="isSearch">
          <label class="text-secondary" for="search">{{text('pos.search')}}</label>
          <span class="flex flex-row items-center">
            <input class="form-control text-primary mb-1 br-1 px-1 py-1 bor-1 w-100" type="text" id="search" #searchText
              [(ngModel)]="search" (keydown.enter)="$event.preventDefault()" autofocus
              (ngModelChange)="onChange($event)" name="search" placeholder="Search...">
            {{searchText.focus()}}
            <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
              <mat-icon class="pointer pl-1 mb-2 mat-icon text-lg" [icIcon]="ic.icClose"></mat-icon>
            </button>
            <mat-icon class="pointer pl-1  mat-icon text-lg" color="primary" matTooltip="Search Products"
              [icIcon]="ic.icSearch" (click)="onChange(search)"></mat-icon>
          </span>
        </span>
        <span class="spacer"></span>
        <span class="" *ngIf="isSearch">
          <button matSuffix mat-raised-button aria-label="Scan Barcode" class="mt-3" color="primary"
            (click)="toggleSearch()">
            {{text('rOrders.eProd')}}
          </button>
        </span>
      </div>
      <div class="container px-0 py-5 mx-auto">
        <div class="flex flex-wrap -m-2">
          <!-- ; trackBy: prodTrackByFn -->
          <div class="p-2 lg:w-1/1 md:w-1/1 w-full" *ngFor="let prod of currentGRNProducts; let i = index">
            <input type="hidden">
            <div class="h-full flex items-center border-gray-200 border p-2 rounded-lg"
              *ngIf="(prod.completedStatus && prod.completedStatus === 'completed')">
              <div class="flex-grow">
                <div class="flex flex-row align-items-center">
                  <h3 class="text-gray-900 title-font font-medium">{{prod.productName}}</h3>
                  <div class="spacer"></div>
                  <button (click)="deleteOrder(i)" class="-mt-2" color="primary" mat-icon-button
                    matTooltip="Remove item" type="button">
                    <mat-icon [icIcon]="ic.icClose"></mat-icon>
                  </button>
                </div>
                <div class="text-gray-500 flex flex-row align-items-center my-2">
                  <span class="px-2 text-sm mr-2 py-1 font-semibold text-green-500 bg-green-100 rounded-full">
                    {{text('supOrd.completed')}}</span>
                  <span
                    class="flex items-center h-6 px-3 text-xs font-semibold text-blue-500 bg-blue-100 rounded-full">#{{prod.id}}</span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">{{text('rOrders.totTax')}}
                    <span class="ml-2">{{prod.tax.gst| number:'1.2-2'}}%</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">{{text('supOrd.cess')}}
                    <span class="ml-2">{{prod.tax.cess| number:'1.2-2'}}%</span></span>
                </div>
                <div class="text-gray-500 flex flex-row align-items-center my-2">
                  <span
                    class="flex items-center h-6 px-3 text-xs font-semibold text-purple-500 bg-purple-100 rounded-full">{{text('supOrd.cSize')}}
                    <span class="ml-2"> {{prod?.caseSize |number:'1.2-2' }}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('supOrd.bPrice')}}
                    <span class="ml-2"> ₹{{prod?.unitCost / prod.unitsize |number:'1.2-2' }}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('supOrd.hsn')}}
                    <span class="ml-2"> {{prod?.hsn}}</span>
                  </span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('rOrders.invoiced')}}
                    <span class="ml-2"> {{prod.shippedQuantity}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('rOrders.ordered')}}
                    <span class="ml-2">
                      {{(prod.orderQuantity * prod.unitsize)| number:'1.2-2'}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full"
                    *ngIf="prod.receivedQuantity > 0">{{text('rOrders.received')}} <span class="ml-2">
                      {{prod.receivedQuantity}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full"
                    *ngIf="prod.receivedQuantity > 0">{{text('pos.mrp')}} <span class="ml-2">
                      ₹{{prod?.mrp|number:'1.2-2'}}</span></span>
                </div>
                <div class="text-gray-500 flex flex-row align-items-center my-2">
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">INVOICE
                    ITEM TOTAL
                    <span class="ml-2">
                      ₹{{(prod.invoicequantity * prod.unitCost/prod.caseSize)| number:'1.2-2'}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">INVOICE
                    PURCHASE PRICE
                    <span class="ml-2"> ₹{{prod?.unitCost / prod.unitsize |number:'1.2-2' }}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">
                    CALCULATED GR AMOUNT
                    <span class="ml-2"> ₹{{((prod.recievedQty * prod.unitCost/prod.caseSize)-prod.discAmount)|
                      number:'1.2-2'}}</span></span>
                </div>
              </div>
            </div>
            <div class="h-full flex items-center border-gray-200 border p-2 rounded-lg"
              *ngIf="(prod.completedStatus && prod.completedStatus === 'not_completed')"
              [ngClass]="{'bg-green-50': ((prod.orderQuantity * prod.unitsize) === ((prod.recievedQty) + prod.receivedQuantity)),
                          'bg-red-50': ((prod.orderQuantity * prod.unitsize) > (prod.recievedQty) + prod.receivedQuantity) }">
              <div class="flex-grow">
                <div class="flex flex-row align-items-center">
                  <h3 class="text-gray-900 title-font font-medium">{{prod.productName}}</h3>
                  <div class="spacer"></div>
                  <button (click)="deleteOrder(i)" class="-mt-2" color="primary" mat-icon-button
                    matTooltip="Remove item" type="button">
                    <mat-icon [icIcon]="ic.icClose"></mat-icon>
                  </button>
                </div>
                <div class="text-gray-500 flex flex-row align-items-center my-1">
                  <span
                    class="flex items-center h-6 px-3 text-xs font-semibold text-blue-500 bg-blue-100 rounded-full">#{{prod.id}}</span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">{{text('rOrders.gst')}}
                    <span class="ml-2">{{prod.tax.gst| number:'1.2-2'}}%</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">{{text('supOrd.cess')}}
                    <span class="ml-2">{{prod.tax.cess| number:'1.2-2'}}%</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-purple-500 bg-purple-100 rounded-full">{{text('supOrd.cSize')}}<span
                      class="ml-2">{{prod.caseSize| number:'1.2-2'}}</span></span>
                </div>
                <div class="text-gray-500 flex flex-row align-items-center my-1">
                  <span
                    class="flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('supOrd.bPrice')}}
                    <span class="ml-2"> ₹{{prod.unitCost / prod.unitsize | number:'1.2-2'}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('rOrders.actPurPri')}}
                    <span class="ml-2">₹{{(((prod.recievedQty *
                      prod.unitCost/prod.caseSize)-prod.discAmount)/prod.recievedQty)| number:'1.2-2'}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('supOrd.hsn')}}
                    <span class="ml-2"> {{prod.hsn}}</span></span>
                  <!-- <span *ngIf="!isSKOrder"
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">INVOICED
                    <span class="ml-2"> {{prod.shippedQuantity}}</span></span> -->
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('rOrders.ordered')}}
                    <span class="ml-2">
                      {{(prod.orderQuantity * prod.unitsize)| number:'1.2-2'}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full"
                    *ngIf="prod.receivedQuantity > 0">{{text('rOrders.received')}} <span class="ml-2">
                      {{prod.receivedQuantity}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-red-500 bg-red-100 rounded-full"
                    *ngIf="prod.receivedQuantity && ((prod.orderQuantity * prod.unitsize) - prod.receivedQuantity) > 0">{{text('rOrders.balance')}}
                    <span class="ml-2"> {{(prod.orderQuantity * prod.unitsize) - prod.receivedQuantity}}</span></span>
                  <span
                    *ngIf="(!isSKOrder) && (prod.invoicequantity) && (prod.recievedQty) && ((prod.invoicequantity - (prod.recievedQty + prod.receivedQuantity)) > 0) && (((prod.orderQuantity * prod.unitsize) - prod.receivedQuantity) !== prod.recievedQty)"
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">{{text('rOrders.shoQty')}}
                    <span class="ml-2">{{prod.invoicequantity - (prod.recievedQty +
                      prod.receivedQuantity)}}</span></span>
                </div>
                <div class="text-gray-500 flex flex-row align-items-center my-2">
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">INVOICE
                    ITEM TOTAL
                    <span class="ml-2">
                      ₹ {{(prod.invoicequantity * prod.unitCost/prod.caseSize)| number:'1.2-2'}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">INVOICE
                    PURCHASE PRICE
                    <span class="ml-2"> ₹{{prod?.unitCost / prod.unitsize |number:'1.2-2' }}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">
                    CALCULATED GR AMOUNT
                    <span class="ml-2"> ₹{{((prod.recievedQty * prod.unitCost/prod.caseSize)-prod.discAmount)|
                      number:'1.2-2'}}</span></span>
                </div>
                <div *ngIf="prod.unitsize > 1" class="text-xs px-2 text-red">{{text('rOrders.pacSizqua')}}</div>
                <!-- fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="5px" -->
                <div class="mt-3 flex">
                  <mat-form-field class="mr-2 w120 text-us">
                    <mat-label>{{text('supOrd.barcode')}}</mat-label>
                    <input matInput [name]="'barcode'+idGenerator(prod)" id="barcode{{i}}"
                      (keydown.enter)="$event.preventDefault()" [(ngModel)]="prod.barCode" (paste)="paste($event, i)"
                      (click)="toggleSearch(true)" placeholder="Barcode" [required]="true" type="text">
                  </mat-form-field>
                  <mat-form-field class="mr-2 w100 text-us">
                    <mat-label>{{text('table.mrp')}}</mat-label>
                    <input matInput [name]="'mrp'+idGenerator(prod)" required placeholder="mrp"
                      (ngModelChange)="checkMinPrice($event, prod)" (click)="toggleSearch(true)" id="mrp"
                      [(ngModel)]="prod.mrp" [required]="true" min="1" type="number" zeroMRPCheck>
                    <mat-hint>{{text('rOrders.noZeMrp')}} ₹{{prod.pprice|number:'1.2-2'}}</mat-hint>
                  </mat-form-field>
                  <mat-form-field class="mr-2 w100 text-us">
                    <mat-label>{{text('rOrders.invQua')}}</mat-label>
                    <input matInput [name]="'invQty'+idGenerator(prod)" required placeholder="Invoiced Quantity"
                      (click)="toggleSearch(true)" id="invQty" [(ngModel)]="prod.invoicequantity" [required]="true"
                      min="1" type="number" numeric decimals="2" minval="1" [readonly]="isSKOrder">
                    <mat-hint *ngIf="isSKOrder && (prod.unitsize > 1)">
                      {{(prod.invoicequantity/prod.unitsize)}} x {{prod.unitsize}} = {{prod.invoicequantity}}
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="mr-2 w80 text-us">
                    <mat-label>{{text('supOrd.rQty')}}</mat-label>
                    <input matInput [name]="'recievedQty'+idGenerator(prod)" (click)="toggleSearch(true)"
                      (change)="calculateTotal($event.target.value, i)" [max]="prod.invoicequantity"
                      [(ngModel)]="prod.recievedQty" min="0" minval="0" numeric decimals="0" [required]="true"
                      type="number">
                    <mat-hint>{{text('rOrders.noDeci')}}</mat-hint>
                    <!-- <mat-hint>Ordered {{(prod.orderQuantity * prod.unitsize)| number:'1.2-2'}}</mat-hint> -->
                  </mat-form-field>
                  <mat-form-field class="mr-2 w80 text-us">
                    <mat-label>{{text('rOrders.frQty')}}</mat-label>
                    <input matInput [name]="'freeQty'+idGenerator(prod)" (click)="toggleSearch(true)" [disabled]="true"
                      [(ngModel)]="prod.freeQty" min="0" numeric decimals="0" minval="0" type="number"
                      [max]="prod.recievedQty">
                    <mat-hint *ngIf="prod.freeQty < 0" class="text-red">{{text('rOrders.canBeNega')}}</mat-hint>
                    <mat-hint>{{text('rOrders.noDeci')}}</mat-hint>
                  </mat-form-field>
                  <mat-form-field class="mr-2 w80 text-us">
                    <mat-label>{{text('supOrd.dAmount')}}</mat-label>
                    <input matInput [name]="'discAmount'+idGenerator(prod)" [disabled]="false"
                      (change)="calculateTotal($event.target.value, i)" (click)="toggleSearch(true)"
                      placeholder="Discount Amount" [(ngModel)]="prod.discAmount" min="0" [required]="true"
                      type="number">
                  </mat-form-field>
                  <mat-form-field class="mr-2 w80 text-us">
                    <mat-label>{{text('rOrders.gstType')}}</mat-label>
                    <mat-select [(ngModel)]="prod.taxType" (ngModelChange)="taxTypeChanged($event)"
                      [name]="'taxType'+idGenerator(prod)" id="taxType" [required]="true" (click)="toggleSearch(true)">
                      <mat-option value="SGST">{{text('rOrders.sgst')}}</mat-option>
                      <mat-option value="IGST">{{text('rOrders.igst')}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="mr-2 w100 text-us">
                    <mat-label>{{text('supOrd.bNumber')}}</mat-label>
                    <input matInput [name]="'batchNumber'+idGenerator(prod)" (click)="toggleSearch(true)"
                      [required]="false" placeholder="Batch Number" [(ngModel)]="prod.batchNo" type="text">
                  </mat-form-field>
                  <mat-form-field class="mr-2 w100 text-us">
                    <mat-label>{{text('supOrd.ExpiryDate')}}</mat-label>
                    <input matInput [name]="'expiryDate'+idGenerator(prod)" (click)="toggleSearch(true)" [min]="today"
                      [(ngModel)]="prod.expiryDate" matInput [required]="false" type="date" placeholder="Expiry Date">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" class="ml-5">
      <div class="mr-2">
        <!--<button (click)="getSavedDraft()" [disabled]="!invoiceNumber" class="mat-primary" mat-raised-button type="submit">
          GET SAVED DRAFT
        </button>-->
        <button class="mr-2" (click)="saveDraft()" [disabled]="!invoiceNumber || isLoading " class="mat-primary"
          mat-raised-button type="submit"> {{text('rOrders.savDra')}} </button>
      </div>
      <div class="spacer"></div>
      <div>
        <!-- isAllComplete -->
        <button class="mr-2" (click)="submitGRN()"
          [disabled]="submitted === 'Yes' || !addGRNForm.form.valid || isLoading || !currentGRNProducts.length || isAllComplete || invoiceNumberNA"
          class="mat-primary" mat-raised-button type="submit">
          {{text('rOrders.subgrnSto')}}
        </button>
        <!-- <button class="mr-2" (click)="confirmCloseInvoice()" [disabled]="!addGRNForm.form.valid || isLoading"
          mat-raised-button class="mat-primary" type="submit">CLOSE INVOICE </button> -->
        <button mat-button mat-dialog-close id="btn" type="button" (click)="bviewChanged('orders'); orders = {}">
          {{text('sUser.close')}}
        </button>
      </div>
    </mat-card-actions>
  </form>
</mat-card>
<s-modal id="filteredItems" *transloco="let text">
  <mat-card class="cardmodal">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">
        {{text('rOrders.addToGrn')}} - {{filteredProducts?.length}} product(s) found
      </h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('filteredItems'); clearSearch();">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-card-content class="p-3 cardmodalContent" *ngIf="filteredProducts">
      <div class="">
        <div class="sm:flex flex-row">
          <div class="w-100">
            <table class="table w-full w-400">
              <thead>
                <tr>
                  <th class="body-2 text-secondary text-left">{{text('pos.productC')}}</th>
                  <th class="body-2 text-secondary">{{text('pos.price')}}</th>
                  <th class="body-2 text-secondary">{{text('pos.action')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let product of filteredProducts; trackBy: trackById">
                  <td class="py-1 border-b">
                    <span class="body-2">{{ product.productName }}</span>
                  </td>
                  <td class="py-1 px-2 border-b  text-center">
                    <span class="body-2">₹{{product.mrp| number:'1.2-2'}}</span>
                  </td>
                  <td class="py-1 border-b font-medium  text-center">
                    <button (click)="addToGRN(product);" class="mat-primary" mat-stroked-button color="primary">
                      {{text('rOrders.add')}}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button mat-dialog-close type="button" (click)="modalService.close('filteredItems'); clearSearch()">
        {{text('sUser.close')}}
      </button>
    </mat-card-actions>
  </mat-card>
</s-modal>

<s-modal id="congirmGRN" *transloco="let text">
  <mat-card class="cardmodal">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">
        {{text('rOrders.conGrn')}} - {{orders.orderID}}
      </h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('congirmGRN')">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-card-content class="p-3 cardmodalContent" *ngIf="filteredProducts">
      <div class="">
        <div class="sm:flex flex-row">
          <div class="w-100">
            <table class="table styled-table w-full w-400">
              <thead>
                <tr>
                  <th class="body-2 text-secondary text-left">{{text('rOrders.proDes')}}</th>
                  <th class="body-2 text-secondary">{{text('supOrd.recQun')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let product of currentGRNProducts; trackBy: trackById">
                  <td class="py-1 border-b">
                    <span class="body-2">{{ product.productName }}</span>
                  </td>
                  <td class="py-1 px-2 border-b  text-center">
                    <span class="body-2">{{product.recievedQty| number:'1.2-2'}}</span>
                  </td>
                  <!-- {{(product.orderQuantity * product.unitsize) - (product.receivedQuantity +
                      product.recievedQty)
                      }} -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button class="mr-2" (click)="createGRN();modalService.close('congirmGRN')"
        [disabled]="isLoading || !currentGRNProducts.length" class="mat-primary" mat-raised-button type="submit">
        {{text('rOrders.confirm')}}
      </button>
      <button mat-button mat-dialog-close type="button" (click)="modalService.close('congirmGRN')">
        {{text('sUser.close')}}
      </button>
    </mat-card-actions>
  </mat-card>
</s-modal>