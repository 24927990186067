<div class="m-5 mrt-30">
  <carousel *ngIf="images && images.length" [images]="images" [autoplay]="true" [loop]="true" [height]="150"
    [cellWidth]="300" [borderRadius]="20" [dots]="true">
  </carousel>

  <br />
  <br />

  <div class="flex" *transloco="let text">
    <div class="w-100">
      <div class="mb-8 rounded-xlg2">
        <div class="grid gap-5 mt-5 mb-5 md:grid-cols-4 xl:grid-cols-4">
          <div class=" flex items-center p-4 bg-white rounded-xlg1 border dark:bg-gray-800" routerLink="/inventory/buy">
            <div class="card flex-fill w-100 p-0 block4_rep">
              <div class="card-body">
                <h4 class="text-primary text-xl">{{text('menu.MInv')}}</h4>
                <img src="assets/img/4blocik_img1.png" alt="" class="w-100" />
              </div>
            </div>
          </div>
          <div class="
              flex
              items-center
              p-4
              bg-white
              rounded-xlg1
              border
              dark:bg-gray-800
            " routerLink="/inventory/sell">
            <div class="card flex-fill w-100 p-0 block4_rep">
              <div class="card-body">
                <h4 class="text-primary text-xl">{{text('menu.pos')}}</h4>
                <img src="assets/img/4blocik_img2.png" alt="" class="w-100" />
              </div>
            </div>
          </div>

          <div class="
              flex
              items-center
              p-4
              bg-white
              rounded-xlg1
              border
              dark:bg-gray-800
            ">
            <div class="card flex-fill w-100 p-0 block4_rep">
              <div class="card-body py-3">
                <h4 class="text-primary text-xl">{{text('menu.Pdash')}}</h4>
                <img src="assets/img/4blocik_img3.png" alt="" class="w-100" />
              </div>
            </div>
          </div>
          <div class="
              flex
              items-center
              p-4
              bg-white
              rounded-xlg1
              border
              dark:bg-gray-800
            ">
            <div class="card flex-fill w-100 p-0 block4_rep">
              <div class="card-body py-3">
                <h4 class="text-primary text-xl">{{text('menu.returns')}}</h4>
                <img src="assets/img/4blocik_img4.png" alt="" class="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>