import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vex-quantity-toggle',
  templateUrl: './quantity-toggle.component.html',
  styleUrls: ['./quantity-toggle.component.scss'],
})
export class QuantityToggleComponent implements OnInit {
  @Input() input = 1;
  @Output() countChange = new EventEmitter<number>();
  @Input() increment = 1;
  @Input() min = 0;
  @Input() max = 999;
  @Input() supplier = 'false';
  count = 0;
  constructor() {
    if (this.min && !this.count) {
      this.count = this.min;
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
    if (this.input) {
      this.count = this.input;
    }
    });
  }
  redQty() {
    if (this.count > 1) {
      // if (this.supplier) {
      //   this.count = this.count / this.increment
      // } else {
      //   this.count = this.count - this.increment;
      // }
      this.count = this.count - this.increment;
      if (this.count < this.min) {
        this.count = this.min;
      }
      this.countChange.emit(this.count);
    }
  }
  addQty() {
    if (this.count < this.max) {
      // if (this.supplier) {
      //   this.count = this.count * this.increment;
      // } else {
      //   this.count = this.count + this.increment;
      // }
      this.count = this.count + this.increment;
    }
    if (this.count > this.max) {
      this.count = this.max;
    }
    this.countChange.emit(this.count);
  }
}
