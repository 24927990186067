import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { CommonService } from '../../_services/common.services';
import { IconService } from '../../_services/icon.service';
import { MyToastrService } from '../../_services/toastr.service';

@Component({
  selector: 'vex-prod-card',
  templateUrl: './prod-card.component.html',
  styleUrls: ['./prod-card.component.scss'],
})
export class ProdCardComponent implements OnInit {
  @Input() product: any;
  @Input() ratecard: any;
  @Input() quality: boolean;
  @Input() budBuy: boolean;
  @Input() aq: any;
  @Input() aSq: any;
  @Input() isCDIT: any = false;
  @Input() isAdvOrder: any = false;
  @Input() supplier: any;
  @Output() openProduct = new EventEmitter();
  @Output() addtocart = new EventEmitter();
  count = 1;
  quantity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  quantityM = ['UM', 'KG', 'Box', 'PKT'];
  selectedQuantityM = 'KG';
  availability = 'yellow';
  currentMargin = 0;
  makeEmpty = false;
  minPrice = 0;
  constructor(
    public cs: CommonService,
    public ic: IconService,
    private toast: MyToastrService,
    public authService: AuthenticationService
  ) {
  }

  preparePSD(slabs: any, rootMRP: any, maxQty: any): any {
    const s = (slabs || []).filter((e: any) => {
      return (e.min < maxQty);
    }).map((e: any) => {
      e._offerPrice = (e.price / e.mrp) * rootMRP;
      e._discount = ((rootMRP - e._offerPrice) / e._offerPrice) * 100;
      return e;
    });
    const sorted = s.sort((a: any, b: any) => {
      const c = a.min;
      const d = b.min;
      if (c < d) {
        return -1;
      } else if (c > d) {
        return 1;
      } else {
        return 0;
      }
    });
    this.minPrice = sorted[sorted.length - 1]._offerPrice;
    return sorted;
  }

  getAQ(prod: any): number {
    if (this.authService.currentUser.usertype === 'BUD') {
      return prod.availableQuantity;
    }
    //  + prod.availableQuantity_Store
    return prod.availableQuantity;
  }

  ngOnInit(): void {
    this.aq = (this.authService.currentUser && this.authService.isBUDC()) ? this.aq : this.aSq;
    // check for array send first if array, is string and has , split send first
    // this.product.productimage = (this.product && this.product.productimage && this.product.productimage.length > 1)
    //   ? this.product.productimage[0] : this.product.productimage;

    // if (this.product.productimage.split(',').length > 1) {
    //   this.product.productimage = this.product.productimage.split(',')[0];
    // }
    if (this.product.isSlabBasedPricing) {
      this.product.priceSlabs = this.preparePSD(this.product.priceSlabs,
        this.product.ratecard.mrp, this.product.ratecard.maxOrderLimit || 0);
    }
    this.product._img = this.cs.getproductimage(this.product.productimage);
    if (!this.product.orderQuantity) {
      this.product.orderQuantity = (this.supplier) ? this.ratecard.minimumQuantity : 1;
      this.getCurrentMargin(this.product.orderQuantity);
    } else {
      if (this.supplier) {
        this.getCurrentMargin(this.product.orderQuantity);
      } else {
        this.getCurrentMargin(this.ratecard.minimumQuantity);
      }
    }
    if (this.product.isSlabBasedPricing) {
      const obj = this.product.priceSlabs.filter((val) => {
        if (this.product.orderQuantity <= val.max && this.product.orderQuantity >= val.min) {
          return true;
        }
      });
      if (obj.length && obj.length === 1) {
        this.ratecard.bpclprice = obj[0]._offerPrice * this.ratecard.caseSize;
      } else {
        const ps = this.product.priceSlabs.sort((a: any, b: any) => {
          const c = a._offerPrice;
          const d = b._offerPrice;
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
        this.ratecard.bpclprice = ps[0]._offerPrice * this.ratecard.caseSize;
      }
    }
    if (!this.product.ratecard) {
      this.product.ratecard = this.ratecard;
    }
    if (this.authService.currentUser.usertype.toLowerCase() === 'bud' && this.product) {
      /*if (
        !this.product.availableQuantity &&
        !this.product.availableQuantity_Store
      ) {
        this.availability = 'yellow';
      } else if (
        !this.product.availableQuantity &&
        this.product.availableQuantity_Store
      ) {
        this.availability = 'orange';
      } else*/
      if (this.product.availableQuantity) {
        this.availability = 'green';
      } else {
        this.availability = 'yellow';
      }
    } else if (this.authService.currentUser.usertype.toLowerCase() === 'budc') {
      if (this.product && !this.product.availableQuantity) {
        this.availability = 'yellow';
      } else if (this.product.availableQuantity) {
        this.availability = 'green';
      } else {
        this.availability = 'yellow';
      }
    }
  }

  emitAddToCart(event: MouseEvent, product: any, ratecard: any): void {
    event.stopPropagation();
    // product.availableQuantity = this.aq;
    // this.product.orderQuantity = this.count;
    this.addtocart.emit({ product, ratecard });
  }

  updateOrderQ($event: any): void {
    if (!this.aq) {
      this.aq = (this.budBuy || !this.supplier) ? this.product.availableQuantity_Store : this.product.availableQuantity;
    }
    this.count = $event;
    if (this.aq > this.count || this.supplier || this.budBuy) {
      this.product.orderQuantity = $event;
    } else {
      this.toast.sToast('error', 'Quantity not available');
    }
    this.getCurrentMargin($event);
  }

  emptyCard(val) {
    this.makeEmpty = val;
    if (this.supplier) {
      this.updateOrderQ(this.ratecard.minimumQuantity);
    }
  }

  getCurrentMargin(oq) {
    if (this.product.isSlabBasedPricing) {
      const obj = this.product.priceSlabs.filter((val) => {
        if (oq <= val.max && oq >= val.min) {
          return true;
        }
      });
      if (obj.length && obj.length === 1) {
        this.currentMargin = obj[0]._discount;
        this.ratecard.bpclprice = obj[0]._offerPrice * this.ratecard.caseSize;
      } else {
        const ps = this.product.priceSlabs.sort((a: any, b: any) => {
          const c = a._offerPrice;
          const d = b._offerPrice;
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    }
  }
}
