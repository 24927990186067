import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StaticFormGeneratorService {
  formFields: any = [
    {
      key: 'region',
      type: 'text',
      label: 'Name',
      required: true,
      hint: '',
      placeholder: 'Enter Name',
    },
    {
      key: 'description',
      type: 'text',
      label: 'Description',
      required: true,
      hint: '',
      placeholder: 'Enter description',
    },
    {
      key: 'country',
      type: 'text',
      label: 'Country',
      required: true,
      hint: '',
      placeholder: 'Enter country',
    },
    {
      key: 'region',
      type: 'text',
      label: 'Region',
      required: true,
      hint: '',
      placeholder: 'Enter region',
    },
    {
      key: 'state',
      type: 'text',
      label: 'State',
      required: true,
      hint: '',
      placeholder: 'Enter state',
    },
    {
      key: 'pincode',
      type: 'text',
      label: 'Pincode',
      required: true,
      hint: '',
      placeholder: 'Enter pincode',
    },
    {
      key: 'mobile',
      type: 'text',
      label: 'Mobile',
      required: true,
      hint: '',
      placeholder: 'Enter mobile',
    },
    {
      key: 'address',
      type: 'text',
      label: 'Address',
      required: true,
      hint: '',
      placeholder: 'Enter address',
    },
    {
      key: 'taxidentifier',
      type: 'text',
      label: 'Tax Identifier',
      required: true,
      hint: '',
      placeholder: 'Enter taxidentifier',
    },
    {
      key: 'uniqueidentifier',
      type: 'text',
      label: 'Unique Identifier',
      required: true,
      hint: '',
      placeholder: 'Enter uniqueidentifier',
    },
  ];
  formOptions: any = {
    title: 'Add Category',
    id: 'addCategory',
    modelKey: 'currentCategory',
    formFields: '',
  };
  formOutput: any = '';
  constructor() {}

  generateFormFields(): void {
    this.formOptions.formFields = this.formFields
      .map((obj: any) => {
        const hint = obj.hint ? `<mat-hint>${obj.hint}</mat-hint>` : '';
        return `<div fxLayout="row" fxLayoutAlign="start center">
                  <mat-form-field class="flex-auto">
                    <mat-label>${obj.label}</mat-label>
                    <input matInput name="${obj.key}" [(ngModel)]="${this.formOptions.modelKey}.${obj.key}" type="${obj.type}"
                        [required]="${obj.required}" placeholder="${obj.placeholder}" />
                        ${hint}
                  </mat-form-field>
                </div>`;
      }).join('');
    this.formOutput = this.generateStaticForm();
    console.log(this.formOutput);
  }

  generateStaticForm(): any {
    return `<form #${this.formOptions.id}="ngForm">
        <mat-card-content class="p-3" *ngIf="${this.formOptions.modelKey}">
            ${this.formOptions.formFields}
        </mat-card-content>
        <mat-card-actions align="end" *ngIf="${this.formOptions.modelKey}">
            <button [disabled]="!${this.formOptions.id}.form.valid || isLoading" *ngIf="!${this.formOptions.modelKey}._id"
                (click)="saveCategory()" mat-raised-button class="mat-primary" type="submit">
                ADD NEW
            </button>
            <button *ngIf="${this.formOptions.modelKey}._id" (click)="updateCategory(${this.formOptions.modelKey})"
                [disabled]="!${this.formOptions.id}.form.valid || isLoading" mat-raised-button class="mat-primary"
                type="submit">
                UPDATE
            </button>
            <button mat-button mat-dialog-close type="button" (click)="modalService.close('${this.formOptions.id}')">
                CLOSE
            </button>
        </mat-card-actions>
    </form>`;
  }
}
