<s-modal id="unauthorized">
  <form #uauthorized="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0">
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <div class="mat-title">Unauthorized!</div>
        <div class="flex flex-row items-center justify-center text-center">
          <img src="/assets/images/unauthorized.png" alt="Release Notes" width="200px">
        </div>
        <mat-card-content class="">
          <h1>You are not authorized to access this page. Please contact administrator.</h1>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-warn" (click)="logout()"
                  id="cancel11">LOGOUT
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
