<div style="display: flex; justify-content: end;">
  <button class="ml-2" color="primary" mat-icon-button type="button" matTooltip="Download Inventory"
    (click)="downloadInventory()">
    <mat-icon [icIcon]="ic.icDownload"></mat-icon>
  </button>
</div>
<app-s-table aria-label="Store products" role="table" [sTableOptions]="storeProductsTableOptions"
  (hyperlinkCallback)="storeProductshyperlinkCall($event)" (sortCallback)="storeProductssortCall($event)"
  (filterCallback)="storeProductsfilterCall($event)" (actionCallback)="storeProductsactionCall($event)"
  (pageCallback)="storeProductspageCall($event)" (refreshCallback)="getStoreProducts()"
  (addCallback)="storeProductsaddTableData()" (timerCallback)="storeProductstimerCallData($event)"></app-s-table>