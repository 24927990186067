import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseRequestService } from './base.service';
import { MyToastrService } from './toastr.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CommonService {
    advAmount: any;
    cuponrupMaxvalue: any;
    constructor(
        public baseRequestService: BaseRequestService,
        private toast: MyToastrService, readonly httpClient: HttpClient,
    ) {
    }
    sglDigit = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    dblDigit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    tensPlace = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    dURL = `/kibana/app/dashboards?security_tenant=${window.location.host.split('.')[0]}`;
    cartList: any = [];
    public sellList: any = [];
    public pSellList: any = [];
    canAutoPrint = false;
    previousLink = '';
    cHash: any;
    language = 'en';
    changeLang = new EventEmitter<string>();
    adMobile: any;
    modifiedDiscoverItems: any;
    currentSupplierCode = '';
    currSuppliedType = '';
    Objectkeys = Object.keys;
    complianceMaster: any = {};
    complianceList: any = [];
    securityMatrix: any = {};
    isTranslateFileAvailable = false;
    currentActiveLanguage = 'en';
    angularEditorChange: Subject<object> = new Subject<object>();
    bwListChange: Subject<object> = new Subject<object>();
    consumerLocationChangeEvent: Subject<object> = new Subject<object>();
    isBottomHitEvent: Subject<object> = new Subject<object>();
    captureScreenChange: Subject<object> = new Subject<object>();
    initCaptureChange: Subject<object> = new Subject<object>();
    openSideNavChange: Subject<object> = new Subject<object>();
    selectedSiteChange: Subject<any> = new Subject<any>();
    rmRefreshEvent: Subject<any> = new Subject<any>();
    selectedNode = {};
    networkDeviceReloadChange: Subject<any> = new Subject<any>();
    addCompanyChange: Subject<any> = new Subject<any>();
    addSiteChange: Subject<any> = new Subject<any>();
    customerLocationModalChange: Subject<any> = new Subject<any>();
    inputHasError = true;
    checkNode = {};
    timeZoneList: any;
    currentTimezone: any;
    unauth: any;
    currentCompany: any;
    ccId: any;
    modalHolder: string[] = [];
    currentAssessment: any;
    currentCompanyView: string;
    currentSelectedLocation: any = {};
    customerDiscountDetails: any = {
        discountApplied: false,
        storeDiscountType: '1',
        storeDiscount: 0,
        promoCode: '',
        couponDiscount: 0
    };
    currentConsumerName = '';
    currentInvoiceNumber = '';
    consumerDeliveryCharges: any = {
        deliveryChargesApplied: false,
        deliveryCharges: 0
    };
    globalDiscount: any = {};
    voucherDiscount: any = {};
    currentSuppliedToState = '';
    newNavigationMenu: Subject<any> = new Subject();
    newNavigationMenuObservable = this.newNavigationMenu.asObservable();
    discountDetailChange: BehaviorSubject<any> = new BehaviorSubject(this.customerDiscountDetails);
    deliveryChargesChange: BehaviorSubject<any> = new BehaviorSubject<any>(this.consumerDeliveryCharges);
    currentGSTTypeChange: BehaviorSubject<any> = new BehaviorSubject<any>({ value: 'regular' });
    currentOrderInvoice: Subject<any> = new Subject();
    currentBuyerGSTNumber = '';
    public blobURL = 'https://nfreports.blob.core.windows.net/';
    UOMList = [
        { name: 'Grams', value: 'g', id: 1 },
        { name: 'Kilograms', value: 'kg', id: 2 },
        { name: 'Milliliter', value: 'ml', id: 3 },
        { name: 'Liter', value: 'l', id: 4 },
        { name: 'Centimeter', value: 'cm', id: 5 },
        { name: 'Meter', value: 'm', id: 6 },
        { name: 'Unit', value: 'unit', id: 7 },
        { name: 'Milligrams', value: 'mg', id: 8 },
    ];
    public orderType = {
        1: 'Aggregator',
        2: 'StockTransfer',
        3: 'Consumer',
    };
    public orderStatus = {
        1: 'Cart',
        2: 'Pending',
        3: 'Rejected',
        4: 'Partial',
        5: 'Completed',
        6: 'Shipped',
        7: 'Drafted',
        8: 'Return',
        9: 'Partially Closed',
        10: 'Cancelled',
        11: 'Exchange',
        12: 'Payment Success',
    };
    public uom = {
        1: 'g',
        2: 'kg',
        3: 'ml',
        4: 'l',
        5: 'cm',
        6: 'm',
        7: 'unit',
        8: 'mg',
    };
    public userStatusHtml = {
        1: `<span class=" inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-green-100 bg-green-700 rounded">APPROVED</span>`,
        2: `<span class="inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-red-100 bg-red-700 rounded">REJECTED</span>`,
        3: `<span class="inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-orange-100 bg-orange rounded">PENDING</span>`,
        4: `<span class="inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-white bg-primary rounded">VERIFIED</span>`,
        5: `<span class="inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-white bg-orange rounded">SUSPEND PENDING</span>`,
        6: `<span class="inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-white bg-red-700 rounded">SUSPENDED</span>`,
        7: `<span class="inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-white bg-orange rounded">REVOKE PENDING</span>`,
    };


    public userStatus = {
        1: 'Approved',
        2: 'Rejected',
        3: 'Pending',
        4: 'Verified',
        5: 'SuspendPending',
        6: 'Suspended',
        7: 'RevokePending'
    };
    public eType = {
        1: 'Supplier',
        2: 'BPCL',
        3: 'InOutStore',
        4: 'BUD',
        5: 'Customer',
    };
    public inventoryType = {
        1: 'Retail',
        2: 'LPG',
        3: 'Lubricants',
    };

    public stateCodeHash = {
        '01': 'Jammu & Kashmir',
        '02': 'Himachal Pradesh',
        '03': 'Punjab',
        '04': 'Chandigarh',
        '05': 'Uttarakhand',
        '06': 'Haryana',
        '07': 'Delhi',
        '08': 'Rajasthan',
        '09': 'Uttar Pradesh',
        10: 'Bihar',
        11: 'Sikkim',
        12: 'Arunachal Pradesh',
        13: 'Nagaland',
        14: 'Manipur',
        15: 'Mizoram',
        16: 'Tripura',
        17: 'Meghalaya',
        18: 'Assam',
        19: 'West Bengal',
        20: 'Jharkhand',
        21: 'Odisha',
        22: 'Chhattisgarh',
        23: 'Madhya Pradesh',
        24: 'Gujarat',
        26: 'Dadra & Nagar Haveli & Daman & Diu',
        27: 'Maharashtra',
        28: 'Andhra Pradesh',
        29: 'Karnataka',
        30: 'Goa',
        31: 'Lakshdweep',
        32: 'Kerala',
        33: 'Tamil Nadu',
        34: 'Pondicherry',
        35: 'Andaman & Nicobar Islands',
        36: 'Telangana',
        38: 'Ladakh'
    };

    allStoreGST = [
        '08AHAPR3911N2Z2',
        '08AHRPR4875L1Z9',
        '08AKUPM2199Q1ZX',
        '08AUJPS4517K1Z4',
        '08BERPD9143P1ZP',
        '08DRQPS1765E1Z9',
        '20AAAHG9633F1ZP',
        '20AAFFG8756Q2ZU',
        '20BFTPK7343R2ZP',
        '20CIFPS4425J1ZB',
        '23AAGPI1827H1Z2',
        '23AANPU3510G1ZT',
        '23ABMPB4376R1Z6',
        '23ADQPV5063P1ZO',
        '23AKBPD6101C1Z8',
        '23ANAPK0527D1ZS',
        '29BFZPP9107A1ZY',
        '36AAMFJ2505K1Z9',
        '36AANPY2647J1ZZ',
        '36AAQFK0820N2ZZ',
        '36ABAPD2227R1ZN',
        '36BOHPS4378P2ZW',
        '36BQSPM5372C1ZJ',
        '06AAACB2902M1ZX',
        '05AAACB2902M1ZZ',
        '07AAACB2902M1ZV',
        '08AAACB2902M1ZT',
        '03AAACB2902M1Z3',
        '02AAACB2902M1Z5',
        '04AAACB2902M1Z1',
        '09AAACB2902M1ZR',
        '01AAACB2902M1Z7',
        '22AAACB2902M1Z3',
        '23AAACB2902M1Z1',
        '24AAACB2902M1ZZ',
        '25AAACB2902M1ZX',
        '27AAACB2902M1ZT',
        '30AAACB2902M1Z6',
        '11AAACB2902M1Z6',
        '10AAACB2902M1Z8',
        '20AAACB2902M1Z7',
        '19AAACB2902M1ZQ',
        '21AAACB2902M1Z5',
        '18AAACB2902M1ZS',
        '16AAACB2902M1ZW',
        '36AAACB2902M1ZU',
        '33AAACB2902M1Z0',
        '29AAACB2902M1ZP',
        '32AAACB2902M1Z2',
        '32AAACB2902M2Z1',
        '37AAACB2902M2ZR',
        '12AAACB2902M1Z4',
        '34AAACB2902M1ZY',
        '26AAACB2902M1ZV',
        '14AAACB2902M1Z0',
        '17AAACB2902M1ZU',
        '15AAACB2902M1ZY',
        '13AAACB2902M1Z2'
    ];
    public gstStateCode = [
        {
            code: '01',
            state: 'Jammu & Kashmir'
        },
        {
            code: '02',
            state: 'Himachal Pradesh'
        },
        {
            code: '03',
            state: 'Punjab'
        },
        {
            code: '04',
            state: 'Chandigarh'
        },
        {
            code: '05',
            state: 'Uttranchal'
        },
        {
            code: '06',
            state: 'Haryana'
        },
        {
            code: '07',
            state: 'Delhi'
        },
        {
            code: '08',
            state: 'Rajasthan'
        },
        {
            code: '09',
            state: 'Uttar Pradesh'
        },
        {
            code: '10',
            state: 'Bihar'
        },
        {
            code: '11',
            state: 'Sikkim'
        },
        {
            code: '12',
            state: 'Arunachal Pradesh'
        },
        {
            code: '13',
            state: 'Nagaland'
        },
        {
            code: '14',
            state: 'Manipur'
        },
        {
            code: '15',
            state: 'Mizoram'
        },
        {
            code: '16',
            state: 'Tripura'
        },
        {
            code: '17',
            state: 'Meghalaya'
        },
        {
            code: '18',
            state: 'Assam'
        },
        {
            code: '19',
            state: 'West Bengal'
        },
        {
            code: '20',
            state: 'Jharkhand'
        },
        {
            code: '21',
            state: 'Orissa'
        },
        {
            code: '22',
            state: 'Chhattisgarh'
        },
        {
            code: '23',
            state: 'Madhya Pradesh'
        },
        {
            code: '24',
            state: 'Gujarat'
        },
        {
            code: '26',
            state: 'Dadra & Nagar Haveli & Daman & Diu'
        },
        {
            code: '27',
            state: 'Maharashtra'
        },
        {
            code: '28',
            state: 'Andhra Pradesh'
        },
        {
            code: '29',
            state: 'Karnataka'
        },
        {
            code: '30',
            state: 'Goa'
        },
        {
            code: '31',
            state: 'Lakshdweep'
        },
        {
            code: '32',
            state: 'Kerala'
        },
        {
            code: '33',
            state: 'Tamil Nadu'
        },
        {
            code: '34',
            state: 'Pondicherry'
        },
        {
            code: '35',
            state: 'Andaman & Nicobar Islands'
        },
        {
            code: '37',
            state: 'Telangana'
        },
        {
            code: '39',
            state: 'Ladakh'
        }
    ];

    public unionTerritoriesCode = {
        '04': 'Chandigarh',
        39: 'Ladakh',
        26: 'Dadra & Nagar Haveli & Daman & Diu',
        35: 'Andaman & Nicobar Islands',
        31: 'Lakshdweep'
    };

    public amountType = { 1: '%', 2: 'Rs.' };

    public availableLocations = [];

    public currentCustLocation = {
        pincode: '',
        storeId: '',
        name: ''
    };

    formatDateFormat(edate: any): string {
        const nth = (d: any) => {
            if (d > 3 && d < 21) {
                return 'th';
            }
            switch (d % 10) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        };
        const fortnightAway = new Date(edate * 1000);
        const date = fortnightAway.getDate();
        const month = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ][fortnightAway.getMonth()];
        return `${date}${nth(date)} ${month} ${fortnightAway.getFullYear()}`;
    }

    getCookies(key: string): any {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    setCookie(name: string, value: string, days: number, path: string) {
        const expires = '; expires=' + new Date(new Date().getTime() + days * 864e5).toUTCString();
        document.cookie = name + '=' + (value || '') + expires + '; path=' + path;
    }

    isPrivateIP(ip: string): boolean {
        const parts = ip.split('.');
        return (
            parts[0] === '10' ||
            (parts[0] === '172' &&
                parseInt(parts[1], 10) >= 16 &&
                parseInt(parts[1], 10) <= 31) ||
            (parts[0] === '192' && parts[1] === '168')
        );
    }

    isUuid(uuid: string): boolean {
        if (uuid) {
            // @ts-ignore
            return (
                uuid.match(/^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/)
                    ?.length > 0
            );
        } else {
            return false;
        }
    }



    checkIfValidIndianMobileNumber(str): boolean {
        // Regular expression to check if string is a Indian mobile number
        const regexExp = /^[6-9]\d{9}$/gi;
        return regexExp.test(str);
    }

    compare(a: number | string, b: number | string, isAsc: boolean): any {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    buildQuery(data: { [x: string]: any }): any {
        const baseQuery = { query: { bool: { must: [] } } };
        this.Objectkeys(data).forEach((obj) => {
            const tmpObj = { match: {} };
            // @ts-ignore
            tmpObj.match[obj] = data[obj];
            // @ts-ignore
            baseQuery.query.bool.must.push(tmpObj);
        });
        return baseQuery;
    }

    onlyUnique(value: any, index: any, self: string | any[]): boolean {
        return self.indexOf(value) === index;
    }

    reloadDevice(deviceId: any): void {
        this.networkDeviceReloadChange.next(deviceId);
    }

    percentCalculator(x: number, y: number): number {
        return Math.floor((y / x) * 100);
    }

    copyClip(cmd: string): void {
        const el = document.createElement('textarea');
        el.value = cmd;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.toast.sToast('success', 'Copied to clipboard');
    }

    getTimeZone(): void {
        const offset = new Date().getTimezoneOffset();
        const timezones = {
            '-12': 'Pacific/Kwajalein',
            '-11': 'Pacific/Samoa',
            '-10': 'Pacific/Honolulu',
            '-9': 'America/Juneau',
            '-8': 'America/Los_Angeles',
            '-7': 'America/Denver',
            '-6': 'America/Mexico_City',
            '-5': 'America/New_York',
            '-4': 'America/Caracas',
            '-3.5': 'America/St_Johns',
            '-3': 'America/Argentina/Buenos_Aires',
            '-2': 'Atlantic/Azores',
            '-1': 'Atlantic/Azores',
            0: 'Europe/London',
            1: 'Europe/Paris',
            2: 'Europe/Helsinki',
            3: 'Europe/Moscow',
            3.5: 'Asia/Tehran',
            4: 'Asia/Baku',
            4.5: 'Asia/Kabul',
            5: 'Asia/Karachi',
            5.5: 'Asia/Calcutta',
            6: 'Asia/Colombo',
            7: 'Asia/Bangkok',
            8: 'Asia/Singapore',
            9: 'Asia/Tokyo',
            9.5: 'Australia/Darwin',
            10: 'Pacific/Guam',
            11: 'Asia/Magadan',
            12: 'Asia/Kamchatka',
        };
        // @ts-ignore
        this.currentTimezone = timezones[-offset / 60];
        const timeZone = localStorage.getItem('_timeZones');
        if (timeZone && timeZone.length > 0) {
            this.timeZoneList = JSON.parse(timeZone);
        } else {
            this.baseRequestService
                .doRequest(`/api/cyberutils/dummy/getSupportedTimeZones`, 'post', {})
                .subscribe((result: any) => {
                    this.timeZoneList = result;
                });
        }
    }

    addCompany(name: any): void {
        this.addCompanyChange.next(name);
    }

    addSite(ele: any, name: any): void {
        ele.action = name.action;
        this.addSiteChange.next(ele);
    }

    async sortFn(arr: any, key?: string): Promise<any> {
        if (key) {
            return await arr.sort((a: any, b: any) => {
                const c = a[key];
                const d = b[key];
                if (c < d) {
                    return -1;
                } else if (c > d) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        return await arr.sort((c: any, d: any) => {
            if (c < d) {
                return -1;
            } else if (c > d) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    addCurrentCustomerLocation(locationVal) {
        this.currentCustLocation = locationVal;
    }

    changecustomerLocationModalChange() {
        this.customerLocationModalChange.next('open');
    }

    newDiscountDetails(values: any): void {
        this.discountDetailChange.next(values);
    }

    newDeliveryCharges(values: any): void {
        this.deliveryChargesChange.next(values);
    }

    getGlobalDiscount(): any {
        this.baseRequestService.doRequest(`/api/coupons/getCentralCoupons`, 'post', {}).subscribe((result: any) => {
            if (result && result.length > 0) {
                this.globalDiscount = result[0];
            } else {
                this.globalDiscount = {};
            }
            this.globalDiscount.couponDiscount = 0;
            console.log(this.globalDiscount);
        });
    }

    validateGlobalDiscountCoupon(rcart: any, cnumber?: any) {
        console.log('rcart', rcart);
        var maxvalue: any = 0;
        const cart = [];
        rcart.forEach((obj: any) => {
            cart.push({
                id: obj._id,
                productName: obj.name,
                unitCost: (obj.ratecard.sprice / obj.ratecard.caseSize),
                orderQuantity: obj.orderQuantity,
                transactionState: 'Open',
                recievedDate: new Date().toISOString(),
                mrp: obj.ratecard.mrp,
                shippedQuantity: 0,
                receivedQuantity: 0,
            });
            // obj.forEach((obj: any) => {
            console.log('obj.allow', obj.allow);
            console.log("obj.orderQuantity", obj.orderQuantity);
            maxvalue += ((obj.allow * obj.orderQuantity))
            // maxvalue += (Math.round(obj.allow * obj.orderQuantity));;
            // })
            console.log('maxvalue', maxvalue);
            this.cuponrupMaxvalue = maxvalue
        });
        const obj1 = {
            cart,
            couponId: this.globalDiscount.code,
            mobileNo: cnumber
        };
        if (this.globalDiscount.code) {
            this.baseRequestService.doRequest('/api/coupons/validateCoupon', 'post', obj1)
                .subscribe((res) => {
                    if (res[0]) {
                        console.log("this.cuponrupMaxvalue", this.cuponrupMaxvalue);
                        console.log("res[1].couponDiscount", res[1].couponDiscount);
                        if (this.cuponrupMaxvalue > res[1].couponDiscount) {
                            this.globalDiscount.couponDiscount = res[1].couponDiscount;
                            this.toast.sToast('info', 'Promo code updated!');
                        } else if (this.cuponrupMaxvalue < res[1].couponDiscount) {
                            this.globalDiscount.couponDiscount = 0;
                            this.toast.sToast('warning', 'Coupon Amount is More Than Discount Amount');
                        }
                        // this.globalDiscount.couponDiscount = (this.cuponrupMaxvalue > res[1].couponDiscount) ? res[1].couponDiscount : 0;
                    } else {
                        this.globalDiscount.couponDiscount = 0;
                    }
                });
        } else {
            this.globalDiscount.couponDiscount = 0;
        }
        // if (Object.keys(this.globalDiscount).length != 0) {

        // } else {
        //   this.globalDiscount['couponDiscount'] = 0;
        // }
    }

    calculateDiscount(total: any): any {
        if (this.globalDiscount && this.globalDiscount.value && this.globalDiscount.amountType && total > 0) {
            if (this.globalDiscount.amountType === 1) {
                return (total * this.globalDiscount.value) / 100;
            } else {
                return this.globalDiscount.value;
            }
        } else {
            return 0;
        }
    }

    invoiceOrderChange(invoice: any) {
        this.currentOrderInvoice.next(invoice);
        // call the function
    }

    getproductimage(productImage) {
        if (productImage) {
            if (Array.isArray(productImage)) {
                return productImage[0] + '?' + new Date().getMilliseconds();
            }
            return productImage.split(',')[0] + '?' + new Date().getMilliseconds();
        }
        return productImage + '?' + new Date().getMilliseconds();
    }

    handle_tens(dgt, prevDgt): any {
        return 0 === dgt ? '' : ' ' + (1 === dgt ? this.dblDigit[prevDgt] : this.tensPlace[dgt]);
    }
    handle_utlc(dgt, nxtDgt, denom): any {
        return (0 !== dgt && 1 !== nxtDgt ? ' ' + this.sglDigit[dgt] : '') + (0 !== nxtDgt || dgt > 0 ? ' ' + denom : '');
    }
    rs2word(price: any): any {
        let str = '';
        let digitIdx = 0;
        let digit = 0;
        let nxtDigit = 0;
        const words = [];
        // tslint:disable-next-line:no-conditional-assignment
        if (price += '', isNaN(+price)) { str = ''; }
        else if (+price > 0 && price.length <= 10) {
            for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) {
                switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
                    case 0:
                        words.push(this.handle_utlc(digit, nxtDigit, ''));
                        break;
                    case 1:
                        words.push(this.handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 2:
                        words.push(0 !== digit ? ' ' + this.sglDigit[digit] +
                            ' Hundred' + (0 !== price[digitIdx + 1] && 0 !== price[digitIdx + 2] ? ' and' : '') : '');
                        break;
                    case 3:
                        words.push(this.handle_utlc(digit, nxtDigit, 'Thousand'));
                        break;
                    case 4:
                        words.push(this.handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 5:
                        words.push(this.handle_utlc(digit, nxtDigit, 'Lakh'));
                        break;
                    case 6:
                        words.push(this.handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 7:
                        words.push(this.handle_utlc(digit, nxtDigit, 'Crore'));
                        break;
                    case 8:
                        words.push(this.handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 9:
                        words.push(0 !== digit ? ' ' + this.sglDigit[digit] +
                            ' Hundred' + (0 !== price[digitIdx + 1] || 0 !== price[digitIdx + 2] ? ' and' : ' Crore') : '');
                }
            }
            str = words.reverse().join('');
        } else { str = ''; }
        return str;
    }

    number2words(n: any): any {
        const num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(' ');
        const tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');
        if (n < 20) {
            return num[n];
        }
        const digit = n % 10;
        if (n < 100) { // tslint:disable-next-line:no-bitwise
            return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
        }
        if (n < 1000) { // tslint:disable-next-line:no-bitwise
            return num[~~(n / 100)] + ' hundred' + (n % 100 === 0 ? '' : ' and ' + this.number2words(n % 100));
        }
        // tslint:disable-next-line:no-bitwise
        return this.number2words(~~(n / 1000)) + ' thousand' + (n % 1000 !== 0 ? ' ' + this.number2words(n % 1000) : '');
    }

}
