import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { AuthGuard } from './core/auth.guard';
import { BudDashboardComponent } from './pages/bud-dashboard/bud-dashboard.component';
import { FpayComponent } from './shared/fpay/fpay.component';
import { SpayComponent } from './shared/spay/spay.component';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/pages/auth/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'spay/:transactionId',
    component: SpayComponent,
  },
  {
    path: 'fpay/:transactionId',
    component: FpayComponent,
  },
  {
    path: 'pv',
    loadChildren: () =>
      import('./pages/pages/auth/payment-validation/payment-validation.module').then(
        (m) => m.PaymentValidationModule
      ),
  },
  {
    path: 'paynow',
    loadChildren: () =>
      import('./pages/pages/auth/pay-now/pay-now.module').then(
        (m) => m.PayNowModule
      ),
  },
  {
    path: 'consumer-paynow',
    loadChildren: () =>
      import('./pages/pages/auth/consumer-pay-now/consumer-pay-now.module').then(
        (m) => m.ConsumerPayNowModule
      ),
  },
  {
    path: 'coming-soon',
    loadChildren: () =>
      import('./pages/pages/coming-soon/coming-soon.module').then(
        (m) => m.ComingSoonModule
      ),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
      },
      {
        path: '',
        // tslint:disable-next-line:max-line-length
        loadChildren: () =>
          import(
            './pages/dashboards/dashboard-analytics/dashboard-analytics.module'
          ).then((m) => m.DashboardAnalyticsModule),
      },
      {
        path: 'bud-dashboard',
        component: BudDashboardComponent,
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('./pages/inventory/inventory.module').then(
            (m) => m.InventoryModule
          ),
        data: { toolbarShadowEnabled: true },
      },
      {
        path: 'consumer',
        loadChildren: () =>
          import('./pages/consumer/consumer.module').then(
            (m) => m.ConsumerModule
          ),
        data: { toolbarShadowEnabled: true },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./pages/reports/reports.module').then((m) => m.reportsModule),
        // data: { toolbarShadowEnabled: true }
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./pages/orders/orders.module').then((m) => m.OrdersModule),
        data: { toolbarShadowEnabled: true },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        data: { toolbarShadowEnabled: true },
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./pages/administration/administration.module').then(
            (m) => m.AdministrationModule
          ),
        data: { toolbarShadowEnabled: true },
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./pages/pages/contact-us/contact-us.module').then(
            (m) => m.ContactUsModule
          ),
      },
      {
        path: 'pages',
        children: [
          {
            path: 'error-404',
            loadChildren: () =>
              import('./pages/pages/errors/error-404/error-404.module').then(
                (m) => m.Error404Module
              ),
          },
          {
            path: 'error-500',
            loadChildren: () =>
              import('./pages/pages/errors/error-500/error-500.module').then(
                (m) => m.Error500Module
              ),
          },
        ],
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/pages/errors/error-404/error-404.module').then(
            (m) => m.Error404Module
          ),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/pages/errors/error-404/error-404.module').then(
        (m) => m.Error404Module
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
