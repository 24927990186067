import { EventEmitter, Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import { Subject } from 'rxjs';
import icLayers from "@iconify/icons-ic/twotone-layers";
import icGroup from "@iconify/icons-ic/group";
import icPerson from "@iconify/icons-ic/person";
import icSettings from "@iconify/icons-ic/twotone-settings";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  _items: any = [
    {
      type: 'link',
      label: 'Dashboard',
      route: '/',
      icon: icLayers,
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'subheading',
      label: 'Services',
      children: [
        {
          type: 'dropdown',
          label: 'Inventory',
          icon: icGroup,
          children: [
            {
              type: 'link',
              label: 'Manage Inventory',
              route: '/inventory/manage-inventory',
              icon: icGroup
            },
            {
              type: 'link',
              label: 'Manage Category',
              route: '/inventory/category',
              icon: icGroup
            },
            {
              type: 'link',
              label: 'Add Inventory',
              route: '/inventory/add-inventory',
              icon: icGroup
            },
          ]
        },
        {
          type: 'dropdown',
          label: 'Orders',
          icon: icPerson,
          children: [
            {
              type: 'link',
              label: 'My Orders',
              route: '/orders/my-orders',
              icon: icPerson
            },
            {
              type: 'link',
              label: 'Place Orders',
              route: '/orders/place-order',
              icon: icPerson
            },
            {
              type: 'link',
              label: 'UD Requests',
              route: '/orders/bud-requests',
              icon: icPerson
            }
          ]
        },
        {
          type: 'dropdown',
          label: 'Settings',
          icon: icSettings,
          children: [
            {
              type: 'link',
              label: 'Users',
              route: '/settings/users',
              icon: icSettings
            },
            {
              type: 'link',
              label: 'BUDC',
              route: '/settings/budc',
              icon: icSettings
            },
            {
              type: 'link',
              label: 'BUD',
              route: '/settings/bud',
              icon: icSettings
            },
            {
              type: 'link',
              label: 'Roles',
              route: '/settings/roles',
              icon: icSettings
            },
          ]
        },
      ]
    }
  ];
  items: NavigationItem[] = [];
  updateItemsList: Subject<object> = new Subject<object>();
  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  constructor() { }

  getNavi() {

  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  updateItems(items: NavigationItem[]) {
    this.items = items;
    this.updateItemsList.next({ value: items });
  }

}
