<div [class.boxed]="isBoxed$ | async" [class.horizontal-layout]="!(isLayoutVertical$ | async)"
  [class.is-mobile]="!(isDesktop$ | async)" [class.vertical-layout]="isLayoutVertical$ | async"
  [class.has-fixed-footer]="
    (isFooterFixed$ | async) && isFooterVisible$ | async
  " [class.has-footer]="isFooterVisible$ | async" [class.scroll-disabled]="scrollDisabled$ | async"
  [class.toolbar-fixed]="isToolbarFixed$ | async" [class.sidenav-collapsed]="sidenavCollapsed$ | async"
  [class.content-container]="containerEnabled$ | async" [class.with-search]="searchOpen$ | async"
  class="page-container">
  <vex-progress-bar></vex-progress-bar>

  <vex-search></vex-search>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav [disableClose]="isDesktop$ | async" [fixedInViewport]="!(isDesktop$ | async)" [mode]="
        !(isDesktop$ | async) || (isLayoutVertical$ | async) ? 'over' : 'side'
      " [opened]="(isDesktop$ | async) && !(isLayoutVertical$ | async)" class="sidenav">
      <ng-container *ngTemplateOutlet="sidenavRef"></ng-container>
    </mat-sidenav>

    <mat-sidenav #quickpanel [fixedInViewport]="!(isDesktop$ | async)" class="quickpanel" mode="over" position="end">
      <ng-container *ngTemplateOutlet="quickpanelRef"></ng-container>
    </mat-sidenav>

    <mat-sidenav-content class="" [ngClass]="{'sidenav-content': isSidenav, 'no-sidenav-content': !isSidenav}"
      vexLazScroll (isBottom)="emitBottomHit()">
      <ng-container *ngTemplateOutlet="toolbarRef"></ng-container>
      <main class="content">
        <router-outlet>
          <div *ngIf="loading$ | async" class="loader-class custom-class">
            <img src="/assets/images/loading.gif" width="30" alt="" />
            <p *ngIf="!contentText || contentText === ''" class="resp-none">
              Loading...
            </p>
            <p class="m-0 resp-none">{{ contentText }}</p>
          </div>
          <div *ngIf="authService.sessionTimeout$ | async " class="loader-class custom-class p-3">
            <span class="mb-3 mt-3">Session about to expire in {{authService.timeoutSec}}s. Do you want to continue
              current session?</span>
            <div class="flex flex-row align-items-center">
              <button (click)="authService.resetSession()" class="form-control bg-primary text-white mr-2">Yes</button>
              <button (click)="authService.logout()" mat-stroked-button
                class="form-control bg-red text-white">Logout</button>
            </div>
          </div>
        </router-outlet>
      </main>

      <ng-container *ngTemplateOutlet="footerRef"></ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
