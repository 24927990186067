import { Component, OnInit, AfterViewInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from '../../_services/modal.service';
import {LoaderService} from '../../_services/loader.service';

@Component({
  selector: 'vex-fpay',
  templateUrl: './fpay.component.html',
  styleUrls: ['./fpay.component.scss']
})
export class FpayComponent implements OnInit, AfterViewInit {
  transactionId: string;
  constructor(private params: ActivatedRoute,
              private modalService: ModalService, private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.transactionId = this.params.snapshot.paramMap.get('transactionId');
    localStorage.setItem('failedId', this.transactionId);
  }

  ngAfterViewInit() {
    this.loaderService.display(true);
    setTimeout(() => { this.modalService.open('failedpayment'); });
    setTimeout(() => { window.close(); }, 2000);
  }
}
