import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import {UserIdleModule} from 'angular-user-idle';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { VexModule } from '../@vex/vex.module';
import { AppFilterPipeModule } from './_filters/app.filter-pipe.module';
import { CustomErrorHandlerService } from './_services/customErrorHandler.service';
/* import { HttpInterceptorService } from "./_services/http-interceptor.service"; */
import { MaterialModule } from './material.module';
import { ToastrModule } from 'ngx-toastr';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BudDashboardComponent } from './pages/bud-dashboard/bud-dashboard.component';
import { DirectivesModule } from './-directives/-directives.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
/* import { ServiceWorkerModule } from '@angular/service-worker'; */
import { environment } from '../environments/environment';
import { TranslocoRootModule } from './transloco-root.module';
@NgModule({
  declarations: [AppComponent, BudDashboardComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AppFilterPipeModule,
    MatInputModule,
    MaterialModule,
    // Vex
    VexModule,
    FormsModule,
    DirectivesModule,
    CustomLayoutModule,
    NgMultiSelectDropDownModule,
    CoreModule,
    ReactiveFormsModule,
    ZXingScannerModule,
    IvyCarouselModule,
      UserIdleModule.forRoot({idle: 1800, timeout: 1800, ping: 480}),
    ToastrModule.forRoot({
      timeOut: 8000,
      progressBar: true,
      enableHtml: true,
      closeButton: true,
      preventDuplicates: true,
    }),
    TranslocoRootModule,
    /* ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), */
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    { provide: ErrorHandler, useClass: CustomErrorHandlerService },
    /* {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    }, */
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
