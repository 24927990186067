import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {WindowRefService} from '../../_services/window-ref.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'vex-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [WindowRefService]
})
export class PaymentComponent implements OnInit {
  @Input() orderId: any;
  @Input() amount: any;
  @Input() currency: any;
  @Input() user: any;
  @Output() transResponse = new EventEmitter();
  @Output() transCancelled = new EventEmitter();
  constructor(private winRef: WindowRefService) { }

  ngOnInit(): void {
    this.payWithRazor(this.orderId);
  }

  payWithRazor(val): void {
    if (!this.orderId) {
      setTimeout(() => { this.payWithRazor(this.orderId); }, 3000);
      return;
    }
    const options: any = {
      key: environment.razorpay_key,
      amount: this.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: this.currency,
      name: '', // company name or product name
      description: '',  // product description
      image: '/assets/images/bpcl.svg', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      prefill: {
        name: this.user.name,
        contact: this.user.phone,
        email: this.user.email
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      console.log(response);
      console.log(options);
      this.transResponse.emit(options);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      this.transCancelled.emit('Transaction cancelled.');
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

}
