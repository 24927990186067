import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { MyToastrService } from "../_services/toastr.service";

@Directive({
  selector: "[numeric]",
})
export class NumericDirective {
  @Input("decimals") decimals = 0;
  @Input("minval") minval = 1;

  private check(value: string) {
    if (this.decimals <= 0) {
      return (
        String(value).match(new RegExp(/^\d+$/)) && value.indexOf(".") === -1
      );
    } else {
      var regExpString =
        "^\\s*((\\d+(\\.\\d{0," +
        this.decimals +
        "})?)|((\\d*(\\.\\d{1," +
        this.decimals +
        "}))))\\s*$";
      return (
        String(value).match(new RegExp(regExpString)) && +value >= this.minval
      );
    }
  }

  private run(oldValue) {
    setTimeout(() => {
      let currentValue: string = this.el.nativeElement.value;
      if (currentValue !== "" && !this.check(currentValue)) {
        this.el.nativeElement.value = oldValue;
        this.toast.sToast("error", "Invalid value. Only numbers are allowed");
        this.el.nativeElement.focus();
      }
    });
  }

  constructor(private el: ElementRef, private toast: MyToastrService) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if ((event.key === "." || event.code === "Period") && this.decimals <= 0) {
      setTimeout(
        () =>
          (this.el.nativeElement.value = this.el.nativeElement.value.replace(
            /\./g,
            ""
          ))
      );
      this.toast.sToast("error", "Invalid value. Only numbers are allowed");
      this.el.nativeElement.focus();
    }
    if (
      event.key === "0" &&
      this.minval > 0 &&
      this.el.nativeElement.value < this.minval
    ) {
      setTimeout(
        () =>
          (this.el.nativeElement.value = this.el.nativeElement.value.replace(
            /\0/g,
            this.minval
          ))
      );
      this.toast.sToast("error", "Invalid value. Only numbers are allowed");
      this.el.nativeElement.focus();
    }
    this.run(this.el.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener("focusout")
  onFocusOut() {
    this.run(this.el.nativeElement.value);
  }
}
