// tslint:disable: no-any
// tslint:disable: no-unsafe-any
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import {NotifierWarningComponent} from '../shared/notifierWarning/notifierWarning.component';
import {NotifierSuccessComponent} from '../shared/notifierSuccess/notifierSuccess.component';
import {NotifierDangerComponent} from '../shared/notifierDanger/notifierDanger.component';
import {NotifierInfoComponent} from '../shared/notifierInfo/notifierInfo.component';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
    providedIn: 'root'
})

export class NotificationService {
    isLoadingAccessInfo = null;
    isEditLoadingAccessInfo = null;
    actionButtonLabel = 'Okay';
    action = true;
    setAutoHide = true;
    autoHide = 2000;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';


    private configSuccess: MatSnackBarConfig = {
        panelClass: ['style-success'],
    };

    private configError: MatSnackBarConfig = {
        panelClass: ['style-error'],
    };

    constructor(public snackBar: MatSnackBar) {
    }

    notification(message, setAutoHide, panelColor?) {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        // config.duration = setAutoHide ? this.autoHide : 0;
        config.duration = 6000;
        config.data = { message };
        if (panelColor === 'success') {
            config.panelClass = ['mat-snackbar-success'];
            this.snackBar.openFromComponent(NotifierSuccessComponent, config);
        }
        if (panelColor === 'error') {
            config.panelClass = ['mat-snackbar-failed'];
            this.snackBar.openFromComponent(NotifierDangerComponent, config);
        }
        if (panelColor === 'warning') {
            config.panelClass = ['mat-snackbar-warning'];
            this.snackBar.openFromComponent(NotifierWarningComponent, config);
        }
        if (panelColor === 'info') {
            config.panelClass = ['mat-snackbar-info'];
            this.snackBar.openFromComponent(NotifierInfoComponent, config);
        }
        // this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config);
    }
}
