import { Injectable } from '@angular/core';
/*import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {environment} from '../../environments/environment';*/

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  // appInsights: ApplicationInsights;
  constructor() {
    /*this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: ['hostcountry.hashinclude.tech'], // , new URL(environment.oidcSettings.redirect_uri).hostname
        instrumentationKey: environment.appInsights.instrumentationKey,
        maxAjaxCallsPerView: -1,
      }
    });
    this.appInsights.loadAppInsights();*/
  }

  logPageView(name?: string, url?: string) {
    // this.appInsights.trackPageView({ name, uri: url});
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    // this.appInsights.trackEvent({ name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    // this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    // this.appInsights.trackException({ exception, severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    // this.appInsights.trackTrace({ message}, properties);
  }
}
