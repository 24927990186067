import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'vex-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {

  date = DateTime.local().toFormat('DD');
  dayName = DateTime.local().toFormat('EEEE');

  isBUD: boolean = false;
  constructor(private authService: AuthenticationService) {
    if (this.authService.currentUser.usertype === "BUD") {
      this.isBUD = true;
    }
  }
  ngOnInit() {
  }

}
