<mat-card class="-mt-14" *transloco="let text">
  <form #addGRNForm="ngForm">
    <mat-card-content class="p-3">
      <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="subheadin-2 m-0" fxFlex="auto"> {{text('rOrders.retProd')}} </h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button" (click)="bviewChanged('orders')">
          <mat-icon [icIcon]="ic.icClose"></mat-icon>
        </button>
      </div>
      <mat-divider class="text-border"></mat-divider>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="10px" class="mt-2">
        <mat-form-field class="w-100">
          <mat-label>{{text('supOrd.oID:')}}</mat-label>
          <input matInput [(ngModel)]="orders.orderID" disabled matInput name="orders" [required]="true" type="text"
            placeholder="Enter Order ID">
        </mat-form-field>
        <mat-form-field class="w-100" *ngIf="orders.invoices && orders.invoices.length">
          <mat-label>{{text('supOrd.choInv')}}</mat-label>
          <mat-select [(ngModel)]="invoiceNumber" name="invoiceNumber" (click)="toggleSearch(true)"
            placeholder="Invoice Number" (ngModelChange)="onUpdate($event)">
            <mat-option value="">{{text('rOrders.choInv')}}</mat-option>
            <mat-option *ngFor="let invoice of orders.invoices;" [value]="invoice.invoiceNo">{{invoice.invoiceNo}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>{{text('supOrd.retBy')}}</mat-label>
          <input matInput [(ngModel)]="returnBy" matInput name="grnby" type="text" (click)="toggleSearch(true)"
            placeholder="Enter Name">
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>{{text('supOrd.eInvDate')}}</mat-label>
          <input matInput [(ngModel)]="invoiceDate" matInput name="invDate" [required]="true" type="date"
            [readonly]="isInvoiceDateReadOnly" (click)="toggleSearch(true)" placeholder="Invoice Date">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center"
        *ngIf="currentInvoice && currentInvoice.invoiceFileLink && currentInvoice.invoiceFileName"
        fxLayout.lt-md="column" fxLayoutGap="20px" class="mb-2">
        <div class="w-100 flex cstm-box p-1">
          <h4 class="action-link-custom" (click)="downloadFile(currentInvoice.invoiceFileLink)">
            {{currentInvoice.invoiceFileName}}</h4>
        </div>
      </div>
      <div fxLayout="row" class="border-2 br-1 p-3 border-gray-100" *ngIf="invoiceNumber">
        <span *ngIf="!isSearch">
          <input type="hidden">
          <button matSuffix mat-raised-button aria-label="Scan Barcode" color="primary" (click)="toggleSearch()">
            {{text('rOrders.sBarSeaPro')}}
          </button>
        </span>
        <span class="font-size-9 mr-2 w-50" *ngIf="isSearch">
          <label class="text-secondary" for="search"> {{text('customer-detail.search')}}</label>
          <span class="flex flex-row items-center">
            <input class="form-control text-primary mb-1 br-1 px-1 py-1 bor-1 w-100" type="text" id="search" #searchText
              [(ngModel)]="search" (keydown.enter)="$event.preventDefault()" autofocus
              (ngModelChange)="onChange($event)" name="search" placeholder="Search...">
            <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
              <mat-icon class="pointer pl-1 mb-2 mat-icon text-lg" [icIcon]="ic.icClose"></mat-icon>
            </button>
            <mat-icon class="pointer pl-1  mat-icon text-lg" color="primary" matTooltip="Search Products"
              [icIcon]="ic.icSearch" (click)="searchNow()"></mat-icon>
          </span>
        </span>
        <span class="spacer"></span>
        <span class="" *ngIf="isSearch">
          <button matSuffix mat-raised-button aria-label="Scan Barcode" class="mt-3" color="primary"
            (click)="toggleSearch()">
            {{text('rOrders.eProd')}}
          </button>
        </span>
      </div>
      <div class="container px-0 py-5 mx-auto"
        *ngIf="masterInvProds && masterInvProds[invoiceNumber] && masterInvProds[invoiceNumber].length">
        <p> {{text('rOrders.retOrd')}}</p>
        <div class="flex flex-wrap -m-2">
          <div class="p-2 lg:w-1/1 md:w-1/1 w-full" *ngFor="let prod of currentReturnProducts; let i = index">
            <input type="hidden">
            <!-- [ngClass]="{'bg-green-50': ((prod.orderQuantity * prod.unitsize) === ((prod.recievedQty) + prod.recievedQty)),
            'bg-red-50': ((prod.orderQuantity * prod.unitsize) > (prod.recievedQty) + prod.recievedQty) }" -->
            <div class="h-full flex items-center border-gray-200 border p-2 rounded-lg">
              <div class="flex-grow">
                <div class="flex flex-row align-items-center">
                  <h3 class="text-gray-900 title-font font-medium">{{prod.productName}}</h3>
                  <div class="spacer"></div>
                  <button (click)="deleteOrder(i)" class="-mt-2" color="primary" mat-icon-button
                    matTooltip="Remove item" type="button">
                    <mat-icon [icIcon]="ic.icClose"></mat-icon>
                  </button>
                </div>
                <div class="text-gray-500 flex flex-row align-items-center my-1">
                  <span
                    class="flex items-center h-6 px-3 text-xs font-semibold text-blue-500 bg-blue-100 rounded-full">#{{prod.id}}</span>
                  <span *ngIf="prod.inventoryid"
                    class="flex items-center h-6 px-3 text-xs font-semibold text-blue-500 bg-blue-100 rounded-full">#{{prod.inventoryid}}</span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">{{text('rOrders.gst')}}
                    <span class="ml-2">{{prod.tax.gst| number:'1.2-2'}}%</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">{{text('supOrd.cess')}}
                    <span class="ml-2">{{prod.tax.cess| number:'1.2-2'}}%</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-purple-500 bg-purple-100 rounded-full">{{text('supOrd.cSize')}}
                    <span class="ml-2">{{prod.caseSize| number:'1.2-2'}}</span></span>
                </div>
                <div class="text-gray-500 flex flex-row align-items-center my-1">
                  <span
                    class="flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('supOrd.bPrice')}}
                    <span class="ml-2"> ₹{{prod.unitCost / prod.unitsize | number:'1.2-2'}}</span></span>
                  <span
                    class="flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('supOrd.mrp')}}
                    <span class="ml-2"> ₹{{prod.mrp / prod.caseSize | number:'1.2-2'}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-purple-500 bg-purple-100 rounded-full">{{text('rOrders.expiry')}}
                    <span class="ml-2">{{prod.expiryDate}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-purple-500 bg-purple-100 rounded-full">{{text('rOrders.batNo')}}
                    <span class="ml-2">{{prod.batchNo}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('supOrd.hsn')}}
                    <span class="ml-2"> {{prod.hsn}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">{{text('rOrders.ordered')}}
                    <span class="ml-2">
                      {{(prod.orderQuantity * prod.unitsize)| number:'1.2-2'}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full"
                    *ngIf="prod.recievedQty > 0">{{text('rOrders.received')}} <span class="ml-2">
                      {{prod.recievedQty}}</span></span>
                  <span
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-red-500 bg-red-100 rounded-full"
                    *ngIf="prod.recievedQty && ((prod.orderQuantity * prod.unitsize) - prod.recievedQty) > 0 && false">{{text('rOrders.balance')}}
                    <span class="ml-2"> {{(prod.orderQuantity * prod.unitsize) - prod.recievedQty}}</span></span>
                  <span
                    *ngIf="(!isSKOrder) && (prod.invoicequantity) && (prod.recievedQty) &&((prod.invoicequantity - prod.recievedQty) > 0) && false"
                    class="ml-2 flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">{{text('rOrders.shoQty')}}
                    <span class="ml-2">{{prod.invoicequantity - prod.recievedQty}}</span></span>
                </div>

                <div class="mt-3 flex">
                  <mat-form-field class="mr-2 w420 ">
                    <mat-label>{{text('rOrders.remark')}}</mat-label>
                    <input matInput [name]="'remark'+idGenerator(prod)" id="remark{{i}}"
                      (keydown.enter)="$event.preventDefault()" [(ngModel)]="prod.remark" placeholder="Remarks"
                      [required]="true" type="text">
                    <!--(paste)="paste($event, i)"
                      (click)="toggleSearch(true)"-->
                  </mat-form-field>
                  <mat-form-field class="mr-2 w420">
                    <mat-label>{{text('rOrders.claQty')}}</mat-label>
                    <input matInput [name]="'cQty'+idGenerator(prod)" required placeholder="Claim Quantity"
                      (click)="toggleSearch(true)" id="mrp{{i}}" [(ngModel)]="prod.claimQty" [required]="true" min="1"
                      numeric type="number" zeroMRPCheck decimals="2" minval="1"
                      [max]="prod.invoicequantity - prod.approvedQuantity">
                    <mat-hint>{{text('rOrders.qtyReturned.')}}</mat-hint>
                  </mat-form-field>
                  <div class="mr-2" *ngIf="reasonHash && objectKeys(reasonHash).length">
                    <mat-form-field appearance="fill" class="">
                      <mat-label>{{text('rOrders.retRea')}}</mat-label>
                      <mat-select [(ngModel)]="prod.reason" [name]="'reason'+idGenerator(prod)" id="reason{{i}}"
                        [required]="true" (ngModelChange)="updateSubReason($event, i)">
                        <mat-option *ngFor="let reason of objectKeys(reasonHash)" [value]="reason"> {{reason}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="mr-2"
                    *ngIf="reasonHash && prod.reason && reasonHash[prod.reason] && reasonHash[prod.reason].length">
                    <mat-form-field appearance="fill" class="">
                      <mat-label>{{text('rOrders.retSubRea')}}</mat-label>
                      <mat-select [(ngModel)]="prod.subreason" [name]="'subreason'+idGenerator(prod)"
                        id="subreason{{i}}" [required]="true">
                        <mat-option *ngFor="let subReason of reasonHash[prod.reason]" [value]="subReason">
                          {{subReason}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="mt-3 flex">
                  <!-- deal upload -->
                  <div class="w-100" fxLayout="column">
                    <p class="mb-1">{{text('rOrders.dItemImg')}} <small>*</small></p>
                    <input type="file" [disabled]="isLoading" accept=".jpg, .jpeg, .png"
                      [name]="'deal_upload'+idGenerator(prod)" id="deal_upload{{i}}"
                      [required]="!prod.deal_upload || !prod.deal_upload.doc"
                      (change)="uploadFile($event, 'deal_uploadimages', i, 'Damaged Image Item')"
                      [(ngModel)]="prod.dealupload" />
                    <mat-hint _ngcontent-opx-c260=""
                      class="mat-hint text-us pt-2 ng-tns-c202-344">{{text('rOrders.fFor')}}
                      *.jpg; *.jpeg; *.png</mat-hint>
                  </div>
                  <!-- pod_image -->
                  <div class="w-100" fxLayout="column">
                    <p class="mb-1">{{text('rOrders.delImg')}}<small>*</small></p>
                    <input type="file" [disabled]="isLoading" accept=".jpg, .jpeg, .png"
                      [required]="!prod.pod_image || !prod.pod_image.doc" [name]="'pod_img'+idGenerator(prod)"
                      id="pod_img{{i}}" (change)="uploadFile($event, 'pod_images', i, 'Delivery Image')"
                      [(ngModel)]="prod.podImage" />
                    <mat-hint _ngcontent-opx-c260=""
                      class="mat-hint text-us pt-2 ng-tns-c202-344">{{text('rOrders.fFor')}}
                      *.jpg; *.jpeg; *.png</mat-hint>
                  </div>
                  <!-- video -->
                  <div class="w-100" fxLayout="column">
                    <p class="mb-1">{{text('rOrders.unpaVid')}} <small>*</small></p><!---->
                    <input type="file" [disabled]="isLoading" accept=".mp4" [required]="!prod.video || !prod.video.doc"
                      [name]="'video'+idGenerator(prod)" id="video{{i}}"
                      (change)="uploadFile($event, 'deal_videos', i, 'Unpacking Video')" [(ngModel)]="prod.video" />
                    <mat-hint _ngcontent-opx-c260="" class="mat-hint text-us pt-2 ng-tns-c202-344">File Format:
                      *.mp4; *.mkv; *.mov; *.avi</mat-hint>
                  </div>
                </div>

                <div class="mt-3 flex" *ngIf="prod.reason === 'MRP Difference'">
                  <mat-form-field class="mr-2 w320 ">
                    <mat-label>{{text('rOrders.actMrp')}}</mat-label>
                    <input matInput [name]="'aMRP'+idGenerator(prod)" id="aMRP{{i}}"
                      (keydown.enter)="$event.preventDefault()" [(ngModel)]="prod.actualMRP" (paste)="paste($event, i)"
                      (click)="toggleSearch(true)" placeholder="Actual MRP" [required]="true" min="1" type="number"
                      zeroMRPCheck decimals="2" minval="1">
                  </mat-form-field>
                  <mat-form-field class="mr-2 w320 ">
                    <mat-label>{{text('rOrders.receMrp')}}</mat-label>
                    <input matInput [name]="'recievedMRP'+idGenerator(prod)" readonly required
                      placeholder="Received at MRP" (click)="toggleSearch(true)" id="recievedatMRP"
                      [(ngModel)]="prod.mrp" [required]="true" min="1" type="number" zeroMRPCheck decimals="2"
                      minval="1">
                  </mat-form-field>
                </div>

                <div class="mt-3 flex" *ngIf="prod.reason === 'Free product Not received'">
                  <mat-form-field class="mr-2 w420 ">
                    <mat-label>{{text('rOrders.fProNam')}}</mat-label>
                    <input matInput [name]="'FreeProdName'+idGenerator(prod)" id="FreeProdName{{i}}"
                      (keydown.enter)="$event.preventDefault()" [(ngModel)]="prod.freeProdName"
                      (paste)="paste($event, i)" (click)="toggleSearch(true)" placeholder="Free Product name"
                      [required]="true" type="text">
                  </mat-form-field>
                  <mat-form-field class="mr-2 w420 ">
                    <mat-label>{{text('rOrders.fProQty')}}</mat-label>
                    <input matInput [name]="'FreeProdQty'+idGenerator(prod)" required
                      placeholder="Free Product Quantity" (click)="toggleSearch(true)" id="FreeProdQty{{i}}"
                      [(ngModel)]="prod.freeProdQty" [required]="true" min="1" type="number" zeroMRPCheck decimals="2"
                      minval="1">
                  </mat-form-field>
                </div>

                <div class="mt-3 flex" *ngIf="prod.reason === 'Wrong Order Received'">
                  <mat-form-field class="mr-2 w420 ">
                    <mat-label>{{text('rOrders.ordId')}}</mat-label>
                    <input matInput [name]="'wOrderID'+idGenerator(prod)" id="wOrderID{{i}}"
                      (keydown.enter)="$event.preventDefault()" [(ngModel)]="prod.wrongOrderID"
                      (paste)="paste($event, i)" (click)="toggleSearch(true)" placeholder="Correct Ordere ID"
                      [required]="true" type="text">
                  </mat-form-field>
                </div>

                <div class="flex flex-col mt-3" *ngIf="prod.assetAvailable">
                  <div class="h3">{{text('rOrders.uplAss')}}</div>
                  <div class="mt-3 flex flex-row">
                    <div *ngIf="prod.deal_upload"
                      class="p-3 border-2 rounded-md flex  flex-col justify-center items-center mx-2 asset-box">
                      <div class="close text-xs" (click)="removeAsset('deal_upload', i)" matTooltip="Remove Asset">x
                      </div>
                      <img height="100px" width="100px" *ngIf="prod.deal_upload.doc" class="h-16"
                        [src]="prod.deal_upload.doc | safe" alt="" />
                      <span class="text-xs primary-text py-2">{{prod.deal_upload.filename|camelToHuman:true}}</span>
                    </div>
                    <div *ngIf="prod.pod_image"
                      class="p-3 border-2 rounded-md flex  flex-col justify-center items-center mx-2 asset-box">
                      <div class="close text-xs" (click)="removeAsset('pod_image', i)" matTooltip="Remove Asset">x</div>
                      <img height="100px" width="100px" *ngIf="prod.pod_image.doc" class="h-16"
                        [src]="prod.pod_image.doc | safe" alt="" />
                      <span class="text-xs primary-text py-2">{{prod.pod_image.filename|camelToHuman:true}}</span>
                    </div>
                    <div *ngIf="prod.video"
                      class="p-3 border-2 rounded-md flex  flex-col justify-center items-center mx-2 asset-box">
                      <div class="close text-xs" (click)="removeAsset('video', i)" matTooltip="Remove Asset">x</div>
                      <video [src]="prod.video.doc | safe" height="200px" width="200px" controls></video>
                      <span class="text-xs primary-text py-2">{{prod.video.filename|camelToHuman:true}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container px-0 py-5 mx-auto"
        *ngIf="masterInvProds && masterInvProds[invoiceNumber] && !masterInvProds[invoiceNumber].length">
        <p class="text-red">
          {{text('rOrders.fAlProInv')}}
        </p>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" class="ml-5">
      <div class="spacer"></div>
      <div>
        <button class="mr-2" (click)="saveDraft()" [disabled]="!invoiceNumber || isLoading " class="mat-primary"
          mat-raised-button type="submit"> {{text('rOrders.savDra')}} </button>
        <!-- (click)="modalService.open('congirmGRN')" -->
        <button class="mr-2" [disabled]="addGRNForm.form.invalid || isLoading || !currentReturnProducts.length"
          (click)="returnProducts()" class="mat-primary" mat-raised-button type="submit">
          {{text('rOrders.retPro')}}
        </button>
        <!-- <button class="mr-2" (click)="confirmCloseInvoice()" [disabled]="!addGRNForm.form.valid || isLoading"
          mat-raised-button class="mat-primary" type="submit">CLOSE INVOICE </button> -->
        <button mat-button mat-dialog-close id="btn" type="button" (click)="bviewChanged('orders'); orders = {}">
          {{text('sUser.close')}}
        </button>
      </div>
    </mat-card-actions>
  </form>
</mat-card>
<s-modal id="filteredItems" *transloco="let text">
  <mat-card class="cardmodal">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">
        {{text('rOrders.addToGrn')}} - {{filteredProducts?.length}} product(s) found
      </h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('filteredItems'); clearSearch();">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-card-content class="p-3 cardmodalContent" *ngIf="filteredProducts">
      <div class="">
        <div class="sm:flex flex-row">
          <div class="w-100">
            <table class="table w-full w-400">
              <thead>
                <tr>
                  <th class="body-2 text-secondary text-left">{{text('pos.productC')}}</th>
                  <th class="body-2 text-secondary">{{text('pos.price')}}</th>
                  <th class="body-2 text-secondary">{{text('pos.action')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let product of filteredProducts; trackBy: trackById">
                  <td class="py-1 border-b">
                    <span class="body-2">{{ product.productName }}</span>
                  </td>
                  <td class="py-1 px-2 border-b  text-center">
                    <span class="body-2">₹{{product.mrp| number:'1.2-2'}}</span>
                  </td>
                  <td class="py-1 border-b font-medium  text-center">
                    <button (click)="addToReturn(product);" class="mat-primary" mat-stroked-button color="primary">
                      {{text('new-prod.add')}}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button mat-dialog-close type="button" (click)="modalService.close('filteredItems'); clearSearch()">
        {{text('sUser.close')}}
      </button>
    </mat-card-actions>
  </mat-card>
</s-modal>

<s-modal id="congirmGRN" *transloco="let text">
  <mat-card class="cardmodal">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">
        {{text('rOrders.conGrn')}} - {{orders.orderID}}
      </h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('congirmGRN')">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-card-content class="p-3 cardmodalContent" *ngIf="filteredProducts">
      <div class="">
        <div class="sm:flex flex-row">
          <div class="w-100">
            <table class="table styled-table w-full w-400">
              <thead>
                <tr>
                  <th class="body-2 text-secondary text-left"> {{text('rOrders.proDes')}}</th>
                  <th class="body-2 text-secondary">{{text('supOrd.recQun')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let product of currentReturnProducts; trackBy: trackById">
                  <td class="py-1 border-b">
                    <span class="body-2">{{ product.productName }}</span>
                  </td>
                  <td class="py-1 px-2 border-b  text-center">
                    <span class="body-2">{{product.recievedQty| number:'1.2-2'}}</span>
                  </td>
                  <!-- {{(product.orderQuantity * product.unitsize) - (product.recievedQty +
                      product.recievedQty)
                      }} -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button class="mr-2" (click)="createGRN();modalService.close('congirmGRN')"
        [disabled]="isLoading || !currentReturnProducts.length" class="mat-primary" mat-raised-button type="submit">
        {{text('rOrders.confirm')}}
      </button>
      <button mat-button mat-dialog-close type="button" (click)="modalService.close('congirmGRN')">
        {{text('sUser.close')}}
      </button>
    </mat-card-actions>
  </mat-card>
</s-modal>