import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoaderService } from '../../_services/loader.service';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { CommonService } from '../../_services/common.services';
import { IconService } from '../../_services/icon.service';
import { MyToastrService } from '../../_services/toastr.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { BaseRequestService } from '../../_services/base.service';

@Component({
  selector: 'vex-store-products',
  templateUrl: './store-products.component.html',
  styleUrls: ['./store-products.component.scss']
})

export class StoreProductsComponent implements OnInit {
  @Input() currentEntity: any;
  layoutCtrl = new FormControl('boxed');
  storeProductsTableOptions: any;
  storeProductscurrentPage = 0;
  storeProductsfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  Objectkeys = Object.keys;
  isLoading = false;
  showSubStoreProducts = false;
  currentStoreProducts: any = { categoryName: '', image: '', description: '' };
  constructor(
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private router: Router,
    public toast: MyToastrService,
    private loaderService: LoaderService,
    public ic: IconService,
    public confirmDialog: ConfirmDialogService,
    public cs: CommonService
  ) {
    this.storeProductsTableOptions = {
      faClass: 'StoreProductMaster',
      customCols: false,
      serverSide: {
        service: '',
        fn: '',
        q: {},
        sort: [{}],
      },
      columns: [
        {
          header: '',
          columnDef: '_img',
          filter: '',
          cell: '(element: any) => `${element._img}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          width: '50px',
          baseImg: true,
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          isHyperlink: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'HSN',
          columnDef: 'hsn',
          filter: '',
          cell: '(element: any) => `${element.hsn}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Material Code',
          columnDef: 'productId',
          filter: '',
          cell: '(element: any) => `${element.productId}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Description',
          columnDef: 'description',
          filter: '',
          cell: '(element: any) => `${element.description}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Tax Rate',
          columnDef: 'taxinfo.gst',
          filter: 'decimalPipe',
          cell: '(element: any) => `${element.taxinfo.gst}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'CESS',
          columnDef: 'taxinfo.cess',
          filter: '',
          cell: '(element: any) => `${element.taxinfo.cess}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'MRP',
          columnDef: '_mrp',
          filter: 'decimalPipe',
          cell: '(element: any) => `${element.ratecard._mrp}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Supplier Name',
          columnDef: 'supplierDetails.supplierName',
          filter: '',
          cell: '(element: any) => `${supplierDetails.supplierName}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
          {
          header: 'Supplier Product ID',
          columnDef: 'supplierDetails.supplierProductId',
          filter: '',
          cell: '(element: any) => `${element.supplierDetails.supplierProductId}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
          {
          header: 'Case Size',
          columnDef: 'ratecard.caseSize',
          filter: '',
          cell: '(element: any) => `${supplierDetails.caseSize}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
          {
          header: 'BPCL Price',
          columnDef: '_unitPrice',
          filter: '',
          cell: '(element: any) => `${_unitPrice}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'ID',
          columnDef: '_id',
          filter: '',
          cell: '(element: any) => `${element._id}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
      ],
      sortOptions: { active: 'productmaster_ref.name', direction: 'asc' },
      _pageData: [],
      tableOptions: {
        title: 'Store Products',
        isServerSide: false,
        selectText: 'product(s)',
        loading: false,
        floatingFilter: true,
        rowSelection: false,
        showAction: false,
        actionMenuItems: [
          {
            text: 'Remove',
            icon: 'delete',
            callback: 'deleteFn',
            isGlobal: false,
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100, 500],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        add: false,
        columnSearch: true,
        compareData: false,
        showColFilter: false
      },
    };
  }
  ngOnInit() {
    this.storeProductsTableOptions.pageData = [];
    this.getStoreProducts();
  }
  storeProductsshowHideLoading(status: any): void {
    const data = Object.assign({}, this.storeProductsTableOptions);
    this.storeProductsTableOptions = {};
    this.storeProductsTableOptions = data;
    this.storeProductsTableOptions.tableOptions.loading = status;
  }
  storeProductssortCall(idata) {
    this.storeProductsTableOptions.sortOptions = idata;
    this.getStoreProducts();
  }

  storeProductsdetailFn(idata: any): void {
    this.currentStoreProducts = idata;
    this.showSubStoreProducts = true;
  }

  storeProductshyperlinkCall(idata: any): void {
    if (idata.col === 'storeProductsgoryName') {
      this.storeProductsdetailFn(idata.row);
    }
  }

  closeSubStoreProducts(): void {
    this.showSubStoreProducts = false;
    this.currentStoreProducts = {};
  }

  storeProductsdeleteFn(currentStoreProducts: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to remove ' + currentStoreProducts.categoryName + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.baseService
          .doRequest(`/api/storeproductmaster/${currentStoreProducts._id}`, 'delete', {
            id: currentStoreProducts._id,
          }).subscribe((result: any) => {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getStoreProducts();
            }, 2000);
          });
      }
    });
  }

  storeProductsactionCall(idata: any): void {
    if (idata.action.text === 'Remove') {
      const dataRow = idata.row;
      this.storeProductsdeleteFn(dataRow);
    }
  }

  storeProductsfilterCall(idata: any): void {
    const fields = [];
    const notInclude = ['_id', 'c', 'expirydate', 'u', 'isSellable', 'isvegetarian', 'isReturnable', 'isConsumable',
      'isVegetarian', 'barcode', 'sku', 'uom', 'maxOrderLimit', 'prodImg', 'taxinfo.gst', '_img', 'taxinfo.cess'];
    this.storeProductsTableOptions.columns.forEach(
      (obj: { columnDef: any }) => {
        if (notInclude.indexOf(obj.columnDef) === -1) {
          fields.push(obj.columnDef);
        }
      }
    );
    /*this.storeProductsTableOptions.columns.forEach((obj) => {
      fields.push(obj.columnDef);
    });*/
    this.storeProductsfilterQuery =
      idata && idata.length > 0
        ? { multi_match: { query: idata, type: 'phrase_prefix', fields } }
        : undefined;
    this.getStoreProducts();
  }

  storeProductspageCall(event: any): void {
    this.storeProductsTableOptions.tableOptions.pageSize = event.pageSize;
    this.storeProductscurrentPage = event.pageIndex;
    this.getStoreProducts();
  }

  storeProductsaddTableData(): void {
  }

  storeProductstimerCallData(idata: any): void {
    this.getStoreProducts();
  }

  getStoreProducts(): void {
    this.loaderService.display(true, 'Getting Products...');
    const params = {
      name: '',
      category: '',
      subcategory: '',
      // inventoryData: false,
      assignedProducts: true,
      storeId: this.currentEntity._id,
      skip: 0,
      limit: 9999,
    };
    this.baseService
      .doRequest(`/api/productmaster/fetchProducts`, 'post', params)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result && result.data && result.data.length) {
          result.data.forEach((obj: any) => {
            obj._img = this.cs.getproductimage(obj.productimage);
            try {
              obj._unitPrice = (obj.ratecard.bpclprice / obj.ratecard.caseSize).toFixed(2);
              obj._mrp = (obj.ratecard.mrp / obj.ratecard.caseSize).toFixed(2);
            } catch (e) {
              console.log(e);
            }
          });
          result.total = result.count;
          this.storeProductsTableOptions.pageData = result.data;
          this.storeProductsTableOptions.tableOptions.pageTotal = result.total;
          this.storeProductsshowHideLoading(false);
        } else {
          this.storeProductsTableOptions.pageData = [];
          this.storeProductsTableOptions.tableOptions.pageTotal = 0;
          this.storeProductsshowHideLoading(false);
        }
      });
  }

  getStoreProductsOld(): void {
    if (!this.currentEntity) { setTimeout(() => { this.getStoreProducts(); }, 1000); return; } else {
      this.storeProductsTableOptions.serverSide.q = { query: { bool: { must: [{ match: { 'entity_ref.id.keyword': this.currentEntity._id } }] } } };
    }
    this.storeProductsshowHideLoading(true);
    let params: any;
    params = {
      query: { bool: { must: [{ match: { 'entity_ref.id.keyword': this.currentEntity._id } }] } },
    };
    if (this.storeProductsfilterQuery && this.storeProductsfilterQuery.multi_match) {
      params.query.bool.must.push(this.storeProductsfilterQuery);
    }
    const sorts: any = [{}];
    if (this.storeProductsTableOptions.sortOptions) {
      sorts[0][this.storeProductsTableOptions.sortOptions.active] = {
        order: this.storeProductsTableOptions.sortOptions.direction,
      };
    }
    const q = JSON.stringify(params);
    const skip = this.storeProductscurrentPage;
    const limit = this.storeProductsTableOptions.tableOptions.pageSize;
    const sort = JSON.stringify(sorts);
    this.baseService
      .doRequest('/api/storeproductmaster', 'get', null, { q, skip, limit, sorts })
      .subscribe((result: any) => {
        this.storeProductsTableOptions.pageData = result.data;
        this.storeProductsTableOptions.tableOptions.pageTotal = result.total;
        this.storeProductsshowHideLoading(false);
      });
  }

  downloadInventory() {
    this.loaderService.display(true, 'Downloading Inventory...');
    this.baseService.doRequest(`/api/productmaster/downloadInventory`, 'post', {
      assignedProducts: true,
      storeId: this.currentEntity._id
    }).subscribe(
      (res) => {
        this.loaderService.display(false);
        if (res[0]) {
          window.open(res[1], '_blank');
        } else {
          this.toast.sToast('error', res[1]);
        }
      });
  }
}
