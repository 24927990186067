import { Directive, HostListener, ElementRef } from '@angular/core';
import { CommonService } from '../_services/common.services';
import { MyToastrService } from '../_services/toastr.service';

@Directive({
  selector: '[appMobileCheck]'
})
export class MobileCheckDirective {

  constructor(private el: ElementRef,
    private toast: MyToastrService,
    private cs: CommonService
  ) {
  }

  // tslint:disable-next-line:max-line-length
  Mobile_REGX = new RegExp(/^[9876][0-9]{9}$/);

  @HostListener('focusout')
  onFocusOut() {
    const current: string = this.el.nativeElement.value;
    if (current !== '') {
      if (current && !String(current).match(this.Mobile_REGX)) {
        this.toast.sToast('error', 'Invalid Mobile Number');
        // console.log("bad Mobile")
        this.el.nativeElement.focus();
        this.cs.inputHasError = true;
      }
      else {
        this.cs.inputHasError = false;
      }
    }
  }
}
