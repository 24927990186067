<div style="width: 100%; display: flex; align-items: center; justify-content: flex-end;">
  <!--  -->
  <button class="btn bg-gradient-secondary btn-apply checkout_btn" mat-icon-button id="print-btn"
    styleSheetFile="assets/print-style.scss" ngxPrint color="primary" [useExistingCss]="true" printSectionId="to-print">
    <mat-icon [icIcon]="ic.icPrint"></mat-icon>
  </button>
</div>
<div id="to-print">
  <div *ngIf="isEnglish" id="invoice-POS">
    <div class="flex flex-col items-center mb-3" id="top">
      <div>
        <div class="logo">
          <img src="assets/img/logo-new.jpg">
          <!--logocr.jpg-->
        </div>
      </div>
      <div class="info" *ngIf="authCurrentEntity">
        <p>{{authCurrentEntity.name}}</p>
        <p>GSTIN:- {{authCurrentEntity.taxidentifier}}</p>
        <p>FSSAI NO:- {{authCurrentEntity.fssainumber}}</p>
        <p>ADDRESS: {{authCurrentEntity.address}}, {{authCurrentEntity.state}}, {{authCurrentEntity.pincode}}</p>
        <p class="py-1 text-xsm">Please call store at {{authCurrentEntity.mobile}} for home delivery</p>
        <!--<p>{{ authService.currentUser.email }}</p>
        <p>Buyer Name: {{cs.currentConsumerName}}</p>
        <p>Supplied To: {{cs.currentSupplierCode }} {{cs.currentSuppliedToState}}</p>-->
      </div>
    </div>
    <div class="mid flex flex-col items-center mb-1">
      <div class="info2">TAX INVOICE</div>
    </div>
    <div id="bot" class="flex flex-col items-center mb-2">
      <div id="table">
        <table>
          <tr class="tabletitle">
            <td>&nbsp;</td>
            <td>
              <p>Bill No.: <b>{{cs.currentInvoiceNumber}}</b></p>
            </td>
            <td>
              <p>Bill Date: <b>{{tDate}}</b></p>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr class="tabletitle">
            <td>&nbsp;</td>
            <td>
              <p>Coupon Code: <b>{{ cs.pSellList.coupon }}</b></p>
            </td>
            <td>
              <p>Cashier: <b>{{ authService.currentUser.firstName }}</b></p>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
        <table class="mb-1" *ngIf="cs.pSellList && cs.pSellList.length">
          <tr class="tabletitle">
            <td>
              &nbsp;
            </td>
            <td class="text-left">
              <p>HSN Particulars</p>
            </td>
            <td class="text-center">
              <p>Qty</p>
            </td>
            <td class="text-right">
              <p>Rate</p>
            </td>
            <td class="text-right">
              <p>Value</p>
            </td>
            <td>&nbsp;</td>
          </tr>
          <ng-container *ngFor="let cart of newCartFormat; index as i">
            <tr class="service bb" *ngIf="cart.gst > 0">
              <td><span>{{i+1}}</span></td>
              <td colspan="5" class="itemtext-disc">CGST: {{cart.gst/2 | number:'1.0-2'}}%, SGST: {{cart.gst/2 |
                number:'1.0-2'}}%, CESS: {{cart.cess}}%</td>
            </tr>
            <tr class="py-1" *ngFor="let prod of cart.products">
              <td>&nbsp;</td>
              <td class="itemtext text-left">
                <div>{{prod.name}}</div>
                <div class="itemtext" *ngIf="prod._discountValue">Disc.: ₹{{(prod._discountValue * prod.orderQuantity) |
                  number:'1.0-2'}}. Net Amt. : ₹{{(prod.orderQuantity * (prod.ratecard.sprice /
                  prod.ratecard.caseSize)) - prod._discountValue | number:'1.0-2'}}
                </div>
              </td>
              <td class="itemtext text-center">{{prod.orderQuantity}}</td>
              <td class="itemtext text-right">₹ {{(prod.ratecard.sprice / prod.ratecard.caseSize) | number:'1.0-2'}}
              </td>
              <td class="itemtext text-right">₹ {{ (prod.orderQuantity * (prod.ratecard.sprice /
                prod.ratecard.caseSize)) | number:'1.0-2'}}
              </td>
              <td>&nbsp;</td>
            </tr>
          </ng-container>
        </table>
        <table class="my-1">
          <tr class="tabletitle">
            <td class="text-center">
              <p>Items: {{cs.pSellList.length}}</p>
            </td>
            <td class="text-center">
              <p>Qty: {{ itemQty }}</p>
            </td>
            <td class="text-center">
              <p>Total: ₹{{ total| number:'1.0-2' }}</p>
            </td>
          </tr>
        </table>
        <table>
          <tr class="tabletitle" *ngIf="productDiscount != 0">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Product-wise Discount</h2>
            </td>
            <td class="payment text-right">
              <h2>- ₹{{ productDiscount | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="storeDiscount != 0">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2 *ngIf="authService.isBUD()">UD Discount</h2>
              <h2 *ngIf="authService.isBUDC()">Staff Discount</h2>
              <h2 *ngIf="authService.isConsumer()">Store Discount</h2>
            </td>
            <td class="payment text-right">
              <h2>- ₹{{ storeDiscount | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="couponDiscount != 0">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Coupon Discount</h2>
            </td>
            <td class="payment text-right">
              <h2>- ₹{{ couponDiscount | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="cs.globalDiscount.couponDiscount> 0">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>{{cs.globalDiscount.description}}</h2>
            </td>
            <td class="payment text-right">
              <h2>- ₹{{ cs.globalDiscount.couponDiscount | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="vDisc > 0">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Voucher: {{cs.voucherDiscount.code}}</h2>
            </td>
            <td class="payment text-right">
              <h2>- ₹{{ vDisc | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="isDeliveryChargesApplied">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Delivery Charges</h2>
            </td>
            <td class="payment text-right">
              <h2>+ ₹{{ deliveryCharge | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="isAdvAmount">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Advance Amount</h2>
            </td>
            <td class="payment text-right">
              <h2>+ ₹{{ cs.advAmount | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Net Amount</h2>
            </td>
            <td class="payment text-right">
              <h2
                *ngIf="netAmount - +couponDiscount - +cs.globalDiscount.couponDiscount - storeDiscount - vDisc + deliveryCharge < 0; else pricing2">
                ₹0
              </h2>
              <ng-template #pricing2>
                <h2>₹{{ netAmount - +couponDiscount - +cs.globalDiscount.couponDiscount - storeDiscount -vDisc +
                  deliveryCharge
                  |
                  number:'1.0-2'}}
                </h2>
              </ng-template>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
        </table>
        <table class="taxTable">
          <tr>
            <td>GST breakup details</td>
          </tr>
        </table>
        <table class="my-2">
          <tr class="tabletitle">
            <td class="text-center">&nbsp;</td>
            <td class="text-center">GST IND</td>
            <td class="text-center">Taxable Amount</td>
            <td class="text-center">CGST</td>
            <td class="text-center">SGST</td>
            <td class="text-center">CESS</td>
            <td class="text-center">Total Amount</td>
            <td class="text-center">&nbsp;</td>
          </tr>
          <ng-container *ngFor="let tax of newCartFormat; let i = index;">
            <tr class="" *ngIf="tax.taxP > 0">
              <td class="itemtext">&nbsp;</td>
              <td class="itemtext text-center">{{i + 1}}</td>
              <td class="itemtext text-right">₹{{tax.baseP | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹{{tax.taxP/2 | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹{{tax.taxP/2 | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹{{tax.cessP | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹{{ netAmount - +couponDiscount - +cs.globalDiscount.couponDiscount -
                storeDiscount -vDisc +
                deliveryCharge
                |
                number:'1.0-2'}}</td>
              <td class="itemtext">&nbsp;</td>
            </tr>
          </ng-container>
          <tr class="service bt">
            <td class="itemtext">&nbsp;</td>
            <td class="itemtext text-center">T:</td>
            <td class="itemtext text-right">₹{{sumOf('baseP') | number:'1.0-2'}}</td>
            <td class="itemtext text-right">₹{{sumOf('taxP') / 2 | number:'1.0-2'}}</td>
            <td class="itemtext text-right">₹{{sumOf('taxP') / 2 | number:'1.0-2'}}</td>
            <td class="itemtext text-right">₹{{sumOf('cessP') | number:'1.0-2'}}</td>
            <td class="itemtext text-right">₹{{ netAmount - +couponDiscount - +cs.globalDiscount.couponDiscount -
              storeDiscount -vDisc +
              deliveryCharge
              |
              number:'1.0-2'}}</td>
            <td class="itemtext">&nbsp;</td>
          </tr>
        </table>
        <table *ngIf="(mrpTotal - netAmount + (+couponDiscount + +cs.globalDiscount.couponDiscount +
          +storeDiscount + +vDisc)) > 0">
          <tr class="tabletitle">
            <td class="text-center">
              <h2>* * Saved Rs. {{mrpTotal - netAmount + (+couponDiscount + +cs.globalDiscount.couponDiscount +
                +storeDiscount ) | number:'1.0-2'}}/- on MRP * *</h2>
            </td>
          </tr>
        </table>
        <p class="legal text-center mt-2">** Thank you ** </p>
        <!--<table class="taxTable">
          <tr><td colspan="5">Tax Inclusive</td></tr>
          <ng-container *ngFor="let prod of cs.sellList; index as i">
            <tr *ngIf="prod?.taxinfo && prod?.taxinfo?.gst > 0 && (cs.currSuppliedType == '1')">
              <td style="text-align: left;">{{i+1}}</td>
              <td>UTGST</td>
              <td style="text-align: right;">@{{(prod?.taxinfo?.gst)/2| number:'1.0-2'}}</td>
              <td>%</td>
              <td style="text-align: right;">₹ {{prod.basePrice| number:'1.0-2'}}</td>
              <td style="text-align: right;">₹ {{prod.gstPrice| number:'1.0-2'}}</td>
            </tr>
            <tr *ngIf="prod?.taxinfo && prod?.taxinfo?.gst > 0 && cs.currSuppliedType == '2'">
              <td style="text-align: left;">{{i+1}}</td>
              <td>SGST</td>
              <td style="text-align: right;">@{{(prod?.taxinfo?.gst)/2| number:'1.0-2'}}</td>
              <td>%</td>
              <td style="text-align: right;">₹ {{prod.basePrice| number:'1.0-2'}}</td>
              <td style="text-align: right;">₹ {{prod.gstPrice| number:'1.0-2'}}</td>
            </tr>
            <tr
              *ngIf="prod?.taxinfo && prod?.taxinfo?.gst > 0  && (cs.currSuppliedType == '1' || cs.currSuppliedType == '2')">
              <td style="text-align: left;">{{i+1}}</td>
              <td>CGST</td>
              <td style="text-align: right;">@{{(prod?.taxinfo?.gst)/2| number:'1.0-2'}}</td>
              <td>%</td>
              <td style="text-align: right;">₹ {{prod.basePrice| number:'1.0-2'}}</td>
              <td style="text-align: right;">₹ {{prod.gstPrice| number:'1.0-2'}}</td>
            </tr>
            <tr *ngIf="prod?.taxinfo && prod?.taxinfo?.gst > 0 && cs.currSuppliedType == '3'">
              <td style="text-align: left;">{{i+1}}</td>
              <td>IGST</td>
              <td style="text-align: right;">@{{(prod?.taxinfo?.gst)| number:'1.0-2'}}</td>
              <td>%</td>
              <td style="text-align: right;">₹ {{prod.basePrice| number:'1.0-2'}}</td>
              <td style="text-align: right;">₹ {{prod.gstPrice| number:'1.0-2'}}</td>
            </tr>
            <tr *ngIf="prod?.taxinfo && prod?.taxinfo?.cess > 0">
              <td style="text-align: right;">{{i+1}}</td>
              <td>CESS</td>
              <td style="text-align: right;">{{prod?.taxinfo?.cess| number:'1.0-2'}}</td>
              <td>%</td>
              <td style="text-align: right;">₹ {{prod.basePrice| number:'1.0-2'}}</td>
              <td style="text-align: right;">₹ {{prod.cessPrice| number:'1.0-2'}}</td>
            </tr>
          </ng-container>
        </table>-->
      </div>
    </div>
  </div>
</div>