import { Directive, HostListener, ElementRef } from '@angular/core';
import { MyToastrService } from '../_services/toastr.service';

@Directive({
  selector: '[appEmailCheck]'
})
export class EmailCheckDirective {

  constructor(private el: ElementRef, private toast: MyToastrService) {
  }

  // tslint:disable-next-line:max-line-length
  EmailID_REGX = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  @HostListener('focusout')
  onFocusOut() {
    const current: string = this.el.nativeElement.value;
    if (current !== '') {
      if (current && !String(current).match(this.EmailID_REGX)) {
        this.toast.sToast('error', 'Invalid Email ID');
        this.el.nativeElement.focus();
      }
    }
  }
}
