import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  private _autofocus;
  renderer: any;
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this._autofocus || typeof this._autofocus === 'undefined') {
      this.renderer.invokeElementMethod(this.el.nativeElement, 'focus', []);
    }
  }

  @Input() set autofocus(condition: boolean) {
    this._autofocus = condition !== false;
  }
}
