<vex-page-layout>
    <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">Product Master</h1>
                <vex-breadcrumbs [crumbs]="['Inventory', 'Product Master']"></vex-breadcrumbs>
            </div>
            <!-- <button class="mt-4 sm:mt-0 mr-1" (click)="syncInventory()" mat-button="">Sync</button>
            <button class="mt-4 sm:mt-0" (click)="placeOrder()" [disabled]="!cs.cartList.length"
                    mat-raised-button="">{{cs.cartList.length}} CART</button> -->
        </div>
    </vex-page-layout-header>
    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxedswa'"
                             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                             class="-mt-6">
        <div class="card overflow-auto -mt-16">
            <app-s-table aria-label="Inventory" role="table" [sTableOptions]="inventoryTableOptions"
                         (sortCallback)="inventorysortCall($event)" (hyperlinkCallback)="hyperlinkCall($event)"
                         (filterCallback)="inventoryfilterCall($event)" (actionCallback)="inventoryactionCall($event)"
                         (pageCallback)="inventorypageCall($event)" (refreshCallback)="getInventory()"
                         (globalActionCallback)="inventoryglobalActionCall($event)"
                         (addCallback)="inventoryaddTableData()"
                         (timerCallback)="inventorytimerCallData($event)"></app-s-table>
        </div>
    </vex-page-layout-content>
</vex-page-layout>
<s-modal id="viewBudOrders">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
            <h2 class="subheading-2 m-0" fxFlex="auto">UD Quantity - {{currentInventory.productName}}</h2>
            <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
                    (click)="modalService.close('viewBudOrders')">
                <mat-icon [icIcon]="ic.icClose"></mat-icon>
            </button>
        </div>
        <mat-divider class="text-border"></mat-divider>
        <mat-card-content class="p-3" *ngIf="currentInventory">
            <mat-list dense class="bor-1 w-300 br-1">
                <mat-list-item class="bb-1">
                    <span class="text-secondary">BUD</span>
                    <span class="spacer"></span>
                    <span class="text-secondary">Quantity</span>
                </mat-list-item>
                <mat-list-item class="bb-1" *ngFor="let bud of budList">
                    <span>{{bud.partner_ref.email}}</span><span class="spacer"></span><span>{{bud.availableQuantity}}</span>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-button mat-dialog-close type="button"
                    (click)="modalService.close('viewBudOrders')">CLOSE
            </button>
        </mat-card-actions>
    </mat-card>
</s-modal>
<s-modal id="orderMore">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title *ngIf="currentProd">
            <h2 class="subheading-2 m-0" fxFlex="auto">Order {{currentProd.productName}}</h2>
            <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
                    (click)="modalService.close('orderMore')">
                <mat-icon [icIcon]="ic.icClose"></mat-icon>
            </button>
        </div>
        <mat-divider class="text-border"></mat-divider>
        <mat-card-content class="p-3" *ngIf="currentProd">
            <mat-form-field appearance="outline">
                <mat-label>Enter Quantity</mat-label>
                <input name="orderQuantity" matInput [(ngModel)]="currentProd.orderQuantity" type="number" placeholder="0"/>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-stroke-button color="primary"  type="button"
                    (click)="addToCart(currentProd)">ADD TO CART
            </button>
            <button mat-button mat-dialog-close type="button"
                    (click)="modalService.close('viewBudOrders')">CLOSE
            </button>
        </mat-card-actions>
    </mat-card>
</s-modal>
<s-modal id="sellProduct">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title *ngIf="currentProd">
            <h2 class="subheading-2 m-0" fxFlex="auto">Sell {{currentProd.productName}}</h2>
            <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
                    (click)="modalService.close('sellProduct')">
                <mat-icon [icIcon]="ic.icClose"></mat-icon>
            </button>
        </div>
        <mat-divider class="text-border"></mat-divider>
        <mat-card-content class="p-3" *ngIf="currentProd">
            <mat-form-field appearance="outline">
                <mat-label>Enter Quantity</mat-label>
                <input name="orderQuantity" matInput [(ngModel)]="currentProd.orderQuantity" type="number" placeholder="0"/>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-stroke-button class="mat-primary"  type="button"
                    (click)="sellProduct(currentProd)">SELL
            </button>
            <button mat-button mat-dialog-close type="button"
                    (click)="modalService.close('sellProduct')">CLOSE
            </button>
        </mat-card-actions>
    </mat-card>
</s-modal>
<s-modal id="addInventory">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
            <h2 class="subheading-2 m-0" fxFlex="auto">Add Inventory</h2>
            <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
                    (click)="modalService.close('addInventory')">
                <mat-icon [icIcon]="ic.icClose"></mat-icon>
            </button>
        </div>
        <mat-divider class="text-border"></mat-divider>
        <mat-card-content class="p-3" *ngIf="currentInventory">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field class="sm:ml-6 flex-auto">
                    <mat-label>Product Name</mat-label>
                    <input matInput name="productName" [(ngModel)]="currentInventory.productName"
                           type="text" required placeholder="Enter Product Name">
                </mat-form-field>
                <mat-form-field class="sm:ml-6 flex-auto">
                    <mat-label>Available Quantity</mat-label>
                    <input matInput name="availableQuantity" [(ngModel)]="currentInventory.availableQuantity"
                           type="number" required placeholder="Enter Available Quantity">
                </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions align="end" *ngIf="currentInventory">
            <button [disabled]="!currentInventory.productName || !currentInventory.availableQuantity || isLoading"
                    (click)="saveInventory()" color="primary" mat-button type="submit">ADD NEW
            </button>
            <!--<button *ngIf="currentInventory._id"
                  (click)="updateInventory(currentInventory)" [disabled]="!addI.form.valid || isLoading"
                    color="primary" mat-button type="submit">UPDATE</button>-->
            <button mat-button mat-dialog-close type="button"
                    (click)="modalService.close('addInventory')">CLOSE
            </button>
        </mat-card-actions>
    </mat-card>
</s-modal>
<s-modal id="placeOrderBUDC">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
            <h2 class="subheading-2 m-0" fxFlex="auto">Checkout ({{cs.cartList.length}})</h2>
            <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
                    (click)="modalService.close('placeOrderBUDC')">
                <mat-icon [icIcon]="ic.icClose"></mat-icon>
            </button>
        </div>
        <mat-divider class="text-border"></mat-divider>
        <mat-card-content class="p-3" *ngIf="cs.cartList && cs.cartList.length">
            <div class="overflow-y-auto" fxFlex="auto">
                <div class="p-gutter"
                     vexContainer
                     gdColumns="1fr 1fr 1fr 1fr"
                     gdColumns.lt-md="1fr 1fr"
                     gdColumns.xs="1fr"
                     gdGap="24px">
                    <mat-card class="mat-elevation-z8" @fadeInUp *ngFor="let prod of cs.cartList; let i = index;">
                        <mat-card-header>
                            <mat-card-title>{{prod.productName}}</mat-card-title>
                        </mat-card-header>
                        <img matCardImage [src]="prod.productImage">
                        <mat-card-footer>
                            <div class="flex flex-row align-items-center">
                                <span class="">
                                    <mat-form-field appearance="outline" class="">
                                        <input name="{{i}}q" matInput [(ngModel)]="prod.orderQuantity" type="number" placeholder="0"/>
                                    </mat-form-field>
                                </span>
                                <span>
                                    <button (click)="cs.cartList.splice(i, 1)" class="mt-4 mat-warn" mat-raised-button="">Delete</button>
                                </span>
                            </div>
                        </mat-card-footer>
                    </mat-card>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions align="end">
            <button (click)="placeOrderNow()" *ngIf="cs.cartList"
                    color="primary" mat-raised-button type="submit">CHECK OUT
            </button>
            <button mat-button mat-dialog-close type="button" (click)="modalService.close('addInventory')">CLOSE
            </button>
        </mat-card-actions>
    </mat-card>
</s-modal>
