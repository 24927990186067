import { Directive, HostListener, ElementRef } from '@angular/core';
import { MyToastrService } from '../_services/toastr.service';

@Directive({
  selector: '[appCompnyNameCheck]'
})
export class CompanyNameCheckDirective {

  constructor(private el: ElementRef,
    private toast: MyToastrService
  ) {
  }

  // tslint:disable-next-line:max-line-length
  CMPNY_NAME = new RegExp(/^[a-zA-Z0-9_., ]*$[^\s]*/);

  @HostListener('focusout')
  onFocusOut() {
    const current: string = this.el.nativeElement.value.trim();
    if (current !== '') {
      if (current && !String(current).match(this.CMPNY_NAME)) {
        this.toast.sToast('error', "Company Name contains invalid characters. Allowed Characters are '_', '.', ',' and space.");
        this.el.nativeElement.focus();
      }
    }
  }
}
