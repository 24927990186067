<ng-container *transloco="let text">
  <div class="outer-card" *ngIf="!makeEmpty">
    <div class="card br15">
      <div class="p-4 br15 text-center hover:bg-hover trans-ease-out cursor-pointer" style="position: relative;"
        [ngClass]="{'out-of-stock': ((!supplier && !budBuy) && product.availableQuantity < 1)  || ( supplier && (product?.ratecard?.minimumQuantity < 1))}">
        <!--|| ((!supplier && budBuy) && product.availableQuantity_Store < 1)-->
        <span class="oos-text rounded bor-1 p-1 border-red font-thin text-red-600"
          *ngIf="!isCDIT && (!supplier && !budBuy) && product.availableQuantity < 1">
          {{text('prodCard.ooS')}}
          <!-- OUT OF STOCK -->
        </span>
        <span class="oos-text rounded bor-1 p-1 border-red font-thin text-red-600"
          *ngIf="supplier && (product?.ratecard?.minimumQuantity < 1)">
          {{text('prodCard.pU')}}
          <!-- PRODUCT UNAVAILABLE -->
        </span>
        <span class="oos-text rounded bor-1 p-1 border-red font-thin text-red-600"
          *ngIf="!isCDIT && (!supplier && budBuy) && product.availableQuantity_Store< 1">
          {{text('prodCard.ooWS')}}
          <!-- OUT OF STOCK WITH STORE -->
        </span>
        <span class="c_indicator " [ngClass]="availability">&nbsp;</span>
        <span class="v_indicator">
          <img src="/assets/svg/veg.svg" alt="Veg" *ngIf="product.isVegetarian == true">
          <img src="/assets/svg/nonveg.svg" alt="Non-Veg" *ngIf="product.isVegetarian == false">
          <img src="/assets/svg/gray.svg" alt="Non-Veg" *ngIf="product.isVegetarian == '-'">
        </span>
        <span class="s_indicator" *ngIf="product.classification">
          <img src="/assets/svg/a.svg" matTooltip="ACE Product" alt="A"
            *ngIf="product.classification.toLowerCase() === 'ace'">
          <img src="/assets/svg/k.svg" matTooltip="King Product" alt="K"
            *ngIf="product.classification.toLowerCase() === 'king'">
          <img src="/assets/svg/q.svg" matTooltip="Queen Product" alt="Q"
            *ngIf="product.classification.toLowerCase() === 'queen'">
        </span>

        <div class="img">
          <div class="banner" *ngIf="product && product.isSlabBasedPricing && currentMargin && supplier">
            <span class="banner-span">{{text('prodCard.margin')}}: <span class="text-bold">
                {{currentMargin |
                number:'1.2-2'}} %</span></span>
          </div>
          <div class="banner" *ngIf="!product.isSlabBasedPricing && (((ratecard?.mrp/ratecard?.caseSize) -
          (ratecard?.bpclprice/ratecard?.caseSize))*100)/(ratecard?.bpclprice/ratecard?.caseSize) && supplier">
            <span class="banner-span">{{text('prodCard.margin')}}: <span class="text-bold">
                {{(((ratecard?.mrp/ratecard?.caseSize) -
                (ratecard?.bpclprice/ratecard?.caseSize))*100)/(ratecard?.bpclprice/ratecard?.caseSize) |
                number:'1.2-2'}} %</span></span>
          </div>
          <img *ngIf="supplier" [src]="product?._img" class="h-24 mx-auto" (click)="openProduct.emit(product)">
          <img *ngIf="!supplier" [src]="product?._img" class="h-24 mx-auto"
            (click)="emitAddToCart($event, product, ratecard)" />
        </div>

        <div class="flex flex-col justify-center h-24">
          <h3 *ngIf="!supplier" class="overflow-auto text-bold text-primary text-sm mt-2"
            (click)="openProduct.emit(product)">
            <div *ngIf="cs.language == 'en'">
              <span *ngIf="product?.posname; else noname" [matTooltip]="product?.posname">{{ product?.posname |
                ellipsis:30 }}</span>
              <ng-template #noname>
                <span [matTooltip]="product?.name">{{ product?.name |
                  ellipsis:30 }}</span>
              </ng-template>
            </div>
            <div *ngIf="cs.language == 'hi'">
              <span *ngIf="product?.posname; else noname" [matTooltip]="product?.posname_hi">{{
                (product?.posname_hi)?product?.posname_hi:product?.posname |
                ellipsis:30 }}</span>
              <ng-template #noname>
                <span [matTooltip]="product?.name">{{ product?.name_hi |
                  ellipsis:30 }}</span>
              </ng-template>
            </div>
          </h3>
          <!-- overflow-auto -->
          <h3 *ngIf="supplier" class=" text-bold text-primary text-sm" (click)="openProduct.emit(product)">
            <span *ngIf="cs.language == 'en'" [matTooltip]="product?.name">{{ product?.name |
              ellipsis:30 }}</span>
            <span *ngIf="cs.language == 'hi'" [matTooltip]="product?.name_hi">{{
              (product?.name_hi)?product?.name_hi:product?.name |
              ellipsis:30 }}</span>
            <!-- <span [matTooltip]="product?.name">{{product?.name | ellipsis:30 }}</span> -->
            <span>({{
              ratecard?.unitsize }}
              {{ cs.uom[ratecard?.unitsofmeasure] }})</span>
          </h3>
          <!-- py-2 -->
          <div class="text-primary flex flex-col items-center">
            <p class=" text-secondary text-xs py-2" [matTooltip]="product?.description">{{ product?.description |
              ellipsis:30 }}</p>
            <div matTooltip="Pricing Slab"
              class="text-xs p-0 mat-tooltip-trigger border-2 bg-green-50 rounded-md px-1 border-green-500 ng-star-inserted"
              *ngIf="supplier && product?.isSlabBasedPricing && product?.priceSlabs && product?.priceSlabs.length"
              (click)="emptyCard(true)">
              <span class="text-xs text-green-500">{{text('pos.bulkDealUpto')}} (<span class="text-bold">₹{{minPrice |
                  number:'1.2-2'}}</span>)</span>
            </div>
          </div>
        </div>
        <div class="flex flex-row items-center space-around  h-20">
          <div *ngIf="!supplier">
            <span class="price">₹{{ ratecard?.sprice/ratecard?.caseSize | number:'1.2-2' }}</span>
            <span class="striked_price">₹{{ ratecard?.mrp/ratecard?.caseSize | number:'1.2-2'}}</span>
          </div>
          <div *ngIf="supplier">
            <span class="price">₹{{ ratecard?.bpclprice/ratecard?.caseSize | number:'1.2-2'}}</span>
            <span class="striked_price">₹{{ ratecard?.mrp/ratecard?.caseSize | number:'1.2-2'}}</span>
          </div>
          <vex-quantity-toggle *ngIf="!supplier && !(!supplier && budBuy)" [supplier]="supplier"
            [input]="product.orderQuantity" (countChange)="updateOrderQ($event)">
          </vex-quantity-toggle>
          <vex-quantity-toggle *ngIf="supplier" [supplier]="supplier" [min]="ratecard?.minimumQuantity"
            [input]="ratecard?.minimumQuantity" [increment]="ratecard?.incQuantity" [max]="ratecard?.maxOrderLimit"
            (countChange)="updateOrderQ($event)">
          </vex-quantity-toggle>
          <vex-quantity-toggle *ngIf="budBuy && isCDIT" [supplier]="supplier" [min]="ratecard?.minimumQuantity"
            [input]="ratecard?.minimumQuantity" [increment]="ratecard?.incQuantity" [max]="ratecard?.maxOrderLimit"
            (countChange)="updateOrderQ($event)">
          </vex-quantity-toggle>
          <vex-quantity-toggle *ngIf="(!supplier && budBuy && !isCDIT)" [input]="product.orderQuantity"
            [max]="product.availableQuantity_Store" (countChange)="updateOrderQ($event)">
            <!--_Store-->
          </vex-quantity-toggle>
        </div>
        <div class="flex flex-row items-center space-around" *ngIf="supplier">
          <div>
            <span class="text-xs text-secondary">
              <!-- {{text('prodCard.MinQ')}} -->
              {{text('prodCard.MinQ')}} - {{ ratecard?.minimumQuantity |
              number:'1.2-2' }}
            </span>
          </div>
          <div>
            <span class="text-xs text-secondary">
              <!-- {{text('prodCard.IncQ')}}  -->
              {{text('prodCard.IncQ')}} - {{ ratecard?.incQuantity | number:'1.2-2'
              }}
            </span>
          </div>
        </div>
        <div class="flex flex-row items-center space-around" *ngIf="supplier">
          <span class="text-xs text-secondary">
            <!-- {{text('prodCard.MaxQ')}}  -->
            {{text('prodCard.MaxQ')}} - {{ ratecard?.maxOrderLimit | number:'1.2-2'
            }}
          </span>
        </div>
        <div class="flex flex-row items-center space-around" *ngIf="!supplier && budBuy && !isCDIT">
          <span class="text-xs text-secondary">
            {{text('prodCard.StorQ')}} - {{ product.availableQuantity_Store }}
          </span>
        </div>
        <div class="text-xs text-secondary">
          <div *ngIf="!supplier && budBuy && !isAdvOrder" class="text-green">
            <!-- {{text('prodCard.C')}}  -->
            {{text('prodCard.C')}}
            <span *ngIf="ratecard?.commissions[0]?.amounttype == 1; else regular">₹{{
              (((ratecard?.sprice /
              ratecard?.caseSize) -
              (ratecard?.bpclprice / ratecard?.caseSize)) * (ratecard?.commissions[0]?.amountvalue * 0.01)) |
              number:'1.2-2'}}</span>
            <ng-template #regular>₹{{ ((ratecard?.sprice / ratecard?.caseSize) - (ratecard?.bpclprice /
              ratecard?.caseSize))
              | number:'1.2-2'}}</ng-template>
          </div>
          <i class="text-xs text-secondary">
            ({{text('pos.incAllTax')}})
            <!-- Inclusive of all Taxes. -->
          </i>
        </div>
        <br />
        <div class="custom-flex cart_btn m-1">
          <button (click)="emitAddToCart($event, product, ratecard)" [disabled]="ratecard?.bpclprice < 0"
            class=" custom-flex  btn  text-white mb-0 mt-lg-auto w-100 shadow-none" type="button" name="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" class="bi bi-cart2"
              viewBox="0 0 16 16">
              <path
                d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z">
              </path>
            </svg>
            <!-- Add to cart -->
            {{text('prodCard.aTC')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *transloco="let text">
  <div class="outer-card" *ngIf="makeEmpty">
    <div class="card br15">
      <div class="px-2 py-4 br15 text-center hover:bg-hover trans-ease-out cursor-pointer" style="position: relative;"
        [ngClass]="{'out-of-stock': ((!supplier && !budBuy) && product.availableQuantity < 1)  ||( supplier && (product?.ratecard?.minimumQuantity < 1))}">
        <!--|| ((!supplier && budBuy) && product.availableQuantity_Store < 1)-->
        <span class="c_indicator_close">
          <mat-icon [icIcon]="ic.icClose" (click)="emptyCard(false)"></mat-icon>
        </span>
        <div class="card-height flex flex-col justify-center">
          <h3 *ngIf="supplier" class="py-2 text-bold text-primary text-sm" (click)="openProduct.emit(product)">
            <span [matTooltip]="product?.name">{{
              product?.name | ellipsis:30 }}</span> <span>({{
              ratecard?.unitsize }}
              {{ cs.uom[ratecard?.unitsofmeasure] }})</span>
          </h3>
          <div class="flex py-2 justify-around align-center">
            <span class="text-sm text-bold text-primary text-base uppercase my-3">Pricing Slabs</span>
            <div class="margin border-2 border-green-200 bg-green-50 tex-green-500">
              <span class="text-xs px-1 text-bold text-green-500">Margin: {{currentMargin | number:'1.2-2'}} %</span>
            </div>
          </div>

          <div class="scroll-table">
            <table class="table table-striped">
              <tr>
                <th>Price</th>
                <th>Min.</th>
                <th>Max.</th>
                <th class="text-green">Margin</th>
              </tr>
              <tbody>
                <tr *ngFor="let slab of product.priceSlabs">
                  <td>
                    <div>
                      <span class="price">₹{{ slab?._offerPrice | number:'1.2-2' }}</span>
                      <span class="striked_price">₹{{ slab?.mrp | number:'1.2-2' }}</span>
                    </div>
                  </td>
                  <td>{{ slab?.min | number:'1.2-2' }}</td>
                  <td>{{ slab?.max | number:'1.2-2' }}</td>
                  <td class="text-green text-bold">{{ slab?._discount | number:'1.2-2' }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="flex flex-row items-center space-around h-20">
          <div *ngIf="!supplier">
            <span class="price">₹{{ ratecard?.sprice/ratecard?.caseSize | number:'1.2-2' }}</span>
            <span class="striked_price">₹{{ ratecard?.mrp/ratecard?.caseSize | number:'1.2-2'}}</span>
          </div>
          <div *ngIf="supplier">
            <span class="price">₹{{ ratecard?.bpclprice/ratecard?.caseSize | number:'1.2-2'}}</span>
            <span class="striked_price">₹{{ ratecard?.mrp/ratecard?.caseSize | number:'1.2-2'}}</span>
          </div>
          <vex-quantity-toggle *ngIf="!supplier && !(!supplier && budBuy)" [supplier]="supplier"
            [input]="product.orderQuantity" (countChange)="updateOrderQ($event)">
          </vex-quantity-toggle>
          <vex-quantity-toggle *ngIf="supplier" [supplier]="supplier" [min]="ratecard?.minimumQuantity"
            [input]="ratecard?.minimumQuantity" [increment]="ratecard?.incQuantity" [max]="ratecard?.maxOrderLimit"
            (countChange)="updateOrderQ($event)">
          </vex-quantity-toggle>
          <vex-quantity-toggle *ngIf="(!supplier && budBuy)" [input]="product.orderQuantity"
            [max]="product.availableQuantity" (countChange)="updateOrderQ($event)">
            <!--_Store-->
          </vex-quantity-toggle>
        </div>
        <br />
        <div class="custom-flex cart_btn m-1">
          <button (click)="emitAddToCart($event, product, ratecard)" [disabled]="ratecard?.bpclprice < 0"
            class=" custom-flex  btn  text-white mb-0 mt-lg-auto w-100 shadow-none" type="button" name="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" class="bi bi-cart2"
              viewBox="0 0 16 16">
              <path
                d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z">
              </path>
            </svg>
            <!-- Add to cart -->
            {{text('prodCard.aTC')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>