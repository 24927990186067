<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col"
>
  <div class="sidenav-toolbar flex flex-none item-center justify-center">
    <img
      style="width: 80%"
      [src]="imageUrl$ | async"
      matTooltip="Toggle sidebar"
      (click)="navigateHome()"
      class="w-6 logo-img select-none flex-none pointer"
    />
    <!-- <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">{{ title$ | async }}</h2> -->
    <!-- <button 
            *ngIf="showCollapsePin$ | async"
            class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
            mat-icon-button
            type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button> -->
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <ng-container *ngFor="let item of items; trackBy: trackByRoute">
        <vex-sidenav-item
          *ngIf="item.isVisible"
          [item]="item"
          [level]="0"
        ></vex-sidenav-item>
      </ng-container>
      <!--<vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>-->
    </div>
  </vex-scrollbar>

  <!-- <div class="px-4 my-6">
    <div class="min-w-0 mx-4 p-4 text-white bg-purple-600 rounded-lg shadow-xs">
      
      <div class="mb-5 mx-auto">                          
          <svg class="h-16 mx-auto" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"></path>
          </svg>
      </div>
  
      <button class="flex items-center mx-auto justify-between px-4 py-2 text-sm font-medium leading-5 text-black transition-colors duration-150 bg-white border border-transparent rounded-full active:bg-tan-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"> <span>Get Help</span>
      </button>
    </div>
  </div> -->
</div>
