<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav();" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>
  <button matTooltip="Close Sidenav" (click)="closeSidenav();" mat-icon-button type="button">
    <mat-icon [icIcon]="icBallot"></mat-icon>
  </button>
  <!--[fxHide]="!mobileQuery"-->
  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block" fxLayout="row"
    fxLayoutAlign="start center">
    <img class="w-8 select-none" src="assets/img/demo/logo.svg" />
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">NFR</h1>
  </a>

  <!-- <button *ngIf="isHorizontalLayout$ | async"
          [fxHide]="mobileQuery"
          [matMenuTriggerFor]="addNewMenu"
          color="primary"
          mat-raised-button
          type="button">ADD NEW
  </button>

  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <mat-icon [icIcon]="icPersonAdd"></mat-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icDoneAll"></mat-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icAssignmentTurnedIn"></mat-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon [icIcon]="icBallot"></mat-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon [icIcon]="icDescription"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icAssignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon [icIcon]="icReceipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>

  <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async"
            [fxHide]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            color="primary"
            mat-button
            type="button">MEGA MENU
      <ic-icon [icon]="icArrowDropDown" class="ltr:-mr-1 rtl:-ml-1" inline="true"></ic-icon>
    </button>
  </div>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [fxHide]="mobileQuery"
       class="px-gutter"
       fxFlex="none"
       fxLayout="row"
       fxLayoutAlign="start center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div> -->

  <div class="flex" style="align-items: center;">
    <div *ngIf="!isConsumer">
      <div *ngIf="!isBudc && !isAdmin; else inOutStore"
        class=" w-100 flex flex-col justify-center text-gray-700 dark:text-gray-200">
        <b>{{ shopname }}</b>
        <span *ngIf="location && !isAdmin" class="text-secondary text-xs" [matTooltip]="location">{{ location |
          ellipsis:30 }}, {{ region }}</span>
      </div>
      <ng-template #inOutStore>
        <div class="flex flex-col justify-center text-gray-700 dark:text-gray-200">
          <b>{{ shopname }}</b>
          <span *ngIf="location && region !== '*'" [matTooltip]="location" class="text-secondary text-xs">{{ location|
            ellipsis:30 }}, {{ region }}</span>
        </div>
      </ng-template>
    </div>
    <!-- <button class="ltr:ml-1 rtl:mr-1" routerLink="/" color="primary" fxFlex="none" mat-button matTooltip="home"
      type="button">
      <mat-icon [icIcon]="ic.icHome"></mat-icon>
    </button> -->
    <div class="location" *ngIf="isConsumer">
      <div>
        <mat-icon [icIcon]="icLocationCity"></mat-icon>
        <span class="address" *ngIf="cs.currentCustLocation.pincode && !mobileQuery">
          <b>{{cs.currentCustLocation.pincode}}</b>
          <span> -</span>
          <span> {{cs.currentCustLocation.name}}</span>
        </span>
        <span class=" ml-2 address" *ngIf="cs.currentCustLocation.pincode && mobileQuery">
          <b>{{cs.currentCustLocation.pincode}}</b>
          <span class="text-xs"> {{cs.currentCustLocation.name}}</span>
        </span>
      </div>
      <button type="button" (click)="callLocationModal()" mat-icon-button>
        <mat-icon [icIcon]="icArrowDropDown"></mat-icon>
      </button>
    </div>
  </div>
  <span fxFlex></span>
  <div class="-mx-1 flex items-center">
    <!--<div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon [icIcon]="icSearch"></mat-icon>
      </button>
    </div>-->

    <div class="px-1 sm:hidden" fxHide.xs>
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>

    <div class="px-1 sm:hidden xs:hidden" fxHide.xs>
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon [icIcon]="icBookmarks"></mat-icon>
      </button>
    </div>
    <!-- isBud -->
    <div class="px-1" *ngIf="!isAdmin">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button"
        class="flex justify-center items-center space-between">
        <img class="icon-img" [src]='path' alt="Eng" class="icon-img ml-1" height="28px" width="28px">
      </button>
    </div>

    <div class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="selectLanguage('en')" class="flex items-center space-between">
        <div><img src="../../../assets/en.svg" alt="Eng"></div>
        <span>English</span>
      </button>
      <button mat-menu-item (click)="selectLanguage('hi')" class="flex items-center space-between">
        <div><img src="../../../assets/hi.svg" alt="Eng"></div>
        <span>Hindi</span>
      </button>

      <!--<button mat-menu-item>
        <mat-icon [icIcon]="emojioneDE"></mat-icon>
        <span>German</span>
      </button>-->
    </mat-menu>
  </div>
</div>

<!--
<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [fxHide]="mobileQuery">
</vex-navigation>-->