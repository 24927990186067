import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { LayoutService } from "../../services/layout.service";
import icBookmarks from "@iconify/icons-ic/twotone-bookmarks";
import emojioneUS from "@iconify/icons-emojione/flag-for-flag-united-states";
import emojioneDE from "@iconify/icons-emojione/flag-for-flag-germany";
import emojioneIN from "@iconify/icons-emojione/flag-for-flag-india";
import icMenu from "@iconify/icons-ic/twotone-menu";
import { ConfigService } from "../../services/config.service";
import { map } from "rxjs/operators";
import icPersonAdd from "@iconify/icons-ic/twotone-person-add";
import icAssignmentTurnedIn from "@iconify/icons-ic/twotone-assignment-turned-in";
import icBallot from "@iconify/icons-ic/twotone-ballot";
import icDescription from "@iconify/icons-ic/twotone-description";
import icAssignment from "@iconify/icons-ic/twotone-assignment";
import icReceipt from "@iconify/icons-ic/twotone-receipt";
import icDoneAll from "@iconify/icons-ic/twotone-done-all";
import { NavigationService } from "../../services/navigation.service";
import icArrowDropDown from "@iconify/icons-ic/twotone-arrow-drop-down";
import { PopoverService } from "../../components/popover/popover.service";
import { MegaMenuComponent } from "../../components/mega-menu/mega-menu.component";
import icSearch from "@iconify/icons-ic/twotone-search";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { IconService } from "src/app/_services/icon.service";
import { CommonService } from '../../../app/_services/common.services';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import { TranslocoService } from '@ngneat/transloco';
import { CustomTranslateService } from '../../../app/_services/custom-translate.service';


@Component({
  selector: "vex-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit {
  @Input() mobileQuery: boolean;
  @Input()
  @HostBinding("class.shadow-b")
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(
    map((config) => config.layout === "horizontal")
  );
  isVerticalLayout$ = this.configService.config$.pipe(
    map((config) => config.layout === "vertical")
  );
  isNavbarInToolbar$ = this.configService.config$.pipe(
    map((config) => config.navbar.position === "in-toolbar")
  );
  isNavbarBelowToolbar$ = this.configService.config$.pipe(
    map((config) => config.navbar.position === "below-toolbar")
  );

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  icLocationCity = icLocationCity;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  emojioneIN = emojioneIN;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;
  username: string;
  location: string;
  isBudc = false;
  isAdmin = false;
  isConsumer: boolean = false;
  region: string;
  userType: string;
  shopname: string;
  isBud: boolean = false;
  path: string = '../../../assets/en.svg';
  collapsed = false;
  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    private authService: AuthenticationService,
    public ic: IconService,
    public cs: CommonService,
    public translateService: TranslocoService,
    public customTranslateService: CustomTranslateService
  ) {
    // this.cs.newNavigationMenuObservable.subscribe((res) => {
    //   console.log("new navigation list")
    // })
  }

  ngOnInit() {
    if (this.authService.isConsumer()) {
      this.isConsumer = true;
    }
    if (this.authService.currentUser.usertype === "BUDC") {
      this.isBudc = true;
    }
    if (this.authService.currentUser.usertype.toLowerCase() === "admin") {
      this.isAdmin = true;
    }
    if (this.authService.currentUser.usertype === 'BUD') {
      this.isBud = true;
    }
    setTimeout(() => {
      this.username = this.authService.currentUser.given_name;
      this.location = this.authService.currentEntity.state;
      this.region = this.authService.currentEntity.region;
      this.userType = this.authService.currentUser.usertype;
      this.shopname = (this.isAdmin) ? `${this.authService.currentUser.given_name} ${this.authService.currentUser.family_name}` : this.authService.currentEntity.name;
    }, 2000);
  }

  toggleCollapse() {
    this.collapsed
      ? this.layoutService.expandSidenav()
      : this.layoutService.collapseSidenav();
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  closeSidenav() {
    this.layoutService.closeSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: "start",
          originY: "bottom",
          overlayX: "start",
          overlayY: "top",
        },
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ],
    });
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  callLocationModal(): void {
    this.cs.changecustomerLocationModalChange() //call the emitter to emit values
  }
  selectLanguage(lang) {
    this.cs.changeLang.next(lang)
    console.log('lang', lang);
    this.cs.language = lang;
    this.path = `../../../assets/${lang}.svg`;
    this.customTranslateService.loadTranslations(lang);
    this.customTranslateService.translationLoadedSubject.next(true);
    this.translateService.setActiveLang(lang);
  }
}
