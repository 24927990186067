<ng-container *transloco="let text">
    <vex-page-layout>
        <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start" *ngIf="currentEntity">
            <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                class="w-full flex sm:flex-row justify-between">
                <button class=" h-10 mt-4" mat-raised-button type="submit" color="primary"
                    (click)="transactionHistoryaddTableData()"
                    *ngIf="authService.currentUser.usertype.toLowerCase() === 'budc'"> Receive Payment</button>
                <div class="spacer"></div>
                <div class="card mat-elevation-z4 mr-4 p-2"
                    *ngIf="currentEntity.creditused || currentEntity.creditused === 0">
                    <div class="flex flex-col justify-center">
                        <span>
                            <h2 class="text-primary">₹{{ currentEntity.creditlimit - currentEntity.creditused |
                                number:'1.0-2' }}</h2>
                        </span>
                        <span class="text-secondary">{{text('bud-agent.ac')}}</span>
                    </div>
                </div>
                <div class="card mat-elevation-z4 mr-4 p-2"
                    *ngIf="currentEntity.creditused || currentEntity.creditused === 0">
                    <div class="flex flex-col justify-center">
                        <span>
                            <h2 class="text-primary">₹{{ currentEntity.creditused | number:'1.0-2' }}</h2>
                        </span>
                        <span class="text-secondary">{{text('bud-agent.cC')}}</span>
                    </div>
                </div>
            </div>
        </vex-page-layout-header>
        <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start" *ngIf="!currentEntity">
            <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                class="w-full flex sm:flex-row justify-between">
                <div>
                    <h1 class="title mt-0 mb-1">{{text('iosAdmin.tranHis')}}</h1>
                    <vex-breadcrumbs [crumbs]="['Orders', 'UD Transaction History']"></vex-breadcrumbs>
                </div>
            </div>
        </vex-page-layout-header>
        <!---->
        <vex-page-layout-content [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
            <div class="card overflow-auto -mt-16">
                <app-s-table aria-label="Transaction History" role="table"
                    [sTableOptions]="transactionHistoryTableOptions" (sortCallback)="transactionHistorysortCall($event)"
                    (filterCallback)="transactionHistoryfilterCall($event)"
                    (actionCallback)="transactionHistoryactionCall($event)"
                    (pageCallback)="transactionHistorypageCall($event)" (refreshCallback)="getTransactionHistory()"
                    (addCallback)="transactionHistoryaddTableData()"
                    (timerCallback)="transactionHistorytimerCallData($event)"></app-s-table>
            </div>
        </vex-page-layout-content>
    </vex-page-layout>
</ng-container>
<s-modal id="receivePayment">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
            <h2 class="subheading-2 m-0" fxFlex="auto" *ngIf="currentEntity">Receive Payment From {{currentEntity.name}}
            </h2>
            <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
                (click)="modalService.close('receivePayment')">
                <mat-icon [icIcon]="ic.icClose"></mat-icon>
            </button>
        </div>
        <mat-divider class="text-border"></mat-divider>
        <form #receivePaymentForm="ngForm">
            <mat-card-content class="p-3" *ngIf="currentTransactionHistory">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field class="flex-auto">
                        <mat-label>Enter Amount</mat-label>
                        <input matInput name="amount" [(ngModel)]="currentTransactionHistory.amount" type="number"
                            min="0" required placeholder="Enter Amount" />
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <button *ngIf="!otpSent" (click)="requestOTP()" class="mat-primary" mat-raised-button>Send
                        OTP</button>
                    <mat-form-field class="flex-auto" *ngIf="currentTransactionHistory.amount && otpSent">
                        <mat-label>Enter OTP</mat-label>
                        <input matInput name="otp" [(ngModel)]="currentTransactionHistory.otp" type="number"
                            minLength="6" maxlength="6" required placeholder="Enter OTP" />
                    </mat-form-field>
                </div>
                <button *ngIf="isResendOTP" (click)="requestOTP()" mat-outline-button class="mat-primary" type="button">
                    RESEND OTP
                </button>
            </mat-card-content>
            <mat-card-actions align="end" *ngIf="currentTransactionHistory">
                <button *ngIf="currentTransactionHistory" (click)="paymentReceived(currentTransactionHistory)"
                    [disabled]="!receivePaymentForm.form.valid || isLoading" mat-raised-button class="mat-primary"
                    type="submit">
                    SUBMIT
                </button>
                <button mat-button mat-dialog-close type="button" (click)="modalService.close('receivePayment')">
                    CLOSE
                </button>
            </mat-card-actions>
        </form>
    </mat-card>
</s-modal>