import { Directive, HostListener, ElementRef } from '@angular/core';
import { MyToastrService } from '../_services/toastr.service';

@Directive({
  selector: '[appInvoiceNumberCheck]'
})
export class InvoiceNumberCheckDirective {

  constructor(private el: ElementRef,
    private toast: MyToastrService
  ) {
  }

  // tslint:disable-next-line:max-line-length
  INV_CHK = new RegExp(/^[a-zA-Z0-9/-]{1,16}$/);

  @HostListener('focusout')
  onFocusOut() {
    const current: string = this.el.nativeElement.value.trim();
    if (current.length) {
      if (current !== '') {
        if (current && !String(current).match(this.INV_CHK)) {
          this.toast.sToast('error', "Invoice Number contains invalid characters. Allowed Characters are '-', '/', 'A-Z', 'a-z', '0-9'. Number of Characters should be upto 16.");
          this.el.nativeElement.focus();
        }
      }
    } else {
      this.toast.sToast('error', "Invoice Number contains invalid characters. Allowed Characters are '-', '/', 'A-Z', 'a-z', '0-9'. Number of Characters should be upto 16.");
      this.el.nativeElement.focus();
    }

  }
}
