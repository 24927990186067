import { Directive, HostListener, ElementRef } from '@angular/core';
import { MyToastrService } from '../_services/toastr.service';

@Directive({
  selector: '[appGSTCheck]'
})
export class GSTCheckDirective {

  constructor(private el: ElementRef,
    private toast: MyToastrService
  ) {
  }

  // tslint:disable-next-line:max-line-length
  GST_REGX = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z1-9]{1}[A-Z0-9]{1}$/);
  NUMBER_REGX = new RegExp(/^[0-9]$/);
  APLHA_REGX = new RegExp(/^[A-Z]$/);

  @HostListener('focusout')
  onFocusOut() {
    const current: string = this.el.nativeElement.value.trim();
    if (current !== '') {
      if (current && !String(current).match(this.GST_REGX)) {
        this.toast.sToast('error', 'Invalid GST');
        // console.log("Bad GST");
        this.el.nativeElement.focus();
      }
      if (current && String(current).match(this.GST_REGX)) {
        const length: number = current.length;
        let codeA: number = 'A'.charCodeAt(0);
        let currentCheckSumValue = current[length - 1];
        let currentGST: String = current.substring(0, length - 1)
        let currentCheckSumCode: number = -1; //possible error area
        if (isNaN(parseInt(currentCheckSumValue))) { //got an alphabet
          currentCheckSumCode = currentCheckSumValue.charCodeAt(0) - codeA + 10;
        } else { //got a number
          currentCheckSumCode = parseInt(currentCheckSumValue)
        }
        let sum: number = 0
        let code: number = 0;
        let product: number;
        let quotient: number;
        let remainder: number;
        let hash: number;
        let finalRemainder: number;
        let calculatedCheckSumCode: number;
        for (var i = 0; i < length - 1; i++) {
          let multiplier: number = (i % 2 == 0) ? 1 : 2;
          if (currentGST[i].match(this.NUMBER_REGX)) {
            code = parseInt(currentGST[i])
          } else if (currentGST[i].match(this.APLHA_REGX)) {
            if (currentGST[i] == 'A') {
              code = 10
            } else {
              code = currentGST[i].charCodeAt(0) - codeA + 10
            }
          }
          product = code * multiplier;
          quotient = Math.floor(product / 36);
          remainder = product % 36;
          hash = quotient + remainder;
          sum += hash;
        }
        finalRemainder = sum % 36;
        calculatedCheckSumCode = Math.abs(36 - finalRemainder)
        if (calculatedCheckSumCode !== 36 || currentCheckSumCode !== 0) {
          if (calculatedCheckSumCode != currentCheckSumCode) {
            this.toast.sToast('error', 'Invalid GST Checksum');
            this.el.nativeElement.focus();
          }
        }
      }
    }
  }
}
