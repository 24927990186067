import { Injectable } from '@angular/core';
import { ICON_REGISTRY_PROVIDER_FACTORY } from '@angular/material/icon';
import icVerticalSplit from '@iconify/icons-ic/twotone-vertical-split';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import icDragHandle from '@iconify/icons-ic/twotone-drag-handle';
import icDashboard from '@iconify/icons-ic/twotone-dashboard';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icFileUpload from '@iconify/icons-ic/baseline-file-upload';
import icDriveFolderUpload from '@iconify/icons-ic/outline-drive-folder-upload';
import icCamera from '@iconify/icons-ic/outline-camera';
import icSave from '@iconify/icons-ic/outline-save';
import icEventAvailable from '@iconify/icons-ic/twotone-event-available';
import icEventBusy from '@iconify/icons-ic/twotone-event-busy';
import icCard from '@iconify/icons-ic/outline-credit-card';
import icBlock from '@iconify/icons-ic/twotone-block';
import icHelp from '@iconify/icons-ic/twotone-help';
import icHome from '@iconify/icons-ic/twotone-home';
import icVpnKey from '@iconify/icons-ic/twotone-vpn-key';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icShoppingCart from '@iconify/icons-ic/twotone-shopping-cart';
import icAddShoppingCart from '@iconify/icons-ic/twotone-add-shopping-cart';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icMail from '@iconify/icons-ic/twotone-mail';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icAdd from '@iconify/icons-ic/twotone-add';
import icMinus from '@iconify/icons-ic/twotone-minus';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icWhatshot from '@iconify/icons-ic/twotone-whatshot';
import icWork from '@iconify/icons-ic/twotone-work';
import icTitle from '@iconify/icons-ic/twotone-title';
import icFile from '@iconify/icons-ic/twotone-file-copy';
import icOffice from '@iconify/icons-ic/twotone-local-post-office';
import icInfo from '@iconify/icons-ic/twotone-info';
import icRoom from '@iconify/icons-ic/twotone-room';
import icExpired from '@iconify/icons-ic/twotone-expand-circle-down';
import icAddress from '@iconify/icons-ic/twotone-format-indent-decrease';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icCheck from '@iconify/icons-ic/twotone-check';
import icidentity from '@iconify/icons-ic/twotone-perm-identity';
import icGender from '@iconify/icons-ic/round-transgender';
import icType from '@iconify/icons-ic/outline-credit-card';
import icId from '@iconify/icons-ic/twotone-credit-card';
import icCalender from '@iconify/icons-ic/twotone-date-range';
import icEarth from '@iconify/icons-fa-solid/globe-asia';
import icFlag from '@iconify/icons-ic/twotone-flag';
import icDescription from '@iconify/icons-ic/twotone-description';
import icContacts from '@iconify/icons-ic/twotone-contacts';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import icSearch from '@iconify/icons-ic/twotone-search';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icChat from '@iconify/icons-ic/twotone-chat';
import icStar from '@iconify/icons-ic/twotone-star';
import icStarBorder from '@iconify/icons-ic/twotone-star-border';
import icBarcode from '@iconify/icons-ic/twotone-barcode';
import icOutlineMinus from '@iconify/icons-ic/outline-minus';
import icOutlineAdd from '@iconify/icons-ic/add-circle';

@Injectable({
  providedIn: 'root'
})

export class IconService {
  public icSave = icSave;
  public icMoreVert = icMoreVert;
  public icVerticalSplit = icVerticalSplit;
  public icVisibility = icVisibility;
  public icVisibilityOff = icVisibilityOff;
  public icDoneAll = icDoneAll;
  public icDriveFolderUpload = icDriveFolderUpload;
  public icFileUpload = icFileUpload;
  public icCamera = icCamera;
  public icDashboard = icDashboard;
  public icVpnKey = icVpnKey;
  public icCard = icCard;
  public icBlock = icBlock;
  public icHelp = icHelp;
  public icHome = icHome;
  public icClose = icClose;
  public icPrint = icPrint;
  public icDownload = icDownload;
  public icDelete = icDelete;
  public icPhone = icPhone;
  public icPerson = icPerson;
  public icMyLocation = icMyLocation;
  public icLocationCity = icLocationCity;
  public icEditLocation = icEditLocation;
  public icMail = icMail;
  public icAccessTime = icAccessTime;
  public icAdd = icAdd;
  public icEdit = icEdit;
  public icWhatshot = icWhatshot;
  public icWork = icWork;
  public icTitle = icTitle;
  public icFile = icFile;
  public icShoppingCart = icShoppingCart;
  public icAddShoppingCart = icAddShoppingCart;
  public icOffice = icOffice;
  public icInfo = icInfo;
  public icRoom = icRoom;
  public icDragHandle = icDragHandle;
  public icExpired = icExpired;
  public icAddress = icAddress;
  public icPersonAdd = icPersonAdd;
  public icCheck = icCheck;
  public icidentity = icidentity;
  public icGender = icGender;
  public icType = icType;
  public icId = icId;
  public icCalender = icCalender;
  public icEventAvailable = icEventAvailable;
  public icEventBusy = icEventBusy;
  public icEarth = icEarth;
  public icFlag = icFlag;
  public icDescription = icDescription;
  public icSearch = icSearch;
  public icCloudDownload = icCloudDownload;
  public icFilterList = icFilterList;
  public icContacts = icContacts;
  public icBusiness = icBusiness;
  public icChat = icChat;
  public icStar = icStar;
  public icStarBorder = icStarBorder;
  public icBarcode = icBarcode;
  public icOutlineAdd = icOutlineAdd;
  public icOutlineMinus = icOutlineMinus;
  public icMinus = icMinus;
  constructor() { }
}
