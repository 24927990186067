export enum ConfigName {
  apollo = 'vex-layout-apollo',
  zeus = 'vex-layout-zeus',
  hermes = 'vex-layout-hermes',
  poseidon = 'vex-layout-poseidon',
  ares = 'vex-layout-ares',
  ikaros = 'vex-layout-ikaros',
  zubarah = 'vex-layout-apollo',
  alkhor = 'vex-layout-hermes',
  poseidons = 'vex-layout-poseidon',
  albidda = 'vex-layout-ares',
  alwakrah = 'vex-layout-zeus',
  alwajbah = 'vex-layout-ikaros',
}
