<s-modal id="successpayment">
  <form #successpayment="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0">
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <div class="mat-title">Payment Success!</div>
        <div class="flex flex-row items-center justify-center text-center">
          <img src="/assets/tick.svg" alt="Success" class="w-16">
        </div>
        <mat-card-content class="">
          <h3><span class="text-secondary">Transaction ID</span> #{{transactionId}}.</h3>
          <p>Redirecting...</p>
        </mat-card-content>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
