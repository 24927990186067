import { AutofocusDirective } from './autofocus.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailCheckDirective } from './email-check.directive';
import { PasswordCheckDirective } from './password-check.directive';
import { NameCheckDirective } from './name-check.directive';
import { UrlCheckDirective } from './url-check.directive';
import { LazScrollDirective } from './laz-scroll.directive';
import { GSTCheckDirective } from './gst-check.directive';
import { MobileCheckDirective } from './mobile-check.directive';
import { PanNumberCheckDirective } from './pan-number-check.direcive';
import { QtyNumberDirective } from './qty-number.direcive';
import { CINCheckDirective } from './cin-check.directive';
import { NumericDirective } from './numeric-only.directive';
import { CompanyNameCheckDirective } from './company-name-check.directive';
import { ZeroCheckDirective } from './zero-check.directive';
import { InvoiceNumberCheckDirective } from './invoice-no-check.directive';
import { DigitOnlyDirective } from './digit-only.directive';

@NgModule({
  declarations: [EmailCheckDirective, PasswordCheckDirective, NameCheckDirective,
    UrlCheckDirective, AutofocusDirective, LazScrollDirective, GSTCheckDirective, DigitOnlyDirective,
    MobileCheckDirective, PanNumberCheckDirective, QtyNumberDirective, CINCheckDirective, NumericDirective, 
    CompanyNameCheckDirective, ZeroCheckDirective, InvoiceNumberCheckDirective],
  imports: [
    CommonModule
  ],
  exports: [EmailCheckDirective, PasswordCheckDirective, NameCheckDirective, PanNumberCheckDirective,
    QtyNumberDirective, DigitOnlyDirective,
    UrlCheckDirective, AutofocusDirective, LazScrollDirective, GSTCheckDirective, MobileCheckDirective, CINCheckDirective, 
    NumericDirective, CompanyNameCheckDirective, ZeroCheckDirective, InvoiceNumberCheckDirective]
})
export class DirectivesModule {
}
