import { StaticFormGeneratorService } from '../../_services/static-form-generator.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseRequestService } from '../../_services/base.service';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { MyToastrService } from '../../_services/toastr.service';
import { LoaderService } from '../../_services/loader.service';
import { IconService } from '../../_services/icon.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { CommonService } from '../../_services/common.services';
import { AuthenticationService } from '../../_services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Component({
    selector: 'vex-suppliers',
    templateUrl: './suppliers.component.html',
    styleUrls: ['./suppliers.component.scss'],
})
export class SuppliersComponent implements OnInit {
    @Input() currentCompany: any;
    @Output() closeCallback = new EventEmitter();
    pincodeSearchInput: Subject<string> = new Subject();
    layoutCtrl = new FormControl('boxed');
    supplierTableOptions: any;
    suppliercurrentPage = 0;
    supplierfilterQuery: any;
    colFilterQuery: any;
    colFilterCols: any = [];
    integrationList: any = [];
    integrationHash: any = {};
    Objectkeys = Object.keys;
    isLoading = false;
    showSubSupplier = false;
    regionStates: any;
    region: any = [];
    state: any = [];
    bView = 'supplier';
    companyList: any = [];
    companyHash: any = {};
    allStates: any;
    selectedStates: any = [];
    docproof: any = [];
    currentSupplier: any = {
        name: '',
        gstIn: '',
        region: [],
        state: [],
        district: '',
        pinCodes: [],
        SAPCode: '',
        BPCLInvoicingParty: '',
        primaryContact: {
            contact: '',
            name: '',
            email: ''
        },
        secondaryContact: {
            contact: '',
            name: '',
            email: ''
        },
        address: {
            state: '',
            region: '',
            pincode: '',
            address: ['', ''],
            landmark: ''
        },
        supplierType: 1,
        company_ref: {
            id: '',
            name: ''
        },
        integration_ref: {
            id: '',
            name: ''
        },
        minOrderValue: 0
    };
    prodMaster: any = {};
    constructor(
        public baseService: BaseRequestService,
        public modalService: ModalService,
        private router: Router,
        public toast: MyToastrService,
        private loaderService: LoaderService,
        public ic: IconService,
        public confirmDialog: ConfirmDialogService,
        public cs: CommonService,
        public authService: AuthenticationService,
        public sf: StaticFormGeneratorService,
        private httpClient: HttpClient
    ) {
        this.supplierTableOptions = {
            faClass: 'Supplier',
            customCols: true,
            serverSide: {
                service: '',
                fn: '',
                q: {
                    query: { bool: { must: [{ exists: { field: 'name' } }] } },
                },
                sort: [{}],
            },
            columns: [
                {
                    header: 'Name',
                    columnDef: 'name',
                    filter: '',
                    cell: '(element: any) => `${element.name}`',
                    order: 0,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'State',
                    columnDef: 'state',
                    filter: '',
                    cell: '(element: any) => `${element.state}`',
                    order: 1,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'Region',
                    columnDef: 'region',
                    filter: '',
                    cell: '(element: any) => `${element.region}`',
                    order: 2,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'Pincodes',
                    columnDef: 'pinCodes',
                    filter: '',
                    cell: '(element: any) => `${element.pinCodes}`',
                    order: 3,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'GSTIN',
                    columnDef: 'gstIn',
                    filter: '',
                    cell: '(element: any) => `${element.gstIn}`',
                    order: 3,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'Distributor Type',
                    columnDef: 'stype',
                    filter: '',
                    cell: '(element: any) => `${element.stype}`',
                    order: 5,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'Integration',
                    columnDef: 'integration_ref.name',
                    filter: '',
                    cell: '(element: any) => `${element.integration_ref.name}`',
                    order: 5,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'State',
                    columnDef: 'address.state',
                    filter: '',
                    cell: '(element: any) => `${element.address.state}`',
                    order: 2,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Region',
                    columnDef: 'address.region',
                    filter: '',
                    cell: '(element: any) => `${element.address.region}`',
                    order: 3,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Pincode',
                    columnDef: 'address.pincode',
                    filter: '',
                    cell: '(element: any) => `${element.address.pincode}`',
                    order: 4,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Address',
                    columnDef: 'address.address.address',
                    filter: '',
                    cell: '(element: any) => `${element.address.address}`',
                    order: 5,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Landmark',
                    columnDef: 'address.landmark',
                    filter: '',
                    cell: '(element: any) => `${element.address.landmark}`',
                    order: 6,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Primary Contact',
                    columnDef: 'primaryContact.contact',
                    filter: '',
                    cell: '(element: any) => `${element.primaryContact.contact}`',
                    order: 7,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Primary Contact Name',
                    columnDef: 'primaryContact.name',
                    filter: '',
                    cell: '(element: any) => `${element.primaryContact.name}`',
                    order: 8,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Primary Contact Email',
                    columnDef: 'primaryContact.email',
                    filter: '',
                    cell: '(element: any) => `${element.primaryContact.email}`',
                    order: 9,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Secondary Contact',
                    columnDef: 'secondaryContact.contact',
                    filter: '',
                    cell: '(element: any) => `${element.secondaryContact.contact}`',
                    order: 10,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Secondary Contact Name',
                    columnDef: 'secondaryContact.name',
                    filter: '',
                    cell: '(element: any) => `${element.secondaryContact.name}`',
                    order: 11,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                }, {
                    header: 'Secondary Contact Email',
                    columnDef: 'secondaryContact.email',
                    filter: '',
                    cell: '(element: any) => `${element.secondaryContact.email}`',
                    order: 12,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: false,
                    iscolumnSearch: false
                },
                {
                    header: 'Date Created',
                    columnDef: 'c',
                    filter: 'utcToLocale',
                    cell: '(element: any) => `${element.c}`',
                    order: 11,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'Date Last Modified',
                    columnDef: 'u',
                    filter: 'utcToLocale',
                    cell: '(element: any) => `${element.u}`',
                    order: 11,
                    visible: true,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'ID',
                    columnDef: '_id',
                    filter: '',
                    cell: '(element: any) => `${element._id}`',
                    order: 11,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
                {
                    header: 'SAP Code',
                    columnDef: 'SAPCode',
                    filter: '',
                    cell: '(element: any) => `${element.SAPCode}`',
                    order: 11,
                    visible: false,
                    isToolTip: false,
                    isToolTipCol: '',
                    hasMultiData: false,
                    class: '',
                    color: '',
                    isProgressCntrl: false,
                    isColoredCntrl: false,
                    colList: [],
                    isfaicon: false,
                    isAddingText: false,
                    addingText: '',
                    img: false,
                    imgPath: '',
                    isSort: true,
                    iscolumnSearch: true,
                },
            ],
            sortOptions: { active: 'name.keyword', direction: 'asc' },
            _pageData: [],
            tableOptions: {
                title: 'Distributors',
                isServerSide: true,
                selectText: 'supplier(s)',
                loading: false,
                floatingFilter: true,
                rowSelection: true,
                showAction: true,
                parentCalls: [],
                actionMenuItems: [],
                pagination: true,
                pageOptions: [5, 10, 25, 100],
                pageSize: 10,
                search: true,
                showhideList: true,
                refreshData: true,
                exportExcel: true,
                add: this.authService.hasPermission('supplier', 'create'),
                columnSearch: true,
                compareData: false,
            },
        };
        /*if (this.authService.hasPermission('supplier', 'syncsuppliermaster')) {
            this.supplierTableOptions.tableOptions.actionMenuItems.push({
                text: 'Sync Distributor Master',
                icon: 'edit',
                callback: 'editFn',
                isGlobal: false,
            });
        }*/
        if (this.authService.hasPermission('supplier', 'update')) {
            this.supplierTableOptions.tableOptions.actionMenuItems.push({
                text: 'Edit',
                icon: 'edit',
                callback: 'editFn',
                isGlobal: false,
            });
        }
        /*if (this.authService.hasPermission('supplier', 'delete')) {
            this.supplierTableOptions.tableOptions.actionMenuItems.push({
                text: 'Delete',
                icon: 'delete',
                callback: 'deleteFn',
                isGlobal: true,
            });
        }*/
        if (this.authService.hasPermission('suppliermaster', 'uploadproducts')) {
            this.supplierTableOptions.tableOptions.actionMenuItems.push({
                text: 'Upload Product CSV File',
                icon: 'edit',
                callback: 'editFn',
                isGlobal: false,
            });
        }
        if (this.authService.hasPermission('suppliermaster', 'downloadtemplate')) {
            this.supplierTableOptions.tableOptions.actionMenuItems.push({
                text: 'Download Product CSV File',
                icon: 'edit',
                callback: 'editFn',
                isGlobal: false,
            });
        }
        if (this.authService.hasPermission('suppliermaster', 'downloadtemplate')) {
            this.supplierTableOptions.tableOptions.parentCalls.push({
                id: 'downloadProdTemplate',
                icon: 'download',
                tooltip: 'Download Product Template',
                iconBtn: true,
                color: 'accent',
            });
        }
    }

    syncSupplierMaster(supplier: any): void {
        const titleName = 'Confirmation';
        const message = 'Are you sure you want to sync master catalog from ' + supplier.name + ' ?';
        const cancelText = 'No';
        const acceptText = 'Yes';
        this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
        this.confirmDialog.dialogResult.subscribe(res => {
            if (res) {
                this.baseService.doRequest(`/api/supplier/syncSupplierMaster`,
                    'post', { supplierId: supplier._id }).subscribe(result => {
                        if (result[0]) {
                            this.toast.sToast('success', 'Supplier master synced successfully');
                        } else {
                            this.toast.sToast('error', 'Error in syncing supplier master');
                        }
                    });
            }
        });
    }

    getAllStates(region): void {
        const states = [];
        if (region[0] === '*') {
            this.Objectkeys(this.regionStates).forEach((key) => {
                this.regionStates[key].forEach((state) => {
                    state.forEach((s) => {
                        states.push(s);
                    });
                });
            });
        } else {
            region.forEach((key) => {
                this.regionStates[key].forEach((state) => {
                    state.forEach((s) => {
                        states.push(s);
                    });
                });
            });
        }
        this.allStates = states;
    }

    triggerClose(): void {
        this.closeCallback.emit(true);
    }

    ngOnInit() {
        this.getStates();
        this.getIntegrations();
        this.supplierTableOptions.pageData = [];
        setTimeout(() => {
            this.getSupplier();
            if (!this.currentCompany) {
                this.getCompany();
            }
        }, 1000);
    }

    suppliershowHideLoading(status: any): void {
        const data = Object.assign({}, this.supplierTableOptions);
        this.supplierTableOptions = {};
        this.supplierTableOptions = data;
        this.supplierTableOptions.tableOptions.loading = status;
    }

    suppliersortCall(idata) {
        this.supplierTableOptions.sortOptions = idata;
        this.getSupplier();
    }
    changePinCodeValue(value) {
        this.pincodeSearchInput.next(value);
    }

    supplierdetailFn(idata: any): void {
        this.currentSupplier = idata;
        this.showSubSupplier = true;
    }

    supplierhyperlinkCall(idata: any): void {
        if (idata.col === 'suppliergoryName') {
            this.supplierdetailFn(idata.row);
        }
    }

    closeSubSupplier(): void {
        this.showSubSupplier = false;
        this.currentSupplier = {
            name: '',
            gstIn: '',
            region: [],
            state: [],
            district: '',
            pinCodes: [],
            SAPCode: '',
            BPCLInvoicingParty: '',
            primaryContact: {
                contact: '',
                name: '',
                email: ''
            },
            secondaryContact: {
                contact: '',
                name: '',
                email: ''
            },
            address: {
                state: '',
                region: '',
                pincode: '',
                address: ['', ''],
                landmark: ''
            },
            supplierType: 1,
            company_ref: {
                id: '',
                name: ''
            },
            integration_ref: {
                id: '',
                name: ''
            },
            minOrderValue: 0
        };
    }

    suppliereditFn(idata: any): void {
        // idata.pinCodes = idata.pinCodes.join(',');
        this.currentSupplier = idata;
        this.getSelectedStates(idata.region);
        this.bView = 'addSupplier';
    }

    updateSupplier(currentSupplier: any): void {
        this.isLoading = true;
        this.loaderService.Modeldisplay(true);
        if (!Array.isArray(currentSupplier.pinCodes)) {
            this.currentSupplier.pinCodes = this.currentSupplier.pinCodes.split(',');
        }
        if (currentSupplier.region.indexOf('*') > -1) {
            currentSupplier.region = ['*'];
        }
        if (currentSupplier.state.indexOf('*') > -1) {
            currentSupplier.state = ['*'];
        }
        if (!Array.isArray(currentSupplier.pincode)) {
            currentSupplier.pincode = currentSupplier.pincode
                ? currentSupplier.pincode
                : '*';
        }
        this.baseService
            .doRequest(`/api/supplier`, 'put', currentSupplier)
            .subscribe((result: any) => {
                this.loaderService.Modeldisplay(false);
                this.toast.sToast('success', 'Updated successfully');
                this.isLoading = false;
                setTimeout(() => {
                    this.getSupplier();
                }, 2000);
                this.closeSupplier();
            });
    }

    saveSupplier(): void {
        this.isLoading = true;
        this.loaderService.Modeldisplay(true, 'Saving supplier');
        this.currentSupplier.pinCodes = this.currentSupplier.pinCodes.split(',');
        if (this.currentSupplier.region.indexOf('*') > -1) {
            this.currentSupplier.region = ['*'];
        }
        if (this.currentSupplier.state.indexOf('*') > -1) {
            this.currentSupplier.state = ['*'];
        }
        if (this.currentCompany && this.currentCompany._id) {
            this.currentSupplier.company_ref.id = this.currentCompany._id;
            this.currentSupplier.company_ref.name = this.currentCompany.name;
        }
        this.baseService
            .doRequest('/api/supplier', 'post', this.currentSupplier)
            .subscribe((result: any) => {
                this.loaderService.Modeldisplay(false);
                this.isLoading = false;
                if (result) {
                    this.toast.sToast('success', 'Added Successfully');
                    this.closeSupplier();
                    setTimeout(() => {
                        this.getSupplier();
                    }, 2000);
                } else {
                    this.toast.sToast('error', JSON.stringify(result));
                }
            });
    }

    supplierdeleteFn(currentSupplier: any): void {
        const titleName = 'Confirmation';
        const message =
            'Are you sure you want to delete ' + currentSupplier.name + ' ?';
        const cancelText = 'No';
        const acceptText = 'Yes';
        this.confirmDialog.confirmDialog(
            titleName,
            message,
            cancelText,
            acceptText
        );
        this.confirmDialog.dialogResult.subscribe((res) => {
            if (res) {
                this.baseService
                    .doRequest(`/api/supplier/${currentSupplier._id}`, 'delete', {
                        id: currentSupplier._id,
                    })
                    .subscribe((result: any) => {
                        this.toast.sToast('success', 'Removed successfully');
                        setTimeout(() => {
                            this.getSupplier();
                        }, 2000);
                    });
            }
        });
    }

    supplierglobalActionCall(idata: any): void {
        if (idata.action.text === 'Delete') {
            this.deleteSelected(idata.row);
        }
    }

    deleteSelected(selectedList: any): void {
        const titleName = 'Confirmation';
        const message = 'Are you sure you want to delete all the selected items?';
        const cancelText = 'No';
        const acceptText = 'Yes';
        this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
        this.confirmDialog.dialogResult.subscribe((res) => {
            if (res) {
                const itemlist = selectedList.map((item: any) => item._id);
                this.baseService
                    .doRequest(`/api/supplier/imasw/bulkdelete`, 'delete', { ids: itemlist })
                    .subscribe((result: any) => {
                        this.toast.sToast('success', 'Removed successfully');
                        setTimeout(() => {
                            this.getSupplier();
                        }, 2000);
                    });
            }
        });
    }

    supplierParentCall(idata: any): void {
        if (idata === 'downloadProdTemplate') {
            this.loaderService.display(true, 'Downloading template');
            this.baseService
                .doRequest(`/api/suppliermaster/downloadTemplate`, 'post', {})
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    if (result) {
                        window.open(result, '_blank');
                    } else {
                        this.toast.sToast('error', result);
                    }
                });
        }
    }

    uploadCSVFile(event: any, key: string): void {
        if (event.target.files.length > 0) {
            this.prodMaster[key] = event.target.files[0];
        }
    }

    saveProductMasterFile(): void {
        this.isLoading = true;
        this.loaderService.Modeldisplay(true, 'Uploading product catalog file...');
        const formData = new FormData();
        this.Objectkeys(this.prodMaster).forEach((obj: any) => {
            formData.append(obj, this.prodMaster[obj]);
        });
        formData.append('supplierId', this.currentSupplier._id);
        this.httpClient
            .post<any>('/api/suppliermaster/uploadProducts', formData)
            .subscribe((result: any) => {
                this.loaderService.Modeldisplay(false); this.isLoading = false;
                if (result[0]) {
                    this.toast.sToast('success', result[1]);
                    this.modalService.close('uploadProducts');
                    setTimeout(() => this.getSupplier(), 2000);
                } else {
                    this.toast.sToast('error', result[1]);
                }
                this.prodMaster = {};
            });
    }

    supplieractionCall(idata: any): void {
        if (idata.action.text === 'Edit') {
            const dataRow = idata.row;
            this.suppliereditFn(dataRow);
        }
        if (idata.action.text === 'Sync Distributor Master') {
            const dataRow = idata.row;
            this.syncSupplierMaster(dataRow);
        }
        if (idata.action.text === 'Delete') {
            const dataRow = idata.row;
            this.supplierdeleteFn(dataRow);
        }
        if (idata.action.text === 'Upload Product CSV File') {
            this.currentSupplier = idata.row;
            this.isLoading = false;
            this.modalService.open('uploadProducts');
        }
        if (idata.action.text === 'Download Product CSV File') {
            this.loaderService.display(true, 'Downloading template');
            this.baseService
                .doRequest(`/api/suppliermaster/downloadTemplate`, 'post', { supplierId: idata.row._id })
                .subscribe((result: any) => {
                    this.loaderService.display(false);
                    if (result) {
                        window.open(result, '_blank');
                    } else {
                        this.toast.sToast('error', result);
                    }
                });
        }
    }

    colFilterCall(event: any): void {
        this.colFilterQuery = [];
        if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
            if (event.value !== '') {
                this.colFilterCols.push(event);
            }
        } else {
            this.colFilterCols.forEach((obj: any, index: number) => {
                if (obj.col === event.col && event.value === '') {
                    this.colFilterCols.splice(index, 1);
                } else if (obj.col === event.col) {
                    obj.value = event.value;
                }
            });
        }
        this.colFilterCols.forEach((obj: any) => {
            // condition for stype to supplierType
            let tmpObj = {};
            if (obj.col === 'stype') {
                let objVal = '';
                objVal = obj.value.toLowerCase() === 'api' ? '1' : '2';
                tmpObj = {
                    bool: {
                        should: [
                            { query_string: { fields: ['supplierType'], query: `${objVal}` } },
                        ],
                    },
                };
            } else {
                tmpObj = {
                    bool: {
                        should: [
                            { query_string: { fields: [obj.col], query: `*${obj.value}*` } },
                        ],
                    },
                };
            }
            this.colFilterQuery.push(tmpObj);
        });
        this.getSupplier();
    }

    supplierfilterCall(idata: any): void {
        const fields = [];
        const notInclude = ['pinCodes', 'gstIn', 'address.pincode', 'primaryContact.contact', 'secondaryContact.contact', 'c', 'u', '_id', 'SAPCode'];
        this.supplierTableOptions.columns.forEach((obj: { columnDef: any }) => {
            if (notInclude.indexOf(obj.columnDef) === -1) {
                fields.push(obj.columnDef);
            }
        });
        this.supplierfilterQuery =
            idata && idata.length > 0
                ? { multi_match: { query: idata, type: 'phrase_prefix', fields } }
                : undefined;
        this.getSupplier();
    }

    supplierpageCall(event: any): void {
        this.supplierTableOptions.tableOptions.pageSize = event.pageSize;
        this.suppliercurrentPage = event.pageIndex;
        this.getSupplier();
    }

    supplieraddTableData(): void {
        if (!this.currentCompany || !this.currentCompany._id) {
            this.getCompany();
        }
        this.currentSupplier = {
            name: '',
            gstIn: '',
            region: [],
            state: [],
            district: '',
            pinCodes: [],
            SAPCode: '',
            BPCLInvoicingParty: '',
            primaryContact: {
                contact: '',
                name: '',
                email: ''
            },
            secondaryContact: {
                contact: '',
                name: '',
                email: ''
            },
            address: {
                state: '',
                region: '',
                pincode: '',
                address: ['', ''],
                landmark: ''
            },
            supplierType: 1,
            company_ref: {
                id: '',
                name: ''
            },
            integration_ref: {
                id: '',
                name: ''
            },
            minOrderValue: 0
        };
        this.bView = 'addSupplier';
    }

    getCompany(): void {
        this.loaderService.display(true, 'Getting Company');
        this.baseService.doRequest(`/api/company`, 'get',
            null, { q: '{}', skip: 0, limit: 1000 })
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result && result.data) {
                    result.data.forEach((obj: any) => {
                        this.companyHash[obj._id] = obj.name;
                    });
                    this.companyList = result.data;
                } else {
                    this.companyList = [];
                    this.toast.sToast('error', result.toString());
                }
            });
    }

    updateIntegration(event): void {
        if (event === 2) {
            this.currentSupplier.integration_ref.id = '';
            this.currentSupplier.integration_ref.name = '';
        }
    }

    setIntegration(event?: any): void {
        this.currentSupplier.integration_ref.name = this.integrationHash[event];
    }

    getIntegrations(): void {
        this.loaderService.display(true, 'Getting Integrations');
        this.baseService.doRequest(`/api/supplier/getApiSuppliers`, 'post', {})
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result) {
                    this.integrationList = result;
                } else {
                    this.integrationList = [];
                    this.toast.sToast('error', result.toString());
                }
            });
    }

    getStates(): void {
        this.loaderService.display(true, 'Getting region, states... ');
        this.baseService
            .doRequest(`/api/utilities/getStates`, 'post', {})
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result) {
                    this.regionStates = result;
                } else {
                    this.toast.sToast('error', result);
                }
            });
    }

    validateRS(key: string, value: any): void {
        const starI = value.indexOf('*');
        if (value.length > 1 && starI > -1) {
            this[key].splice(starI, 1);
        }
        if (key !== 'region') {
            this.getAllStates(this.region);
        }
    }

    validateAll(key: string, value: any): void {
        const starI = value.indexOf('*');
        if (value.length > 1 && starI > -1) {
            this.currentSupplier[key].splice(starI, 1);
        }
    }

    suppliertimerCallData(idata: any): void {
        this.getSupplier();
    }

    getSelectedCompany(event?: any): void {
        if (event) {
            this.currentSupplier.company_ref.name = this.companyHash[event];
        }
    }

    getSelectedStates(event?: any): void {
        if (event) {
            this.currentSupplier.region = event;
        }
        if (!this.currentSupplier.state) { this.currentSupplier.state = []; }
        this.selectedStates = [];
        if (this.currentSupplier.region.indexOf('*') > -1) {
            this.Objectkeys(this.regionStates).forEach(region => {
                this.regionStates[region].forEach(state => {
                    this.selectedStates.push(state);
                });
            });
            this.currentSupplier.region = ['*'];
        } else {
            this.currentSupplier.region.forEach(region => {
                this.regionStates[region].forEach(state => {
                    this.selectedStates.push(state);
                });
            });
        }
    }

    closeSupplier(): void {
        this.bView = 'supplier';
    }

    getSupplier(): void {
        this.suppliershowHideLoading(true);
        let params: any;
        params = {
            query: { bool: { must: [] } },
        };
        if (this.currentCompany && this.currentCompany._id) {
            params.query.bool.must.push({ match: { 'company_ref.id.keyword': this.currentCompany._id } });
        }
        if (this.supplierfilterQuery && this.supplierfilterQuery.multi_match) {
            params.query.bool.must.push(this.supplierfilterQuery);
        }
        if (this.colFilterQuery && this.colFilterQuery.length) {
            // @ts-ignore
            params.query.bool.filter = [];
            this.colFilterQuery.forEach((obj: any) => {
                if (obj.bool.should[0].match) {
                    params.query.bool.must.push(obj);
                } else {
                    params.query.bool.filter.push(obj);
                }
            });
        }

        const sorts: any = [{}];
        if (this.supplierTableOptions.sortOptions) {
            sorts[0][this.supplierTableOptions.sortOptions.active] = {
                order: this.supplierTableOptions.sortOptions.direction,
            };
        }
        const q = JSON.stringify(params);
        const skip = this.suppliercurrentPage;
        const limit = this.supplierTableOptions.tableOptions.pageSize;
        const sort = JSON.stringify(sorts);
        this.baseService
            .doRequest('/api/supplier', 'get', null, { q, skip, limit, sorts })
            .subscribe((result: any) => {
                result.data.forEach((x: any) => {
                    x.stype = x.supplierType === 1 ? 'API' : 'Manual';
                });
                this.supplierTableOptions.pageData = result.data;
                this.supplierTableOptions.tableOptions.pageTotal = result.total;
                this.suppliershowHideLoading(false);
            });
    }
}
