<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <mat-icon *ngIf="isIcon" [icIcon]="icWarning" class="float-left mt-1 mr-1 mat-warn"></mat-icon>
    <h2 class="headline m-0" fxFlex="auto">{{title}}</h2>
    <!--<button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>-->
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-content fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" class="mb-2">
      <p  class="mt-2 mb-2 py-2" fxFlex="auto" *ngIf="!HtmlMsg">{{message}}</p>
      <div  class="mt-2 mb-2 py-2" fxFlex="auto" *ngIf="HtmlMsg" [innerHTML]="message"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="Reverse" class="mb-1">
    <button mat-raised-button class="mat-primary" (click)="onDismiss()" *ngIf="!CancelColor"
            id="cnclaBtn">{{CancelText}}</button>
    <button mat-raised-button (click)="onDismiss()" class="mat-primary" [ngStyle]="{color: CancelColor}"
            *ngIf="CancelColor"
            id="cnclbBtn">{{CancelText}}</button>
    <button mat-stroked-button class="mat-warn" (click)="onConfirm()" *ngIf="!AcceptColor"
            id="accptaBtn">{{AcceptText}}</button>
    <button mat-stroked-button (click)="onConfirm()" *ngIf="AcceptColor" id="accptbBtn" class="mat-warn"
            [ngStyle]="{'background-color': AcceptColor}">{{AcceptText}}</button>
</mat-dialog-actions>
<mat-dialog-actions align="end" *ngIf="!Reverse" class="mb-1">
    <button mat-stroked-button class="mat-warn" (click)="onDismiss()" *ngIf="!CancelColor"
            id="cncl1Btn">{{CancelText}}</button>
    <button mat-stroked-button class="mat-warn" (click)="onDismiss()" [ngStyle]="{color: CancelColor}"
            *ngIf="CancelColor"
            id="cncl2Btn">{{CancelText}}</button>
    <button mat-raised-button class="mat-primary" (click)="onConfirm()" *ngIf="!AcceptColor"
            id="accpt1Btn">{{AcceptText}}</button>
    <button mat-raised-button class="mat-primary" (click)="onConfirm()" *ngIf="AcceptColor" id="accpt2Btn"
            [ngStyle]="{'background-color': AcceptColor}">{{AcceptText}}</button>
</mat-dialog-actions>
