<a *ngIf="isLink(item) && !isFunction(item.route) && isVisible(item)"
   [class.hover:bg-hover]="!(isActive$ | async)(item)"
   [ngClass]="{ 'bg-primary text-primary-contrast': (isActive$ | async)(item), 'navigation-color': !(isActive$ | async)(item) }"
   [routerLink]="item.route"
   class="navigation-item"
   matRipple>
  {{ item.label }}
</a>

<div (click)="item.route()"
     *ngIf="isLink(item) && isFunction(item.route) && isVisible(item)"
     [class.hover:bg-hover]="!(isActive$ | async)(item)"
     class="navigation-item navigation-color"
     matRipple>
  {{ item.tLabel | transloco }}
</div>

<ng-container *ngIf="(isSubheading(item) && item.children?.length > 0) || isDropdown(item)  && isVisible(item)">
  <div [class.hover:bg-hover]="!(isActive$ | async)(item)"
       [matMenuTriggerFor]="menu"
       [ngClass]="{ 'bg-primary text-primary-contrast': (isActive$ | async)(item), 'navigation-color': !(isActive$ | async)(item) }"
       class="navigation-item"
       matRipple>
    {{ item.tLabel | transloco }}
  </div>

  <mat-menu #menu="matMenu" yPosition="below">
    <ng-container *ngFor="let child of item.children">
      <a *ngIf="isLink(child) && !isFunction(child.route)  && isVisible(item)"
         [routerLink]="child.route"
         [ngClass]="{ 'text-primary': (isActive$ | async)(child) }"
         class="navigation-menu-item"
         mat-menu-item>
        <mat-icon [icIcon]="child.icon" class="text-current"></mat-icon>
        <span>{{ child.label }}</span>
      </a>

      <div (click)="child.route()"
           *ngIf="isLink(child) && isFunction(child.route)  && isVisible(item)"
           class="navigation-menu-item"
           mat-menu-item>
        <mat-icon [icIcon]="child.icon" class="text-current"></mat-icon>
        <span>{{ child.label }}</span>
      </div>

      <ng-container *ngIf="isDropdown(child) && isVisible(item)">
        <button [matMenuTriggerFor]="level1"
                [ngClass]="{ 'text-primary': (isActive$ | async)(child) }"
                class="navigation-menu-item"
                mat-menu-item>
          <mat-icon [icIcon]="child.icon" class="text-current"></mat-icon>
          <span>{{ child.label }}</span>
        </button>

        <mat-menu #level1="matMenu" yPosition="below">
          <ng-container *ngFor="let item of child.children">
            <ng-container [ngTemplateOutletContext]="{ item: item }"
                          [ngTemplateOutlet]="linkTemplate"></ng-container>

            <ng-container *ngIf="isDropdown(item)">
              <button [matMenuTriggerFor]="level2"
                      class="navigation-menu-item"
                      [ngClass]="{ 'text-primary': (isActive$ | async)(item) }"
                      mat-menu-item>{{ item.tLabel | transloco }}</button>

              <mat-menu #level2="matMenu" yPosition="below">
                <ng-container *ngFor="let child of item.children">
                  <ng-container [ngTemplateOutletContext]="{ item: child }"
                                [ngTemplateOutlet]="linkTemplate"></ng-container>

                  <ng-container *ngIf="isDropdown(child)">
                    <button [matMenuTriggerFor]="level3"
                            class="navigation-menu-item"
                            [ngClass]="{ 'text-primary': (isActive$ | async)(child) }"
                            mat-menu-item>{{ child.label }}</button>

                    <mat-menu #level3="matMenu" yPosition="below">
                      <ng-container *ngFor="let item of child.children">
                        <ng-container [ngTemplateOutletContext]="{ item: item }"
                                      [ngTemplateOutlet]="linkTemplate"></ng-container>

                        <ng-container *ngIf="isDropdown(item)">
                          <button [matMenuTriggerFor]="level4"
                                  class="navigation-menu-item"
                                  [ngClass]="{ 'text-primary': (isActive$ | async)(item) }"
                                  mat-menu-item>{{ item.tLabel | transloco }}</button>

                          <mat-menu #level4="matMenu" yPosition="below">
                            <ng-container *ngFor="let child of item.children">
                              <ng-container [ngTemplateOutletContext]="{ item: child }"
                                            [ngTemplateOutlet]="linkTemplate"></ng-container>

                              <ng-container *ngIf="isDropdown(child)">
                                <button [matMenuTriggerFor]="level5"
                                        class="navigation-menu-item"
                                        [ngClass]="{ 'text-primary': (isActive$ | async)(child) }"
                                        mat-menu-item>{{ child.label }}</button>

                                <mat-menu #level5="matMenu" yPosition="below">
                                  <ng-container *ngFor="let item of child.children">
                                    <ng-container [ngTemplateOutletContext]="{ item: item }"
                                                  [ngTemplateOutlet]="linkTemplate"></ng-container>
                                  </ng-container>
                                </mat-menu>
                              </ng-container>
                            </ng-container>
                          </mat-menu>
                        </ng-container>
                      </ng-container>
                    </mat-menu>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </ng-container>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
  </mat-menu>

  <ng-template #linkTemplate let-item="item">
    <a *ngIf="isLink(item) && !isFunction(item.route)  && isVisible(item)"
       [routerLink]="item.route"
       class="navigation-menu-item"
       [ngClass]="{ 'text-primary': (isActive$ | async)(item) }"
       mat-menu-item>{{ item.tLabel | transloco }}</a>

    <div (click)="item.route()"
         *ngIf="isLink(item) && isFunction(item.route)  && isVisible(item)"
         class="navigation-menu-item"
         [ngClass]="{ 'text-primary': (isActive$ | async)(item) }"
         mat-menu-item>{{ item.tLabel | transloco }}</div>
  </ng-template>
</ng-container>
