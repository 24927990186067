<!--suppress TypeScriptUnresolvedVariable -->
<mat-card *ngIf="isLoading">
  <mat-card-content>
    <p><i class="fa fa-spin fa-spinner fa-lg"></i> Loading...</p>
  </mat-card-content>
</mat-card>
<mat-card class="table-card ibtn p-0" *ngIf="!isLoading">
  <mat-toolbar class="table-tools" role="heading" *ngIf="selection.selected.length === 0">
    <span class="mat-title mb-0">
      {{ tableOptions.title }}
      <span class="fw300" *ngIf="tableOptions.pageTotal !== 0 && tableOptions.title !== ''">
        ({{ tableOptions.pageTotal }})
      </span></span>
    <!-- <button class="px-1 lh-25" mat-stroked-button *ngIf="tableOptions.add" type="button"  matTooltip="Add New Record"  color="primary"
          (click)="AddTableRecord()" id ="addbtn{{tableOptions.title}}">
      <mat-icon [icIcon]="icAdd"></mat-icon>
    </button> -->
    <div fxFlex fxLayout fxLayoutAlign="end" *ngIf="tableOptions.floatingFilter">
      <div fxLayout fxLayoutGap="15px" class="navigation-items">
        <form fxLayout="row" fxLayoutAlign="center center">
          <button class="gray mr-2" matSuffix mat-icon-button *ngIf="tableOptions.add" matTooltip="Add New Record"
            color="primary" (click)="AddTableRecord($event)" id="addbtn{{ tableOptions.title }}" type="button">
            <mat-icon [icIcon]="icAdd"></mat-icon>
          </button>
          <mat-form-field class="pl20" style="padding: 10px; font-size: 15px" *ngIf="tableOptions.dropdwn">
            <mat-select [multiple]="false" placeholder="Asset" (selectionChange)="filterDrpLstChange($event.value)"
              [formControl]="assetDrpCtrl">
              <mat-option *ngFor="let item of tableOptions.filterDrpLstFilteredList" [value]="item._id">
                {{ item.ip }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!--<div fxFlex fxLayout fxLayoutAlign="end" *ngIf="tableOptions.add"></div>-->
          <mat-form-field floatLabel="never" class="search-tool" *ngIf="tableOptions.search">
            <input matInput class="form-control mat-small" [(ngModel)]="filterText" (keyup.enter)="doFilter(filterText)"
              type="text" name="filterText" id="filterText" placeholder="Search" />
            <button class="gray" matSuffix (click)="doFilter(filterText)" mat-icon-button *ngIf="!filterClicked"
              color="primary">
              <mat-icon class="h-10" [icIcon]="icSearch"></mat-icon>
            </button>
            <button class="gray" matSuffix mat-icon-button *ngIf="filterClicked"
              (click)="filterText = ''; doFilter(filterText)" color="primary">
              <mat-icon class="h-10" [icIcon]="icClose"></mat-icon>
            </button>
          </mat-form-field>
          <!-- <div fxFlex fxLayout fxLayoutAlign="end" *ngIf="tableOptions.refreshData">
            <button class="gray" matSuffix mat-icon-button matTooltip="Reload Table Data" (click)="RefreshTableData();"
              color="primary">
              <mat-icon id="rfrbtn{{tableOptions.title}}" [icIcon]="icRefresh"></mat-icon>
            </button>
          </div>
          <div *ngIf="tableOptions.refreshData">
            <mat-form-field style="font-size: 14px;width: 61px">
              <mat-label>Every</mat-label>
              <mat-select [(value)]="selectedTimer" (selectionChange)="timerCallData()" matTooltip="Refresh Interval">
                <mat-option value="0">Never</mat-option>
                <mat-option value="60">1 Min</mat-option>
                <mat-option value="300">5 Min</mat-option>
                <mat-option value="1800">30 Min</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <button mat-icon-button matTooltip="Filter List" color="primary" *ngIf="
              tableOptions.showColFilter &&
              _sTableOptions.columns &&
              _sTableOptions.columns.length
            " [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
            <mat-icon [icIcon]="icFilterList"></mat-icon>
          </button>
          <button mat-icon-button matTooltip="More Options" color="primary" *ngIf="tableOptions"
            [matMenuTriggerFor]="optionMenu" #optionMenuTrigger="matMenuTrigger">
            <mat-icon [icIcon]="icMoreVert"></mat-icon>
          </button>
          <mat-menu #filterMenu="matMenu" class="col-filters">
            <mat-card class="pt-0 filter-card max-300 overflow-auto" (click)="$event.stopPropagation()">
              <h3>Filter</h3>
              <mat-card-content>
                <form>
                  <ng-container *ngFor="let column of _sTableOptions.columns">
                    <mat-form-field *ngIf="column.iscolumnSearch" class="w-100" appearance="fill">
                      <input matInput type="text" placeholder="Search {{ column.header }}" *ngIf="!column.selectFilter"
                        name="{{ column.columnDef }}" [(ngModel)]="colfilter[column.columnDef]" (input)="
                          columnFilter(
                            $any($event.target).value,
                            column.columnDef
                          )
                        " [disabled]="!column.iscolumnSearch" />
                      <mat-select placeholder="Choose {{ column.header }}" name="{{ column.columnDef }}"
                        *ngIf="column.selectFilter" [(ngModel)]="colfilter[column.columnDef]"
                        [disabled]="!column.iscolumnSearch" (selectionChange)="
                          columnFilter($event.value, column.columnDef)
                        ">
                        <mat-option value="">All</mat-option>
                        <mat-option *ngFor="let val of column.selectFilterArr" [value]="val.value">{{ val.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </form>
              </mat-card-content>
            </mat-card>
          </mat-menu>
          <mat-menu #optionMenu="matMenu" class="col-filters">
            <button mat-icon-button menuOpen="false" class="ml-2" (click)="openListExport($event)" matTooltip="Download"
              color="primary" #exportMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="exportMenu"
              *ngIf="tableOptions.exportExcel">
              <mat-icon [icIcon]="icDownload"></mat-icon>
            </button>
            <div mat-menu-item *ngIf="tableOptions.refreshData">
              <button matSuffix mat-icon-button matTooltip="Reload Table Data" (click)="RefreshTableData()">
                <mat-icon color="primary" id="rfrbtn{{ tableOptions.title }}" [icIcon]="icRefresh"></mat-icon>
              </button>
            </div>
            <div mat-menu-item *ngIf="tableOptions.refreshData">
              <mat-form-field style="font-size: 14px; width: 61px">
                <mat-label>Every</mat-label>
                <mat-select color="blue" [(value)]="selectedTimer" (selectionChange)="timerCallData()"
                  matTooltip="Refresh Interval">
                  <mat-option value="0">Never</mat-option>
                  <mat-option value="60">1 Min</mat-option>
                  <mat-option value="300">5 Min</mat-option>
                  <mat-option value="1800">30 Min</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <button mat-icon-button (click)="openListColumns($event)" class="ml-2" #listMenuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="belowMenu" color="primary" matTooltip="View Column, Reorder Columns"
              *ngIf="tableOptions.showhideList">
              <mat-icon [icIcon]="icViewColumn"></mat-icon>
            </button>
            <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
              <!--<div>
              <button mat-button (click)="ExportToExcel()">
                Export to Excel
              </button></div>
            <div>
              <button mat-button (click)="exportAs('pdf', 'l','current')">
                Export Current Page to PDF
              </button>
            </div>
            <div>
              <button mat-button (click)="exportAs('pdf', 'l','whole')">
                Export Whole Data to PDF
              </button>
            </div>-->
              <button mat-menu-item id="currentSelection" (click)="downloadAsXls('filtered')">
                <mat-icon [icIcon]="icFilterList"></mat-icon>
                <span>Filtered Data</span>
              </button>
              <button mat-menu-item id="fullData" (click)="downloadAsXls('full')">
                <mat-icon [icIcon]="icDownload"></mat-icon>
                <span>Full Data</span>
              </button>
              <!-- <button mat-menu-item color="primary" (click)="downloadTableData('EXCEL')">
              <mat-icon [icIcon]="faexcel"></mat-icon> Export to Excel
            </button>
            <button mat-menu-item color="primary" (click)="downloadTableData('CSV')">
              <mat-icon [icIcon]="facsv"></mat-icon> Export to CSV
            </button> -->
            </mat-menu>
            <mat-menu #belowMenu="matMenu" [hasBackdrop]="false" yPosition="below" class="max-300 overflow-auto">
              <!-- <div *ngFor="let item of showhideList; let i = index"
                  style="padding: 5px;overflow: hidden;font-family: Arial, Helvetica, sans-serif">
                  <mat-checkbox color="primary" [checked]="item.visible==true?item:''" (click)="$event.stopPropagation()"
                    (change)="showHideColumn(i, item)">
                  </mat-checkbox>
                  {{item.header}}
            </div> -->
              <mat-card class="mat-elevation-z0 py-1">
                <mat-card-content>
                  <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let item of showhideList; let i = index"
                      cdkDrag>
                      <mat-icon class="mr-1" [icIcon]="icDragHandle"></mat-icon>
                      <mat-checkbox color="primary" [checked]="item.visible == true ? item : ''"
                        (click)="$event.stopPropagation()" class="absolute left-align">
                        {{ item.header }}
                      </mat-checkbox>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </mat-menu>
          </mat-menu>
          <!--<button mat-icon-button matTooltip="Filter List"
                  *ngIf="sTableOptions.cFilter" color="primary"
                  [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
            <mat-icon [icIcon]="icFilterList"></mat-icon></button>
            <mat-menu #filterMenu="matMenu" class="col-filters  max-300 overflow-auto">
              <mat-card class="filter-card" (click)="$event.stopPropagation();">
                <mat-card-header>
                  <mat-card-title>Filter</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <form #filterForm="ngForm" role="form">
                    <mat-form-field *ngIf="sTableOptions.columns && sTableOptions.columns.length" class="w-100">
                      <mat-label>Choose filter column</mat-label>
                      <mat-select placeholder="Choose filter column" name="chooseFilter" required [(ngModel)]="cFilter.selectedCol">
                        <mat-option value="">Choose filter column</mat-option>
                        <mat-option *ngFor="let val of sTableOptions.columns" [value]="val.col">{{val.header}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="cFilter.selectedCol" class="w-100">
                      <mat-label>Search {{colHash[cFilter.selectedCol].header}}</mat-label>
                      <input matInput type="text" trim="blur" *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'text'"
                             placeholder="Search {{colHash[cFilter.selectedCol].header}}" required
                              name="{{colHash[cFilter.selectedCol].col}}s" [(ngModel)]="colHash[cFilter.selectedCol].val" >
                      <mat-select placeholder="Search {{colHash[cFilter.selectedCol].header}}" name="{{colHash[cFilter.selectedCol].col}}s"
                                  *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'select'" required
                                  [(ngModel)]="colHash[cFilter.selectedCol].val">
                        <mat-option *ngFor="let val of colHash[cFilter.selectedCol].colFilters.options" [value]="val.value">{{val.name}}</mat-option>
                      </mat-select>
                      <ng-container *ngIf="colHash[cFilter.selectedCol].colFilters.type === 'range'">
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate name="startDate" [(ngModel)]="colHash[cFilter.selectedCol].start"
                                 placeholder="Start date">
                          <input matEndDate name="startDate" [(ngModel)]="colHash[cFilter.selectedCol].end"
                                 placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </ng-container>
                    </mat-form-field>
                    <button (click)="columnFilterxlsx(colHash[cFilter.selectedCol])" [disabled]="!filterForm.form.valid"
                            color="primary" mat-raised-button="">Add Filter</button>
                     <button class="ml-2" (click)="cFilter.selectedCol = ''"  mat-stroked-button="">Clear</button>
                  </form>
                </mat-card-content>
              </mat-card>
            </mat-menu>-->
          <!-- <button id="gbtn{{sTableOptions.title}}" color="primary" matSuffix mat-icon-button *ngIf="!hideDownload"
                  matTooltip="Download as XLSx" [matMenuTriggerFor]="xlsMenu"
              aria-label="download xlsx menu">
              <mat-icon [icIcon]="icDownload"></mat-icon>
            </button>
      <mat-menu #xlsMenu="matMenu">
        <button mat-menu-item id="currentSelection" (click)="downloadAsXls('filtered')">
          <mat-icon [icIcon]="icFilterList"></mat-icon>
          <span>Filtered Data</span>
        </button>
        <button mat-menu-item id="fullData" (click)="downloadAsXls('full')">
          <mat-icon [icIcon]="icDownload"></mat-icon>
          <span>Full Data</span>
        </button>
      </mat-menu> -->
          <button mat-icon-button *ngIf="tableOptions.compareData" matTooltip="Compare Data" color="primary"
            (click)="CompareData(compSelection.selected)">
            <mat-icon [icIcon]="icCompare"></mat-icon>
          </button>
          <button mat-icon-button matTooltip="Save Settings" (click)="SaveSettings()" color="primary"
            *ngIf="tableOptions.saveData">
            <mat-icon [icIcon]="icSave"></mat-icon>
          </button>
          <button mat-raised-button color="primary" (click)="publish()" *ngIf="tableOptions.publish">
            Publish
          </button>
          <a mat-icon-button matTooltip="Help Link" [href]="baseService.helpLinks[tableOptions.id]" target="_blank"
            color="primary" *ngIf="
              baseService.helpLinks &&
              baseService.helpLinks[tableOptions.id] &&
              baseService.helpLinks[tableOptions.id] !== ''
            ">
            <mat-icon [icIcon]="icHelp"></mat-icon>
          </a>
          <!-- <button mat-icon-button menuOpen="false" (click)="openListExport()" matTooltip="Download" color="primary"
            #exportMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="exportMenu" *ngIf="tableOptions.exportExcel">
            <mat-icon [icIcon]="icDownload"></mat-icon>
          </button> -->
          <button *ngFor="let pbtn of tableOptions.parentCalls" mat-icon-button [matTooltip]="pbtn.tooltip"
            [color]="pbtn.color" (click)="ParentCalls(pbtn.id)">
            <mat-icon *ngIf="pbtn.icon === 'upload'" [icIcon]="ic.icFileUpload"></mat-icon>
            <mat-icon *ngIf="pbtn.icon === 'drive_folder_upload'" [icIcon]="ic.icDriveFolderUpload"></mat-icon>
            <mat-icon *ngIf="pbtn.icon === 'download'" [icIcon]="ic.icDownload"></mat-icon>
          </button>
          <!-- <mat-menu #exportMenu="matMenu" [hasBackdrop]="false" yPosition="below">
            <button mat-menu-item color="primary" (click)="downloadTableData('EXCEL')">
              <mat-icon [icIcon]="faexcel"></mat-icon> Export to Excel
            </button>
            <button mat-menu-item color="primary" (click)="downloadTableData('CSV')">
              <mat-icon [icIcon]="facsv"></mat-icon> Export to CSV
            </button>
          </mat-menu> -->
        </form>
      </div>
    </div>
  </mat-toolbar>
  <mat-toolbar class="select-tools" *ngIf="selection.selected.length > 0">
    <span>{{ selection.selected.length }}
      {{ tableOptions.selectText }} selected.</span>
    <div fxFlex fxLayout fxLayoutAlign="end">
      <ng-container *ngIf="!tableOptions.isHideGlobal">
        <button mat-icon-button color="primary" id="gbtnSelction{{ tableOptions.title }}" mat-raised-button
          [matMenuTriggerFor]="gactionSMenu" [disabled]="selection.selected.length === 0"
          aria-label="global action with a menu" *ngIf="checkGlobal(tableOptions.actionMenuItems)">
          <mat-icon [icIcon]="icMoreVert"></mat-icon>
        </button>
        <mat-menu #gactionSMenu="matMenu">
          <ng-container *ngFor="let item of tableOptions.actionMenuItems">
            <a id="ganchsel{{ item.text }}" class="mat-primary" *ngIf="item.isGlobal" mat-menu-item
              (click)="globalActionCall(selection.selected, item)">
              <!--<mat-icon *ngIf=item.icon [icIcon]="item.icon"></mat-icon>-->
              <mat-icon *ngIf="!item.icon" [icIcon]="icArrowForwardIos"></mat-icon>
              <span>{{ item.text }}</span>
            </a>
          </ng-container>
        </mat-menu>
      </ng-container>
      <button class="gray" matSuffix mat-icon-button (click)="clearSelection()">
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <mat-chip-list aria-label="Column Filters" class="">
    <mat-chip class="m-chip" *ngFor="let filter of colFilters" (removed)="removeFilter(filter)">
      {{ filter.name }} = *{{ filter.value }}*
      <mat-icon matChipRemove [icIcon]="icClose"></mat-icon>
    </mat-chip>
  </mat-chip-list>
  <mat-card-content [ngClass]="{ 'mt-2': colFilters.length }">
    <mat-progress-bar class="m-t-4px" mode="indeterminate" *ngIf="tableOptions.loading"></mat-progress-bar>
    <div #MAINTABLEDIV class="overflow-auto">
      <table id="mytable" class="ibtn crud-table mt-2 w-web-fill" [ngClass]="{ tablePadding: isTablePadding === true }"
        mat-table [dataSource]="dataSource" *ngIf="dataSource" matSort #MainTable
        [matSortActive]="_sTableOptions['sortOptions']['active']"
        [matSortDirection]="_sTableOptions['sortOptions']['direction']" (matSortChange)="customSort($event)">
        <ng-container matColumnDef="select" *ngIf="tableOptions.rowSelection" sticky>
          <th mat-header-cell *matHeaderCellDef style="word-wrap: break-word; width: 5%">
            <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" id="_selectDeselectAll"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" style="word-wrap: break-word; width: 5%">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()" [id]="row._id"
              (change)="$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="compare" *ngIf="tableOptions.compareData">
          <th mat-header-cell *matHeaderCellDef style="word-wrap: break-word; width: 5%">
            Compare
            <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox> -->
          </th>
          <td mat-cell *matCellDef="let row" style="word-wrap: break-word; width: 5%">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
              (change)="$event ? compSelection.toggle(row) : null" [checked]="compSelection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of _sTableOptions.columns" [matColumnDef]="column.columnDef">
          <th mat-header-cell *matHeaderCellDef [width]="column.width">
            <mat-label mat-sort-header [disabled]="
                column.isSort === undefined || column.isSort === null
                  ? 'false'
                  : !column.isSort
              ">
              <span>{{ column.header }}</span>
            </mat-label>
            <!--Column Search Code in the Table header section-->
            <!--<div *ngIf="tableOptions.columnSearch && dontShow">
              <mat-form-field>
                <input matInput type="text" trim="blur" placeholder="Search {{column.header}}"
                       *ngIf="!column.selectFilter"
                  (input)='columnFilter($any($event.target).value,column.columnDef)'
                  [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch ">
                <mat-select placeholder="Choose filter value" name="{{column.columnDef}}"
                            *ngIf="column.selectFilter" [(ngModel)]="colfilter[column.columnDef]"
                            [disabled]="(column.iscolumnSearch === undefined || column.iscolumnSearch===null) ? 'false' : !column.iscolumnSearch "
                (selectionChange)= "columnFilter($event.value, column.columnDef)">
                  <mat-option value="">All</mat-option>
                  <mat-option *ngFor="let val of column.selectFilterArr" [value]="val.value">{{val.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>-->
          </th>
          <td mat-cell *matCellDef="let row" [style.min-width]="column.width" [ngClass]="{
              'text-center': column.align === 'center',
              'text-left': !column.align || column.align === 'left',
              'text-right': column.align === 'right'
            }" style="word-wrap: break-word">
            <span *ngIf="column.filter">
              <span *ngIf="column.isHyperlink && !column.isToolTip">
                <ng-container *ngIf="column.isTarget; then thenTemplate; else elseTemplate"></ng-container>
                <ng-template #thenTemplate>
                  <div innerHTML="{{
                      getProperty(row, column.columnDef)
                        | formatCell: column.filter
                    }}"></div>
                </ng-template>
                <ng-template #elseTemplate>
                  <a class="pointer" (click)="hyperLinkCall(row, column.columnDef)"
                    [id]="getProperty(row, column.columnDef)">
                    <span>{{
                      getProperty(row, column.columnDef)
                      | formatCell: column.filter
                      }}</span>
                  </a>
                </ng-template>
              </span>
              <ng-container *ngIf="!column.isHyperlink && !column.isHtml">
                <span *ngIf="column.isToolTip" matTooltipClass="example-tooltip-red"
                  matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                  {{
                  getProperty(row, column.columnDef)
                  | formatCell: column.filter
                  }}</span>
                <span *ngIf="!column.isToolTip">{{
                  getProperty(row, column.columnDef) | formatCell: column.filter
                  }}</span>
              </ng-container>
              <span *ngIf="!column.isHyperlink && column.isHtml">
                <span [innerHTML]="getProperty(row, column.columnDef) | safeHTML"></span>
                <!--{{getProperty(row, column.columnDef) | formatCell:column.filter}}-->
              </span>
            </span>

            <ng-container *ngIf="column.hasMultiData">
              <div *ngFor="let col of column.colList">
                <span *ngIf="col.isHyperlink && !col.isToolTip">
                  <a class="pointer" (click)="hyperLinkCall(row, col.col)" [id]="getProperty(row, column.columnDef)">
                    <span>{{ getProperty(row, col.col) }}</span>
                  </a>
                </span>
                <span *ngIf="col.isToolTip && !col.isHyperlink" matTooltipClass="example-tooltip-red"
                  matTooltip="{{ getProperty(row, col.isToolTipCol) }}">{{ getProperty(row, col.col) }}</span>
                <span *ngIf="col.isHyperlink && col.isToolTip" matTooltipClass="example-tooltip-red"
                  matTooltip="{{ getProperty(row, col.isToolTipCol) }}">
                  <a class="pointer" [id]="getProperty(row, column.columnDef)" (click)="hyperLinkCall(row, col.col)">
                    <span>{{ getProperty(row, col.col) }}</span>
                  </a>
                </span>
                <span *ngIf="col.isProgressCntrl">
                  <span class="mat-elevation-z2" [ngClass]="{
                      'progress-s':
                        getProperty(row, 'warn') > getProperty(row, col.col),
                      'progress-d':
                        getProperty(row, 'warn') <= getProperty(row, col.col)
                    }">
                    <span class="percent-b">{{ getProperty(row, col.col) }}%</span>
                    <span class="p-bar" [style.width]="getProperty(row, col.col) + '%'"></span>
                  </span>
                </span>
                <span *ngIf="col.isColoredCntrl">
                  <span *ngIf="getProperty(row, col.col) == '-'">{{
                    getProperty(row, col.col)
                    }}</span>
                  <span class="label" *ngIf="getProperty(row, col.col) != '-'"
                    [ngStyle]="{ 'background-color': col.color }">{{ getProperty(row, col.col) }}</span>
                </span>

                <span *ngIf="col.isfaicon">
                  <i class="{{ col.class }}" [ngStyle]="{ color: col.color }"></i>
                </span>

                <span *ngIf="col.filter">
                  {{ getProperty(row, col.col) | formatCell: col.filter }}
                </span>

                <span *ngIf="col.isAddingText">
                  {{ col.addingText }}{{ getProperty(row, col.col) }}
                </span>

                <span *ngIf="col.img && col.imgPath">
                  <img [src]="col.imgPath + 'generic.svg'" *ngIf="getProperty(row, col.col) === null" alt="generic"
                    class="{{ col.class }}" />
                  <img [src]="col.imgPath + getProperty(row, col.col)" *ngIf="getProperty(row, col.col) !== null"
                    alt="logo" class="{{ col.class }}" />
                </span>

                <span *ngIf="
                    !col.isHyperlink &&
                    !col.isProgressCntrl &&
                    !col.isColoredCntrl &&
                    !col.isToolTip &&
                    !col.filter &&
                    !col.isfaicon &&
                    !col.isAddingText &&
                    !col.img &&
                    !col.baseImg
                  ">
                  {{ getProperty(row, col.col) }}
                </span>
              </div>
            </ng-container>

            <ng-container *ngIf="column.isProgressCntrl">
              <div class="mat-elevation-z2" [ngClass]="{
                  'progress-s':
                    getProperty(row, 'warn') >
                    getProperty(row, column.columnDef),
                  'progress-d':
                    getProperty(row, 'warn') <=
                    getProperty(row, column.columnDef)
                }">
                <span class="percent-b">{{ getProperty(row, column.columnDef) }}%</span>
                <div class="p-bar" [style.width]="getProperty(row, column.columnDef) + '%'"></div>
              </div>
            </ng-container>

            <ng-container *ngIf="column.baseImg">
              <span *ngIf="getProperty(row, column.columnDef)">
                <img [src]="getProperty(row, column.columnDef) | safe" alt="" class="vendor-logo" />
              </span>
            </ng-container>
            <!-- <ng-container *ngIf="column.isDynamicCntrl">
                  <div [innerHTML]="getProperty(row, column.columnDef)"></div>
                </ng-container> -->

            <ng-container *ngIf="column.isColoredCntrl">
              <ng-container *ngIf="
                  column.isCustomText;
                  then thenTemplate;
                  else elseTemplate
                "></ng-container>
              <ng-template #thenTemplate>
                <!-- <span *ngIf="getProperty(row, column.columnDef) == '-'">{{getCustomText(getProperty(row, column.columnDef))}}</span> -->
                <span class="label" *ngIf="getProperty(row, column.columnDef) != '-'"
                  [ngStyle]="getCustomColor(getProperty(row, column.columnDef))">{{ getCustomText(getProperty(row,
                  column.columnDef)) }}</span>
              </ng-template>
              <ng-template #elseTemplate>
                <span *ngIf="getProperty(row, column.columnDef) == '-'">{{
                  getProperty(row, column.columnDef)
                  }}</span>
                <span class="label" *ngIf="getProperty(row, column.columnDef) != '-'"
                  [ngStyle]="{ 'background-color': column.color }">{{ getProperty(row, column.columnDef) }}</span>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="column.statusicon">
              <img class="vendor-logo" [src]="getIconPath(row, column)" [matTooltip]="getIconTooltip(row, column)"
                [alt]="getIconTooltip(row, column)" [ngClass]="column.statusIconClass" />
              <div>
                <span hidden [innerHTML]="getIconTooltip(row, column) | safeHTML"></span>
              </div>
            </ng-container>

            <ng-container *ngIf="!column.filter">
              <ng-container *ngIf="column.icon">
                <ng-container>
                  <span>
                    <ng-container *ngFor="let icon of column.iconList">
                      <mat-icon [ngClass]="[icon.class]" *ngIf="
                          getProperty(row, column.columnDef) === icon.value
                        ">
                        {{ icon._icon }}
                      </mat-icon>
                    </ng-container>
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngFor="let img of column.conditionList">
                <img class="{{ img.class }}" [src]="img._img" [alt]="img.value"
                  *ngIf="getProperty(row, column.columnDef) === img.value" [matTooltip]="img.displaytext" />
                <div>
                  <span hidden *ngIf="getProperty(row, column.columnDef) === img.value"
                    [innerHTML]="getProperty(row, column.columnDef)"></span>
                </div>
              </ng-container>
              <ng-container *ngIf="column.img && column.imgPath">
                <img [src]="column.imgPath + 'generic.svg'" alt="generic" *ngIf="
                    getProperty(row, column.columnDef) === null ||
                    getProperty(row, column.columnDef) === undefined
                  " class="{{ column.class }}" />

                <img [src]="column.imgPath + getProperty(row, column.columnDef)"
                  [alt]="getProperty(row, column.columnDef)" title="{{
                    column.isToolTipCol === undefined
                      ? ''
                      : getProperty(row, column.isToolTipCol)
                  }}" *ngIf="
                    getProperty(row, column.columnDef) !== null &&
                    getProperty(row, column.columnDef) !== undefined
                  " class="{{ column.class }}" />
                <div>
                  <span hidden [innerHTML]="getProperty(row, column.columnDef)"></span>
                </div>
              </ng-container>
              <!-- <ng-container *ngIf="column.isAddingText">
                    {{ tableOptions.addingText}}
                  </ng-container> -->
              <ng-container *ngIf="column.isAddingText">
                {{ column.addingText }} {{ getProperty(row, column.columnDef) }}
              </ng-container>
              <span *ngIf="column.isHyperlink && !column.isToolTip">
                <ng-container *ngIf="column.isTarget; then thenTemplate; else elseTemplate"></ng-container>
                <ng-template #thenTemplate>
                  <div [innerHTML]="getProperty(row, column.columnDef)"></div>
                </ng-template>
                <ng-template #elseTemplate>
                  <a class="pointer" (click)="hyperLinkCall(row, column.columnDef)"
                    [id]="getProperty(row, column.columnDef)">
                    <span>{{ getProperty(row, column.columnDef) }}</span>
                  </a>
                </ng-template>
              </span>
              <span *ngIf="column.isToolTip && !column.isHyperlink" matTooltipClass="example-tooltip-red"
                matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                {{ getProperty(row, column.columnDef) }}
              </span>
              <span *ngIf="column.isHyperlink && column.isToolTip" matTooltipClass="example-tooltip-red"
                matTooltip="{{ getProperty(row, column.isToolTipCol) }}">
                <a class="pointer" (click)="hyperLinkCall(row, column.columnDef)"
                  [id]="getProperty(row, column.columnDef)">
                  <span>{{ getProperty(row, column.columnDef) }}</span>
                </a>
              </span>
              <span *ngIf="column.isfaicon">
                <i class="{{ column.class }}" [ngStyle]="{ color: column.color }"></i>{{ getProperty(row,
                column.columnDef) }}
              </span>
              <ng-container *ngIf="
                  !column.icon &&
                  !column.img &&
                  !column.isHyperlink &&
                  !column.baseImg &&
                  !column.isProgressCntrl &&
                  !column.isColoredCntrl &&
                  !column.hasMultiData &&
                  !column.statusicon &&
                  !column.isfaicon &&
                  !column.isHtml &&
                  !column.isAddingText &&
                  !column.isToolTip
                ">
                {{ getProperty(row, column.columnDef) }}
              </ng-container>
              <span *ngIf="!column.isHyperlink && column.isHtml">
                <span [innerHTML]="getProperty(row, column.columnDef) | safeHTML"></span>
                <!--{{getProperty(row, column.columnDef) | formatCell:column.filter}}-->
              </span>
            </ng-container>
            <ng-container *ngIf="column.isHoverInfo">
              <span>
                <mat-icon (mouseenter)="hoverInfo(row, column.columnDef)" class="sm-icon" [icIcon]="ic.icInfo">
                </mat-icon>
              </span>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="action" *ngIf="tableOptions.showAction" stickyEnd>
          <th mat-header-cell *matHeaderCellDef style="word-wrap: break-word"
            [ngClass]="tableOptions.showAction === true ? 'color1' : 'color2'">
            <!--<button id="gbtn{{tableOptions.title}}" mat-icon-button [matMenuTriggerFor]="gactionMenu" [disabled]="selection.selected.length === 0"
              aria-label="global action with a menu">
              <mat-icon [icIcon]="icMoreVert"></mat-icon>
            </button>-->Action
            <mat-menu #gactionMenu="matMenu">
              <ng-container *ngFor="let item of tableOptions.actionMenuItems">
                <a id="ganch{{ item.text }}" *ngIf="item.isGlobal" mat-menu-item
                  (click)="globalActionCall(selection.selected, item)">
                  <!--<mat-icon *ngIf=item.icon [icIcon]="item.icon"></mat-icon>-->
                  <mat-icon *ngIf="!item.icon" [icIcon]="icArrowForwardIos"></mat-icon>
                  <span>{{ item.text }}</span>
                </a>
              </ng-container>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element" style="word-wrap: break-word"
            [ngClass]="tableOptions.showAction == true ? 'color1' : 'color2'">
            <button id="btn{{ tableOptions.title }}" mat-icon-button color="primary" [matMenuTriggerFor]="actionMenu"
              aria-label="row action with a menu" [disabled]="selection.selected.length > 0">
              <mat-icon [icIcon]="icMoreVert"></mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <ng-container *ngFor="let item of tableOptions.actionMenuItems">
                <a id="anch{{ item.text }}" *ngIf="
                    item.callback && !item.hideLocal && isShow(element, item)
                  " mat-menu-item (click)="actionCall(element, item)">
                  <!--<mat-icon *ngIf=item.icon [icIcon]="item.icon"></mat-icon>-->
                  <mat-icon *ngIf="!item.icon" [icIcon]="icArrowForwardIos"></mat-icon>
                  <span>{{ item.text }}</span>
                </a>
              </ng-container>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (click)="rowClick(row, i)"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
      </table>
      <p class="ml-2 py-2" *ngIf="!dataSource.data || dataSource.data.length === 0">
        <span *ngIf="tableOptions.loading">Please wait!</span>
        <span *ngIf="
            !tableOptions.loading &&
            tableOptions.pageTotal === 0 &&
            !tableOptions.nomsg
          ">
          No data available!
        </span>
        <span *ngIf="
            !tableOptions.loading &&
            tableOptions.pageTotal === 0 &&
            tableOptions.nomsg
          ">
          {{ tableOptions.nomsg }}
        </span>
      </p>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="
        tableOptions.loading &&
        dataSource &&
        dataSource.data &&
        dataSource.data.length > 25
      ">
    </mat-progress-bar>
    <mat-paginator class="mr20 t-paginator" *ngIf="tableOptions.pagination && tableOptions.pageTotal < 10000"
      [pageSize]="tableOptions.pageSize" showFirstLastButtons [length]="tableOptions.pageTotal" #paginator
      [pageSizeOptions]="tableOptions.pageOptions" (page)="pageChanged($event)"></mat-paginator>
    <mat-paginator class="mr20 t-paginator" *ngIf="tableOptions.pagination && tableOptions.pageTotal > 9999"
      [pageSize]="tableOptions.pageSize" [length]="tableOptions.pageTotal" #paginator
      [pageSizeOptions]="tableOptions.pageOptions" (page)="pageChanged($event)"></mat-paginator>
  </mat-card-content>
</mat-card>

<s-modal id="hoverDialog">
  <mat-card style="width: 400px !important" *ngIf="hoverDialogData">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="subheading-2 m-0" fxFlex="auto">Break Up</h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button"
        (click)="modalService.close('hoverDialog')">
        <mat-icon [icIcon]="ic.icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-card-content>
      <table class="table w-full popup-card">
        <thead>
          <tr>
            <!-- <th class="body-2 text-secondary">NO.</th> -->
            <th class="body-2 text-secondary">PRODUCT</th>
            <!-- <th class="body-2 text-secondary">PRICE PER UNIT</th> -->
            <th class="body-2 text-secondary">QUANTITY</th>
            <th class="body-2 text-secondary text-right">PRICE</th>
            <th class="body-2 text-secondary text-right">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- <td class="py-1 border-b">{{i + 1}}</td> -->
            <td class="py-1 border-b">
              <span class="mr-1 float-left">
                <img [src]="hoverDialogData.productimage" class="h-4" alt="" />
              </span>
              <span class="body-2">{{ hoverDialogData.name }}</span>
            </td>
            <!-- <td class="py-1 border-b">₹{{prod.ratecard.sprice}}</td> -->
            <td>20</td>
            <td class="py-1 border-b font-medium text-right">
              ₹{{ hoverDialogData.quantity1 }}
            </td>
            <td class="py-1 border-b font-medium">
              <button mat-button="">
                <mat-icon [icIcon]="ic.icMoreVert"></mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
</s-modal>
