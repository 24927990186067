<div style="width: 100%; display: flex; align-items: center; justify-content: flex-end;">
  <button class="btn bg-gradient-secondary btn-apply checkout_btn" mat-icon-button
    styleSheetFile="assets/print-style.scss" ngxPrint color="primary" [useExistingCss]="true" printSectionId="to-print">
    <mat-icon [icIcon]="ic.icPrint"></mat-icon>
  </button>
</div>


<div id="to-print">
  <div *ngIf="isEnglish" id="invoice-POS">
    <div class="flex flex-col items-center mb-3" id="top">
      <div>
        <div class="logo">
          <img src="assets/img/logo-new.jpg">
          <!--logocr.jpg-->
        </div>
      </div>
      <div class="info" *ngIf="authCurrentEntity && authService.currentUser.usertype !== 'CONSUMER'">
        <p>{{authCurrentEntity.name}}</p>
        <p>GSTIN:- {{authCurrentEntity.taxidentifier}}</p>
        <p>FSSAI NO:- {{authCurrentEntity.fssainumber}}</p>
        <p>ADDRESS:- {{authCurrentEntity.address}}, {{authCurrentEntity.state}}, {{authCurrentEntity.pincode}}</p>
        <p class="text-xsm py-1">Please call store at {{authCurrentEntity.mobile}} for home delivery</p>
      </div>
      <div class="info" *ngIf="invoiceDetails && authService.currentUser.usertype === 'CONSUMER' ">
        <p>{{invoiceDetails.name}}</p>
        <p>GSTIN:- {{invoiceDetails.taxidentifier}}</p>
        <p>FSSAI NO:- {{invoiceDetails.fssainumber}}</p>
        <p>ADDRESS:- {{invoiceDetails.address}}, {{invoiceDetails.state}}, {{invoiceDetails.pincode}}</p>
        <p class="text-xsm py-1">Please call store at {{invoiceDetails.mobile}} for home delivery</p>
      </div>
    </div>
    <div class="mid flex flex-col items-center mb-1">
      <div class="info2">TAX INVOICE</div>
      <p><i>Duplicate Copy</i></p>
    </div>
    <div id="bot" class="flex flex-col items-center mb-2">
      <div id="table">
        <table>
          <tr class="tabletitle">
            <td>&nbsp;</td>
            <td>
              <p>Bill No.: <b>{{order?.orderID}}</b></p>
            </td>
            <td>
              <p>Bill Date: <b>{{ returnOrderTime(order?.ordertime) }}</b></p>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr class="tabletitle">
            <td>&nbsp;</td>
            <td>
              <p>Coupon Code: <b>{{order?.promoCode}}</b></p>
            </td>
            <!--send and recieve coupon code-->
            <td>
              <p>Cashier: <b>{{ authService.currentUser.firstName }}</b></p>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
        <table>
          <tr class="tabletitle">
            <td>
              &nbsp;
            </td>
            <td class="text-left">
              <p>HSN Particulars</p>
            </td>
            <td class="text-center">
              <p>Qty</p>
            </td>
            <td class="text-right">
              <p>Rate</p>
            </td>
            <td class="text-right">
              <p>Value</p>
            </td>
            <td>&nbsp;</td>
          </tr>
          <ng-container *ngFor="let cart of newCartFormat; index as i">
            <tr class="service bb" *ngIf="cart?.gst > 0">
              <td><span>{{i+1}}</span></td>
              <td colspan="5" class="itemtext text-left">CGST: {{cart?.gst/2 | number:'1.0-2'}}%, SGST: {{cart?.gst/2 |
                number:'1.0-2'}}%, CESS: {{cart?.cess| number:'1.0-2'}}%</td>
            </tr>
            <tr *ngFor="let prod of cart.products">
              <td>&nbsp;</td>
              <td class="itemtext text-left">{{prod.productName}}</td>
              <td class="itemtext text-center">{{prod.orderQuantity}}</td>
              <td class="itemtext text-right">₹ {{prod.unitCost | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹ {{(prod.orderQuantity * prod.unitCost) | number:'1.0-2'}}</td>
              <td>&nbsp;</td>
            </tr>
          </ng-container>
        </table>
        <table>
          <tr class="tabletitle">
            <td class="text-center">
              <p>Items: {{order?.orderedProducts?.length}}</p>
            </td>
            <td class="text-center">
              <p>Qty: {{ itemQty }}</p>
              <!--fix here-->
            </td>
            <td class="text-center">
              <p>Total: ₹{{ total| number:'1.0-2' }}</p>
              <!--fix here-->
            </td>
          </tr>
        </table>
        <table>
          <tr class="tabletitle" *ngIf="order?.storeDiscount != 0">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2 *ngIf="authService.isBUD()">UD Discount</h2>
              <h2 *ngIf="authService.isBUDC()">Staff Discount</h2>
              <h2 *ngIf="authService.isConsumer()">Store Discount</h2>
            </td>
            <td class="payment text-right">
              <h2>- ₹{{ order?.storeDiscount | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="order?.couponDiscount != 0">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Coupon Discount</h2>
            </td>
            <td class="payment text-right">
              <h2>- ₹{{ order?.couponDiscount | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="order?.globalDiscount?.amt> 0">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>{{order?.globalDiscount?.desc}}</h2>
            </td>
            <td class="payment text-right">
              <h2>- ₹{{ order?.globalDiscount?.amt | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle" *ngIf="order?.deliveryCharges">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Delivery Charges</h2>
            </td>
            <td class="payment text-right">
              <h2>+ ₹{{ order?.deliveryCharges | number:'1.0-2' }}</h2>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
          <tr class="tabletitle">
            <td>&nbsp;</td>
            <td colSpan="2" style="text-align: right; padding-right: 2px;" class="Rate">
              <h2>Net Amount</h2>
            </td>
            <td class="payment text-right">
              <h2 *ngIf="order?.amountPaid < 0; else pricing">₹0
              </h2>
              <ng-template #pricing>
                <h2>₹{{ order?.amountPaid | number:'1.0-2'}}</h2>
              </ng-template>
            </td>
            <td style="width: 3pt">&nbsp;</td>
          </tr>
        </table>
        <table class="taxTable">
          <tr>
            <td>GST breakup details</td>
          </tr>
        </table>
        <table>
          <tr class="tabletitle">
            <td class="text-center">&nbsp;</td>
            <td class="text-center">GST IND</td>
            <td class="text-center">Taxable Amount</td>
            <td class="text-center">CGST</td>
            <td class="text-center">SGST</td>
            <td class="text-center">CESS</td>
            <td class="text-center">Total Amount</td>
            <td class="text-center">&nbsp;</td>
          </tr>
          <ng-container *ngFor="let tax of newCartFormat; let i = index;">
            <tr class="" *ngIf="tax.taxP > 0">
              <td class="itemtext">&nbsp;</td>
              <td class="itemtext text-center">{{i + 1}}</td>
              <td class="itemtext text-right">₹{{tax.baseP | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹{{tax.taxP/2 | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹{{tax.taxP/2 | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹{{tax.cessP | number:'1.0-2'}}</td>
              <td class="itemtext text-right">₹{{tax.baseP + tax.taxP + tax.cessP | number:'1.0-2'}}</td>
              <td class="itemtext">&nbsp;</td>
            </tr>
          </ng-container>
          <tr class="service bt">
            <td class="itemtext">&nbsp;</td>
            <td class="itemtext text-center">T:</td>
            <td class="itemtext text-right">₹{{sumOf('baseP') | number:'1.0-2'}}</td>
            <td class="itemtext text-right">₹{{sumOf('taxP') / 2 | number:'1.0-2'}}</td>
            <td class="itemtext text-right">₹{{sumOf('taxP') / 2 | number:'1.0-2'}}</td>
            <td class="itemtext text-right">₹{{sumOf('cessP') | number:'1.0-2'}}</td>
            <td class="itemtext text-right">₹{{sumOf('baseP') + sumOf('taxP') + sumOf('cessP') | number:'1.0-2'}}</td>
            <td class="itemtext">&nbsp;</td>
          </tr>
        </table>
        <table>
          <tr class="tabletitle" *ngIf="order && order.saveAmt > 0">
            <td class="text-center">
              <h2>* * Saved Rs. {{order?.saveAmt | number:'1.0-2'}}/- on MRP * *</h2>
            </td>
          </tr>
        </table>
        <p class="legal text-center">** Thank you ** </p>
      </div>
    </div>
  </div>
</div>