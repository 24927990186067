<div class="footer" fxLayout="row">
  <div
    class="justify-center"
    fxFlex="auto"
    fxLayout="row"
    fxLayoutAlign="start center"
    vexContainer
  >
    <!--<a class="action"
       color="primary"
       fxFlex="none"
       href="//1.envato.market/0vkRO"
       id="get-vex"
       mat-raised-button>
      <ic-icon [icon]="icShoppingBasket" class="ltr:mr-2 rtl:ml-2" inline="true" size="18px"></ic-icon>
      <span>Get Vex (Angular 10+)</span>
    </a>
    <div class="ltr:ml-4 rtl:mr-4" fxHide fxShow.gt-sm>
      Vex - Angular 10+ Material Design Admin Template - Save 100s of hours designing and coding
    </div>-->
    <p>Copyrights@2022, BPCL</p>
  </div>
</div>
